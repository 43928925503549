import { ACSpinner } from '@antech-consulting/components';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { PENDING, REJECTED } from 'features/orders/constants/TableConstants';
import useTable from 'hooks/useTable';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'store/store';
import { transformtextCaseToCamelCase } from 'utils/helpers';

import PageHeader from '../../../../components/PageHeader/PageHeader';
import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import Tastygator from '../../../../images/Tastygator.png';
import { getStatusColor } from '../../../../utils/getColorStatus';
import { Product } from '../../../product/services/getProducts';
import { useGetOrderByIdQuery } from '../../services';
import { OrderDetailViewProps } from '../../services/getOrderById';
import ChangeOrderStatus from '../ChangeOrderStatus/ChangeOrderStatus';
import styles from './OrderDetailViewTable.module.scss';
import orderDetailViewTableSettings from './orderDetailViewTableSettings';

const headerStyle = {
  marginBottom: '10px',
};

export type OrderProduct = {
  basePrice: number;
  baseProductPrice: string;
  discountedPrice: number;
  document: string;
  product: Product;
  productPrice: string;
  quantity: number;
  total: string;
  uuid: string;
};

const OrderDetailViewTable: FC<OrderDetailViewProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const tableSettings = useTable(orderDetailViewTableSettings);
  const { data: order, isFetching, isLoading, isSuccess } = useGetOrderByIdQuery(orderId);

  const { data } = useGetPartnerApplicationSettingsQuery();

  const productsImageQuality = data?.dashboardImageQualitySettings?.productList;

  const roles = store.getState().auth.roles;
  const system = roles[ROLE_SYSTEM as never];

  const orderTableData = useMemo(() => {
    return order?.items?.map((item) => {
      const { document, product } = item;
      const imageUrl = document && document[productsImageQuality];
      return {
        rawData: item,
        render: {
          ...item,
          product: (
            <div className={styles.product}>
              <img
                alt={product?.name}
                className={styles.productImage}
                src={imageUrl ? imageUrl : Tastygator}
              />
              <div>{product?.name}</div>
            </div>
          ),
        },
      };
    });
  }, [order?.items]);

  const headerTitle = `${t('orders.order')}` + `: #${order?.code}`;
  const headerSecondaryText = `${t('orders.created')}` + `: ${order?.created}`;
  const statusColor = getStatusColor(order?.status);

  return isSuccess ? (
    <>
      <div>
        <PageHeader
          primaryText={headerTitle}
          secondaryText={headerSecondaryText}
          style={headerStyle}
        />
        <div className={styles.status}>
          <div>
            {t('orders.status')}:&nbsp;
            <span className={styles[`${statusColor}`]}>
              {t(`orders.${transformtextCaseToCamelCase(order.status)}`)}
            </span>
          </div>
          <div className={styles.seen}>
            {order?.status === PENDING && (
              <div>
                {t('orders.seen')}:&nbsp;
                <span>
                  {order?.seen === true ? <>{t('orders.isSeen')}</> : <>{t('orders.notSeen')}</>}
                </span>
              </div>
            )}
          </div>
          <div>
            {order?.status === REJECTED && (
              <div>
                {t('orders.rejectMessage')}:&nbsp;
                <span>{order?.rejectMessage || FORM_ELEMENT_EMPTY}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {system && <ChangeOrderStatus isFetching={isFetching} isLoading={isLoading} order={order} />}
      <StyledACTable
        {...tableSettings}
        isLoading={!isSuccess}
        pagesCount={1}
        rows={orderTableData}
        tableFooter={false}
      />
    </>
  ) : (
    <ACSpinner />
  );
};

export default OrderDetailViewTable;
