import { ACInput } from '@antech-consulting/components';
import {
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_PASSWORD,
} from 'constants/FormConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { authTextField } from 'features/auth/constants/StyleConstants';
import { useTranslation } from 'react-i18next';

import { style } from '../../../../constants/StyleConstants';
import { FormDataType } from '../../constants/LoginFormConstants';

const LoginForm = ({ errors, formData, handleChange }: BaseFormProps<FormDataType>) => {
  const { t } = useTranslation();

  const { email, password } = formData;

  return (
    <div>
      <div style={authTextField}>
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          errorMessage={errors?.email}
          fullWidth
          hasError={!!errors?.email}
          id={FORM_ELEMENT_EMAIL}
          label={t('loginForm.email')}
          name={FORM_ELEMENT_EMAIL}
          onChange={handleChange}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputAuth}
          type={FORM_ELEMENT_EMAIL}
          value={email}
        />
      </div>
      <div style={authTextField}>
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          errorMessage={errors?.password}
          fullWidth
          hasError={!!errors?.password}
          id={FORM_ELEMENT_PASSWORD}
          label={t('loginForm.password')}
          name={FORM_ELEMENT_PASSWORD}
          onChange={handleChange}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputAuth}
          type={FORM_ELEMENT_PASSWORD}
          value={password}
        />
      </div>
    </div>
  );
};
export default LoginForm;
