/* eslint-disable indent */
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { useGetProductsStockQuery } from 'features/product/services';
import { Product } from 'features/product/services/getProducts';
import { RequestBody, ResponseBody } from 'features/product/services/getProductsStock';
import { uniqBy } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const maximumAvailability = 999;

export type Category = {
  checked: boolean;
  name: string;
  uuid: string;
};

export type ProductFilters = {
  categories: Category[];
  code: string;
  name: string;
};

const initialFiltersState: ProductFilters = {
  categories: [],
  code: '',
  name: '',
};

type useProductFiltersType = {
  filters: ProductFilters;
  initialFilters: ProductFilters;
  setFilters: Dispatch<SetStateAction<ProductFilters>>;
};

type ApiResult = {
  data: Product[] | undefined;
  isFetching: boolean;
  isSuccess: boolean;
  refetch: () => QueryActionCreatorResult<
    QueryDefinition<RequestBody, BaseQueryFn, string, ResponseBody, string>
  >;
};

type ProductAvailabilityType = {
  productList: Product[];
  updateAvailability: (item: Product) => void;
  updateProductList: (products: Product[]) => void;
};

const useProducts = (): [ApiResult, useProductFiltersType, ProductAvailabilityType] => {
  const initialFilters = useRef<ProductFilters>(initialFiltersState);
  const initialProducts = useRef<Product[]>([]);

  const [filters, setFilters] = useState<ProductFilters>(initialFiltersState);
  const [productList, setProductList] = useState<Product[]>([]);
  const { id } = useParams();

  const { data, isFetching, isSuccess, refetch } = useGetProductsStockQuery({ id, filters });

  const handleOldAvailability = (uuid: string) =>
    initialProducts.current?.find(({ uuid: initialUuid }) => uuid === initialUuid)?.available;

  const handleAvailability = (newAvailability: number) => {
    if (newAvailability > maximumAvailability) return maximumAvailability;
    else if (!newAvailability) return 0;
    else return newAvailability;
  };

  const updateProductList = (newProducts: Product[]) => setProductList(newProducts);

  const updateAvailability = useCallback(
    (product: Product) => {
      setProductList((prevState) =>
        uniqBy(
          [
            {
              ...product,
              oldAvailability: handleOldAvailability(product.uuid),
              available: handleAvailability(product.available),
            },
            ...prevState,
          ],
          'uuid',
        ),
      );
    },
    [productList],
  );

  useEffect(() => {
    if (!filters.categories.length && data) {
      initialProducts.current = data?.content;
      initialFilters.current = { ...initialFilters.current, categories: data.productsCategories };
      setFilters((prevState) => ({ ...prevState, categories: initialFilters.current.categories }));
    }
  }, [data]);

  const productFilters = {
    filters,
    initialFilters: initialFilters.current,
    setFilters,
  };

  const productAvailability = {
    productList,
    updateProductList,
    updateAvailability,
  };

  const api = { data: data?.content, isSuccess, isFetching, refetch };

  return [api, productFilters, productAvailability];
};

export default useProducts;
