import { FORM_ELEMENT_CODE, FORM_ELEMENT_RATING } from 'constants/FormConstants';
import {
  TABLE_ELEMENT_COMMENT,
  TABLE_ELEMENT_CREATED_BY,
  TABLE_INITIAL_ROWS_NUMBER,
} from 'constants/TableConstants';
import { TABLE_ELEMENT_PICKED_UD_AT } from 'features/place/constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const tableOrderReviewsSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'placeReviewsTable',
  columns: [
    {
      title: 'place.order',
      field: FORM_ELEMENT_CODE,
    },
    {
      title: 'place.createdBy',
      field: TABLE_ELEMENT_CREATED_BY,
    },
    {
      title: 'place.pickedUpAt',
      field: TABLE_ELEMENT_PICKED_UD_AT,
    },
    {
      title: 'place.rating',
      field: FORM_ELEMENT_RATING,
    },
    {
      title: 'place.comment',
      field: TABLE_ELEMENT_COMMENT,
    },
  ],
  disableSorting: {
    columns: [
      FORM_ELEMENT_CODE,
      TABLE_ELEMENT_CREATED_BY,
      TABLE_ELEMENT_PICKED_UD_AT,
      FORM_ELEMENT_RATING,
      TABLE_ELEMENT_COMMENT,
    ],
  },
};

export default tableOrderReviewsSettings;
