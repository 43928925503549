import { ACButton } from '@antech-consulting/components';
import { faBan, faFloppyDisk, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { MainContentRefContext } from '../../App/MainApp';
import styles from './FormStickyFooter.module.scss';

const FontAwesomeIconStyle = {
  marginRight: '5px',
};
const DeleteButtonStyle = {
  style: { color: 'rgb(220,38,38)' },
};

const SaveButtonStyle = {
  backgroundColor: 'var(--text-secondary)',
};

const DiscardButtonStyle = {
  color: 'var(--text-secondary)',
};

const FormStickyFooter = ({
  additionalActionButtons,
  onDelete,
  onDeleteText,
  onDiscard,
  onDiscardText,
  onSubmit,
  onSubmitText,
  shouldDisableSubmitButton,
}: any) => {
  let style = {};
  !onDelete && !additionalActionButtons && (style = { justifyContent: 'flex-end' });

  const { mainContentRef, setHasFooterActionsContainer } = useContext(MainContentRefContext);

  // TODO This condition is because tests
  // if (!mainContentRef?.current) {
  //    return null
  // }

  let domElement = document.body;

  if (mainContentRef?.current) {
    domElement = mainContentRef.current;
  }

  useEffect(() => {
    if (setHasFooterActionsContainer !== undefined) {
      setHasFooterActionsContainer(true);
    }
    return () => {
      if (setHasFooterActionsContainer !== undefined) {
        setHasFooterActionsContainer(false);
      }
    };
  }, [setHasFooterActionsContainer]);

  return createPortal(
    <footer className={styles.footer}>
      <div className={styles.footerContent} style={style}>
        <div className={styles.leftActionButtons}>
          {!!onDelete && (
            <div className={styles.leftActionButtons}>
              <ACButton onClick={onDelete} style={DeleteButtonStyle.style} variant={'text'}>
                <FontAwesomeIcon icon={faTrashCan} style={FontAwesomeIconStyle} />
                {onDeleteText}
              </ACButton>
            </div>
          )}
          {!!additionalActionButtons && (
            <div className={styles.leftActionButtons}>
              {additionalActionButtons?.map((b: typeof ACButton) => {
                return b;
              })}
            </div>
          )}
        </div>
        <div className={styles.actionButtons}>
          {!!onDiscard && (
            <ACButton onClick={onDiscard} style={DiscardButtonStyle} variant={'outlined'}>
              <FontAwesomeIcon icon={faBan} style={FontAwesomeIconStyle} />
              {onDiscardText}
            </ACButton>
          )}
          {!!onSubmit && (
            <ACButton
              disabled={shouldDisableSubmitButton}
              onClick={onSubmit}
              style={SaveButtonStyle}
              variant={'contained'}
            >
              <FontAwesomeIcon icon={faFloppyDisk} style={FontAwesomeIconStyle} />
              {onSubmitText}
            </ACButton>
          )}
        </div>
      </div>
    </footer>,
    domElement,
  );
};

export default memo(FormStickyFooter);
