import { TableSettingsType } from 'hooks/useTable';

const statisticsTableProductCategoriesSettings: TableSettingsType = {
  initialRowsNumber: 10,
  allItemsCount: 5,
  pagesCount: 1,
  dataTestId: 'tablePlaceStatistics',
  columns: [
    {
      title: 'productCategory.nameColumn',
      field: 'name',
      initialEditValue: 'Name',
    },
    {
      title: 'placeStatistics.sold',
      field: 'sold',
      initialEditValue: 'Sold',
    },
    {
      title: 'placeStatistics.percentage',
      field: 'percentage',
      initialEditValue: 'Percentage',
    },
  ],
};

export default statisticsTableProductCategoriesSettings;
