import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PLACE } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PlaceStatisticsResponse, PlaceStatisticsParameters>({
    query: ({ from, id, interval, to }) =>
      `/statistics/place/${id}?from=${from}&to=${to}&group-interval=${interval}`,
    transformResponse: (response: PlaceStatisticsResponse) => ({
      groupInterval: response.groupInterval,
      ordersRevenue: response.ordersRevenue,
      ordersPerStatus: response.ordersPerStatus,
      ordersDistribution: response.ordersDistribution,
      productCategoriesSoldPercentageInOrders: response.productCategoriesSoldPercentageInOrders,
      productCategoriesSoldPercentageInOrdersItems:
        response.productCategoriesSoldPercentageInOrdersItems,
      productsSoldPercentageInOrders: response.productsSoldPercentageInOrders,
      productsSoldPercentageInOrdersItems: response.productsSoldPercentageInOrdersItems,
      uniqueUsersInOrders: response.uniqueUsersInOrders,
      uniqueProductsSoldInOrders: response.uniqueProductsSoldInOrders,
      bestSellingProducts: response.bestSellingProducts,
      savedKilograms: response.savedKilograms,
      averageOrderSize: response.averageOrderSize,
    }),
    providesTags: [TAG_PLACE],
  });

export type ProductCategoriesSoldPercentage = {
  nameEn?: string;
  nameHr?: string;
  nameSr?: string;
  percentage: number;
  sold: number;
};

export type PlaceStatisticsParameters = {
  from?: string;
  id?: string;
  interval?: string;
  to?: string;
};

export type OrdersRevenue = {
  gross: number;
  intervalValue: string;
  net: number;
};

export type OrdersDistribution = {
  hour: number;
  totalCreated: number;
  totalPickedUp: number;
};

export type ItemsSoldPercentage = {
  name: string;
  percentage: number;
  sold: number;
};

export type UniqueUsersInOrders = {
  ordersCount: number;
  percentage: number;
  usersCount: number;
};

export type BestSellingProducts = {
  count: number;
  name: string;
};

export type IntervalValue = {
  ACCEPTED: number;
  CANCELED: number;
  PAYMENT_FAILED: number;
  PENDING: number;
  PICKED_UP: number;
  READY: number;
  REFUNDED: number;
  REJECTED: number;
};

export type OrdersPerStatus = {
  intervalValue: IntervalValue;
};

export type PlaceStatisticsResponse = {
  averageOrderSize: number;
  bestSellingProducts: BestSellingProducts[];
  groupInterval: string;
  ordersDistribution: OrdersDistribution[];
  ordersPerStatus: OrdersPerStatus;
  ordersRevenue: OrdersRevenue[];
  productCategoriesSoldPercentageInOrders: {
    count: number;
    productCategoriesSoldPercentage: ItemsSoldPercentage[];
  };
  productCategoriesSoldPercentageInOrdersItems: {
    count: number;
    productCategoriesSoldPercentage: ItemsSoldPercentage[];
  };
  productsSoldPercentageInOrders: {
    count: number;
    productsSoldPercentage: ItemsSoldPercentage[];
  };
  productsSoldPercentageInOrdersItems: {
    count: number;
    productsSoldPercentage: ItemsSoldPercentage[];
  };
  savedKilograms: number;
  uniqueProductsSoldInOrders: [];
  uniqueUsersInOrders: UniqueUsersInOrders;
};
