import { FORM_ELEMENT_PRODUCT } from 'constants/FormConstants';
import { TABLE_ELEMENT_TOTAL, TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import {
  BASE_PRODUCT_PRICE,
  PRODUCT_PRICE,
  QUANTITY,
} from 'features/orders/constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const orderDetailViewTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'orderDetailTable',
  columns: [
    {
      title: 'orders.productColumn',
      field: FORM_ELEMENT_PRODUCT,
    },
    {
      title: 'product.basePriceColumn',
      field: BASE_PRODUCT_PRICE,
    },
    {
      title: 'product.discountedPriceColumn',
      field: PRODUCT_PRICE,
    },
    {
      title: 'product.quantityColumn',
      field: QUANTITY,
    },
    {
      title: 'product.totalColumn',
      field: TABLE_ELEMENT_TOTAL,
    },
  ],
};

export default orderDetailViewTableSettings;
