import { FORM_ELEMENT_COUNT, FORM_ELEMENT_NAME } from 'constants/FormConstants';
import {
  TABLE_ALL_ITEMS_COUNT,
  TABLE_ELEMENT_GROSS,
  TABLE_ELEMENT_NET,
  TABLE_INITIAL_ROWS_NUMBER,
  TABLE_PAGES_COUNT,
} from 'constants/TableConstants';
import { TFunction } from 'i18next';

export const getTableNumberOrdersConstants = (
  t: TFunction<'translation', undefined, 'translation'>,
) => {
  return {
    initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
    allItemsCount: TABLE_ALL_ITEMS_COUNT,
    pagesCount: TABLE_PAGES_COUNT,
    dataTestId: 'numberOfOrdersPerPlace',
    columns: [
      {
        title: t('companyStatistics.name'),
        field: FORM_ELEMENT_NAME,
      },
      {
        title: t('companyStatistics.count'),
        field: FORM_ELEMENT_COUNT,
      },
    ],
  };
};

export const getTablePlacesConstants = (t: TFunction<'translation', undefined, 'translation'>) => {
  return {
    initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
    allItemsCount: TABLE_ALL_ITEMS_COUNT,
    pagesCount: TABLE_PAGES_COUNT,
    dataTestId: 'placesRevenue',
    columns: [
      {
        title: t('companyStatistics.name'),
        field: FORM_ELEMENT_NAME,
      },
      {
        title: t('companyStatistics.gross'),
        field: TABLE_ELEMENT_GROSS,
      },
      {
        title: t('companyStatistics.net'),
        field: TABLE_ELEMENT_NET,
      },
    ],
  };
};
