import { ACTabs } from '@antech-consulting/components';
import { TAB_FIRST, TAB_ZERO } from 'constants/TabsConstants';
import AppUserBasicInfo from 'features/appUser/components/AppUserBasicInfo/AppUserBasicInfo';
import AppUserOrders from 'features/appUser/components/AppUserOrders/AppUserOrders';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';

const AppUserDetailView = () => {
  const { id: appUserId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || TAB_ZERO);

  const handleChange = (tabId: number | string) => {
    setDefaultValueTab(tabId);
    navigate(`/app-user/${appUserId}?value=${tabId}`);
  };

  const tabs = [
    {
      label: t('appUsers.userDetailView'),
      id: TAB_ZERO,
      content: <AppUserBasicInfo />,
    },
    {
      label: t('appUsers.appUserOrders'),
      id: TAB_FIRST,
      content: <AppUserOrders />,
    },
  ];

  return (
    <ACTabs defaultTab={defaultValueTab} onChange={handleChange} style={style.ACTabs} tabs={tabs} />
  );
};

export default AppUserDetailView;
