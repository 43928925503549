import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { TAG_PRODUCTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<DocumentDTO, string>({
    query: (id) => `/documents/download-links?ids=${id}`,
    providesTags: [TAG_PRODUCTS],
  });
