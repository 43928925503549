import { FORM_ELEMENT_EMAIL } from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import { TableSettingsType } from 'hooks/useTable';
import { AddCircle } from 'iconsax-react';

import { FORM_ELEMENT_ROLE } from './PlaceContants';

const { ADD } = tableActionTypes;

const placeEmployeesTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'placeTable',
  actions: [
    {
      redirect: 'modal',
      header: true,
      name: ADD,
      icon: <AddCircle data-testid='addButton' />,
      tooltip: 'place.add',
    },
  ],
  columns: [
    {
      title: 'place.userEmail',
      field: FORM_ELEMENT_EMAIL,
    },
    {
      title: 'place.userRole',
      field: FORM_ELEMENT_ROLE,
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_EMAIL, FORM_ELEMENT_ROLE] },
};

export default placeEmployeesTableSettings;
