/* eslint-disable no-undef */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import api from '../services/api';
import auth from './Auth';
import globalData from './GlobalData';
import language from './Language';
import notification from './Notification';
import setting from './Setting';
import theme from './Theme';

const reducers = combineReducers({
  api: api.reducer,
  notification,
  auth,
  language,
  theme,
  setting,
  globalData,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'language', 'theme', 'setting', 'globalData'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  // replace for combine reducers
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware);
    return middlewares;
  },
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export { persistor };
