import { ACButton } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { style } from 'constants/StyleConstants';
import {
  formDataInitialState,
  FormDataType,
} from 'features/user/constants/ChangePasswordFormConstants';
import { validationSchema } from 'features/user/constants/ChangePasswordSchema';
import { useChangePasswordMutation } from 'features/user/services';
import { ChangePasswordRequest } from 'features/user/services/changePassword';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useTranslation } from 'react-i18next';

import PasswordBaseForm from '../PasswordBaseForm/PasswordBaseForm';
import styles from './PasswordTab.module.scss';

const Password = () => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const [changePassword] = useChangePasswordMutation();

  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );

  const onSubmit = async () => {
    const ChangePasswordRequestDTO: ChangePasswordRequest = {
      ...formData,
    };
    try {
      await changePassword(ChangePasswordRequestDTO).unwrap();
      displaySuccess({ key: 'partnerUser.changePassword' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <>
      <PasswordBaseForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <div className={styles.buttonsWrapper}>
        <ACButton
          className={styles.buttonWrapper}
          dataTestid='ButtonSubmitPassword'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
        >
          {t('password.saveButton')}
        </ACButton>
      </div>
    </>
  );
};
export default Password;
