import api from '../../../services/api';
import approveCompanyRequest from './approveCompanyRequest';
import createCompanyRequest from './createCompanyRequest';
import deleteCompanyRequest from './deleteCompanyRequest';
import getCompanyRequestById from './getCompanyRequestById';
import getCompanyRequests from './getCompanyRequests';
import rejectCompanyRequest from './rejectCompanyRequest';
import requestAdditionalInfo from './requestAdditionalInfo';
import updateCompanyRequest from './updateCompanyRequest';

export const companyRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCompanyRequest: createCompanyRequest(build),
    deleteCompanyRequest: deleteCompanyRequest(build),
    updateCompanyRequest: updateCompanyRequest(build),
    getCompanyRequests: getCompanyRequests(build),
    getCompanyRequestById: getCompanyRequestById(build),
    approveCompanyRequest: approveCompanyRequest(build),
    rejectCompanyRequest: rejectCompanyRequest(build),
    requestAdditionalInfo: requestAdditionalInfo(build),
  }),
  overrideExisting: false,
});

export const {
  useApproveCompanyRequestMutation,
  useCreateCompanyRequestMutation,
  useDeleteCompanyRequestMutation,
  useLazyGetCompanyRequestByIdQuery,
  useLazyGetCompanyRequestsQuery,
  useRejectCompanyRequestMutation,
  useRequestAdditionalInfoMutation,
  useUpdateCompanyRequestMutation,
} = companyRequestApi;
