import { OptionsType } from 'constants/TypeConstants';
import { Company } from 'features/company/services/getCompanies';

import { PlaceCategories } from '../services/getPlaceCategories';
import { WorkingHoursType } from '../services/updatePlace';

export type FormDataTypePlace = {
  apartment?: number | string;
  beneficiaryAccount?: string;
  category: string | OptionsType | PlaceCategories;
  chosenLocation?: any;
  city: OptionsType | string;
  company?: OptionsType | string | Company;
  country?: OptionsType | string;
  description: string;
  document?: string;
  fee?: string | number;
  floor?: number | string;
  fridayFrom?: string;
  fridayTo?: string;
  lastOrderAt?: string;
  lastStockChangeAt?: string;
  latitude: number;
  longitude: number;
  minimumOrderAmount?: number | string;
  minimumOrderAmountRoot?: number | string;
  mondayFrom?: string;
  mondayTo?: string;
  name: string;
  owner?: string;
  phoneNumber?: string;
  rating?: string;
  saturdayFrom?: string;
  saturdayTo?: string;
  showWeight?: boolean;
  street: string;
  streetNumber: string;
  sundayFrom?: string;
  sundayTo?: string;
  thursdayFrom?: string;
  thursdayTo?: string;
  tuesdayFrom?: string;
  tuesdayTo?: string;
  uuid: string;
  website?: string;
  wednesdayFrom?: string;
  wednesdayTo?: string;
  workingHours?: WorkingHoursType;
  zipCode: string;
};

export const formDataInitialState: FormDataTypePlace = {
  uuid: '',
  name: '',
  description: '',
  street: '',
  streetNumber: '',
  city: '',
  zipCode: '',
  longitude: 0,
  latitude: 0,
  category: '',
  company: '',
  country: '',
  document: '',
  floor: 0 || '',
  apartment: 0 || '',
  phoneNumber: '',
  website: '',
  minimumOrderAmount: '',
  beneficiaryAccount: '',
  rating: '',
  workingHours: {},
  minimumOrderAmountRoot: '',
  fee: '',
  lastStockChangeAt: '',
  lastOrderAt: '',
  showWeight: false,
};

export const CATEGORIES = ['Restaurant', 'Grocery', 'Bakery', 'Caffe'];

export const MAP_STYLE = 'mapbox://styles/mapbox/streets-v11';
export type FormDataEmployeeType = {
  email?: string;
  role: string;
};

export const formDataInitialStateEmployee = {
  email: '',
  role: '',
};
