import { ACDrawer } from '@antech-consulting/components';
import {
  ROLE_PARTNER_MANAGER,
  ROLE_PARTNER_OWNER,
  ROLE_PARTNER_REGISTERED,
  ROLE_SYSTEM,
  ROLE_TERMINAL,
} from 'constants/RolesConstants';
import {
  Additem,
  AddSquare,
  Bag,
  Building,
  ClipboardText,
  Diagram,
  DocumentCopy,
  People,
  Profile2User,
  Setting2,
  Shop,
  Task,
  User,
} from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/store';

import { style } from '../../constants/StyleConstants';
import Logo from '../../images/logo.png';
import Tasty from '../../images/tasty.png';
import styles from './SideBar.module.scss';

export type SideBarProps = {
  isMobileOpen: boolean;
  setIsMobileOpen: (prev: boolean) => void;
};

const logoDimension = 40;

const Sidebar = ({ isMobileOpen, setIsMobileOpen }: SideBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const roles = store.getState().auth.roles;

  const system = roles[ROLE_SYSTEM as never];
  const partnerManager = roles[ROLE_PARTNER_MANAGER as never];
  const partnerOwner = roles[ROLE_PARTNER_OWNER as never];
  const partnerRegistered = roles[ROLE_PARTNER_REGISTERED as never];
  const terminal = roles[ROLE_TERMINAL as never];

  const homeNavigate = () => {
    if (system || partnerManager || partnerOwner) {
      navigate('/places');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } else {
      navigate('/company-request/add');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
  };

  const handleMobileOpen = () => {
    setIsMobileOpen(false);
  };

  const SideBarListRegister = [
    {
      title: t('sideBar.addCompanyRequest'),
      icon: <AddSquare className={styles.icon} />,
      match: '/company-request/add',
    },
    {
      title: t('sideBar.companyRequest'),
      icon: <Additem className={styles.icon} />,
      match: '/company-requests',
    },
  ];

  const SideBarListPartner = [
    {
      title: t('sideBar.partnerUsers'),
      icon: <Profile2User className={styles.icon} />,
      match: '/partner-users',
    },
    {
      title: t('sideBar.company'),
      icon: <Building className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addCompanyRequest'),
          icon: <AddSquare className={styles.icon} />,
          match: '/company-request/add',
        },
        {
          title: t('sideBar.companyRequest'),
          icon: <Additem className={styles.icon} />,
          match: '/company-requests',
        },
        {
          title: t('sideBar.companyList'),
          icon: <Task className={styles.icon} />,
          match: '/companies',
        },
      ],
    },
    {
      title: t('sideBar.place'),
      icon: <Shop className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addPlace'),
          icon: <AddSquare className={styles.icon} />,
          match: '/place/add',
        },
        {
          title: t('sideBar.placeList'),
          icon: <Task className={styles.icon} />,
          match: '/places',
        },
      ],
    },
    {
      title: t('sideBar.product'),
      icon: <Bag className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addProduct'),
          icon: <AddSquare className={styles.icon} />,
          match: '/product/add',
        },
        {
          title: t('sideBar.productList'),
          icon: <Task className={styles.icon} />,
          match: '/choose-place',
        },
      ],
    },
    {
      title: t('sideBar.productCategory'),
      icon: <Bag className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addProductCategoryRequest'),
          icon: <AddSquare className={styles.icon} />,
          match: '/product-category-request/add',
        },
        {
          title: t('sideBar.productCategoryRequests'),
          icon: <DocumentCopy className={styles.icon} />,
          match: '/product-category-requests',
        },
        {
          title: t('sideBar.productCategory'),
          icon: <Task className={styles.icon} />,
          match: '/product-categories',
        },
      ],
    },
    {
      title: t('sideBar.orders'),
      match: '/orders',
      icon: <ClipboardText className={styles.icon} />,
    },
  ];

  const SideBarListAdmin = [
    {
      title: t('sideBar.users'),
      icon: <People className={styles.icon} />,
      items: [
        {
          title: t('sideBar.partnerUsers'),
          icon: <Profile2User className={styles.icon} />,
          match: '/partner-users',
        },
        {
          title: t('sideBar.appUsers'),
          icon: <Profile2User className={styles.icon} />,
          match: '/app-users',
        },
      ],
    },
    {
      title: t('sideBar.company'),
      icon: <Building className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addCompanyRequest'),
          icon: <AddSquare className={styles.icon} />,
          match: '/company-request/add',
        },
        {
          title: t('sideBar.companyRequest'),
          icon: <Additem className={styles.icon} />,
          match: '/company-requests',
        },
        {
          title: t('sideBar.addCompany'),
          icon: <AddSquare className={styles.icon} />,
          match: '/company/add',
        },
        {
          title: t('sideBar.companyList'),
          icon: <Task className={styles.icon} />,
          match: '/companies',
        },
      ],
    },
    {
      title: t('sideBar.place'),
      icon: <Shop className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addPlace'),
          icon: <AddSquare className={styles.icon} />,
          match: '/place/add',
        },
        {
          title: t('sideBar.placeList'),
          icon: <Task className={styles.icon} />,
          match: '/places',
        },
      ],
    },
    {
      title: t('sideBar.product'),
      icon: <Bag className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addProduct'),
          icon: <AddSquare className={styles.icon} />,
          match: '/product/add',
        },
        {
          title: t('sideBar.productList'),
          icon: <Task className={styles.icon} />,
          match: '/choose-place',
        },
      ],
    },
    {
      title: t('sideBar.productCategory'),
      icon: <Bag className={styles.icon} />,
      items: [
        {
          title: t('sideBar.addProductCategoryRequest'),
          icon: <AddSquare className={styles.icon} />,
          match: '/product-category-request/add',
        },
        {
          title: t('sideBar.productCategoryRequests'),
          icon: <DocumentCopy className={styles.icon} />,
          match: '/product-category-requests',
        },
        {
          title: t('sideBar.addProductCategory'),
          match: '/product-category/add',
          icon: <AddSquare className={styles.icon} />,
        },
        {
          title: t('sideBar.productCategory'),
          icon: <Task className={styles.icon} />,
          match: '/product-categories',
        },
      ],
    },
    {
      title: t('sideBar.orders'),
      match: '/orders',
      icon: <ClipboardText className={styles.icon} />,
    },
    {
      title: t('sideBar.admin'),
      icon: <User className={styles.icon} />,
      items: [
        {
          title: t('sideBar.statistics'),
          icon: <Diagram className={styles.icon} />,
          match: '/system-statistics',
        },
        {
          title: t('sideBar.maintenance'),
          icon: <Setting2 className={styles.icon} />,
          match: '/maintenance',
        },
        {
          title: t('sideBar.inventoryList'),
          icon: <Task className={styles.icon} />,
          match: '/inventories',
        },
      ],
    },
  ];
  return (
    <ACDrawer
      className={styles.sideBar}
      closeHandle={handleMobileOpen}
      content={
        (system && SideBarListAdmin) ||
        (partnerManager && SideBarListPartner) ||
        (partnerOwner && SideBarListPartner) ||
        (partnerRegistered && SideBarListRegister) ||
        (terminal && SideBarListPartner)
      }
      header={({ isShrink }) => (
        <div className={styles.header} onClick={homeNavigate} role='sidebarHeader'>
          {isShrink && <img className={styles.logo} height={40} src={Tasty} width={100} />}
          <img height={logoDimension} src={Logo} width={logoDimension} />
        </div>
      )}
      isMobileOpen={isMobileOpen}
      navListClassName={styles.navWrapper}
      style={style.ACDrawer}
      subNavItemClassName={styles.navItem}
    />
  );
};

export default Sidebar;
