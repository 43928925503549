import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  password: {
    isRequired: true,
    min: 8,
  },
  email: {
    isRequired: true,
    isEmail: true,
  },
};
