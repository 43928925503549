import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { SortProps } from 'constants/TypeConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Countries, void>({
    query: () => '/countries',
    transformResponse: (response: Countries) => {
      return {
        ...response,
        content: response?.content,
        currentPage: response?.pageable?.pageNumber,
        totalElements: response?.totalElements,
        totalPages: response?.totalPages,
      };
    },
    keepUnusedDataFor: 0,
  });

export type Countries = {
  content?: Country[];
  currentPage?: number;
  label?: string;
  pageable?: {
    pageNumber?: number;
  };
  totalElements?: number;
  totalPages?: number;
  uuid?: string;
  value?: string;
};
export type Country = {
  createdAt: string;
  currency: string;
  dateFormat: string;
  flag: string;
  fullName: string;
  isoCurrency: string;
  isoName: string;
  minimumOrderAmount?: number;
  name?: string;
  shortName: string;
  uuid: string;
  value?: string;
  visible: boolean;
};

export type CountryOption = {
  createdAt: string;
  currency: string;
  dateFormat: string;
  flag: string;
  fullName: string;
  isoCurrency: string;
  isoName: string;
  label?: string;
  name?: string;
  shortName: string;
  uuid: string;
  value?: string;
  visible: boolean;
};

export type CountryParameters = {
  ids?: string;
  page?: number;
  size?: number;
  sort?: SortProps;
};
