import { ACSpinner } from '@antech-consulting/components';
import { useCreateProductCategoryRequestMutation } from 'features/productCategoryRequest/services';
import { NewProductCategoryRequest } from 'features/productCategoryRequest/services/createProductCategoryRequest';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/productCategoryRequest/components/BaseForm/BaseForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/productCategoryRequest/constants/FormConstants';
import { validationSchema } from '../../features/productCategoryRequest/constants/ProductCategoryRequestSchema';

const AddProductCategoryRequest = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [createProductCategoryRequest, { isLoading }] = useCreateProductCategoryRequestMutation();

  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const productCategoryRequestAddDTO: NewProductCategoryRequest = { ...trimmedData };
    try {
      await createProductCategoryRequest(productCategoryRequestAddDTO).unwrap();
      navigate('/product-category-requests');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'productCategoryRequests.createProductCategoryRequest' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = useCallback(() => {
    navigate('/product-category-requests');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }, []);

  return !isLoading ? (
    <>
      <PageHeader primaryText={t('productCategoryRequests.titleCreate')} />
      <BaseForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddProductCategoryRequest;
