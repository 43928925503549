import { ACTimeLine } from '@antech-consulting/components';
import { ArrowUp } from 'iconsax-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { transformToTimeFormat } from '../../../../utils/helpers';
import {
  ACCEPTED,
  activityColor,
  CANCELED,
  dotStyle,
  PAYMENT_FAILED,
  PENDING,
  PICKED_UP,
  READY,
  REJECTED,
} from '../../constants/TableConstants';
import { OrderProps } from '../../services/getOrderById';
import styles from './OrderActivity.module.scss';

const arrowUpStyle = {
  width: '22px',
  height: 'auto',
  color: 'var(--text-transparent)',
  paddingBottom: '25px',
};

const OrderTimeLine: FC<OrderProps> = ({ order }) => {
  const { t } = useTranslation();
  const { acceptedAt, createdAt, readyAt, status, updatedAt } = order;
  const labelText =
    (status === REJECTED && t('orders.rejectedAt')) ||
    (status === CANCELED && t('orders.canceledAt')) ||
    (status === PAYMENT_FAILED && t('orders.paymentFailedAt'));
  const statusUnsuccessful =
    status === CANCELED || status === REJECTED || status === PAYMENT_FAILED;
  const inactiveOrderStyle = {
    color: activityColor.inactive,
  };

  const successfullOrder = [
    {
      labelRightRender: (
        <div
          style={{
            color: status === PICKED_UP ? activityColor.active : 'var(--text-transparent)',
          }}
        >
          <div className={styles.title}>{t('orders.pickedUpAt')} </div>
          <div>{status === PICKED_UP && transformToTimeFormat(updatedAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor:
              status === PICKED_UP ? activityColor.active : 'var(--text-transparent)',
          }}
        />
      ),
    },
    {
      labelRightRender: (
        <div className={styles.label}>
          <div className={styles.title}> {t('orders.readyAt')}</div>
          <div>{(status === READY || status === PICKED_UP) && transformToTimeFormat(readyAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor:
              status === PICKED_UP ? 'var(--text-secondary)' : 'var(--text-transparent)',
          }}
        />
      ),
    },
    {
      labelRightRender: (
        <div className={styles.label}>
          <div className={styles.title}>{t('orders.acceptedAt')}</div>
          <div> {acceptedAt && transformToTimeFormat(acceptedAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor:
              status === READY || status === PICKED_UP
                ? 'var(--text-secondary)'
                : 'var(--text-transparent)',
          }}
        />
      ),
    },
    {
      labelRightRender: (
        <div className={styles.label}>
          <div className={styles.title}>{t('orders.createdAt')}</div>
          <div> {createdAt && transformToTimeFormat(createdAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor:
              status !== PENDING ? 'var(--text-secondary)' : 'var(--text-transparent)',
          }}
        />
      ),
    },
  ];

  const unsuccessfulOrder = [
    {
      labelRightRender: (
        <div style={inactiveOrderStyle}>
          <div className={styles.title}>{labelText}</div>
          <div>{transformToTimeFormat(updatedAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor: activityColor.inactive,
          }}
        />
      ),
    },
    {
      labelRightRender: (
        <div
          style={{
            color: 'var(--text-transparent)',
            marginRight: '15px',
          }}
        >
          <div className={styles.title}>{t('orders.createdAt')}</div>
          <div>{createdAt && transformToTimeFormat(createdAt)}</div>
        </div>
      ),
      dotRender: (
        <div
          style={{
            ...dotStyle,
            backgroundColor: 'var(--text-secondary)',
          }}
        />
      ),
    },
  ];

  const timeLine = () => {
    switch (status) {
      case PENDING:
        return successfullOrder.slice(-1);
      case ACCEPTED:
        return successfullOrder.slice(-2);
      case READY:
        return successfullOrder.slice(-3);
      default:
        return successfullOrder;
    }
  };

  const wrapperHeight = () => {
    if (status === PENDING) {
      return '80px';
    } else if (statusUnsuccessful || status === ACCEPTED) {
      return '300px';
    } else {
      return '450px';
    }
  };

  const timeLineStyle = {
    line: {
      height: statusUnsuccessful || status === ACCEPTED ? '80%' : '70%',
      backgroundColor: activityColor.border,
    },
    labelRightWrapper: {
      width: statusUnsuccessful || status === ACCEPTED ? '200px' : '180px',
    },
    wrapper: {
      height: wrapperHeight(),
      padding: '20px 20px 20px 10px',
    },
    dotLineWrapp: {
      justifyContent: 'center',
    },
  };

  return (
    <div className={styles.timeLine}>
      <ArrowUp style={arrowUpStyle} />
      <ACTimeLine
        content={statusUnsuccessful ? unsuccessfulOrder : timeLine()}
        sides='right'
        style={timeLineStyle}
      />
    </div>
  );
};

export default OrderTimeLine;
