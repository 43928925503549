import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { FormHeader } from 'components/FormHeader/FormHeader';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import {
  convertACAutocompleteEventValueFromStringToBoolean,
  getYesNoOptionsArray,
} from 'features/maintenance/helpers';
import { t } from 'i18next';

import {
  FORM_ELEMENT_CURRENT_ANDROID_VERSION,
  FORM_ELEMENT_DASHBOARD_MAINTENANCE_MODE,
  FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY,
  FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE,
  FORM_ELEMENT_MAINTENANCE_MODE_TITLE,
  FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION,
  FORM_ELEMENT_MOBILE_MAINTENANCE_MODE,
  FORM_ELEMENT_PLACE_DETAIL,
  FORM_ELEMENT_PLACE_LIST,
  FORM_ELEMENT_PRODUCT_CATEGORY_ALL,
  FORM_ELEMENT_PRODUCT_DETAIL,
  FORM_ELEMENT_PRODUCT_LIST,
  FormDataType,
} from '../../../constants/PartnerAppSettingsFormConstants';
import styles from './BaseForm.module.scss';

const BaseForm = ({ errors, formData, handleChange }: BaseFormProps<FormDataType>) => {
  const {
    currentAndroidVersion,
    dashboardMaintenanceMode,
    firebaseTokenValidity,
    maintenanceModeMessage,
    maintenanceModeTitle,
    minimalRequiredAndroidVersion,
    mobileMaintenanceMode,
    placeDetail,
    placeList,
    productCategoryAll,
    productDetail,
    productList,
  } = formData;

  return (
    <>
      <Divider />
      <form>
        <FormHeader
          primaryText={t('maintenance.partnerAppSettings.mobileVersion')}
          secondaryText={t('maintenance.partnerAppSettings.mobileVersionSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.currentAndroidVersion}
            fullWidth
            hasError={!!errors?.currentAndroidVersion}
            id={FORM_ELEMENT_CURRENT_ANDROID_VERSION}
            label={t('maintenance.partnerAppSettings.currentAndroidVersion')}
            name={FORM_ELEMENT_CURRENT_ANDROID_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={currentAndroidVersion}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.minimalRequiredAndroidVersion}
            fullWidth
            hasError={!!errors?.minimalRequiredAndroidVersion}
            id={FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION}
            label={t('maintenance.partnerAppSettings.minimalRequiredAndroidVersion')}
            name={FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={minimalRequiredAndroidVersion}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.partnerAppSettings.maintenanceMode')}
          secondaryText={t('maintenance.partnerAppSettings.maintenanceModeSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='mobileMaintenanceMode'
            errorMessage={errors?.mobileMaintenanceMode}
            fullWidth
            hasError={!!errors?.mobileMaintenanceMode}
            label={t('maintenance.partnerAppSettings.mobileMaintenanceMode')}
            name={FORM_ELEMENT_MOBILE_MAINTENANCE_MODE}
            onChange={(e) => {
              handleChange(convertACAutocompleteEventValueFromStringToBoolean(e));
            }}
            options={getYesNoOptionsArray()}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={mobileMaintenanceMode ? t('maintenance.yes') : t('maintenance.no')}
          />
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='dashboardMaintenanceMode'
            errorMessage={errors?.dashboardMaintenanceMode}
            fullWidth
            hasError={!!errors?.dashboardMaintenanceMode}
            label={t('maintenance.partnerAppSettings.dashboardMaintenanceMode')}
            name={FORM_ELEMENT_DASHBOARD_MAINTENANCE_MODE}
            onChange={(e) => {
              handleChange(convertACAutocompleteEventValueFromStringToBoolean(e));
            }}
            options={getYesNoOptionsArray()}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={dashboardMaintenanceMode ? t('maintenance.yes') : t('maintenance.no')}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.partnerAppSettings.message')}
          secondaryText={t('maintenance.partnerAppSettings.messageSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeTitle}
            errorMessage={errors?.maintenanceModeTitle}
            fullWidth
            hasError={!!errors?.maintenanceModeTitle}
            id={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            label={t('maintenance.partnerAppSettings.maintenanceModeTitle')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeTitle}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeMessage}
            errorMessage={errors?.maintenanceModeMessage}
            fullWidth
            hasError={!!errors?.maintenanceModeMessage}
            id={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            isTextArea
            label={t('maintenance.partnerAppSettings.maintenanceModeMessage')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeMessage}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.partnerAppSettings.fireBase')}
          secondaryText={t('maintenance.partnerAppSettings.fireBaseSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={firebaseTokenValidity}
            errorMessage={errors?.firebaseTokenValidity}
            fullWidth
            hasError={!!errors?.firebaseTokenValidity}
            id={FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY}
            label={t('maintenance.partnerAppSettings.firebaseTokenValidity')}
            name={FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={firebaseTokenValidity}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.partnerAppSettings.imageQualitySettings')}
          secondaryText={t('maintenance.partnerAppSettings.imageQualitySettingsSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeList}
            errorMessage={errors?.placeList}
            fullWidth
            hasError={!!errors?.placeList}
            id={FORM_ELEMENT_PLACE_LIST}
            label={t('maintenance.partnerAppSettings.placeList')}
            name={FORM_ELEMENT_PLACE_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeDetail}
            errorMessage={errors?.placeDetail}
            fullWidth
            hasError={!!errors?.placeDetail}
            id={FORM_ELEMENT_PLACE_DETAIL}
            label={t('maintenance.partnerAppSettings.placeDetail')}
            name={FORM_ELEMENT_PLACE_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productList}
            errorMessage={errors?.productList}
            fullWidth
            hasError={!!errors?.productList}
            id={FORM_ELEMENT_PRODUCT_LIST}
            label={t('maintenance.partnerAppSettings.productList')}
            name={FORM_ELEMENT_PRODUCT_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productDetail}
            errorMessage={errors?.productDetail}
            fullWidth
            hasError={!!errors?.productDetail}
            id={FORM_ELEMENT_PRODUCT_DETAIL}
            label={t('maintenance.partnerAppSettings.productDetail')}
            name={FORM_ELEMENT_PRODUCT_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productCategoryAll}
            errorMessage={errors?.productCategoryAll}
            fullWidth
            hasError={!!errors?.productCategoryAll}
            id={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            label={t('maintenance.partnerAppSettings.productCategoryAll')}
            name={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productCategoryAll}
          />
        </div>
      </form>
    </>
  );
};

export default BaseForm;
