import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Place } from 'features/place/services/createPlace';
import { store } from 'store/store';

import {
  createQueryString,
  handleDateAndTime,
  transformSnakeCaseToText,
} from '../../../utils/helpers';

const BASE_URL = '/orders';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Orders, OrderParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const ordersResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const ordersData = ordersResponse.data as Orders;
      const countriesContent = store.getState().auth.userCountry;
      const { dateFormat } = countriesContent;
      const { language } = store.getState().language;
      const countries = store.getState().globalData.countries;
      const orders = ordersData.content.map((order: Order) => {
        const { createdAt, status, totalDiscountedPrice } = order;
        const country = countries.find((country) => country.uuid === order.country);
        const { isoCurrency } = country;
        const lastActiveAtFormatted = handleDateAndTime(createdAt, dateFormat, language);
        const { formattedDate, formattedTime } = lastActiveAtFormatted;
        const placeName = order.place as Place;
        return {
          ...order,
          createdAt: `${formattedDate}, ${formattedTime}`,
          status: transformSnakeCaseToText(status),
          totalDiscountedPrice: `${totalDiscountedPrice} ${isoCurrency}`,
          place: placeName.name,
        };
      });
      return {
        data: {
          content: orders,
          totalElements: ordersData.totalElements,
          totalPages: ordersData.totalPages,
        },
      };
    },
  });

export type OrderParameters = {
  code?: string;
  company?: string;
  from?: string;
  ids?: string;
  place?: string;
  statuses?: string;
  to?: string;
  user?: string;
};

export type Orders = {
  content: Order[];
  totalElements: number;
  totalPages: number;
};

export type Order = {
  acceptedAt: string;
  code: string;
  company: string;
  country: string;
  createdAt: string;
  createdBy: string;
  creditCardId?: string;
  note: string;
  place: string | Place;
  readyAt: string;
  seen: boolean;
  seenAt: string;
  status: string;
  totalBasePrice: number;
  totalDiscountedPrice: number | string;
  totalProductBasePrice: string;
  totalProductDiscountedPrice: string;
  updatedAt: string;
  updatedBy: string;
  uuid: string;
};
