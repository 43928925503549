import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { AddCircle, InfoCircle, UserRemove } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { transformACState } from '../../../../utils/helpers';
import { PartnerUser } from '../../services/getPartnerUsers';
import { useBlockPartnerUserMutation, useLazyGetPartnerUsersQuery } from '../../services/index';
import partnerUsersTableSettings from './partnerUsersTableSettings';

export type FilterProps = {
  searchTerm: string;
};

const TablePartnerUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const tableSettings = useTable(partnerUsersTableSettings);
  const { selectedRow, setSelectedRow } = tableSettings;
  const [isBlockModalOpen, toggleBlock] = useToggle();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const system = roles[ROLE_SYSTEM as never];

  const [fetchPartnerUsers, { data: partnerUsers, isFetching, isSuccess }] =
    useLazyGetPartnerUsersQuery();
  const [blockRequest, { isLoading }] = useBlockPartnerUserMutation();

  const handleTableStateChange = (tableState: TableStateProps<FilterProps>) => {
    fetchPartnerUsers(transformACState(tableState, TABLE_ELEMENT_CREATED_AT));
  };

  const partnerUsersTableData = useMemo(() => {
    return partnerUsers?.content?.map((partnerUser) => {
      return {
        rawData: partnerUser,
        render: partnerUser,
      };
    });
  }, [partnerUsers?.content]);

  const handleBlock = ({ rowData }: { rowData: PartnerUser }) => {
    toggleBlock();
    setSelectedRow(rowData);
  };

  const handleDetailView = ({ rowData }: { rowData: PartnerUser }) => {
    navigate(`/partner-user/${rowData?.uuid}`);
    setSelectedRow(rowData);
  };

  const blockPartnerUser = {
    icon: <UserRemove data-testid='blockButton' />,
    tooltip: {
      text: t('partnerUsers.blockUser'),
      style: {
        left: '5px',
      },
    },
    onClick: handleBlock,
  };

  const detailViewPartnerUser = {
    icon: <InfoCircle data-testid='detailViewButton' />,
    tooltip: {
      text: t('partnerUsers.detailView'),
    },
    onClick: handleDetailView,
  };

  const additionalActions = [detailViewPartnerUser, blockPartnerUser];

  const handleAddProduct = () => {
    navigate('/partner-user/add');
  };

  const additionalHeaderActions = [
    {
      title: t('partnerUsers.addUser'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('partnerUsers.addUser'),
      },
      onClick: handleAddProduct,
    },
  ];

  const handleBlockUser = async () => {
    try {
      await blockRequest(selectedRow?.uuid).unwrap();
      toggleBlock();
      displaySuccess({ key: t('blockModal.blockModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleBlock();
      displayError({ key: code });
    }
  };

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: partnerUsers?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter],
  });

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={system && additionalActions}
          allItemsCount={partnerUsers?.totalElements}
          defaultSort={DEFAULT_SORT}
          filter={filtersToDisplay}
          headerActions={system ? additionalHeaderActions : null}
          onItemClicked={system ? (rowData) => handleDetailView({ rowData }) : null}
          onTableStateChange={handleTableStateChange}
          pagesCount={partnerUsers?.totalPages}
          rows={partnerUsersTableData}
        />
      </ACLoading>
      {isBlockModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleBlock}
          disabled={isLoading}
          handleSubmit={handleBlockUser}
          header={t('blockModal.titleBlock')}
          icon={<UserRemove style={style.RedIconModal} />}
          isShow={isBlockModalOpen}
          message={t('blockModal.descriptionBlock')}
        />
      )}
    </>
  );
};
export default TablePartnerUsers;
