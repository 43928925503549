import {
  FORM_ELEMENT_ADDRESS,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_NAME,
} from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const tableArchivePlaceSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'placeTable',
  columns: [
    {
      title: 'place.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'place.address',
      field: FORM_ELEMENT_ADDRESS,
    },
    {
      title: 'place.countryColumn',
      field: FORM_ELEMENT_COUNTRY,
    },
  ],
};

export default tableArchivePlaceSettings;
