import { FormValidationType } from 'hooks/useForm';

export const validationPhoneSchema: FormValidationType = {
  phoneNumber: {
    isRequired: true,
    isPhoneNumber: true,
  },
  phoneNumberCountryCode: {
    isRequired: true,
  },
};
