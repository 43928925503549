import { ACButton } from '@antech-consulting/components';
import Config from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDown, ArrowUp } from 'react-ionicons';
import { GeolocateControl, Map, Marker } from 'react-map-gl';

import { FormDataTypePlace, MAP_STYLE } from '../../constants/FormConstants';
import { MapData } from '../BaseForm/BaseForm';
import styles from '../BaseForm/BaseForm.module.scss';

const mapStyle = {
  width: '100%',
  height: '400px',
};

export type MapFormProps = {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  formData: FormDataTypePlace;
  handleMapActions: (event: any, geolocate: boolean) => void;
  handleViewportChange?: (viewport: any) => void;
  mapData: MapData;
  mapViewport?: any;
  placeId?: string;
};

const MapForm = ({
  coordinates,
  formData,
  handleMapActions,
  handleViewportChange,
  mapData,
  mapViewport,
  placeId,
}: MapFormProps) => {
  const { t } = useTranslation();
  const [openMap, setOpenMap] = useState(!placeId && true);
  const handleOpenMap = () => {
    setOpenMap(!openMap);
  };
  const { latitude, longitude } = formData;
  const { addMarker } = mapData;

  const ButtonStyle = {
    backgroundColor: 'var(--text-silver)',
  };

  return (
    <div className={styles.mapInformation}>
      <div className={styles.mapHeader}>
        <div className={styles.mapHeaderTitle}>
          {placeId
            ? t('form.place.editMapHeaderCoordinates')
            : t('form.place.addMapHeaderCoordinates')}
        </div>
        {!openMap ? (
          <ACButton dataTestid='arrowDownButton' onClick={handleOpenMap} style={ButtonStyle}>
            <ArrowDown color='white' />
          </ACButton>
        ) : (
          <ACButton dataTestid='arrowUpButton' onClick={handleOpenMap} style={ButtonStyle}>
            <ArrowUp color='white' />
          </ACButton>
        )}
      </div>
      {openMap && (
        <Map
          attributionControl={true}
          initialViewState={mapViewport}
          interactive={true}
          mapStyle={MAP_STYLE}
          mapboxAccessToken={Config.MAP_KEY}
          onClick={(event) => handleMapActions(event, false)}
          onDrag={(event) => handleViewportChange(event?.viewState)}
          onZoom={(event) =>
            handleViewportChange({ ...event?.viewState, zoom: event?.viewState.zoom })
          }
          onZoomStart={(event) =>
            handleViewportChange({ ...event?.viewState, zoom: event?.viewState.zoom })
          }
          style={mapStyle}
          testMode={true}
          viewState={{ ...mapViewport, width: 100, height: 400 }}
        >
          <GeolocateControl position='top-left' showUserLocation={true} trackUserLocation={true} />
          {addMarker && longitude && latitude && (
            <Marker
              anchor='center'
              color='var(--text-secondary)'
              draggable={true}
              latitude={placeId ? latitude : coordinates.latitude}
              longitude={placeId ? longitude : coordinates.longitude}
              onDrag={(event) => handleMapActions(event, false)}
            />
          )}
        </Map>
      )}
    </div>
  );
};

export default MapForm;
