import { useEffect, useState } from 'react';
import { handleShowingFilters } from 'utils/helpers';

import { FilterType } from './useTable';

export type DisplayingFiltersProps = {
  filters?: FilterType[];
  isSuccess?: boolean;
  totalElements?: number;
};

const useDisplayingFilters = ({ filters, isSuccess, totalElements }: DisplayingFiltersProps) => {
  const [initialTotalElements, setInitialTotalElements] = useState(totalElements);

  useEffect(() => {
    isSuccess && setInitialTotalElements(totalElements);
  }, [isSuccess]);

  const filtersToDisplay = handleShowingFilters(initialTotalElements, filters);

  return { filtersToDisplay };
};

export default useDisplayingFilters;
