import useObjectTranslation from 'hooks/useObjectTranslation';
import useTable from 'hooks/useTable';
import { useMemo } from 'react';

import StyledACTable from '../../../../../components/StyledACTable/StyledACTable';
import { ProductCategoriesSoldPercentage } from '../../../services/getPlaceStatistics';
import statisticsTableProductCategoriesSettings from './statisticsTableProductCategoriesSettings';

const TableProductCategoriesStatistics = ({
  tableData,
}: {
  tableData: Array<ProductCategoriesSoldPercentage>;
}) => {
  const tableSettings = useTable(statisticsTableProductCategoriesSettings);

  const { getNameTranslation } = useObjectTranslation();

  const rows = useMemo(() => {
    return tableData?.map((data) => {
      const { percentage, sold } = data;
      const categoryName = getNameTranslation(data);
      const tableInformation = {
        ...tableData,
        name: categoryName,
        sold,
        percentage,
      };
      return {
        rawData: tableInformation,
        render: tableInformation,
      };
    });
  }, [tableData]);

  return (
    <StyledACTable
      {...tableSettings}
      columns={tableSettings.columns}
      rows={rows}
      statistics={true}
      tableFooter={false}
    />
  );
};
export default TableProductCategoriesStatistics;
