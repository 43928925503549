import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import {
  FORM_ELEMENT_ADDRESS,
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_LAST_ORDER_AT,
  FORM_ELEMENT_LAST_STOCK_CHANGED,
  FORM_ELEMENT_NAME,
  IS_EMPLOYED,
} from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { FORM_ELEMENT_FEE } from 'features/place/constants/PlaceContants';
import { imagePlace } from 'features/place/constants/StyleConstants';
import { DISABLE_SORTING_PLACE_COLUMNS } from 'features/place/constants/TableConstants';
import { Place } from 'features/place/services/createPlace';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { AddCircle, Archive, Diagram, ExportSquare } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { handleDefaultPlaceImage, transformACState } from '../../../../utils/helpers';
import {
  useArchivePlaceMutation,
  useChangePlacesStatusMutation,
  useLazyGetPlacesQuery,
} from '../../services/index';
import styles from './TablePlace.module.scss';
import tablePlaceSettings from './tablePlaceSettings';

export type FilterPropsPlace = {
  code: string;
  statuses: string;
};

const TablePlace = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const tableSettings = useTable(tablePlaceSettings);
  const { selectedRow, setSelectedRow } = tableSettings;
  const [fetchPlace, { data: places, isFetching, isSuccess }] = useLazyGetPlacesQuery();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [isPreparationModalOpen, togglePreparation] = useToggle();
  const [isArchivePlaceModalOpen, toggleArchive] = useToggle();

  const [archivePlace, { isLoading: isLoadingArchive }] = useArchivePlaceMutation();
  const [changeStatus, { isLoading: isLoadingStatus }] = useChangePlacesStatusMutation();
  const { cities, countries } = useAppSelector(({ globalData }) => globalData);
  const system = roles[ROLE_SYSTEM as never];
  const { data } = useGetPartnerApplicationSettingsQuery();

  const placesImageQuality = data?.dashboardImageQualitySettings?.placeList;

  const placesTableData = useMemo(() => {
    return places?.content?.map((place) => {
      const { category, document } = place;
      const imageUrl = document && document[placesImageQuality];
      const imageFile = handleDefaultPlaceImage(category, imageUrl);
      return {
        rawData: place,
        render: {
          ...place,
          name: (
            <div className={styles.place}>
              <img alt={place?.name} src={imageFile} style={imagePlace} />
              <div>{place?.name}</div>
            </div>
          ),
        },
      };
    });
  }, [places?.content]);

  const handleSetInPreparation = ({ rowData }: { rowData: Place }) => {
    setSelectedRow(rowData);
    togglePreparation();
  };

  const handleChangeStatus = async () => {
    try {
      await changeStatus({ id: selectedRow?.uuid, verificationType: 'in-preparation' }).unwrap();
      togglePreparation();
      displaySuccess({ key: 'place.putInPreparation' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      togglePreparation();
      displayError({ key: code });
    }
  };

  const handleChangeTableState = (tableState: TableStateProps<FilterPropsPlace>) => {
    const tableData = system ? { ...tableState } : { ...tableState, [IS_EMPLOYED]: 'true' };
    fetchPlace(transformACState(tableData, FORM_ELEMENT_NAME));
  };

  const handleItemClicked = ({ rowData }: { rowData: Place }) => {
    const { uuid } = rowData;
    navigate(`/place-statistics/${uuid}`);
  };

  const handleArchive = ({ rowData }: { rowData: any }) => [
    setSelectedRow(rowData),
    toggleArchive(),
  ];

  const handelArchivePlace = async () => {
    try {
      await archivePlace(selectedRow?.uuid).unwrap();
      toggleArchive();
      displaySuccess({ key: t('archiveModal.archiveModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleArchive();
      displayError({ key: code });
    }
  };

  const additionalActions = [
    {
      icon: <ExportSquare data-testid='preparationButton' />,
      tooltip: {
        text: t('place.putInPreparation'),
      },
      onClick: handleSetInPreparation,
    },
    {
      icon: <Diagram data-testid='placeStatistics' />,
      tooltip: {
        text: t('placeStatistics.title'),
      },
      onClick: handleItemClicked,
    },
    {
      title: t('archiveModal.archive'),
      icon: <Archive data-testid='archiveButton' />,
      tooltip: {
        text: t('place.archivePlace'),
        style: {
          left: '10%',
        },
      },
      onClick: handleArchive,
    },
  ];

  const additionalFilters = [
    {
      field: FORM_ELEMENT_COUNTRY,
      filterType: 'selectSingle',
      title: t('place.countryColumn'),
      filterSelectData: countries?.map(({ shortName, uuid }) => {
        return {
          value: uuid,
          render: shortName,
        };
      }),
    },
    {
      field: FORM_ELEMENT_CITY,
      filterType: 'selectSingle',
      title: t('place.cityColumn'),
      filterSelectData: cities?.map(({ name, uuid }) => {
        return {
          value: uuid,
          render: name,
        };
      }),
    },
  ];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: places?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter, ...additionalFilters],
  });

  const handleAddPlace = () => {
    navigate('/place/add');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const additionalHeaderActions = [
    {
      title: t('place.addPlace'),
      icon: <AddCircle data-testid='addPlace' />,
      tooltip: {
        text: t('place.addPlace'),
      },
      onClick: handleAddPlace,
    },
  ];

  const additionalTableColumns = system
    ? [
        {
          title: t('place.lastStockChangeAtColumn'),
          field: FORM_ELEMENT_LAST_STOCK_CHANGED,
        },
        {
          title: t('place.lastOrderAtColumn'),
          field: FORM_ELEMENT_LAST_ORDER_AT,
        },
        {
          title: t('place.feeColumn'),
          field: FORM_ELEMENT_FEE,
        },
      ]
    : [];

  const handleOnItemClicked = ({ rowData }: { rowData: Place }) => {
    const { uuid } = rowData;
    navigate(`/place/${uuid}`, { state: { previousLocation: location.pathname } });
  };

  return countries ? (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={[...tableSettings.actions, ...additionalActions]}
          allItemsCount={places?.totalElements}
          columns={[...tableSettings.columns, ...additionalTableColumns]}
          defaultSort={{
            field: FORM_ELEMENT_NAME,
            type: 'asc',
          }}
          disableSorting={DISABLE_SORTING_PLACE_COLUMNS}
          filter={filtersToDisplay}
          headerActions={additionalHeaderActions}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleChangeTableState}
          pagesCount={places?.totalPages}
          rows={placesTableData}
        />
      </ACLoading>
      {isPreparationModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={togglePreparation}
          disabled={isLoadingStatus}
          handleSubmit={handleChangeStatus}
          header={t('place.changeStatus')}
          icon={<ExportSquare style={style.RedIconModal} />}
          isShow={isPreparationModalOpen}
          message={t('place.inPreparationPlace')}
        />
      )}
      {isArchivePlaceModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleArchive}
          disabled={isLoadingArchive}
          handleSubmit={handelArchivePlace}
          header={t('archiveModal.titleArchive')}
          icon={<Archive style={style.RedIconModal} />}
          isShow={isArchivePlaceModalOpen}
          message={t('archiveModal.descriptionArchive')}
        />
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};
export default TablePlace;
