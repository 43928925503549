import TableProductCategory from '../../features/productCategory/components/TableProductCategory/TableProductCategory';

const ProductCategory = () => {
  return (
    <div>
      <TableProductCategory />
    </div>
  );
};
export default ProductCategory;
