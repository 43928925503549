import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, ResendVerifyEmailRequest>({
    query: (credentials) => ({
      url: '/verification/partner-user/verify/resend-email',
      method: 'POST',
      body: credentials,
    }),
  });

export type ResendVerifyEmailRequest = { email: string };
