import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_COMPANY_REQUEST, TAG_COMPANY_REQUESTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/company-request/${id}/approve`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_COMPANY_REQUESTS, TAG_COMPANY_REQUEST],
  });
