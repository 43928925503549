import TableProductCategoryRequest from '../../features/productCategoryRequest/components/TableProductCategoryRequest/TableProductCategoryRequest';

const ProductCategoryRequest = () => {
  return (
    <div>
      <TableProductCategoryRequest />
    </div>
  );
};
export default ProductCategoryRequest;
