import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import PageHeader from 'components/PageHeader/PageHeader';
import { authDescription } from 'features/auth/constants/StyleConstants';
import { RegistrationRequest } from 'features/auth/services/registration';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CountryCodesResponse } from 'services/getCountryCodes';
import { trimObjectValues } from 'utils/helpers';

import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { RegistrationForm } from '../../features/auth';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/auth/constants/RegistrationFormConstants';
import { validationSchema } from '../../features/auth/constants/RegistrationSchema';
import { useRegistrationMutation } from '../../features/auth/services';
import { useForm } from '../../hooks/useForm';
import { Country } from '../../services/country';

const RegistrationPartnerUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registration] = useRegistrationMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );

  const onSubmit = async () => {
    const countryId = formData?.country as Country;
    const phoneNumberCountryCode = formData?.phoneNumberCountryCode as CountryCodesResponse;
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const RegistrationRequestDTO: RegistrationRequest = {
      ...trimmedData,
      country: countryId?.uuid,
      phoneNumberCountryCode: phoneNumberCountryCode?.isoName,
    };
    try {
      await registration(RegistrationRequestDTO).unwrap();
      navigate('/partner-users');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'registration.registerUser' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = () => {
    navigate('/partner-users');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return (
    <>
      <PageHeader primaryText={t('registrationPartnerForm.title')} />
      <p style={authDescription}>{t('registrationPartnerForm.description')}</p>
      <RegistrationForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  );
};

export default RegistrationPartnerUsers;
