import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { TAG_PRODUCT_CATEGORY } from 'services/TagsConstants';

import { ProductCategory } from './getProductCategories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductCategory, ProductCategoryParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id } = _arg;
      const productCategoryResponse = await fetchWithBQ(`/product-category/${id}`);
      const productCategoryData = productCategoryResponse.data as ProductCategory;
      const documentResponse = productCategoryData?.document
        ? await fetchWithBQ(`/documents/download-links?ids=${productCategoryData?.document}`)
        : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const productCategory = {
        ...productCategoryData,
        document: documentData ? documentData[productCategoryData?.document] : '',
      };
      return {
        data: productCategory,
      };
    },
    providesTags: [TAG_PRODUCT_CATEGORY],
  });

export type ProductCategoryParameters = {
  id?: string;
  ids?: string;
};
