export const PRODUCT_CODES = {
  PR001: 'productDoNotExist',
  PR002: 'productNameExist',
  PR003: 'productCompanyNotAccessible',
  PR004: 'productDiscountedPriceGreaterThanBasePrice',
  PR005: 'productRatingOrder',
  PR006: 'productRatingExist',
  PR008: 'productImageNotExist',
  PR010: 'productActiveOrder',
  PR011: 'codeAlreadyExist',
  PR012: 'productPickupHoursMisconfigured',
};
