import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, Employee>({
    query: ({ employee, id }) => ({
      url: `/place/${id}/add-employee`,
      method: 'POST',
      body: employee,
    }),
  });

export type EmployeeBody = {
  email?: string;
  role?: string;
};

export type Employee = {
  employee: EmployeeBody;
  id: string;
};
