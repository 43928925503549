import { FORM_ELEMENT_NAME } from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import { TableSettingsType } from 'hooks/useTable';
import { Edit2, Trash } from 'iconsax-react';

const { DELETE, EDIT } = tableActionTypes;

const productCategoryTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'productCategoriesTable',
  filter: [
    {
      field: FORM_ELEMENT_NAME,
      title: 'productCategory.nameColumn',
      filterType: 'text',
    },
  ],
  actions: [
    {
      redirect: '/product-category',
      header: false,
      name: EDIT,
      icon: <Edit2 data-testid='editButton' />,
      tooltip: 'productCategory.titleUpdate',
      tooltipStyle: {
        width: '110px',
      },
    },
    {
      redirect: 'modal',
      name: DELETE,
      icon: <Trash data-testid='deleteButton' />,
      tooltip: 'productCategory.titleDelete',
      header: false,
      tooltipStyle: {
        left: -12,
        width: '110px',
      },
    },
  ],
};

export default productCategoryTableSettings;
