import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_BLOCKED_APP_USERS } from 'services/TagsConstants';

import { createQueryString } from '../../../utils/helpers';
import { AppUser, AppUsersParameters, AppUsersResponse } from './getAppUsers';

const BASE_URL = '/app-users/blocked';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<AppUsersResponse, AppUsersParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const appUsersResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const appUsersData = appUsersResponse.data as AppUsersResponse;
      const appUsers = appUsersData?.content.map((appUser: AppUser) => {
        return {
          ...appUser,
        };
      });
      return {
        data: {
          content: appUsers,
          totalElements: appUsersData?.totalElements,
          totalPages: appUsersData?.totalPages,
        },
      };
    },
    providesTags: [TAG_BLOCKED_APP_USERS],
  });
