import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { CityDTO } from 'constants/TypeConstants';
import { User } from 'features/user/services/getCurrent';
import { setUserBasicInfo } from 'store/Auth';
import { setCities, setCountries } from 'store/GlobalData';
import { store } from 'store/store';

import { Countries, Country } from './country';
import { CitiesResponse } from './getCities';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<AppData, void>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const userResponse = await fetchWithBQ('/partner-user/current');
      const userData = userResponse.data as User;
      const countriesResponse = await fetchWithBQ('/countries');
      const countriesData = countriesResponse.data as Countries;

      const matchedCountry = countriesData.content.find(
        (country) => country?.uuid === userData?.country,
      );
      const citiesResponse = await fetchWithBQ('/cities');
      const citiesData = citiesResponse.data as CitiesResponse;

      const appData = {
        countries: countriesData.content,
        cities: citiesData.content,
        userData: userData,
      };

      store.dispatch(setCities(citiesData?.content));
      store.dispatch(setCountries(countriesData?.content));

      const basicInfo = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phoneNumber,
        phoneNumberCountryCode: userData.phoneNumberCountryCode,
        email: userData.email,
        userCountry: matchedCountry,
        roles: userData.roles,
      };

      store.dispatch(setUserBasicInfo(basicInfo));

      return {
        data: {
          content: appData,
        },
      };
    },
    providesTags: ['appData'],
    keepUnusedDataFor: 0,
  });

export type AppData = {
  content: {
    cities: CityDTO[];
    countries: Country[];
    userData: User;
  };
};
