import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  ROLE_ELEMENT_MANAGER,
  ROLE_ELEMENT_OWNER,
  ROLE_ELEMENT_TERMINAL,
  ROLE_PARTNER_MANAGER,
  ROLE_PARTNER_OWNER,
  ROLE_TERMINAL,
} from 'constants/RolesConstants';
import { Place } from 'features/place/services/createPlace';
import { Places } from 'features/place/services/getPlaces';
import { Country } from 'services/country';
import { TAG_PARTNER_USER } from 'services/TagsConstants';
import { store } from 'store/store';

import { PartnerUser } from './getPartnerUsers';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<User, PartnerUserParams>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id } = _arg;
      const userResponse = await fetchWithBQ(`/partner-user/${id}`);
      const userData = userResponse.data as User;
      const nameRoles = Object.keys(userData?.roles);
      const placesIds = nameRoles.map((object) => userData?.roles[object]);
      const placeId = Object.values(placesIds).flat();
      const unverifiedPlacesResponse = await fetchWithBQ('/places/unverified?size=100');
      const unverifiedPlacesData = unverifiedPlacesResponse.data as Places;
      const unverifiedPlaces = unverifiedPlacesData?.content
        ?.map((unverfiedPlace) => placeId?.filter((id) => id === unverfiedPlace?.uuid))
        ?.filter((place) => place.length > 0)
        .flat()
        .map((item) => item);
      const verifiedPlaces = placeId.filter((value) => !unverifiedPlaces.includes(value));
      const placeIds = unverifiedPlaces?.length > 0 ? verifiedPlaces : placeId;
      const allPlaceResponse = await fetchWithBQ(
        `/places?ids=${placeIds}&statuses=ACTIVE,IN_PREPARATION,ARCHIVED`,
      );
      const allPlacesData = allPlaceResponse.data as Places;
      const role = nameRoles?.map((objectKey) => {
        const roleName =
          (objectKey === ROLE_PARTNER_OWNER && ROLE_ELEMENT_OWNER) ||
          (objectKey === ROLE_PARTNER_MANAGER && ROLE_ELEMENT_MANAGER) ||
          (objectKey === ROLE_TERMINAL && ROLE_ELEMENT_TERMINAL);
        const places = allPlacesData?.content
          ?.map((place) => {
            const matched = userData?.roles[objectKey].includes(place.uuid);
            return matched && place.name;
          })
          .filter((place) => typeof place === 'string');
        return (
          roleName && {
            name: roleName,
            places: places,
          }
        );
      });
      const finalRoles = role.filter((role) => role.places !== undefined);
      const countries = store.getState().globalData.countries;
      const matchedCountry = countries?.find(
        (country: Country) => country.uuid === userData?.country,
      );
      return {
        data: {
          ...userData,
          country: matchedCountry?.shortName,
          roles: finalRoles,
        },
      };
    },
    providesTags: [TAG_PARTNER_USER],
  });

export type PartnerUserParams = {
  id?: string;
};

export type Roles = {
  name: string;
  places: Place[] | string[];
};

export type User = Omit<PartnerUser, 'country'> & {
  country: Country | string;
  lastActiveAt: string;
  roles: Roles[];
};
