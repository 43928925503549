import { ACCard, ACSpinner } from '@antech-consulting/components';
import { StatisticsParameters } from 'features/systemStatistics/services/getSystemStatistics';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { ClipboardText, MoneyRecive } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import CardStatistics from '../../features/company/components/CardStatistics/CardStatistics';
import StatisticsBaseForm from '../../features/company/components/StatisticsBaseForm/StatisticsBaseForm';
import TableNumberOfOrdersPerPlace from '../../features/company/components/TableNumberOfOrdersPerPlace/TableNumberOfOrdersPerPlace';
import TablePlacesRevenue from '../../features/company/components/TablePlacesRevenue/TablePlacesRevenue';
import { validationSchema } from '../../features/company/constants/CompanyStatisticsSchema';
import { formDataInitialState } from '../../features/company/constants/FormConstantsStatistics';
import { useLazyGetCompanyStatisticsQuery } from '../../features/company/services';
import { CompanyStatisticsParameters } from '../../features/company/services/getCompanyStatistics';
import styles from './CompanyStatistics.module.scss';

const CompanyStatistics = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { displayError } = useDisplayNotification();
  const [fetchCompanyStatistics, { data: companyStatistics, isFetching, isSuccess }] =
    useLazyGetCompanyStatisticsQuery();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<StatisticsParameters>(formDataInitialState, validationSchema);
  const [isSubmitable, setIsSubmitable] = useState<boolean>(false);
  const [currentRange, setCurrentRange] = useState<CompanyStatisticsParameters | null>(null);

  useEffect(() => {
    formData.from && formData.to ? setIsSubmitable(true) : setIsSubmitable(false);
  }, [formData]);

  useEffect(() => {
    if (location?.search !== '') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const fromDate = urlSearchParams.get('from');
      const toDate = urlSearchParams.get('to');
      const companyStatisticsDTO: CompanyStatisticsParameters = {
        ...formData,
        id: id,
        from: fromDate,
        to: toDate,
      };
      try {
        if (id) {
          fetchCompanyStatistics(companyStatisticsDTO).unwrap();
          setCurrentRange(companyStatisticsDTO);
          setFormData(formDataInitialState);
        }
      } catch (error) {
        const {
          data: { code },
        } = error as ErrorMessage;
        displayError({ key: code });
      }
    }
  }, []);

  const onSubmit = async () => {
    const companyStatisticsDTO: CompanyStatisticsParameters = {
      ...formData,
      id: id,
      from: formData?.from,
      to: formData?.to,
    };
    try {
      if (id) {
        await fetchCompanyStatistics(companyStatisticsDTO).unwrap();
        setCurrentRange(companyStatisticsDTO);
        setFormData(formDataInitialState);
        navigate(
          `/company-statistics/${id}?from=${companyStatisticsDTO?.from}&to=${companyStatisticsDTO?.to}`,
        );
      }
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return !isFetching ? (
    <>
      <PageHeader primaryText={t('companyStatistics.title')} />
      {currentRange && isSuccess && params.get('from') !== null && (
        <p className={styles.subtitle}>
          {currentRange?.from} - {currentRange?.to}
        </p>
      )}
      <StatisticsBaseForm
        errors={errors as StatisticsParameters}
        handleChange={handleChangeFormData}
        isSubmitable={isSubmitable}
        onSubmit={() => handleSubmit(onSubmit)}
      />
      {isSuccess && params.get('from') !== null && (
        <div className={styles.form}>
          <div className={styles.cards}>
            <CardStatistics
              dataStatistics={companyStatistics?.totalOrdersCount}
              header={t('companyStatistics.totalOrdersCount')}
              icon={<ClipboardText />}
            />
            <CardStatistics
              dataStatistics={companyStatistics?.companyRevenueGross}
              header={t('companyStatistics.companyRevenueGross')}
              icon={<MoneyRecive />}
            />
            <CardStatistics
              dataStatistics={companyStatistics?.companyRevenueNet}
              header={t('companyStatistics.companyRevenueNet')}
              icon={<MoneyRecive />}
            />
          </div>
          <div className={styles.cardsTable}>
            <ACCard
              bodyChildren={
                <TableNumberOfOrdersPerPlace
                  header={t('companyStatistics.numberOfOrdersPerPlace')}
                  orders={companyStatistics?.numberOfOrdersPerPlace}
                />
              }
              className={styles.table}
            />
            <ACCard
              bodyChildren={
                <TablePlacesRevenue
                  header={t('companyStatistics.placesRevenue')}
                  places={companyStatistics?.placesRevenue}
                />
              }
              className={styles.table}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' />
  );
};
export default CompanyStatistics;
