import { ACCard } from '@antech-consulting/components';
import { ReactElement } from 'react';

import styles from './SavedKilogramsCard.module.scss';

const SavedKilogramsCard = ({
  data,
  header,
  icon,
}: {
  data: number;
  header: string;
  icon: ReactElement;
}) => {
  return (
    <ACCard
      bodyChildren={
        <div>
          <div className={styles.nameBody}>{header}</div>
          <div className={styles.description}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.descriptionBody}>{data}</div>
          </div>
        </div>
      }
      className={styles.statisticsCard}
      dataTestiId='cardStatistic'
    />
  );
};

export default SavedKilogramsCard;
