import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCT, TAG_PRODUCTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, RequestBodyType>({
    query: ({ payload }) => ({
      url: '/products/change-stock',
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: [TAG_PRODUCTS, TAG_PRODUCT],
  });

type RequestBodyType = {
  payload: {
    products: {
      amount: number;
      product: string;
    }[];
  };
};
