import api from '../../../services/api';
import approveProductCategoryRequest from './approveProductCategoryRequest';
import createProductCategoryRequest from './createProductCategoryRequest';
import deleteProductCategoryRequest from './deleteProductCategoryRequest';
import getProductCategoryRequestById from './getProductCategoryRequestById';
import getProductCategoryRequests from './getProductCategoryRequests';
import rejectProductCategoryRequest from './rejectProductCategoryRequest';
import updateProductCategoryRequest from './updateProductCategoryRequest';

export const productCategoryRequestsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductCategoryRequests: getProductCategoryRequests(build),
    approveProductCategoryRequest: approveProductCategoryRequest(build),
    rejectProductCategoryRequest: rejectProductCategoryRequest(build),
    createProductCategoryRequest: createProductCategoryRequest(build),
    getProductCategoryRequestById: getProductCategoryRequestById(build),
    updateProductCategoryRequest: updateProductCategoryRequest(build),
    deleteProductCategoryRequest: deleteProductCategoryRequest(build),
  }),
  overrideExisting: false,
});

export const {
  useApproveProductCategoryRequestMutation,
  useCreateProductCategoryRequestMutation,
  useDeleteProductCategoryRequestMutation,
  useGetProductCategoryRequestByIdQuery,
  useLazyGetProductCategoryRequestsQuery,
  useRejectProductCategoryRequestMutation,
  useUpdateProductCategoryRequestMutation,
} = productCategoryRequestsApi;
