import { ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import UserAppSettingsBaseForm from 'features/maintenance/components/UserAppMaintenance/BaseForm/BaseForm';
import {
  formDataInitialState as UserAppSettingsFormDataInitialState,
  FormDataType as UserAppSettingsFormDataType,
} from 'features/maintenance/constants/UserAppSettingsFormConstants';
import { validationSchema as UserAppSettingsValidationSchema } from 'features/maintenance/constants/UserAppSettingsFormSchema';
import { useUserAppSettingsForm } from 'features/maintenance/hooks/useUserAppSettingsForm';
import { useUpdateUserApplicationSettingsMutation } from 'features/maintenance/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { trimObjectValues } from 'utils/helpers';

const UserAppSettings = () => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { displayError, displaySuccess } = useDisplayNotification();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<UserAppSettingsFormDataType>(
      UserAppSettingsFormDataInitialState,
      UserAppSettingsValidationSchema,
    );
  const { data: initialUserAppSettingsFormData } = useUserAppSettingsForm(setFormData);

  const [updateUserApplicationSettingsRequest] = useUpdateUserApplicationSettingsMutation();

  useEffect(() => {
    const {
      placeDetail,
      placeList,
      productCategoryAll,
      productDetail,
      productList,
      storyDetail,
      storyDetailHeader,
      storyList,
      ...restData
    } = formData;
    const newData = {
      ...restData,
      imageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
        storyDetail,
        storyDetailHeader,
        storyList,
      },
    };
    const isUserFormDirty = !isEqual(initialUserAppSettingsFormData, newData);
    setIsFormDirty(isUserFormDirty);
  }, [initialUserAppSettingsFormData, formData]);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<UserAppSettingsFormDataType>(formData);
    const {
      placeDetail,
      placeList,
      productCategoryAll,
      productDetail,
      productList,
      storyDetail,
      storyDetailHeader,
      storyList,
      ...restData
    } = trimmedData;
    const userSettingsData = {
      ...restData,
      imageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
        storyDetail,
        storyDetailHeader,
        storyList,
      },
    };
    try {
      await updateUserApplicationSettingsRequest(userSettingsData).unwrap();
      displaySuccess({ key: 'maintenance.updateUserAppSettings' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <>
      {initialUserAppSettingsFormData ? (
        <UserAppSettingsBaseForm
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          setFormData={setFormData}
        />
      ) : (
        <ACSpinner background='var(--text-secondary)' size='xxs' />
      )}

      <FormStickyFooter
        onSubmit={() => {
          handleSubmit(onSubmit);
        }}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={!isFormDirty}
      />
    </>
  );
};

export default UserAppSettings;
