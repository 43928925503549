type PageHeaderProps = {
  primaryText: string;
  secondaryText?: string;
  style?: React.CSSProperties;
};

const PageHeader = ({ primaryText, secondaryText, style }: PageHeaderProps) => {
  return (
    <div>
      <h2 style={style}>{primaryText}</h2>
      <p>{secondaryText}</p>
    </div>
  );
};
export default PageHeader;
