import { ACAutocompleteV2, ACCheckBox, ACInput } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { FormHeader } from 'components/FormHeader/FormHeader';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import {
  convertACAutocompleteEventValueFromStringToBoolean,
  getYesNoOptionsArray,
} from 'features/maintenance/helpers';
import { t } from 'i18next';

import {
  APPLE,
  FACEBOOK,
  FORM_ELEMENT_MAINTENANCE_MODE,
  FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE,
  FORM_ELEMENT_MAINTENANCE_MODE_TITLE,
  FORM_ELEMENT_PLACE_DETAIL,
  FORM_ELEMENT_PLACE_LIST,
  FORM_ELEMENT_PRODUCT_CATEGORY_ALL,
  FORM_ELEMENT_PRODUCT_DETAIL,
  FORM_ELEMENT_PRODUCT_LIST,
  FORM_ELEMENT_STORY_DETAIL,
  FORM_ELEMENT_STORY_DETAIL_HEADER,
  FORM_ELEMENT_STORY_LIST,
  GOOGLE,
  MAGIC_LINK,
  SHOW_CATEGORIES,
  SHOW_OFFERS,
} from '../../../constants/UserAppSettingsFormConstants';
import { FormDataType } from '../../../constants/WebAppSettingsFormConstants';
import styles from './BaseForm.module.scss';

type BaseFormWebAppMaintenanceProps = BaseFormProps<FormDataType> & {
  setFormData?: (formData: FormDataType) => void;
};

const BaseForm = ({
  errors,
  formData,
  handleChange,
  setFormData,
}: BaseFormWebAppMaintenanceProps) => {
  const {
    loginTypes,
    maintenanceMode,
    maintenanceModeMessage,
    maintenanceModeTitle,
    placeDetail,
    placeList,
    productCategoryAll,
    productDetail,
    productList,
    showOffersOnHome,
    showProductCategories,
    storyDetail,
    storyDetailHeader,
    storyList,
  } = formData;

  const { apple, facebook, google, magicLink } = loginTypes;

  const handleShowInWebApp = (event, name) => {
    return setFormData({ ...formData, [name]: event?.target?.checked });
  };

  const handleShowTypes = (event, name) => {
    return setFormData({
      ...formData,
      loginTypes: { ...loginTypes, [name]: event?.target?.checked },
    });
  };

  return (
    <>
      <Divider />
      <form>
        <FormHeader
          primaryText={t('maintenance.webAppSettings.message')}
          secondaryText={t('maintenance.webAppSettings.messageSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='maintenanceMode'
            errorMessage={errors?.maintenanceMode}
            fullWidth
            hasError={!!errors?.maintenanceMode}
            label={t('maintenance.webAppSettings.maintenanceMode')}
            name={FORM_ELEMENT_MAINTENANCE_MODE}
            onChange={(e) => {
              handleChange(convertACAutocompleteEventValueFromStringToBoolean(e));
            }}
            options={getYesNoOptionsArray()}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceMode ? t('maintenance.yes') : t('maintenance.no')}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeTitle}
            errorMessage={errors?.maintenanceModeTitle}
            fullWidth
            hasError={!!errors?.maintenanceModeTitle}
            id={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            label={t('maintenance.webAppSettings.maintenanceModeTitle')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeTitle}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeMessage}
            errorMessage={errors?.maintenanceModeMessage}
            fullWidth
            hasError={!!errors?.maintenanceModeMessage}
            id={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            isTextArea
            label={t('maintenance.webAppSettings.maintenanceModeMessage')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeMessage}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.webAppSettings.showOptions')}
          secondaryText={t('maintenance.webAppSettings.showOptionsSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <div className={styles.showInformation}>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>
                {t('maintenance.webAppSettings.showProductCategories')}
              </div>
              <ACCheckBox
                checked={showProductCategories}
                dataTestId='showProductCategories'
                onChange={(event) => handleShowInWebApp(event, SHOW_CATEGORIES)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>
                {t('maintenance.webAppSettings.showOffersOnHome')}
              </div>
              <ACCheckBox
                checked={showOffersOnHome}
                dataTestId='showOffersOnHome'
                onChange={(event) => handleShowInWebApp(event, SHOW_OFFERS)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
          </div>
        </div>
        <FormHeader
          primaryText={t('maintenance.webAppSettings.imageQualitySettings')}
          secondaryText={t('maintenance.webAppSettings.imageQualitySettingsSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeDetail}
            errorMessage={errors?.placeDetail}
            fullWidth
            hasError={!!errors?.placeDetail}
            id={FORM_ELEMENT_PLACE_DETAIL}
            label={t('maintenance.webAppSettings.placeDetail')}
            name={FORM_ELEMENT_PLACE_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeList}
            errorMessage={errors?.placeList}
            fullWidth
            hasError={!!errors?.placeList}
            id={FORM_ELEMENT_PLACE_LIST}
            label={t('maintenance.webAppSettings.placeList')}
            name={FORM_ELEMENT_PLACE_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productCategoryAll}
            errorMessage={errors?.productCategoryAll}
            fullWidth
            hasError={!!errors?.productCategoryAll}
            id={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            label={t('maintenance.webAppSettings.productCategoryAll')}
            name={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productCategoryAll}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productDetail}
            errorMessage={errors?.productDetail}
            fullWidth
            hasError={!!errors?.productDetail}
            id={FORM_ELEMENT_PRODUCT_DETAIL}
            label={t('maintenance.webAppSettings.productDetail')}
            name={FORM_ELEMENT_PRODUCT_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productList}
            errorMessage={errors?.productList}
            fullWidth
            hasError={!!errors?.productList}
            id={FORM_ELEMENT_PRODUCT_LIST}
            label={t('maintenance.webAppSettings.productList')}
            name={FORM_ELEMENT_PRODUCT_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyDetail}
            errorMessage={errors?.storyDetail}
            fullWidth
            hasError={!!errors?.storyDetail}
            id={FORM_ELEMENT_STORY_DETAIL}
            label={t('maintenance.webAppSettings.storyDetail')}
            name={FORM_ELEMENT_STORY_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyDetailHeader}
            errorMessage={errors?.storyDetailHeader}
            fullWidth
            hasError={!!errors?.storyDetailHeader}
            id={FORM_ELEMENT_STORY_DETAIL_HEADER}
            label={t('maintenance.webAppSettings.storyDetailHeader')}
            name={FORM_ELEMENT_STORY_DETAIL_HEADER}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyDetailHeader}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyList}
            errorMessage={errors?.storyList}
            fullWidth
            hasError={!!errors?.storyList}
            id={FORM_ELEMENT_STORY_LIST}
            label={t('maintenance.webAppSettings.storyList')}
            name={FORM_ELEMENT_STORY_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyList}
          />
          <div>
            <FormHeader
              primaryText={t('maintenance.webAppSettings.loginTypes')}
              secondaryText={t('maintenance.webAppSettings.loginTypesSubtitle')}
            />
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.webAppSettings.facebook')}</div>
              <ACCheckBox
                checked={facebook}
                dataTestId='facebook'
                onChange={(event) => handleShowTypes(event, FACEBOOK)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.webAppSettings.apple')}</div>
              <ACCheckBox
                checked={apple}
                dataTestId='apple'
                onChange={(event) => handleShowTypes(event, APPLE)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.webAppSettings.magicLink')}</div>
              <ACCheckBox
                checked={magicLink}
                dataTestId='magicLink'
                onChange={(event) => handleShowTypes(event, MAGIC_LINK)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.webAppSettings.google')}</div>
              <ACCheckBox
                checked={google}
                dataTestId='google'
                onChange={(event) => handleShowTypes(event, GOOGLE)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BaseForm;
