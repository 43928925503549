import { ACLoading, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_COUNTRY, FORM_ELEMENT_PLACE } from 'constants/FormConstants';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { Inventory } from 'features/inventory/services/getInventories';
import { useLazyGetPlacesRecursiveQuery } from 'features/place/services';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import { AddCircle, Trash } from 'iconsax-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/store';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { transformACState } from '../../../../utils/helpers';
import { useDeleteInventoryMutation, useLazyGetInventoriesQuery } from '../../services/index';
import inventoryTableSettings from './inventoryTableSettings';

export type FilterProps = {
  name: string;
};

const TableInventory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableSettings = useTable(inventoryTableSettings);

  const { isDeleteEnabled, selectedRow, toggleDelete } = tableSettings;
  const [deleteInventory, { isLoading: isLoadingDelete }] = useDeleteInventoryMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const [fetchInventories, { data: inventory, isFetching, isSuccess }] =
    useLazyGetInventoriesQuery();
  const [fetchPlaces, { data: places, isSuccess: isSuccessPlaces }] =
    useLazyGetPlacesRecursiveQuery();
  const countries = store.getState().globalData.countries;
  const handleChangeTableData = (tableData: TableStateProps<FilterProps>) => {
    fetchInventories(transformACState(tableData, TABLE_ELEMENT_CREATED_AT), true);
  };

  const handleAddInventory = () => {
    navigate('/inventory/add');
  };

  useEffect(() => {
    if (inventory && inventory.totalElements !== 0) {
      fetchPlaces();
    }
  }, [inventory]);

  const handleDelete = async () => {
    try {
      await deleteInventory(selectedRow?.uuid).unwrap();
      toggleDelete();
      displaySuccess({ key: 'inventory.removeInventory' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const inventoryTableData = useMemo(() => {
    return inventory?.content?.map((inventory) => {
      return {
        rawData: inventory,
        render: inventory,
      };
    });
  }, [inventory?.content]);

  const additionalHeaderActions = [
    {
      title: t('inventory.titleCreate'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('inventory.titleCreate'),
      },
      onClick: handleAddInventory,
    },
  ];

  const additionalFilters = [
    {
      field: FORM_ELEMENT_PLACE,
      filterType: 'selectSingle',
      title: t('orders.place'),
      filterSelectData: places?.content?.map((item) => {
        return {
          value: item?.uuid,
          render: item?.name,
        };
      }),
    },
    {
      field: FORM_ELEMENT_COUNTRY,
      filterType: 'selectSingle',
      title: t('inventory.country'),
      filterSelectData: countries?.map((item) => {
        return {
          value: item?.uuid,
          render: item?.shortName,
        };
      }),
    },
  ];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: inventory?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter, ...additionalFilters],
  });

  const handleOnItemClicked = ({ rowData }: { rowData: Inventory }) => {
    const { uuid } = rowData;
    navigate(`/inventory/${uuid}`);
  };

  return (
    <>
      <ACLoading
        isLoading={isFetching || isLoadingDelete}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={tableSettings?.actions}
          allItemsCount={inventory?.totalElements}
          defaultSort={DEFAULT_SORT}
          filter={isSuccessPlaces ? filtersToDisplay : null}
          headerActions={additionalHeaderActions}
          headerTitle={`${t('inventory.tableName')}`}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleChangeTableData}
          pagesCount={inventory?.totalPages}
          rows={inventoryTableData}
        />
      </ACLoading>
      {isDeleteEnabled && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDelete}
          header={t('deleteModal.headerRemoveInventory')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteEnabled}
          message={t('deleteModal.messageRemoveInventory')}
        />
      )}
    </>
  );
};
export default TableInventory;
