import { tableActionTypes } from 'constants/useTableConstants';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useToggle from './useToggle';

export type TableSettingsType = {
  actions?: ActionType[];
  allItemsCount?: number;
  columns?: ColumnType[];
  dataTestId: string;
  disableSorting?: { all?: boolean; columns?: string[] };
  filter?: FilterType[];
  headerActions?: ActionType[];
  initialRowsNumber: number;
  pagesCount?: number;
};

export type FilterType = {
  errorMessage?: string;
  field: string;
  filterSelectData?: { render: string; value: string }[];
  filterType?: string;
  isValid?: (value: string) => void;
  title?: string;
};

export type useTableSettingsType = {
  actions?: ActionType[];
  allItemsCount?: number;
  columns: ColumnType[];
  dataTestId: string;
  filter?: FilterType[];
  headerActions: ActionType[];
  initialRowsNumber: number;
  isAddEnabled: boolean;
  isDeleteEnabled: boolean;
  isDetailViewEnabled: boolean;
  isEditEnabled: boolean;
  pagesCount?: number;
  selectedRow: any;
  setSelectedRow: (row: any) => void;
  toggleAdd: () => void;
  toggleDelete: () => void;
  toggleDetailView: () => void;
  toggleEdit: () => void;
};

export type ColumnType = {
  field: string;
  initialEditValue?: string;
  title: string;
};

export type ActionType = {
  header?: boolean;
  icon: ReactElement;
  name?: string;
  onClick?: (rowData: never) => void;
  redirect?: string;
  tooltip?:
    | {
        style?: React.CSSProperties;
        text?: string;
      }
    | string;
  tooltipStyle?: React.CSSProperties;
};

const { ADD, DELETE, DETAIL_VIEW, EDIT } = tableActionTypes;

const useTable = (tableSettings: TableSettingsType): useTableSettingsType => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAddEnabled, toggleAdd] = useToggle();
  const [isEditEnabled, toggleEdit] = useToggle();
  const [isDeleteEnabled, toggleDelete] = useToggle();
  const [isDetailViewEnabled, toggleDetailView] = useToggle();
  const [selectedRow, setSelectedRow] = useState();

  const handleAddIcon = () => {
    const { redirect } = tableSettings.actions.filter(({ name }) => name === ADD)[0];
    redirect === 'modal' ? toggleAdd() : navigate(`${redirect}`);
  };

  const handleEditIcon = ({ rowData }) => {
    const { redirect } = tableSettings.actions.filter(({ name }) => name === EDIT)[0];
    setSelectedRow(rowData);
    redirect === 'modal'
      ? toggleEdit()
      : navigate(`${redirect}/${rowData?.product?.uuid ? rowData.product.uuid : rowData.uuid}`);
  };

  const handleDeleteIcon = ({ rowData }) => {
    setSelectedRow(rowData);
    toggleDelete();
  };
  const handleDetailViewIcon = ({ rowData }) => {
    setSelectedRow(rowData);
    const { redirect } = tableSettings.actions.filter(({ name }) => name === DETAIL_VIEW)[0];
    redirect == 'modal' ? toggleDetailView() : navigate(`${redirect}/${rowData.uuid}`);
  };

  const assignAction = (name: string) => {
    switch (name) {
      case ADD:
        return handleAddIcon;
      case EDIT:
        return handleEditIcon;
      case DELETE:
        return handleDeleteIcon;
      case DETAIL_VIEW:
        return handleDetailViewIcon;
    }
  };
  const handleActions = () =>
    tableSettings?.actions
      ?.map(({ header, icon, name, tooltip, tooltipStyle }) => {
        return (
          !header && {
            icon,
            tooltip: {
              text: t(`${tooltip as string}`),
              style: tooltipStyle,
            },
            onClick: assignAction(name),
          }
        );
      })
      .filter((action) => action);

  const handleHeaderActions = () =>
    tableSettings?.actions
      ?.map(({ header, icon, name, tooltip }) => {
        return (
          header && {
            icon,
            tooltip: {
              text: t(`${tooltip}`),
            },
            onClick: assignAction(name),
          }
        );
      })
      .filter((action) => action);

  const handleFilters = () =>
    tableSettings?.filter?.map((filter) => {
      return { ...filter, title: t(filter.title), errorMessage: t(filter.errorMessage) };
    });
  const handleColumns = () =>
    tableSettings?.columns?.map((column) => {
      return { ...column, title: t(column.title) };
    });

  return {
    ...tableSettings,
    columns: handleColumns(),
    filter: handleFilters(),
    actions: handleActions(),
    headerActions: handleHeaderActions(),
    isEditEnabled,
    isAddEnabled,
    isDeleteEnabled,
    selectedRow,
    isDetailViewEnabled,
    toggleAdd,
    toggleEdit,
    toggleDelete,
    toggleDetailView,
    setSelectedRow,
  };
};
export default useTable;
