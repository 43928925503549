import { FetchArgs } from '@reduxjs/toolkit/query';
import { FORM_ELEMENT_NAME, LANGUAGES } from 'constants/FormConstants';
import { NOTIFICATION_TYPE } from 'constants/NotificationConstants';
import { ROLE_PARTNER_MANAGER, ROLE_PARTNER_OWNER, ROLE_TERMINAL } from 'constants/RolesConstants';
import { SortProps } from 'constants/TypeConstants';
import { PLACE_CATEGORIES, PLACE_STATUSES } from 'features/place/constants/PlaceContants';
import { PLACE_DEFAULT_IMAGE } from 'features/place/constants/PlaceContants';
import { validationSchemaHourly } from 'features/place/constants/PlaceStatisticsHourlySchema';
import { validationSchema } from 'features/place/constants/PlaceStatisticsSchema';
import { FilterType } from 'hooks/useTable';
import moment from 'moment';
import { CurrentUser } from 'store/Auth';

const listOfArgsToEncode = ['search-term'];

const ZERO_ELEMENTS = 0;
const {
  BAKERY,
  BUTCHERY_SHOP,
  CAFFE,
  CATERING,
  FAST_FOOD,
  FLOWER_SHOP,
  GAS_STATION,
  GREEN_GROCERY,
  GROCERY,
  HOTEL,
  PASTRY_SHOP,
  RESTAURANT,
} = PLACE_CATEGORIES;

export type QueryObject = {
  [key: string]: string | number | object;
} & {
  currentPage?: number;
  itemsPerPage?: number;
  sort?: SortProps;
};

export const validateEmail = (value: string) => {
  if (
    /^([\w-]+(?:[.+][\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/.test(
      value,
    )
  ) {
    return true;
  }
  return false;
};

export const countNumericDigits = (inputString: string) => {
  const numericDigits = inputString.replace(/\D/g, '');
  return numericDigits.length;
};

export const validatePassword = (value: string) => {
  if (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@_`{}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@_`{}~]{8,}$/.test(
      value,
    )
  ) {
    return true;
  }
  return false;
};

export const validatePhoneNumber = (value: string, isPlaceForm: boolean) => {
  if (isPlaceForm) {
    const regexValue =
      /^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\d{3})[- .]?\d{3}[- .]?\d{4}$|^(\+\d{1,3}( )?)?(\d{3}[ ]?){2}\d{3}$|^(\+\d{1,3}( )?)?(\d{3}[ ]?)(\d{2}[ ]?){2}\d{2}$/;
    const isValidPhone = regexValue.test(value);
    return isValidPhone && true;
  } else if (/^\d{0,30}$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const validateNumberField = (value: string) => {
  if (/^[0-9]*$/.test(value)) {
    return true;
  }
  return false;
};

export const validateNumberDecimalField = (value: string) => {
  if (/^\d+(\.\d+)*$/.test(value)) {
    return true;
  }
  return false;
};

export const validatePriceField = (value: string) => {
  if (/^\d+(\.\d{1,2})?$/.test(value)) {
    return true;
  }
  return false;
};

export const validateWebsite = (value: string) => {
  const websiteValidation =
    /^((ftp|http|https):\/\/)?(www.)?(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  if (websiteValidation.test(value)) {
    return true;
  }
  return false;
};

export const camelCaseToKebabCase = (key: string): string => {
  return `${key
    .split(/(?=[A-Z])/)
    .join('-')
    .toLowerCase()}`;
};

export const transformSnakeCaseToText = (value: string) => {
  return value[0] + value.substring(1).split('_').join(' ').toLowerCase();
};
export const transformTextToSnakeCase = (value: string) => {
  return value[0] + value.substring(1).split(' ').join('_').toUpperCase();
};
export const transformSnakeCaseToTextFormTranslates = (value: string) => {
  return value.substring(0).split('_').join('').toLowerCase();
};
export const transformtextCaseToCamelCase = (value: string) => {
  return (
    value.charAt(0).toLowerCase() +
    value
      .slice(1)
      .trim()
      .replace(/[-_\s]+(.)?/g, (_, item: string) => (item ? item.toUpperCase() : ''))
  );
};

export const checkIfPropertyExistsInObject = (object: object, property: string) =>
  Object.prototype.hasOwnProperty.call(object, property);

export const transformACState = (
  ACState: any,
  defaultField: string,
  searchProperty = FORM_ELEMENT_NAME,
) => {
  const transformSortField = () => {
    if (checkIfPropertyExistsInObject(ACState, 'sortType')) {
      let field = ACState.sortType.split('-')[0];
      let type = ACState.sortType.split('-')[1];
      if (field === 'title') {
        field = FORM_ELEMENT_NAME;
      }
      if (!field) {
        field = defaultField;
      }
      if (!type) {
        type = 'asc';
      }
      return { field, type };
    }
    return {
      ...ACState.sort,
      type: ACState.sort.type,
      field: !ACState.sort.field ? defaultField : ACState.sort.field,
    };
  };
  return {
    ...ACState,
    size: ACState.itemsPerPage,
    page: ACState.currentPage - 1,
    sort: transformSortField(),
    ...(ACState.filter || []),
    ...(checkIfPropertyExistsInObject(ACState, 'searchQuery') && {
      [searchProperty]: ACState.searchQuery,
    }),
  };
};

export const encodeUrlParams = (args: string | FetchArgs) => {
  const encodePath = (path: string) => {
    return path.includes('?')
      ? `${path?.toString().split('?')[0]}?${path
          ?.toString()
          ?.split('?')[1]
          ?.split('&')
          ?.map((param) => {
            const keyValueParam = param?.split('=');
            const encodedValue = listOfArgsToEncode.includes(keyValueParam[0])
              ? encodeURIComponent(keyValueParam[1])
              : keyValueParam[1];
            return `${keyValueParam[0]}=${encodedValue}`;
          })
          ?.join('&')}`
      : path;
  };

  if (typeof args === 'object') {
    return { ...args, url: encodePath(args.url) };
  } else {
    return encodePath(args);
  }
};

export const createQueryString = (
  queryObject: QueryObject | Record<string, string | number | string[] | undefined | boolean>,
  baseUrl: string,
  blacklist: string[] = [],
) => {
  const queryParams: string[] = [];
  const blacklistedItems: string[] = ['currentPage', 'itemsPerPage', ...blacklist];

  Object.keys(queryObject).forEach((key) => {
    if (!blacklistedItems.includes(key) && Object.prototype.hasOwnProperty.call(queryObject, key)) {
      switch (typeof queryObject[key]) {
        case 'string':
          queryParams.push(`${camelCaseToKebabCase(key)}=${queryObject[key] as string}`);
          break;
        case 'number':
          queryParams.push(`${camelCaseToKebabCase(key)}=${queryObject[key] as string}`);
          break;
        case 'boolean':
          queryParams.push(`${camelCaseToKebabCase(key)}=${queryObject[key] as string}`);
          break;
        case 'object':
          if (Array.isArray(queryObject[key])) {
            queryParams.push(
              `${camelCaseToKebabCase(key)}=${(queryObject[key] as string[]).join(',')}`,
            );
          } else if (key === 'sort' && queryObject[key] instanceof Object) {
            const obj = queryObject[key] as unknown as { field: string; type: string };
            queryParams.push(`${camelCaseToKebabCase(key)}=${obj.field},${obj.type}`);
          }
          break;
        default:
          break;
      }
    }
  });

  const queryString = queryParams.join('&');
  const separator = queryString ? (baseUrl.includes('?') ? '&' : '?') : '';

  return `${baseUrl}${separator}${queryString}`;
};

export const transformToTimeFormat = (time: string) => {
  const timeToFormat = new Date(time);
  return moment(timeToFormat).format('LLL');
};

export const isEmptyField = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};

export const transformSnakeCaseRoles = (role: string) => {
  if (role === ROLE_PARTNER_OWNER) {
    return 'Owner';
  } else if (role === ROLE_PARTNER_MANAGER) {
    return 'Manager';
  } else if (role === ROLE_TERMINAL) {
    return 'Terminal';
  }
};
export const transformRolesToSnakeCase = (role: string) => {
  if (role === 'Owner') {
    return ROLE_PARTNER_OWNER;
  } else if (role === 'Manager') {
    return ROLE_PARTNER_MANAGER;
  } else if (role === 'Terminal') {
    return ROLE_TERMINAL;
  }
};

type UserInformationType = {
  currentUser: CurrentUser;
  haveInitials: boolean;
};

export const userInitials = ({ currentUser, haveInitials }: UserInformationType) => {
  const { firstName, lastName } = currentUser;
  if (haveInitials) {
    return firstName[0] + lastName[0];
  }
  return firstName + ' ' + lastName;
};

export const handleRating = (item) => {
  const { ratingCount, ratingSum } = item;
  return ratingSum !== 0 ? Number(ratingSum / ratingCount).toFixed(2) : 'N/A';
};

export const createMap = (list) => {
  const uuid = 'uuid';
  return new Map(
    list?.map((item) => {
      if (item.length === 2) {
        return [item[1].uuid, item[1]];
      }
      return [item[uuid], item];
    }),
  );
};

export const handleDefaultPlaceImage = (placeCategory: string, document: string) => {
  let defaultImage: string;
  const {
    defaultBakeryImage,
    defaultCafeImage,
    defaultCateringImage,
    defaultFastFoodImage,
    defaultFlowerShopImage,
    defaultGasStationImage,
    defaultGreenGroceryImage,
    defaultGroceryImage,
    defaultHotelImage,
    defaultPastryShopImage,
    defaultRestaurantImage,
    defeaultButcheryImage,
  } = PLACE_DEFAULT_IMAGE;

  switch (placeCategory) {
    case RESTAURANT:
      defaultImage = defaultRestaurantImage as string;
      break;
    case BAKERY:
      defaultImage = defaultBakeryImage as string;
      break;
    case CAFFE:
      defaultImage = defaultCafeImage as string;
      break;
    case GROCERY:
      defaultImage = defaultGroceryImage as string;
      break;
    case BUTCHERY_SHOP:
      defaultImage = defeaultButcheryImage as string;
      break;
    case FAST_FOOD:
      defaultImage = defaultFastFoodImage as string;
      break;
    case PASTRY_SHOP:
      defaultImage = defaultPastryShopImage as string;
      break;
    case HOTEL:
      defaultImage = defaultHotelImage as string;
      break;
    case GREEN_GROCERY:
      defaultImage = defaultGreenGroceryImage as string;
      break;
    case CATERING:
      defaultImage = defaultCateringImage as string;
      break;
    case FLOWER_SHOP:
      defaultImage = defaultFlowerShopImage as string;
      break;
    case GAS_STATION:
      defaultImage = defaultGasStationImage as string;
      break;
    default:
      defaultImage = defaultRestaurantImage as string;
      break;
  }
  return document ? document : defaultImage;
};

export const handleNotificationColor = (notificationType) => {
  const { ERROR, INFO, SUCCESS, WARNING } = NOTIFICATION_TYPE;
  if (notificationType === ERROR) {
    return { color: 'var(--error)' };
  } else if (notificationType === SUCCESS) {
    return { color: 'var(--success)' };
  } else if (notificationType === WARNING) {
    return { color: 'var(--warning)' };
  } else if (notificationType === INFO) {
    return { color: 'var(--info)' };
  }
};

export const getNameFieldTranslation = (
  language: string,
  nameEn: string,
  nameSr: string,
  nameHr: string,
) => {
  switch (language) {
    case 'srb':
      return nameSr;
    case 'en':
      return nameEn;
    case 'hr':
      return nameHr;
    default:
      return nameEn;
  }
};

export const handleUpdatePlaceNavigation = (placeStatus: string, verified: boolean) => {
  if (placeStatus === PLACE_STATUSES.ACTIVE && verified) {
    return '/places?value=0';
  } else if (placeStatus === PLACE_STATUSES.IN_PREPARATION && verified) {
    return '/places?value=1';
  } else {
    return '/places?value=2';
  }
};

export const handleShowingFilters = (totalElements: number, filters: FilterType[]) => {
  if (totalElements !== ZERO_ELEMENTS) {
    return filters;
  }
  return undefined;
};

export const trimObjectValues = <FormDataType>(obj) => {
  const trimmedObject = {} as FormDataType;
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    trimmedObject[key] = typeof value === 'string' ? value.trim() : value;
  });
  return trimmedObject;
};

export const handleDuplicates = (array: string[]) => {
  return array.filter((item, index) => array.indexOf(item) === index);
};

export const getValidationSchema = (interval) => {
  switch (interval) {
    case 'HOURLY':
    case 'ČASOVNO':
      return validationSchemaHourly;
    case 'DAILY':
    case 'WEEKLY':
    case 'MONTHLY':
    case 'DNEVNO':
    case 'NEDELJNO':
    case 'MESEČNO':
      return validationSchema;
    default:
      return {};
  }
};

export const handleDateAndTime = (date: string, dateFormat: string, language: string) => {
  const formattedDate = moment(date).format(dateFormat.toUpperCase());
  const formattedTime =
    language === LANGUAGES.EN ? moment(date).format('LT') : `${moment(date).format('HH:mm')}h`;

  return { formattedDate, formattedTime };
};

export const handleCountryDisplay = (language, country, countrySr, countryHr) => {
  switch (language) {
    case LANGUAGES.EN:
      return country?.name;
    case LANGUAGES.SRB:
      return countrySr;
    case LANGUAGES.HR:
      return countryHr;
    default:
      return country?.name;
  }
};

export const handleNavigateLink = () => {
  const navEvent = new PopStateEvent('popstate');
  window.dispatchEvent(navEvent);
};
