import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { OptionsType } from 'constants/TypeConstants';
import { TAG_COMPANIES } from 'services/TagsConstants';

import { CountryOption } from '../../../services/country';
import { Owner } from '../constants/FormConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, NewCompany>({
    query: (credentials) => ({
      url: '/companies',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_COMPANIES],
  });

export type NewCompany = {
  apartment?: number | string;
  city?: OptionsType | string;
  country: CountryOption | string;
  floor?: number | string;
  idNumber: string;
  name: string;
  owner: string | Owner;
  street: string;
  streetNumber: string;
  vatNumber: string;
  zipCode: string;
};
