import api from '../../../services/api';
import addPlaceEmployee from './addPlaceEmployee';
import archivePlace from './archivePlace';
import createPlace from './createPlace';
import getArchivedPlaces from './getArchivedPlaces';
import getInPreparationPlaces from './getInPreparationPlaces';
import getLocation from './getLocation';
import getMapLocation from './getMapLocation';
import getPlaceById from './getPlaceById';
import getPlaceCards from './getPlaceCards';
import getPlaceCategories from './getPlaceCategories';
import getPlaces from './getPlaces';
import getPlacesRecursive from './getPlacesRecursive';
import getPlaceStatistics from './getPlaceStatistics';
import getUnverifiedPlaces from './getUnverifiedPlaces';
import placeActions from './placeActions';
import removePlaceEmployee from './removePlaceEmployee';
import removePlaceImage from './removePlaceImage';
import unarchivePlace from './unarchivePlace';
import updatePlace from './updatePlace';
import updatePlaceEmployee from './updatePlaceEmployee';
import uploadPlaceImage from './uploadPlaceImage';

export const placeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlaces: getPlaces(build),
    getUnverifiedPlaces: getUnverifiedPlaces(build),
    getInPreparationPlaces: getInPreparationPlaces(build),
    getPlaceById: getPlaceById(build),
    createPlace: createPlace(build),
    updatePlace: updatePlace(build),
    uploadPlaceImage: uploadPlaceImage(build),
    getMapLocation: getMapLocation(build),
    addPlaceEmployee: addPlaceEmployee(build),
    removePlaceEmployee: removePlaceEmployee(build),
    updatePlaceEmployee: updatePlaceEmployee(build),
    removePlaceImage: removePlaceImage(build),
    getPlaceStatistics: getPlaceStatistics(build),
    changePlacesStatus: placeActions(build),
    archivePlace: archivePlace(build),
    unarchivePlace: unarchivePlace(build),
    getArchivedPlaces: getArchivedPlaces(build),
    getPlacesRecursive: getPlacesRecursive(build),
    getLocation: getLocation(build),
    getPlaceCategories: getPlaceCategories(build),
    getPlaceCards: getPlaceCards(build),
  }),
  overrideExisting: false,
});

export const {
  useAddPlaceEmployeeMutation,
  useArchivePlaceMutation,
  useChangePlacesStatusMutation,
  useCreatePlaceMutation,
  useGetPlaceCategoriesQuery,
  useGetPlacesRecursiveQuery,
  useLazyGetArchivedPlacesQuery,
  useLazyGetInPreparationPlacesQuery,
  useLazyGetLocationQuery,
  useLazyGetMapLocationQuery,
  useLazyGetPlaceByIdQuery,
  useLazyGetPlaceCardsQuery,
  useLazyGetPlaceStatisticsQuery,
  useLazyGetPlacesQuery,
  useLazyGetPlacesRecursiveQuery,
  useLazyGetUnverifiedPlacesQuery,
  useRemovePlaceEmployeeMutation,
  useRemovePlaceImageMutation,
  useUnarchivePlaceMutation,
  useUpdatePlaceEmployeeMutation,
  useUpdatePlaceMutation,
  useUploadPlaceImageMutation,
} = placeApi;
