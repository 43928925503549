import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UploadRequest } from 'constants/TypeConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<UploadImageProductCategoryDTO, UploadRequest>({
    query: (credentials: UploadRequest) => ({
      url: `/product-category/${credentials.id}/upload-image`,
      method: 'POST',
      body: credentials.file,
    }),
  });

export type UploadImageProductCategoryDTO = {
  productCategory?: string;
  uuid?: string;
};
