import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { OptionsType } from 'constants/TypeConstants';
import {
  TAG_IN_PREPARATION_PLACES,
  TAG_PLACE,
  TAG_PLACES,
  TAG_UNVERIFIED_PLACES,
} from 'services/TagsConstants';

import { NewPlace } from './createPlace';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, PlaceUpdateDTO>({
    query: (data) => {
      const { uuid, ...payload } = data;
      return {
        url: `/place/${uuid}`,
        method: 'PATCH',
        body: payload,
      };
    },
    invalidatesTags: [TAG_PLACES, TAG_IN_PREPARATION_PLACES, TAG_UNVERIFIED_PLACES, TAG_PLACE],
  });

export type WorkingHoursType = {
  fridayFrom?: string;
  fridayTo?: string;
  mondayFrom?: string;
  mondayTo?: string;
  saturdayFrom?: string;
  saturdayTo?: string;
  sundayFrom?: string;
  sundayTo?: string;
  thursdayFrom?: string;
  thursdayTo?: string;
  tuesdayFrom?: string;
  tuesdayTo?: string;
  wednesdayFrom?: string;
  wednesdayTo?: string;
};

export type PlaceUpdateDTO = NewPlace & {
  chosenLocation?: OptionsType;
  owner?: string;
  rating?: string;
  uuid: string;
};
