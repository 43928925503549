import { ACSpinner } from '@antech-consulting/components';
import { FILE } from 'constants/FormConstants';
import { NewProductCategory } from 'features/productCategory/services/createProductCategory';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/productCategory/components/BaseForm/BaseForm';
import UploadForm from '../../features/productCategory/components/UploadForm/UploadForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/productCategory/constants/FormConstants';
import { validationSchema } from '../../features/productCategory/constants/ProductCategorySchema';
import {
  useCreateProductCategoryMutation,
  useUploadProductCategoryImageMutation,
} from '../../features/productCategory/services';
import { useFileUpload } from '../../hooks/useFileUpload';
import styles from './AddProductCategory.module.scss';

const AddProductCategory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createProductCategory, { isLoading }] = useCreateProductCategoryMutation();
  const [uploadDocument, { isLoading: isLoadingDocument }] =
    useUploadProductCategoryImageMutation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const buttonsDisabled = isLoading || isLoadingDocument;

  const [file, error, handleFileChange] = useFileUpload();

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const productCategoryAddDTO: NewProductCategory = { ...trimmedData };
    try {
      const response = await createProductCategory(productCategoryAddDTO).unwrap();
      if (response && file) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        await uploadDocument({
          file: dataImage,
          id: response?.uuid,
        });
      }
      navigate('/product-categories');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'productCategory.createProductCategory' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };
  const onDiscard = useCallback(() => {
    navigate('/product-categories');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }, []);

  return !isLoading ? (
    <>
      <PageHeader primaryText={t('productCategory.titleCreate')} />
      <div className={styles.wrapper}>
        <BaseForm
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          setFormData={setFormData}
        />
        <div style={{ flex: 1 }}>
          <UploadForm file={file} formData={formData} handleChange={handleFileChange} />
        </div>
      </div>
      <FormStickyFooter
        buttonsDisabled={buttonsDisabled}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={buttonsDisabled}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddProductCategory;
