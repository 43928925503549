import { ACButton, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { style } from 'constants/StyleConstants';
import { formDataInitialState, FormDataType } from 'features/orders/constants/FormConstants';
import {
  useAcceptOrderMutation,
  usePickUpOrderMutation,
  useReadyOrderMutation,
  useRejectOrderMutation,
  useSeenOrderMutation,
} from 'features/orders/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import useToggle from 'hooks/useToggle';
import { CloseCircle, Eye, TickCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

import BodyRejectMessage from '../BodyRejectMessage/BodyRejectMessage';
import styles from './ChangeOrderStatus.module.scss';

const buttonStyle = {
  background: 'var(--background)',
  color: 'var(--text-secondary)',
  margin: '10px 10px 10px 0',
};

export const orderStatuses = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  READY: 'Ready',
};

const ChangeOrderStatus = ({ isFetching, isLoading, order }) => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { seen, status, uuid } = order;
  const [modalOpen, toggleModal] = useToggle(false);
  const [isRejectModalOpen, toggleReject] = useToggle(false);
  const [isAcceptModalOpen, toggleAccept] = useToggle(false);

  const { formData, handleChangeFormData, setFormData } = useForm<FormDataType>(
    formDataInitialState,
    null,
  );
  const { rejectMessage } = formData;

  const handleOpenRejectModal = () => {
    toggleReject();
    setFormData({ rejectMessage: '' });
  };

  const { ACCEPTED, PENDING, READY, REJECTED } = orderStatuses;

  const [seeOrder, { isLoading: isLoadingSee }] = useSeenOrderMutation();
  const [rejectOrder, { isLoading: isLoadingReject }] = useRejectOrderMutation();
  const [acceptOrder, { isLoading: isLoadingAccept }] = useAcceptOrderMutation();
  const [readyOrder, { isLoading: isLoadingReady }] = useReadyOrderMutation();
  const [pickUpOrder, { isLoading: isLoadingPickUp }] = usePickUpOrderMutation();

  const isLoadingOrder =
    isLoadingSee ||
    isLoadingReject ||
    isLoadingAccept ||
    isLoadingReady ||
    isLoadingPickUp ||
    isFetching;
  const handleSubmitOrderStatus = async ({ key, orderAction }) => {
    try {
      await orderAction(uuid).unwrap();
      toggleModal();
      displaySuccess({ key });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleModal();
      displayError({ key: code });
    }
  };

  const handleSubmitAcceptOrder = async () => {
    try {
      await acceptOrder(uuid).unwrap();
      toggleAccept();
      displaySuccess({ key: 'orders.acceptOrder' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleAccept();
      displayError({ key: code });
    }
  };

  const handleRejectOrder = async () => {
    try {
      await rejectOrder({ id: uuid, message: rejectMessage }).unwrap();
      toggleReject();
      displaySuccess({ key: 'orders.rejectOrder' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleReject();
      displayError({ key: code });
    }
  };

  return (
    <>
      {status === PENDING || status === ACCEPTED ? (
        <div className={styles.orderStatusTitle}>{t('orders.statusTitle')}</div>
      ) : null}
      {!isLoading ? (
        <div>
          {status === PENDING && !seen && (
            <ACButton disabled={isLoadingOrder} onClick={toggleModal} style={buttonStyle}>
              {t('orders.seeOrder')}
            </ACButton>
          )}
          {status === PENDING && seen && (
            <div className={styles.buttonsAcceptReject}>
              <ACButton disabled={isLoadingOrder} onClick={toggleAccept} style={buttonStyle}>
                {t('orders.acceptOrder')}
              </ACButton>
              <ACButton
                disabled={isLoadingOrder}
                onClick={handleOpenRejectModal}
                style={buttonStyle}
              >
                {t('orders.rejectOrder')}
              </ACButton>
            </div>
          )}
          {status === ACCEPTED && (
            <ACButton disabled={isLoadingOrder} onClick={toggleModal} style={buttonStyle}>
              {t('orders.orderReady')}
            </ACButton>
          )}
          {status === READY && (
            <ACButton disabled={isLoadingOrder} onClick={toggleModal} style={buttonStyle}>
              {t('orders.pickUpOrder')}
            </ACButton>
          )}
          {modalOpen && status === PENDING && !seen && (
            <Modal
              buttonText={t('orders.seeOrder')}
              closeModal={toggleModal}
              disabled={isLoadingOrder}
              handleSubmit={() =>
                handleSubmitOrderStatus({
                  key: 'orders.seeOrder',
                  orderAction: seeOrder,
                })
              }
              header={t('orders.titleSeeOrder')}
              icon={<Eye style={style.BlueIconModal} />}
              isShow={modalOpen}
              message={t('orders.descriptionSeeOrder')}
            />
          )}
          {isAcceptModalOpen && (
            <Modal
              buttonText={t('orders.acceptOrder')}
              closeModal={toggleAccept}
              disabled={isLoadingOrder}
              handleSubmit={handleSubmitAcceptOrder}
              header={t('orders.titleAcceptOrder')}
              icon={<TickCircle style={style.BlueIconModal} />}
              isShow={isAcceptModalOpen}
              message={t('orders.descriptionAcceptOrder')}
            />
          )}
          {isRejectModalOpen && (
            <Modal
              body={
                <BodyRejectMessage
                  handleChangeFormData={handleChangeFormData}
                  message={rejectMessage}
                />
              }
              buttonText={t('orders.rejectOrder')}
              closeModal={toggleReject}
              disabled={isLoadingOrder}
              handleSubmit={handleRejectOrder}
              header={t('orders.titleRejectOrder')}
              icon={<CloseCircle style={style.RedIconModal} />}
              isRejectOrder
              isShow={isRejectModalOpen}
              message={t('orders.descriptionRejectOrder')}
            />
          )}
          {modalOpen && (status === ACCEPTED || status === REJECTED) && (
            <Modal
              buttonText={t('orders.orderReady')}
              closeModal={toggleModal}
              disabled={isLoadingOrder}
              handleSubmit={() =>
                handleSubmitOrderStatus({ key: 'orders.readyOrder', orderAction: readyOrder })
              }
              header={t('orders.titleReadyOrder')}
              icon={<TickCircle style={style.BlueIconModal} />}
              isShow={modalOpen}
              message={t('orders.descriptionReadyOrder')}
            />
          )}
          {modalOpen && status === READY && (
            <Modal
              buttonText={t('orders.pickUpOrder')}
              closeModal={toggleModal}
              disabled={isLoadingOrder}
              handleSubmit={() =>
                handleSubmitOrderStatus({ key: 'orders.pickUpOrder', orderAction: pickUpOrder })
              }
              header={t('orders.titlePickUpOrder')}
              icon={<TickCircle style={style.BlueIconModal} />}
              isShow={modalOpen}
              message={t('orders.descriptionPickUpOrder')}
            />
          )}
        </div>
      ) : (
        <ACSpinner background='var(--text-secondary)' size='xs' />
      )}
    </>
  );
};

export default ChangeOrderStatus;
