import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, void>({
    query: () => ({
      url: '/verification/partner-user/issue-sms-code',
      method: 'POST',
    }),
  });
