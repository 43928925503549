import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { PlaceParameters } from './getMapLocation';

const latitude = 45.255;
const longitude = 19.845;

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<any, Parameters>({
    query: ({ accessToken, search }) => {
      return {
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?limit=15&proximity=${longitude}%2C${latitude}&routing=true&types=address,place,country,postcode&language=en,sr,hr&access_token=${accessToken}`,
      };
    },
    transformResponse: (response: any) => {
      return {
        content: response?.features,
      };
    },
  });

export type MapContext = {
  id: string;
  text: string;
  text_sr?: string;
};

export type MapContent = {
  address: string;
  context: MapContext[];
  id: string;
  place_name: string;
  place_type: string[];
  text: string;
};

export type MapLocation = {
  content: MapContent;
  features: MapLocation[];
};

export type Parameters = PlaceParameters & {
  isClicked?: boolean;
  search?: string;
};
