import {
  FORM_ELEMENT_CODE,
  FORM_ELEMENT_FROM,
  FORM_ELEMENT_PLACE,
  FORM_ELEMENT_STATUS,
  FORM_ELEMENT_TO,
} from 'constants/FormConstants';
import { TABLE_ELEMENT_CREATED_AT, TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import {
  ACCEPTED,
  CANCELED,
  PAYMENT_FAILED,
  PENDING,
  PICKED_UP,
  READY,
  REJECTED,
  TOTAL_DISCOUNTED_PRICE,
} from 'features/orders/constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';
import { InfoCircle } from 'iconsax-react';

export const ORDER_STATUS = [
  PENDING,
  ACCEPTED,
  READY,
  REJECTED,
  CANCELED,
  PICKED_UP,
  PAYMENT_FAILED,
];

const { DETAIL_VIEW } = tableActionTypes;

const ordersTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'ordersTable',
  filter: [
    {
      field: FORM_ELEMENT_CODE,
      filterType: 'text',
      title: 'orders.orderFilter',
    },
    {
      field: FORM_ELEMENT_FROM,
      title: 'orders.fromDateFilter',
      filterType: 'date',
    },
    {
      field: FORM_ELEMENT_TO,
      title: 'orders.toDateFilter',
      filterType: 'date',
    },
  ],
  columns: [
    {
      field: FORM_ELEMENT_CODE,
      title: 'orders.codeColumn',
    },
    {
      field: TABLE_ELEMENT_CREATED_AT,
      title: 'orders.dateColumn',
    },
    {
      field: FORM_ELEMENT_STATUS,
      title: 'orders.statusColumn',
    },
    {
      field: TOTAL_DISCOUNTED_PRICE,
      title: 'orders.totalColumn',
    },
    {
      field: FORM_ELEMENT_PLACE,
      title: 'orders.place',
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_PLACE] },

  actions: [
    {
      redirect: '/order',
      header: false,
      name: DETAIL_VIEW,
      icon: <InfoCircle data-testid='detailViewButton' />,
      tooltip: 'orders.viewOrder',
    },
  ],
};

export default ordersTableSettings;
