import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_APP_USER } from 'services/TagsConstants';
import { store } from 'store/store';

import { AppUser } from './getAppUsers';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<AppUser, AppUserParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id } = _arg;
      const appUserResponse = await fetchWithBQ(`/app-user/${id}`);
      const appUserData = appUserResponse.data as AppUser;
      const { countries } = store.getState().globalData;
      const matchedCountry = countries?.find((country) => country.uuid === appUserData.country);
      const appUser = {
        ...appUserData,
        country: matchedCountry?.shortName || '',
      };
      return {
        data: appUser,
      };
    },
    providesTags: [TAG_APP_USER],
  });

export type AppUserParameters = {
  id?: string;
};
