import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  TAG_IN_PREPARATION_PLACES,
  TAG_PLACE,
  TAG_PLACES,
  TAG_UNVERIFIED_PLACES,
} from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/place/${id}/remove-image`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_PLACES, TAG_IN_PREPARATION_PLACES, TAG_UNVERIFIED_PLACES, TAG_PLACE],
  });
