export const BOTTOM_SCROLL_OFFSET = 15;
export const AC_INPUT_MARGIN_BOTTOM = 'md';
export const AC_INPUT_ROUND = 'md';
export const IS_EMPLOYED = 'is-employed';
export const AUTO_COMPLETE_OFF = 'off';
export const FILE = 'file';
export const UPLOAD_FILE = 'uploadFile';
export const UPLOAD_SIZE = 10;
export const UPLOAD_UNIT_MB = 'MB';
export const UPLOAD_IMAGE_PNG = 'image/png';
export const UPLOAD_IMAGE_JPEG = 'image/jpeg';
export const UPLOAD_IMAGE_JPG = 'image/jpg';
export const FORM_ELEMENT_PASSWORD = 'password';
export const FORM_ELEMENT_CONFIRM_PASSWORD = 'confirmPassword';
export const FORM_ELEMENT_PHONE_NUMBER = 'phoneNumber';
export const FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE = 'phoneNumberCountryCode';
export const FORM_ELEMENT_CODE = 'code';
export const FORM_ELEMENT_NAME = 'name';
export const FORM_ELEMENT_DESCRIPTION = 'description';
export const FORM_ELEMENT_PLACE = 'place';
export const FORM_ELEMENT_PRODUCT = 'product';
export const FORM_ELEMENT_SERIALNUMBER = 'serialNumber';
export const FORM_ELEMENT_CATEGORY = 'category';
export const FORM_ELEMENT_COMPANY = 'company';
export const FORM_ELEMENT_STREET = 'street';
export const FORM_ELEMENT_FLOOR = 'floor';
export const FORM_ELEMENT_APARTMENT = 'apartment';
export const FORM_ELEMENT_COUNTRY = 'country';
export const FORM_ELEMENT_CITY = 'city';
export const FORM_ELEMENT_ZIP_CODE = 'zipCode';
export const FORM_ELEMENT_INVENTORY = 'inventory';
export const FORM_ELEMENT_ADD_INVENTORY = 'addInventory';
export const FORM_ELEMENT_EMAIL = 'email';
export const FORM_ELEMENT_COMPANY_REQUEST = 'companyRequest';
export const FORM_ELEMENT_STATUS = 'status';
export const FORM_ELEMENT_STREET_NUMBER = 'streetNumber';
export const FORM_ELEMENT_FIRST_NAME = 'firstName';
export const FORM_ELEMENT_LAST_NAME = 'lastName';
export const FORM_ELEMENT_PHONE = 'phone';
export const FORM_ELEMENT_PRODUCT_CATEGORY = 'productCategory';
export const FORM_ELEMENT_NAME_EN = 'nameEn';
export const FORM_ELEMENT_NAME_SR = 'nameSr';
export const FORM_ELEMENT_NAME_HR = 'nameHr';
export const FORM_ELEMENT_ID_NUMBER = 'idNumber';
export const FORM_ELEMENT_VAT_NUMBER = 'vatNumber';
export const FORM_ELEMENT_FROM = 'from';
export const FORM_ELEMENT_TO = 'to';
export const FORM_ELEMENT_COUNT = 'count';
export const FORM_ELEMENT_RATING = 'rating';
export const FORM_ELEMENT_LAST_STOCK_CHANGED = 'lastStockChangeAt';
export const FORM_ELEMENT_LAST_ORDER_AT = 'lastOrderAt';
export const FORM_ELEMENT_ADDRESS = 'address';
export const FORM_ELEMENT_EMPTY = '/';
export const LANGUAGES = {
  EN: 'en',
  SRB: 'srb',
  HR: 'hr',
};
export const LANGUAGES_OPTIONS = [
  { label: 'English', value: 'en' },
  {
    label: 'Serbian',
    value: 'srb',
  },
  {
    label: 'Croatian',
    value: 'hr',
  },
];

export const LANGUAGES_OPTIONS_LOGIN = [
  { label: 'English', value: 'en', isoName: 'GB' },
  {
    label: 'Serbian',
    value: 'srb',
    isoName: 'RS',
  },
  {
    label: 'Croatian',
    value: 'hr',
    isoName: 'HR',
  },
];
