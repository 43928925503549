import { OptionsType } from 'constants/TypeConstants';
import { Place } from 'features/place/services/createPlace';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { Country } from 'services/country';
import { store } from 'store/store';
import { trimObjectValues } from 'utils/helpers';

import { useLazyGetPlaceByIdQuery, useLazyGetPlacesRecursiveQuery } from '../../place/services';
import { FormDataType } from '../constants/FormConstants';
import { useLazyGetInventoryByIdQuery } from '../services';
import { InventoryDTO } from '../services/createInventory';
import { Inventory } from '../services/getInventories';
import { InventoryParameters } from '../services/getInventoryById';

const findCountry = (countryId: string, entireCountryArray: Country[]) => {
  const foundCountry = entireCountryArray?.find((el) => countryId === el.uuid);
  if (foundCountry) {
    return {
      ...foundCountry,
      uuid: foundCountry?.uuid,
      name: foundCountry?.shortName,
    };
  }
};

export const transformToDTOFormat = (formData: FormDataType): InventoryDTO => {
  const place = formData?.place as OptionsType;
  const country = formData?.country as OptionsType;
  const InventoryDTOObject: InventoryDTO = {
    ...formData,
    place: place?.uuid,
    country: country?.uuid,
  };
  return InventoryDTOObject;
};

export const transformInventoryToInventoryDTO = (inventory: Inventory) => {
  return {
    description: inventory?.description,
    name: inventory?.name,
    place: inventory?.place,
    serialNumber: inventory?.serialNumber,
    type: inventory?.type,
    uuid: inventory?.uuid,
    country: inventory?.country,
  };
};

export const useInventoriesForm = (
  formData?: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  inventoryId?: InventoryParameters,
) => {
  const [fetchInventoryById, { data: inventory, isSuccess: isSuccessInventory }] =
    useLazyGetInventoryByIdQuery();
  const [fetchPlaces, { data: places, isSuccess: isSuccessPlaces }] =
    useLazyGetPlacesRecursiveQuery();
  const [fetchPlace, { data: placeById, isSuccess: isSuccessPlaceById }] =
    useLazyGetPlaceByIdQuery();

  const countries = store.getState().globalData.countries;
  const isSuccessEdit = isSuccessInventory && isSuccessPlaceById;

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedFormData: InventoryDTO = transformToDTOFormat(formData);
    const transformedInventory = transformInventoryToInventoryDTO(inventory);
    const trimmedData = trimObjectValues<FormDataType>(transformedFormData);
    const disabled = _.isEqual(trimmedData, transformedInventory);
    return disabled;
  }, [formData, places, inventory]);

  const initialFormValues = () => {
    const country = findCountry(inventory?.country, countries);
    setFormData({
      name: inventory?.name,
      serialNumber: inventory?.serialNumber,
      type: inventory?.type,
      description: inventory?.description,
      uuid: inventory?.uuid,
      place: placeById?.name,
      country,
    });
  };

  const countriesOptions = useMemo(() => {
    return countries?.map((country) => {
      return {
        ...country,
        name: country?.shortName,
      };
    });
  }, [countries]);

  useEffect(() => {
    if (inventoryId) {
      fetchInventoryById(inventoryId, true);
    }
  }, []);

  useEffect(() => {
    if (inventory) {
      fetchPlace({ id: inventory?.place });
    }
  }, [inventory]);

  useEffect(() => {
    if (isSuccessEdit) initialFormValues();
  }, [isSuccessEdit, inventory]);

  useEffect(() => {
    const country = formData?.country as OptionsType;
    country?.uuid && fetchPlaces({ country: country?.uuid });
  }, [formData?.country, inventory]);

  return {
    isSuccessPlaces,
    isSuccessEdit,
    placeOptions: places?.content,
    inventory,
    places,
    countries,
    shouldDisableSubmitButton,
    countriesOptions,
  };
};

export default useInventoriesForm;
