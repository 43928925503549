import { ACAutocompleteV2, ACCheckBox, ACInput, ACToolTip } from '@antech-consulting/components';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  BOTTOM_SCROLL_OFFSET,
  FORM_ELEMENT_CATEGORY,
  FORM_ELEMENT_CODE,
  FORM_ELEMENT_DESCRIPTION,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_PLACE,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps, OptionsType } from 'constants/TypeConstants';
import { InfoCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { store } from 'store/store';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import {
  FORM_ELEMENT_AUTOMATIC_PICKUP_STOCK_RESET,
  FORM_ELEMENT_AUTOMATIC_WORKING_HOURS_STOCK_RESET,
  FORM_ELEMENT_AVAILABLE,
  FORM_ELEMENT_BASE_PRICE,
  FORM_ELEMENT_DISCOUNTED_PRICE,
  FORM_ELEMENT_INITIAL_DAILY_AVAILABLE,
  FORM_ELEMENT_SHOW_WEIGHT,
  FORM_ELEMENT_WEIGHT,
  FormDataType,
} from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

const inputStyle = { borderRadius: '8px 0 0 8px' };

const ACToolTipAvailableStyle = { width: '350px', zIndex: 1 };

const ACToolTipInitialAvailableStyle = { width: '500px', zIndex: 1 };

const ACInputStyle = {
  label: {
    fontSize: 'var(--font-size-paragraph)',
    color: 'var(--text-transparent)',
  },
  addonWrapperAfter: {
    padding: '0',
  },
  wrapper: {
    width: '100%',
  },
};

const ACToolTipStyle = { width: '500px' };

type BaseFormProductProps = BaseFormProps<FormDataType> & {
  placeName?: string;
  placesOptions?: OptionsType[];
  productCategoryOptions: OptionsType[];
  setFormData?: (formData: FormDataType) => void;
};

const BaseForm = ({
  errors,
  formData,
  handleChange,
  placeName,
  placesOptions,
  productCategoryOptions,
  setFormData,
}: BaseFormProductProps) => {
  const { t } = useTranslation();
  const {
    available,
    basePrice,
    category,
    code,
    description,
    discountedPrice,
    initialDailyAvailable,
    name,
    place,
    uuid,
    weight,
  } = formData;

  const countries = store.getState().globalData.countries;
  const countryData = countries && countries.find((country) => country?.uuid === place?.country);
  const isoFormat = place ? countryData?.isoCurrency : '';

  const handleCheckboxChange = (fieldName, event) => {
    setFormData({ ...formData, [fieldName]: event?.target?.checked });
  };

  return (
    <form className={styles.basicInformationSection}>
      <FormHeader
        primaryText={t('product.subtitleBaseForm')}
        secondaryText={t('product.descriptionBaseForm')}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        errorMessage={errors?.name}
        fullWidth
        hasError={!!errors?.name}
        id={FORM_ELEMENT_NAME}
        label={t('product.name')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_NAME}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={name}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        errorMessage={errors?.description}
        fullWidth
        hasError={!!errors?.description}
        id={FORM_ELEMENT_DESCRIPTION}
        isTextArea
        label={t('product.description')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_DESCRIPTION}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={description}
      />
      <div className={styles.initialDailyAvailableWrapper}>
        <div>
          <ACToolTip
            label={t('product.initialDailyAvailableTooltip')}
            position='bottomLeft'
            style={ACToolTipInitialAvailableStyle}
          >
            <InfoCircle className={styles.infoCircleIcon} />
          </ACToolTip>
        </div>
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          errorMessage={errors?.initialDailyAvailable}
          fullWidth
          hasError={!!errors?.initialDailyAvailable}
          id={FORM_ELEMENT_INITIAL_DAILY_AVAILABLE}
          label={t('product.initialDailyAvailable')}
          marginBottom={AC_INPUT_MARGIN_BOTTOM}
          name={FORM_ELEMENT_INITIAL_DAILY_AVAILABLE}
          onChange={handleChange}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputForm}
          type='text'
          value={initialDailyAvailable}
        />
      </div>
      {!!uuid && (
        <div className={styles.initialDailyAvailableWrapper}>
          <div>
            <ACToolTip
              label={t('product.availableTooltip')}
              position='bottomLeft'
              style={ACToolTipAvailableStyle}
            >
              <InfoCircle className={styles.infoCircleIcon} />
            </ACToolTip>
          </div>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.available}
            fullWidth
            hasError={!!errors?.available}
            id={FORM_ELEMENT_AVAILABLE}
            label={t('product.available')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_AVAILABLE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            type='text'
            value={available}
          />
        </div>
      )}
      <div className={styles.divider} />
      <div className={styles.flexWrapper}>
        <FormHeader
          primaryText={t('product.subtitleOrganizations')}
          secondaryText={t('product.descriptionOrganizations')}
        />
        <ACAutocompleteV2
          bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
          closeOnSelect
          dataTestId='category'
          errorMessage={errors?.category}
          fullWidth
          getOptionLabel={(option) => option?.name}
          getSelectByProperty={(option) => option?.uuid}
          hasError={!!errors?.category}
          id={FORM_ELEMENT_CATEGORY}
          label={t('product.category')}
          marginBottom={AC_INPUT_MARGIN_BOTTOM}
          name={FORM_ELEMENT_CATEGORY}
          onChange={(event) => {
            handleChange(event);
          }}
          options={productCategoryOptions}
          renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputForm}
          value={category}
        />
        {uuid ? (
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            disabled
            fullWidth
            id={FORM_ELEMENT_PLACE}
            label={t('product.place')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_PLACE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            type='string'
            value={placeName}
          />
        ) : (
          <ACAutocompleteV2
            bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
            closeOnSelect
            dataTestId='place'
            errorMessage={errors?.place}
            fullWidth
            getOptionLabel={(option) => option?.name}
            hasError={!!errors?.place}
            id={FORM_ELEMENT_PLACE}
            label={t('product.place')}
            name={FORM_ELEMENT_PLACE}
            onChange={(event) => {
              handleChange(event);
            }}
            options={placesOptions}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={place}
          />
        )}
        <div className={styles.infoCodeWeight}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.code}
            fullWidth
            hasError={!!errors?.code}
            id={FORM_ELEMENT_CODE}
            label={t('product.code')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_CODE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={code}
          />
          <ACInput
            addonAfter='[g]'
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.weight}
            fullWidth
            hasError={!!errors?.weight}
            id={FORM_ELEMENT_WEIGHT}
            label={t('product.weight')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_WEIGHT}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={{
              ...style.ACInputForm,
              affixWrapper: inputStyle,
            }}
            type='text'
            value={weight}
          />
        </div>
      </div>
      <div className={styles.showWeightWrapper}>
        <div className={styles.showWeightInfo}>
          <ACToolTip
            label={t('product.showWeightInfoTooltip')}
            position='bottomLeft'
            style={ACToolTipStyle}
          >
            <InfoCircle />
          </ACToolTip>
          <div className={styles.showWeightText}>{t('product.showWeight')}</div>
        </div>
        <ACCheckBox
          checked={formData?.showWeight}
          dataTestId='showWeight'
          onChange={(event) => handleCheckboxChange(FORM_ELEMENT_SHOW_WEIGHT, event)}
          selectedBgColor='var(--text-secondary)'
          selectedBorderColor='var(--text-transparent)'
          style={style.ACCheckBox}
        />
      </div>

      <div className={styles.divider} />
      <div className={styles.flexWrapper}>
        <FormHeader
          primaryText={t('product.subtitlePricing')}
          secondaryText={t('product.descriptionPricing')}
        />
        <div className={styles.price}>
          <ACInput
            addonAfter={isoFormat}
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.basePrice}
            fullWidth
            hasError={!!errors?.basePrice}
            id={FORM_ELEMENT_BASE_PRICE}
            label={t('product.basePrice')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_BASE_PRICE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyle}
            type='text'
            value={basePrice}
          />
          <ACInput
            addonAfter={isoFormat}
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.discountedPrice}
            fullWidth
            hasError={!!errors?.discountedPrice}
            id={FORM_ELEMENT_DISCOUNTED_PRICE}
            label={t('product.discountedPrice')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_DISCOUNTED_PRICE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyle}
            type='text'
            value={discountedPrice}
          />
        </div>
      </div>

      <div className={styles.pickupHoursWrapper}>
        <div className={styles.pickupHours}>
          <div>{t('product.automaticPickupStockResetLabel')}</div>
          <ACCheckBox
            checked={formData?.automaticPickupStockReset}
            dataTestId='automaticPickupStockReset'
            onChange={(event) =>
              handleCheckboxChange(FORM_ELEMENT_AUTOMATIC_PICKUP_STOCK_RESET, event)
            }
            selectedBgColor='var(--text-secondary)'
            selectedBorderColor='var(--text-transparent)'
            style={style.ACCheckBox}
          />
        </div>
        <div className={styles.pickupHours}>
          <div>{t('product.automaticWorkingHoursStockResetLabel')}</div>
          <ACCheckBox
            checked={formData?.automaticWorkingHoursStockReset}
            dataTestId='automaticWorkingHoursStockReset'
            onChange={(event) =>
              handleCheckboxChange(FORM_ELEMENT_AUTOMATIC_WORKING_HOURS_STOCK_RESET, event)
            }
            selectedBgColor='var(--text-secondary)'
            selectedBorderColor='var(--text-transparent)'
            style={style.ACCheckBox}
          />
        </div>
      </div>
    </form>
  );
};

export default BaseForm;
