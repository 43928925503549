export const GENERAL_CODES = {
  G001: 'objectMissingOrInvalidData',
  G002: 'unauthenticated',
  G003: 'unauthorized',
  G004: 'resourceInUse',
  G005: 'oldVersion',
  G006: 'missingParameters',
  G007: 'mediaTypeNotSupported',
  G008: 'unexpectedError',
  G009: 'invalidEnumValue',
  G010: 'invalidParameterValue',
  G011: 'invalidToken',
  G012: 'tokenExpired',
  G013: 'invalidFieldValue',
  G014: 'notAllowedParameter',
  G015: 'entitityInUse',
  G016: 'methodNotAllowed',
  G017: 'oldVersion',
  G018: 'notImplemented',
  G019: 'malformedJson',
};
