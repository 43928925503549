import { ACTable } from '@antech-consulting/components';
import { useTranslation } from 'react-i18next';

import styles from './StyledACTable.module.scss';

const styleFilterTooltip = { zIndex: '1' };

const StyledACTable = (props: any) => {
  const customScroll = props?.statistics ? 'scroll' : 'unset';
  const customHeight = props?.statistics ? '350px' : 'auto';
  const { t } = useTranslation();

  return (
    <ACTable
      actionsLabel={t('table.actions')}
      filterTooltips={{
        clear: { label: t('filter.clear') },
        close: { label: t('filter.close'), style: styleFilterTooltip },
      }}
      footerResultsLabel={t('table.results')}
      footerRowsDropdownLabel={t('table.rows')}
      noResultsLabel={t('table.noResults')}
      {...props}
      paginationStyle={{
        paginationItemActiveClass: `${styles.paginationButtonActive}`,
      }}
      style={{
        tableWrapper: {
          color: 'var(--text-primary)',
          background: 'var(--text-background)',
          border: 'none',
          boxShadow: 'none',
          height: customHeight,
          overflow: customScroll,
          paddingTop: '15px',
        },
        tableHeaderStyle: {
          color: 'var(--text-primary)',
          background: 'var(--text-background)',
          fontSize: 'var( --font-size-header)',
          fontWeight: '600',
          padding: '10px 0 15px',
        },
        tableColumnHeadStyle: {
          color: 'var(--text-transparent)',
          background: 'var(--background-secondary)',
          fontSize: 'var(--font-size-label)',
          fontWeight: '600',
          letterSpacing: '0.8px',
          padding: '20px 15px',
          textTransform: 'uppercase',
          textAlign: 'left',
        },
        tableRowStyle: {
          color: 'var(--text-transparent)',
          fontSize: 'var(--font-size-label)',
          cursor: 'auto',
        },
        actionsClassName: `${styles.actions}`,
        filterButtonClassName: `${styles.filterButton}`,
        headerActionsClassName: `${styles.headerActions}`,
      }}
    />
  );
};

export default StyledACTable;
