import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { SortProps } from 'constants/TypeConstants';
import { PartnerUserResponse } from 'features/partnerUser/services/getPartnerUsers';
import moment from 'moment';
import {
  TAG_PRODUCT_CATEGORY_REQUEST,
  TAG_PRODUCT_CATEGORY_REQUESTS,
} from 'services/TagsConstants';
import { store } from 'store/store';

import { Country } from '../../../services/country';
import { createQueryString, handleDuplicates } from '../../../utils/helpers';

const BASE_URL = '/product-category-requests';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductCategoryRequests, ProductCategoryRequestsParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const countriesContent = store.getState().auth.userCountry;
      const roles = store.getState().auth.roles;
      const system = roles[ROLE_SYSTEM as never];
      const { dateFormat } = countriesContent;
      const productCategoryRequestsResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const productCategoryRequestsData =
        productCategoryRequestsResponse.data as ProductCategoryRequests;
      const requestersIds = productCategoryRequestsData.content.map((productCategoryRequest) => {
        return productCategoryRequest?.createdBy ? productCategoryRequest?.createdBy : null;
      });
      const ids = handleDuplicates(requestersIds);
      const requestersResponse = system
        ? await fetchWithBQ(`/partner-users?size=${ids?.length}&ids=${ids}`)
        : null;
      const requestersData = requestersResponse
        ? (requestersResponse?.data as PartnerUserResponse)
        : null;
      const categories = productCategoryRequestsData.content.map(
        (productCategoryRequest: ProductCategoryRequest) => {
          const matchedRequester = requestersData
            ? requestersData.content.find((requester) => {
                return requester.uuid === productCategoryRequest.createdBy;
              })
            : null;
          const { createdAt, updatedAt } = productCategoryRequest;
          const formatDateCreate = moment(createdAt).format(dateFormat.toUpperCase());
          const formatDateUpdate = moment(updatedAt).format(dateFormat.toUpperCase());
          return {
            ...productCategoryRequest,
            createdAt: formatDateCreate,
            updatedAt: formatDateUpdate,
            createdBy: matchedRequester ? matchedRequester.email : createdAt,
          };
        },
      );
      return {
        data: {
          content: categories,
          totalElements: productCategoryRequestsData?.totalElements,
          totalPages: productCategoryRequestsData?.totalPages,
          currentPage: productCategoryRequestsData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_PRODUCT_CATEGORY_REQUESTS],
  });

export type ProductCategoryRequestsParameters = {
  country?: Country;
  ids?: string;
  name?: string;
  page?: number;
  size?: number;
  sort?: SortProps;
};

export type ProductCategoryRequests = {
  content: ProductCategoryRequest[];
  currentPage?: number;
  pageable?: {
    pageNumber: number;
  };
  totalElements: number;
  totalPages: number;
};

export type ProductCategoryRequest = {
  createdAt?: string;
  createdBy?: string;
  name: string;
  status?: string;
  updatedAt?: string;
  updatedBy?: string;
  uuid: string;
};
