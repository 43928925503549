export type FormDataType = {
  newPassword: string;
  oldPassword: string;
};

export const formDataInitialState: FormDataType = {
  oldPassword: '',
  newPassword: '',
};

export const FORM_ELEMENT_OLD_PASSWORD = 'oldPassword';
export const FORM_ELEMENT_NEW_PASSWORD = 'newPassword';
