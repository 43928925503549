import {
  STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED,
  STATUS_ELEMENT_APPROVED,
  STATUS_ELEMENT_PENDING,
  STATUS_ELEMENT_REJECTED,
} from 'constants/StatusConstants';
import {
  ACCEPTED,
  CANCELED,
  PAYMENT_FAILED,
  PENDING,
  PICKED_UP,
  READY,
  REJECTED,
} from 'features/orders/constants/TableConstants';

export const getStatusColor = (status: string) => {
  if (status === PENDING || status === ACCEPTED || status === READY) {
    return 'orange';
  } else if (status === REJECTED || status === CANCELED || status === PAYMENT_FAILED) {
    return 'red';
  } else if (status === PICKED_UP) {
    return 'green';
  } else {
    return 'black';
  }
};

export const getCompanyRequestStatusColor = (status: string) => {
  switch (status) {
    case STATUS_ELEMENT_PENDING:
      return 'orange';
    case STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED:
      return 'addInfoColor';
    case STATUS_ELEMENT_APPROVED:
      return 'green';
    case STATUS_ELEMENT_REJECTED:
      return 'red';
    default:
      return 'black';
  }
};
