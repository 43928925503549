import { ACCard } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { ReactElement } from 'react';

import styles from './CardStatistics.module.scss';

const CardStatistics = ({
  header,
  icon,
  statisticsData,
}: {
  header: string;
  icon: ReactElement;
  statisticsData: number;
}) => {
  const CardBody = () => {
    return (
      <>
        <div className={styles.nameBody}>{header}</div>
        <div className={styles.description}>
          <div style={style.IconStatistics}>{icon}</div>
          <div style={style.DescriptionCardStatistics}>{statisticsData}</div>
        </div>
      </>
    );
  };

  return (
    <ACCard
      bodyChildren={<CardBody />}
      className={styles.statisticsCard}
      dataTestiId='cardSystemStatistic'
      key='cardSystemStatistic'
    />
  );
};

export default CardStatistics;
