import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USER } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, ChangePasswordRequest>({
    query: (credentials: ChangePasswordRequest) => ({
      url: 'partner-user/current/change-password',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_USER],
  });

export type ChangePasswordRequest = { newPassword: string; oldPassword: string };
