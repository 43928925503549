import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import {
  blockUserTooltip,
  detailViewTooltip,
  FilterProps,
  INCLUDE_NOT_VERIFIED,
} from 'features/appUser/constants/AppUserConstants';
import { useBlockAppUserMutation, useLazyGetAppUsersQuery } from 'features/appUser/services';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { InfoCircle, UserRemove } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { transformACState } from '../../../../utils/helpers';
import { AppUser } from '../../services/getAppUsers';
import appUsersTableSettings from './appUsersTableSettings';

const TableAppUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [selectedRow, setSelectedRow] = useState<AppUser>(null);
  const [isBlockModalOpen, toggleBlock] = useToggle();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const tableSettings = useTable(appUsersTableSettings);
  const system = roles[ROLE_SYSTEM as never];

  const [fetchAppUsers, { data: appUsers, isFetching, isSuccess }] = useLazyGetAppUsersQuery();

  const handleTableStateChange = (tableState: TableStateProps<FilterProps>) => {
    const systemState = { ...tableState, [INCLUDE_NOT_VERIFIED]: 'true' };
    fetchAppUsers(transformACState(system ? systemState : tableState, TABLE_ELEMENT_CREATED_AT));
  };

  const [blockRequest, { isLoading }] = useBlockAppUserMutation();

  const appUsersTableData = useMemo(() => {
    return appUsers?.content?.map((appUser) => {
      return {
        rawData: appUser,
        render: appUser,
      };
    });
  }, [appUsers?.content]);

  const handleBlock = ({ rowData }: { rowData: AppUser }) => {
    toggleBlock();
    setSelectedRow(rowData);
  };

  const handleDetailView = ({ rowData }: { rowData: AppUser }) => {
    navigate(`/app-user/${rowData?.uuid}`, { state: { previousLocation: location.pathname } });
    setSelectedRow(rowData);
  };

  const additionalActions = [
    {
      icon: <InfoCircle data-testid='detailViewButton' />,
      tooltip: {
        text: t('appUsers.detailView'),
        style: detailViewTooltip,
      },
      onClick: handleDetailView,
    },
    {
      icon: <UserRemove data-testid='blockButton' />,
      tooltip: {
        text: t('appUsers.blockUser'),
        style: blockUserTooltip,
      },
      onClick: handleBlock,
    },
  ];

  const handleBlockAppUser = async () => {
    try {
      await blockRequest(selectedRow?.uuid).unwrap();
      toggleBlock();
      displaySuccess({
        key: t('blockModal.blockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleBlock();
      displayError({ key: code });
    }
  };

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: appUsers?.totalElements,
    isSuccess,
    filters: tableSettings.filter,
  });

  return (
    <>
      <ACLoading
        isLoading={isFetching || isLoading}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={appUsers?.totalElements}
          defaultSort={DEFAULT_SORT}
          filter={filtersToDisplay}
          onItemClicked={(rowData) => handleDetailView({ rowData })}
          onTableStateChange={handleTableStateChange}
          pagesCount={appUsers?.totalPages}
          rows={appUsersTableData}
        />
      </ACLoading>
      {isBlockModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleBlock}
          disabled={isLoading}
          handleSubmit={handleBlockAppUser}
          header={t('blockModal.titleBlock')}
          icon={<UserRemove style={style.RedIconModal} />}
          isShow={isBlockModalOpen}
          message={t('blockModal.descriptionBlock')}
        />
      )}
    </>
  );
};
export default TableAppUsers;
