import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCT_CATEGORIES } from 'services/TagsConstants';

import { ProductCategory } from './getProductCategories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<ProductCategory, NewProductCategory>({
    query: (credentials) => ({
      url: '/product-categories',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_PRODUCT_CATEGORIES],
  });

export type NewProductCategory = {
  nameEn?: string;
  nameHr?: string;
  nameSr?: string;
  showInUserApp?: boolean;
};
