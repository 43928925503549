import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  TAG_PRODUCT_CATEGORY_REQUEST,
  TAG_PRODUCT_CATEGORY_REQUESTS,
} from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, UpdateProductCategoryRequest>({
    query: ({ payload, requestId }) => ({
      url: `/product-category-request/${requestId}`,
      method: 'PATCH',
      body: payload,
    }),
    invalidatesTags: [TAG_PRODUCT_CATEGORY_REQUESTS, TAG_PRODUCT_CATEGORY_REQUEST],
  });

export type UpdateProductCategoryRequest = {
  payload: {
    name: string;
  };
  requestId?: string;
};
