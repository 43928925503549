import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { useGetCurrentQuery, useUpdateCurrentMutation } from 'features/user/services';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInitialAppDataQuery } from 'services';
import { changeLanguage } from 'store/Language';
import { formatLanguageForApi, formatLanguageForI18n } from 'utils/format-language';

import { useAppSelector } from './hooks';

const useInitialAppData = (): boolean => {
  const { isFetching, isSuccess } = useInitialAppDataQuery();
  const {
    data: currentUser,
    isFetching: isFetchingCurrent,
    isSuccess: isCurrentUserSuccess,
  } = useGetCurrentQuery(null, {
    skip: isSuccess,
  });
  const { isFetching: isFetchingAppSettings } = useGetPartnerApplicationSettingsQuery();
  const [updateCurrentUser] = useUpdateCurrentMutation();
  const dispatch = useDispatch();
  const { language } = useAppSelector(({ language }) => language);

  const shouldShowSplashScreen = isFetching || isFetchingCurrent || isFetchingAppSettings;

  useEffect(() => {
    const currentUserLanguage = currentUser?.settings?.dashboard?.language;
    const localLanguageFormattedForApi = formatLanguageForApi(language);
    if (isCurrentUserSuccess && !currentUserLanguage) {
      updateCurrentUser({
        settings: { dashboard: { language: localLanguageFormattedForApi } },
      });
    } else if (isCurrentUserSuccess && currentUserLanguage !== localLanguageFormattedForApi) {
      i18next.changeLanguage(formatLanguageForI18n(currentUserLanguage));
      dispatch(changeLanguage({ language: formatLanguageForI18n(currentUserLanguage) }));
    }
  }, [isCurrentUserSuccess]);

  return shouldShowSplashScreen;
};

export default useInitialAppData;
