import { createSlice } from '@reduxjs/toolkit';

type ApplicationSetting = {
  currentAndroidVersion: string;
  dashboardMaintenanceMode: boolean;
  firebaseTokenValidity: number;
  minimalRequiredAndroidVersion: string;
  mobileMaintenanceMode: boolean;
};

const initialApplicationSetting: ApplicationSetting = {
  currentAndroidVersion: '',
  dashboardMaintenanceMode: false,
  firebaseTokenValidity: 50,
  minimalRequiredAndroidVersion: '',
  mobileMaintenanceMode: false,
};

type ApplicationSettingPayload = {
  payload: ApplicationSetting;
};

const applicationSettingSlice = createSlice({
  name: 'applicationSetting',
  initialState: initialApplicationSetting,
  reducers: {
    setApplicationSetting: (
      state,
      {
        payload: {
          currentAndroidVersion,
          dashboardMaintenanceMode,
          firebaseTokenValidity,
          minimalRequiredAndroidVersion,
          mobileMaintenanceMode,
        },
      }: ApplicationSettingPayload,
    ) => {
      state.currentAndroidVersion = currentAndroidVersion;
      state.dashboardMaintenanceMode = dashboardMaintenanceMode;
      state.firebaseTokenValidity = firebaseTokenValidity;
      state.minimalRequiredAndroidVersion = minimalRequiredAndroidVersion;
      state.mobileMaintenanceMode = mobileMaintenanceMode;
    },
  },
});

export const { setApplicationSetting } = applicationSettingSlice.actions;

export default applicationSettingSlice.reducer;
