import { ACButton, ACSpinner } from '@antech-consulting/components';
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowRight, CloseSquare } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

import { Product } from '../../services/getProducts';
import styles from './ProductsStock.module.scss';

export type ConfirmModalStockProps = {
  cancelProductStockUpdate: () => void;
  confirmProductStockUpdate: () => void;
  isLoading: boolean;
  productsToUpdateAvailability: Product[];
  setOpenModal: (value: boolean) => void;
};

const fontAwesomeIconStyle = {
  marginRight: '5px',
};

const buttonStyle = {
  backgroundColor: 'var(--text-secondary',
};

const iconStyle = {
  color: 'var(--text-secondary)',
};

const ConfirmModalStock = ({
  cancelProductStockUpdate,
  confirmProductStockUpdate,
  isLoading,
  productsToUpdateAvailability,
  setOpenModal,
}: ConfirmModalStockProps) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.modalWrapper}>
      {isLoading && (
        <ACSpinner background='var(--text-secondary)' className={styles.acSpinner} size='xs' />
      )}
      <div className={styles.modalHeader}>
        <h2>{t('product.stock')}</h2>
        <ACButton dataTestid='closeStockButton' onClick={handleClose} style={buttonStyle}>
          <CloseSquare />
        </ACButton>
      </div>
      <div className={styles.stockInfoHeader}>
        <h3>{t('product.name')}</h3>
        <h3>{t('product.stock')}</h3>
      </div>
      <hr />
      <div className={styles.scrollableDiv}>
        {productsToUpdateAvailability.map(({ available, name, oldAvailability, uuid }) => {
          return (
            <div key={uuid}>
              <div className={styles.stockInfo}>
                <div>{name}</div>
                <div className={styles.stockUpdateInfo}>
                  <div>{oldAvailability}</div>
                  <ArrowRight style={iconStyle} />
                  <div>{available}</div>
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
      <div className={styles.confirmationTitle}>{t('product.confirmationTitle')}</div>
      <div className={styles.modalFooter}>
        <ACButton
          dataTestid='discardStockButton'
          onClick={cancelProductStockUpdate}
          variant='outlined'
        >
          <FontAwesomeIcon icon={faBan} style={fontAwesomeIconStyle} />
          {t('footer.discardButton')}
        </ACButton>
        <ACButton
          dataTestid='saveStockButton'
          onClick={confirmProductStockUpdate}
          style={buttonStyle}
          variant='contained'
        >
          <FontAwesomeIcon icon={faFloppyDisk} style={fontAwesomeIconStyle} />
          {t('footer.saveButton')}
        </ACButton>
      </div>
    </div>
  );
};

export default ConfirmModalStock;
