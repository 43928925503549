import { ACButton } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { SmsTracking } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styles from './ForgotPasswordSuccess.module.scss';

const ForgotPasswordSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/forgot-password');
  };

  const handleGoSignIn = () => {
    navigate('/');
  };

  return (
    <div className={styles.forgotSuccess}>
      <div className={styles.form}>
        <SmsTracking className={styles.icon} />
        <div>
          <h3 className={styles.title}>{t('forgotPassword.passwordSuccessTitle')}</h3>
          <p className={styles.description}>{t('forgotPassword.passwordSuccessDescription')}</p>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <ACButton dataTestid='ButtonBack' fullWidth onClick={handleGoBack} style={style.Button}>
          {t('forgotPassword.buttonBack')}
        </ACButton>
        <ACButton dataTestid='ButtonSignUp' fullWidth onClick={handleGoSignIn} style={style.Button}>
          {t('loginForm.buttonSignIn')}
        </ACButton>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
