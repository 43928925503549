import { ACCard } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './UniqueProductsSoldInOrdersCard.module.scss';

const UniqueProductsSoldInOrdersCard = ({
  data,
  header,
  icon,
}: {
  data: Array<string>;
  header: string;
  icon: ReactElement;
}) => {
  const { t } = useTranslation();
  const products = useMemo(() => {
    return data?.map((listData) => {
      return (
        <div className={styles.list} key={'listData'}>
          <div className={styles.icon}>{icon}</div>
          <div>{listData}</div>
        </div>
      );
    });
  }, [data]);

  return (
    <ACCard
      bodyChildren={
        <div>
          <div style={style.StatisticHeader}>{header}</div>
          <div className={styles.descriptionBody}>
            {data?.length !== 0 ? products : t('product.noResultsText')}
          </div>
        </div>
      }
      className={styles.statisticsList}
      dataTestiId='listStatistic'
    />
  );
};

export default UniqueProductsSoldInOrdersCard;
