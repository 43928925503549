import { ACAutocompleteV2, ACButton, ACInput } from '@antech-consulting/components';
import {
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_PHONE_NUMBER,
  FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import {
  authDescriptionRegister,
  authForm,
  authIcon,
  authImage,
  authTitleRegister,
} from 'features/auth/constants/StyleConstants';
import { ErrorsType, HandleChangeType } from 'hooks/useForm';
import { Call } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useGetCountryCodesQuery } from 'services';

import { FormDataTypePhone } from '../../constants/ChangePhoneNumber';
import styles from './ChangePhoneNumber.module.scss';

const styleComponent = {
  ACAutocomplete: {
    wrapper: {
      marginRight: '20px',
    },
    inputWrapper: {
      backgroundColor: 'var(--background)',
      height: '50px',
      border: '1px solid #d9d9d9',
    },
  },
  ACInputStyle: {
    wrapper: {
      height: '50px',
      marginTop: '15px'
    },
    affixWrapper: {
      borderRadius: '8px',
      height: '50px',
    },
  },
};

export type ChangePhoneNumberRequestProps = {
  errors: ErrorsType;
  formData: FormDataTypePhone;
  handleChangeFormData: (event: HandleChangeType) => void;
  handleSubmit: () => void;
};

const ChangePhoneNumberForm = ({
  errors,
  formData,
  handleChangeFormData,
  handleSubmit,
}: ChangePhoneNumberRequestProps) => {
  const { t } = useTranslation();
  const { data: countryCodes, isSuccess } = useGetCountryCodesQuery();
  const { phoneNumber, phoneNumberCountryCode } = formData;
  const countryCodeOptions = isSuccess ? countryCodes : [];
  const CustomLabel = (country) => {
    return (
      <div className={styles.options}>
        <span className={styles.name}>(+{country?.code})</span>
        <div className={styles.name}>{country?.label}</div>
      </div>
    );
  };

  return (
    <div style={authForm}>
      <div style={authImage}>
        <Call style={authIcon} />
      </div>
      <h3 style={authTitleRegister}>{t('changePhoneNumber.title')}</h3>
      <p style={authDescriptionRegister}>{t('changePhoneNumber.description')}</p>
      <div className={styles?.input} data-testid='input'>
        <ACAutocompleteV2
          closeOnSelect
          dataTestId='callingCode'
          errorMessage={errors?.phoneNumberCountryCode}
          fullWidth
          getCustomRender={(country) => CustomLabel(country)}
          getOptionLabel={(options) => {
            return options?.codeLabel;
          }}
          hasError={!!errors?.phoneNumberCountryCode}
          id={FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE}
          label={t('phoneNumber.callingCode')}
          name={FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE}
          onChange={(event) => {
            handleChangeFormData(event);
          }}
          options={countryCodeOptions}
          renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
          rounded={AC_INPUT_ROUND}
          style={styleComponent.ACAutocomplete}
          value={phoneNumberCountryCode}
        />
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          dataTestId='phoneNumber'
          errorMessage={errors?.phoneNumber}
          fullWidth
          hasError={!!errors?.phoneNumber}
          id={FORM_ELEMENT_PHONE_NUMBER}
          label={t('phoneNumber.phoneNumber')}
          name={FORM_ELEMENT_PHONE_NUMBER}
          onChange={handleChangeFormData}
          rounded={AC_INPUT_ROUND}
          style={styleComponent.ACInputStyle}
          type='text'
          value={phoneNumber}
        />
      </div>
      <ACButton
        className={styles.buttonWrapper}
        dataTestid='ButtonChangePhoneNumber'
        fullWidth
        onClick={handleSubmit}
        style={style.Button}
      >
        {t('changePhoneNumber.button')}
      </ACButton>
    </div>
  );
};

export default ChangePhoneNumberForm;
