import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USER_APPLICATION_SETTINGS } from 'services/TagsConstants';

import { FormDataType } from '../constants/UserAppSettingsFormConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, FormDataType>({
    query: (patch) => {
      return {
        url: 'system/user-application-setting',
        method: 'PATCH',
        body: patch,
      };
    },
    invalidatesTags: [TAG_USER_APPLICATION_SETTINGS],
  });
