import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { UserResponse } from './login';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<Omit<UserResponse, 'roles'>, VerifyEmailRequest>({
    query: (credentials) => ({
      url: `/verification/partner-user/verify/email/${credentials?.token}`,
      method: 'POST',
    }),
  });

export type VerifyEmailRequest = { token: string };
