import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_INVENTORIES } from 'services/TagsConstants';

import { Inventory } from './getInventories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<Inventory, InventoryDTO>({
    query: (credentials) => ({
      url: '/inventories',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_INVENTORIES],
  });

export type InventoryDTO = Omit<Inventory, 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy'>;
