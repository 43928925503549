import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { AppUser } from 'features/appUser/services/getAppUsers';

import { createQueryString } from '../../../utils/helpers';

const BASE_URL = '/orders/ratings';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Ratings, RatingParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { place, ...params } = _arg;
      const ordersRatingResponse = await fetchWithBQ(
        createQueryString(params, `${BASE_URL}?place=${place}`),
      );
      const ratingData = ordersRatingResponse.data as Ratings;
      const users = ratingData.content.map((rating: Rating) => {
        return rating?.user;
      });
      const usersResponse = await fetchWithBQ(`/app-users?ids=${users}`);
      const userData = usersResponse.data as UsersResponse;
      const ratings = ratingData.content.map((rating: Rating) => {
        const matchedUser = userData.content.find((user) => user?.uuid === rating?.user);
        return {
          ...rating,
          pickedUpAt: new Date(rating.pickedUpAt).toLocaleString(),
          createdBy: `${matchedUser?.firstName} ${matchedUser?.lastName}`,
          userData: matchedUser,
          comment: rating?.comment || FORM_ELEMENT_EMPTY,
        };
      });
      return {
        data: {
          content: ratings,
          totalElements: ratingData?.totalElements,
          totalPages: ratingData?.totalPages,
        },
      };
    },
  });

export type Rating = {
  code?: string;
  comment?: string;
  createdBy: string;
  firstName?: string;
  lastName?: string;
  order?: string;
  pickedUpAt: string;
  rating: number;
  user?: string;
  userData?: AppUser;
};

export type Ratings = {
  content: Rating[];
  totalElements: number;
  totalPages: number;
  users?: AppUser[];
};

export type RatingParameters = {
  place?: string;
  user?: string;
};

export type UsersResponse = {
  content: AppUser[];
  totalElements: number;
  totalPages: number;
};
