import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PLACE } from 'services/TagsConstants';

import { Employee } from './addPlaceEmployee';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, Employee>({
    query: ({ employee, id }) => ({
      url: `/place/${id}/update-employee`,
      method: 'POST',
      body: employee,
    }),
    invalidatesTags: [TAG_PLACE],
  });
