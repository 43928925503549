export type FormDataType = {
  document?: string;
  nameEn?: string;
  nameHr?: string;
  nameSr?: string;
  showInUserApp?: boolean;
  uuid?: string;
};

export const formDataInitialState: FormDataType = {
  nameEn: '',
  nameSr: '',
  nameHr: '',
  showInUserApp: false,
};
