import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  currentAndroidVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  minimalRequiredAndroidVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  mobileMaintenanceMode: {
    isRequired: true,
  },
  dashboardMaintenanceMode: {
    isRequired: true,
  },
  maintenanceModeTitle: {
    isRequired: true,
  },
  maintenanceModeMessage: {
    isRequired: true,
  },
  firebaseTokenValidity: {
    isRequired: true,
    onlyNumbers: true,
  },
  placeDetail: {
    isRequired: true,
  },
  placeList: {
    isRequired: true,
  },
  productCategoryAll: {
    isRequired: true,
  },
  productDetail: {
    isRequired: true,
  },
  productList: {
    isRequired: true,
  },
};
