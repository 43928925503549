import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USERS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<NewPassword, NewPassword>({
    query: (credentials) => ({
      url: '/partner-user/forgot-password',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_USERS],
  });

export type NewPassword = {
  password: string;
  token: string;
};
