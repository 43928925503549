import { ACButton, ACFilter, ACLoading, ACModal, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_CODE, FORM_ELEMENT_NAME } from 'constants/FormConstants';
import { FORM_ELEMENT_CATEGORIES } from 'features/product/constants/FormConstants';
import { Product } from 'features/product/services/getProducts';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useProducts, { ProductFilters } from 'hooks/useProductStock';
import useToggle from 'hooks/useToggle';
import { Save2 } from 'iconsax-react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';

import { useUpdateProductsStockMutation } from '../../services/index';
import AvailableStock from './AvailableStock';
import ConfirmModalStock from './ConfirmModalStock';
import styles from './ProductsStock.module.scss';

type FilterField = {
  field: string;
  filterSelectData?: { render: string; value: string }[];
  filterType: 'text' | 'selectMulti';
  title: string;
};

const styleFilterTooltip = { zIndex: '1' };
const styleFilters = {
  borderRadius: 8,
  borderColor: 'rgb(217, 217, 217)',
  marginTop: 15,
};
const styleAcFilterButton = { borderRadius: 50 };

const ProductsStock = () => {
  const { t } = useTranslation();
  const [api, productFilters, productAvailability] = useProducts();
  const { data, isFetching } = api;
  const { productList, updateAvailability, updateProductList } = productAvailability;
  const { initialFilters, setFilters } = productFilters;

  const [isConfirmStockModalOpen, toggleConfirmStockModal] = useToggle();
  const [areFiltersVisible, toggleFilters] = useToggle();
  const [updateStock, { isLoading }] = useUpdateProductsStockMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const confirmProductStockUpdate = async () => {
    try {
      const products = productList?.map(({ available, uuid }) => {
        return { product: uuid, amount: available };
      });
      await updateStock({ payload: { products } }).unwrap();
      updateProductList([]);
      displaySuccess({ key: 'productStock.productStockUpdated' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
    toggleConfirmStockModal();
  };

  const cancelProductStockUpdate = () => {
    updateProductList(data);
    updateProductList([]);
    toggleConfirmStockModal();
  };

  const handleItemIndicatorColor = useCallback(
    ({ available, uuid }: Product) => {
      const initialAvailableProducts = data?.find((product) => product?.uuid === uuid)?.available;
      if (initialAvailableProducts > available) return available - initialAvailableProducts;
      else if (initialAvailableProducts < available) return available - initialAvailableProducts;
      else return 0;
    },
    [data],
  );

  const handleListItems = useMemo(
    () =>
      data?.map((initialProduct) => {
        const foundObj = productList?.find(
          (productItem) => initialProduct.uuid === productItem.uuid,
        );
        return foundObj
          ? { ...foundObj, oldAvailability: initialProduct.available }
          : initialProduct;
      }),
    [data, productList],
  );

  const categoriesArray = useMemo(
    () =>
      initialFilters?.categories?.map(({ name, uuid }) => {
        return { render: name, value: uuid };
      }),
    [initialFilters],
  );

  const filterFields: FilterField[] = [
    {
      field: FORM_ELEMENT_NAME,
      title: t('productCategory.name'),
      filterType: 'text',
    },
    {
      field: FORM_ELEMENT_CODE,
      title: t('product.code'),
      filterType: 'text',
    },
    {
      field: FORM_ELEMENT_CATEGORIES,
      title: t('product.categoryColumn'),
      filterType: 'selectMulti',
      filterSelectData: categoriesArray,
    },
  ];

  const handleFilterChange = (data: ProductFilters) => {
    setFilters(data);
  };

  const handleFilterToggle = () => {
    setFilters(initialFilters);
    toggleFilters();
  };

  return (
    <ACLoading
      isLoading={isFetching}
      loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
    >
      <>
        <Divider />
        {areFiltersVisible && (
          <ACFilter
            closeButtonVisible
            dataTestId='productStockFilters'
            filter={filterFields}
            onCloseButtonClick={handleFilterToggle}
            onFilterChanged={handleFilterChange}
            style={styleFilters}
            tooltips={{
              clear: { label: t('filter.clear'), style: styleFilterTooltip },
              close: { label: t('filter.close') },
            }}
          />
        )}
        <div className={styles.openFiltersButton}>
          <div className={styles.title}>{t('product.stockTitle')}</div>
          <ACButton
            className={styles.buttonFilter}
            dataTestid='filterButton'
            onClick={handleFilterToggle}
            style={styleAcFilterButton}
          >
            <FaFilter data-testid='addFilterButton' />
          </ACButton>
        </div>
        {data?.length > 0 && (
          <div className={styles.listWrapper}>
            <ul>
              {handleListItems?.map((product) => (
                <div key={product.uuid}>
                  <AvailableStock
                    handleItemIndicatorColor={handleItemIndicatorColor}
                    product={product}
                    updateAvailability={updateAvailability}
                  />
                </div>
              ))}
            </ul>
          </div>
        )}
        {data?.length === 0 && (
          <h1 className={styles.noResultsText}>{t('product.noResultsText')}</h1>
        )}

        {!!productList?.length && (
          <div className={styles.confirmModalButton}>
            <ACButton
              dataTestid='modalButton'
              onClick={toggleConfirmStockModal}
              style={{ backgroundColor: 'var(--text-secondary)' }}
            >
              <p className={styles.saveProductStockButton}>{t('product.saveProductStock')}</p>
              <Save2 />
            </ACButton>
          </div>
        )}
        {isConfirmStockModalOpen && (
          <ACModal isShow={isConfirmStockModalOpen} onClose={toggleConfirmStockModal}>
            <ConfirmModalStock
              cancelProductStockUpdate={cancelProductStockUpdate}
              confirmProductStockUpdate={confirmProductStockUpdate}
              isLoading={isLoading}
              productsToUpdateAvailability={productList}
              setOpenModal={toggleConfirmStockModal}
            />
          </ACModal>
        )}
      </>
    </ACLoading>
  );
};
export default ProductsStock;
