import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { AppUser, AppUsersResponse } from './getAppUsers';

export const BASE_URL = '/app-users';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<AppUser[], void>({
    async queryFn(_queryParams, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 0;
      const appUsers: AppUser[] = [];
      const fetchAppUsers = async () => {
        const appUsersFromBackend = await fetchWithBQ(`/app-users?page=${page}&sort=email,asc`);
        const {
          content: appUsersContent,
          totalElements,
          totalPages,
        } = appUsersFromBackend.data as AppUsersResponse;

        if (totalElements > 0) {
          appUsers.push(...appUsersContent);
          page = page + 1;
          if (totalPages > page) {
            await fetchAppUsers();
          }
        }
      };
      await fetchAppUsers();
      return { data: appUsers };
    },
    keepUnusedDataFor: 0,
  });
