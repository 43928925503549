import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import ChangePhoneNumberForm from 'features/auth/components/ChangePhoneNumberForm/ChangePhoneNumberForm';
import {
  CountryCodeType,
  formDataInitialStatePhone,
  FormDataTypePhone,
} from 'features/auth/constants/ChangePhoneNumber';
import { validationPhoneSchema } from 'features/auth/constants/ChangePhoneNumberSchema';
import { useChangePhoneNumberMutation } from 'features/auth/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useNavigate } from 'react-router-dom';

const ERROR_CODE_TRY_FOR_MINUTE = 'PU016.3';
const ERROR_CODE_TRY_FOR_HOUR = 'PU017.3';

const ChangePhoneNumber = () => {
  const { displayError, displaySuccess, displayWarning } = useDisplayNotification();
  const navigate = useNavigate();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataTypePhone>(
    formDataInitialStatePhone,
    validationPhoneSchema,
  );
  const { phoneNumber, phoneNumberCountryCode } = formData;
  const [changePhoneNumber] = useChangePhoneNumberMutation();

  const onSubmitRequest = async () => {
    try {
      const { value } = phoneNumberCountryCode as CountryCodeType;
      const phoneNumberChangeDTO = {
        phoneNumber: phoneNumber.toString(),
        phoneNumberCountryCode: value,
      };
      await changePhoneNumber(phoneNumberChangeDTO).unwrap();
      localStorage.setItem(
        'ChangePhoneNumberUserPhoneNumber',
        JSON.stringify({ phoneNumber, phoneNumberCountryCode: value }),
      );
      navigate('/verify-phone-number');
      displaySuccess({ key: 'changePhoneNumber.phoneNumberChanged' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      code === ERROR_CODE_TRY_FOR_MINUTE || code === ERROR_CODE_TRY_FOR_HOUR
        ? displayWarning({ key: code })
        : displayError({ key: code });
    }
  };

  return (
    <ChangePhoneNumberForm
      errors={errors}
      formData={formData}
      handleChangeFormData={handleChangeFormData}
      handleSubmit={() => handleSubmit(onSubmitRequest)}
    />
  );
};

export default ChangePhoneNumber;
