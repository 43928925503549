import { ACButton, ACLoading, ACModal, ACSpinner } from '@antech-consulting/components';
import DetailViewModal from 'components/DetailViewModal/DetailViewModal';
import { Divider } from 'components/Divider/Divider';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { buttonStyle } from 'features/orders/components/DetailViewPlace/DetailViewPlace';
import { FilterProps } from 'features/product/constants/TableConstants';
import { useAppSelector } from 'hooks/hooks';
import useTable from 'hooks/useTable';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { handleNavigateLink, transformACState } from '../../../../utils/helpers';
import { useLazyGetProductRatingQuery } from '../../services/index';
import styles from './ProductReview.module.scss';
import productReviewSettings from './productReviewSettings';

const ProductReview = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableSettings = useTable(productReviewSettings);
  const { actions, isDetailViewEnabled, selectedRow, toggleDetailView } = tableSettings;
  const [fetchProductReview, { data: productsReview, isFetching }] = useLazyGetProductRatingQuery();

  const handleChangeTableData = (tableData: TableStateProps<FilterProps>) => {
    fetchProductReview(transformACState({ ...tableData, id: id }, TABLE_ELEMENT_CREATED_AT));
  };

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const isSystemRoot = roles[ROLE_SYSTEM];

  const productTableData = useMemo(() => {
    return productsReview?.content?.map((review) => {
      return {
        rawData: review,
        render: {
          ...review,
          comment:
            review?.comment?.length > 20
              ? review?.comment.substring(0, 20) + '...'
              : review?.comment,
          createdBy: (
            <>
              <div>{review?.createdBy}</div>
              <div>{review?.user?.email}</div>
            </>
          ),
        },
      };
    });
  }, [productsReview?.content]);

  const ModalBody = () => {
    const { comment, createdAt, createdBy, rating } = selectedRow;

    const handleNavigate = () => {
      handleNavigateLink();
      navigate(`/app-user/${selectedRow?.user?.uuid}`, {
        state: { previousLocation: location.pathname },
      });
    };
    return (
      <div className={styles.modalInfo}>
        <div className={styles.linkSection}>
          <div className={styles.labelInfo}>
            {`${t('productReview.createdBy')}`}
            <span className={styles.ratingInfo}>{createdBy}</span>
          </div>
          {isSystemRoot && (
            <ACButton className={styles.link} onClick={handleNavigate} style={buttonStyle}>
              {t('orders.linkUserDetailView')}
            </ACButton>
          )}
        </div>
        <div className={styles.labelInfo}>
          {`${t('productReview.createdAt')}`}
          <span className={styles.ratingInfo}>{createdAt}</span>
        </div>
        <div className={styles.labelInfo}>
          {`${t('productReview.rating')}`}
          <span className={styles.ratingInfo}>{rating}</span>
        </div>
        <div className={styles.labelInfo}>
          {`${t('productReview.comment')}`}
          <span className={styles.ratingInfo}>{comment}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={actions}
          allItemsCount={productsReview?.totalElements}
          defaultSort={DEFAULT_SORT}
          onTableStateChange={handleChangeTableData}
          pagesCount={productsReview?.totalPages}
          rows={productTableData}
        />
      </ACLoading>
      {isDetailViewEnabled ? (
        <ACModal isShow={isDetailViewEnabled} onClose={toggleDetailView}>
          <DetailViewModal
            headerMessage={t('productReview.headerModal')}
            message={t('productReview.messageModal')}
            setIsDetailViewDialogOpen={toggleDetailView}
          >
            <ModalBody />
          </DetailViewModal>
        </ACModal>
      ) : null}
    </>
  );
};
export default ProductReview;
