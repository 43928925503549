import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { DocumentDTO, SortProps } from 'constants/TypeConstants';
import { TAG_PLACES } from 'services/TagsConstants';
import { store } from 'store/store';

import { createQueryString, handleDateAndTime } from '../../../utils/helpers';
import { Place } from './createPlace';

const BASE_URL = '/places';
const isEmployed = 'is-employed';

export const DECIMAL_PLACES = 2;

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Places, PlaceParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const placeResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const placesData = placeResponse.data as Places;
      const documents = placesData.content.map((place: Place) => {
        return place?.document ? place?.document : null;
      });
      const documentIds = documents.filter((id) => id !== null);
      const documentResponse =
        documentIds?.length > 0
          ? await fetchWithBQ(`/documents/download-links?ids=${documentIds}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const cities = store.getState().globalData.cities;
      const countries = store.getState().globalData.countries;
      const { dateFormat } = store.getState().auth.userCountry;
      const { language } = store.getState().language;
      const places = placesData.content.map((place: Place) => {
        const { lastOrderAt, lastStockChangeAt, ratingCount, ratingSum } = place;
        const matchedCity = cities?.find((city) => city?.uuid === place?.city);
        const matchedCountry = countries?.find((country) => country?.uuid === place?.country);
        const rating = ratingSum / ratingCount;
        const lastOrderAtFormatted = handleDateAndTime(lastOrderAt, dateFormat, language);
        const lastStockChangeAtFormatted = handleDateAndTime(
          lastStockChangeAt,
          dateFormat,
          language,
        );
        const { formattedDate: orderDate, formattedTime: orderTime } = lastOrderAtFormatted;
        const { formattedDate: stockDate, formattedTime: stockTime } = lastStockChangeAtFormatted;
        return {
          ...place,
          city: matchedCity?.name,
          document: documentData ? documentData[place?.document] : null,
          rating: rating ? rating.toFixed(DECIMAL_PLACES) : FORM_ELEMENT_EMPTY,
          country: matchedCountry?.shortName,
          address: `${place?.street} ${place?.streetNumber}, ${matchedCity?.name}`,
          lastStockChangeAt: lastStockChangeAt ? `${stockDate}, ${stockTime}` : FORM_ELEMENT_EMPTY,
          lastOrderAt: lastOrderAt ? `${orderDate}, ${orderTime}` : FORM_ELEMENT_EMPTY,
        };
      });
      return {
        data: {
          content: places,
          totalElements: placesData?.totalElements,
          totalPages: placesData?.totalPages,
          currentPage: placesData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_PLACES],
  });

export type Places = {
  content: Place[];
  currentPage?: number;
  pageable?: {
    pageNumber: number;
  };
  totalElements?: number;
  totalPages?: number;
};

export type PlaceParameters = {
  categories?: string;
  ids?: string;
  [isEmployed]?: string;
  name?: string;
  page?: number;
  productCategories?: string;
  size?: number;
  sort?: SortProps;
  statuses?: string | string[];
};
