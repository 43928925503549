import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { getTableNumberOrdersConstants } from '../../constants/TableStatisticsConstants';
import { NumberOfOrdersPerPlace } from '../../services/getCompanyStatistics';

const TableNumberOfOrdersPerPlace = ({
  header,
  orders,
}: {
  header: string;
  orders: Array<NumberOfOrdersPerPlace>;
}) => {
  const { t } = useTranslation();
  const tableSettings = getTableNumberOrdersConstants(t);
  const rows = useMemo(() => {
    return orders?.map((data) => {
      return {
        rawData: {
          ...orders,
          name: data.name,
          count: data.count,
        },
        render: {
          ...orders,
          name: data.name,
          count: data.count,
        },
      };
    });
  }, [orders]);

  return (
    <StyledACTable
      {...tableSettings}
      headerTitle={header}
      rows={rows}
      statistics
      tableFooter={false}
    />
  );
};
export default TableNumberOfOrdersPerPlace;
