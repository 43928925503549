import { TOKEN_TYPE_PARTNER_VERIFY_PHONE } from 'constants/RolesConstants';

import { useAppSelector } from '../hooks/hooks';
import { CurrentUser } from '../store/Auth';
import styles from './App.module.scss';
import AuthApp from './AuthApp';
import MainApp from './MainApp';

const App = () => {
  const { accessToken, typeUser }: CurrentUser = useAppSelector(({ auth }) => auth);

  return (
    <>
      <div className={styles.app}>
        {accessToken && typeUser !== TOKEN_TYPE_PARTNER_VERIFY_PHONE ? <MainApp /> : <AuthApp />}
      </div>
    </>
  );
};
export default App;
