import { ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import { FILE } from 'constants/FormConstants';
import WorkingHoursForm from 'features/place/components/WorkingHoursForm/WorkingHoursForm';
import { ProductDTO } from 'features/product/services/getProductById';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/product/components/BaseForm/BaseForm';
import UploadForm from '../../features/product/components/UploadForm/UploadForm';
import { formDataInitialState, FormDataType } from '../../features/product/constants/FormConstants';
import { validationSchema } from '../../features/product/constants/ProductSchema';
import useProductsForm, {
  transformToDTOFormat,
} from '../../features/product/hooks/useProductsForm';
import {
  useCreateProductMutation,
  useUploadProductImageMutation,
} from '../../features/product/services';
import { useFileUpload } from '../../hooks/useFileUpload';
import styles from './AddProduct.module.scss';

const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const [createProduct, { isLoading: isLoadingCreateProduct }] = useCreateProductMutation();
  const [uploadDocument] = useUploadProductImageMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const [file, error, handleFileChange] = useFileUpload();

  const { categoryOptions, isSuccessAdd, placeOptions } = useProductsForm(
    formData,
    setFormData,
    null,
    file === '',
    error,
    id,
  );

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const productData = Object.fromEntries(
      Object.entries(trimmedData).filter(([_, v]) => v !== ''),
    ) as FormDataType;
    const { description, ...restData } = productData;
    const productAddData = description !== '' ? productData : restData;
    const productAddDTO: ProductDTO = transformToDTOFormat({ ...productAddData }, null);
    const product = omit(productAddDTO, ['productName']);
    try {
      const response = await createProduct(product).unwrap();
      if (response && file) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        uploadDocument({
          file: dataImage,
          id: response?.uuid,
        });
      }
      const path = location?.state?.previousLocation || `/products/${response?.place}?value=0`;
      navigate(path);
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'product.addProduct' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = useCallback(() => {
    id || location?.state?.selectedProduct ? navigate(-1) : navigate('/choose-place');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }, [location?.state?.selectedProduct]);

  return isSuccessAdd && !isLoadingCreateProduct ? (
    <>
      <PageHeader primaryText={t('product.titleCreate')} />
      <div className={styles.wrapper}>
        <>
          <BaseForm
            errors={errors}
            formData={formData}
            handleChange={handleChangeFormData}
            placesOptions={placeOptions}
            productCategoryOptions={categoryOptions}
            setFormData={setFormData}
          />
          <div style={{ flex: 1 }}>
            <UploadForm file={file} formData={formData} handleChange={handleFileChange} />
          </div>
        </>
      </div>
      <WorkingHoursForm
        errors={errors}
        handleChange={handleChangeFormData}
        headerPrimaryText={t('product.pickupHours')}
        headerSecondaryText={t('product.addPickupHours')}
        hours={formData?.pickupHours}
      />
      <FormStickyFooter
        buttonsDisabled={isLoadingCreateProduct}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={isLoadingCreateProduct}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddProduct;
