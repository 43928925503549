import api from '../../../services/api';
import blockAppUser from '../../appUser/services/blockAppUser';
import getAppUsers from '../../appUser/services/getAppUsers';
import getBlockedAppUsers from '../../appUser/services/getBlockedAppUsers';
import unblockAppUser from '../../appUser/services/unblockAppUser';
import getAppUserById from './getAppUserById';
import getAppUsersRecursive from './getAppUsersRecursive';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAppUsers: getAppUsers(build),
    blockAppUser: blockAppUser(build),
    getBlockedAppUsers: getBlockedAppUsers(build),
    unblockAppUser: unblockAppUser(build),
    getAppUsersRecursive: getAppUsersRecursive(build),
    getAppUserById: getAppUserById(build),
  }),
  overrideExisting: false,
});

export const {
  useBlockAppUserMutation,
  useGetAppUserByIdQuery,
  useLazyGetAppUserByIdQuery,
  useLazyGetAppUsersQuery,
  useLazyGetAppUsersRecursiveQuery,
  useLazyGetBlockedAppUsersQuery,
  useUnblockAppUserMutation,
} = userApi;
