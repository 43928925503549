import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_BLOCKED_PARTNER_USERS } from 'services/TagsConstants';
import { createQueryString } from 'utils/helpers';

import { PartnerUserParameters, PartnerUserResponse } from './getPartnerUsers';

const BASE_URL = '/partner-users/blocked';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PartnerUserResponse, PartnerUserParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const blockedPartnerUsersResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const blockedPartnerUsersData = blockedPartnerUsersResponse.data as PartnerUserResponse;
      return {
        data: {
          content: blockedPartnerUsersData?.content,
          totalElements: blockedPartnerUsersData?.totalElements,
          totalPages: blockedPartnerUsersData?.totalPages,
        },
      };
    },
    providesTags: [TAG_BLOCKED_PARTNER_USERS],
  });
