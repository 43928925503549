import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearNotification, NotificationState } from 'store/Notification';

const useNotification = (
  messagePayload: NotificationState,
): {
  closeMessage: () => void;
  message: string;
  open: boolean;
  type: string;
} => {
  const { key, open, timeout, type } = messagePayload;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeMessage = () => {
    dispatch(clearNotification());
  };

  const closeMessageAfterInterval = () => {
    setTimeout(() => {
      closeMessage();
    }, timeout);
  };

  useEffect(() => {
    closeMessageAfterInterval();
  }, [key]);

  return { closeMessage, message: t(`${key}`), open, type };
};

export default useNotification;
