import ChangePhoneNumber from '../ChangePhoneNumber/ChangePhoneNumber';
import Password from '../PasswordTab/PasswordTab';

const SettingsTab = () => {
  return (
    <>
      <Password />
      <ChangePhoneNumber />
    </>
  );
};

export default SettingsTab;
