import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UploadRequest } from 'constants/TypeConstants';
import {
  TAG_IN_PREPARATION_PLACES,
  TAG_PLACE,
  TAG_PLACES,
  TAG_UNVERIFIED_PLACES,
} from 'services/TagsConstants';
import { setErrorMessage, setInfoMessage, setSuccessMessage } from 'store/Notification';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<UploadDocumentPlaceDTO, UploadRequest>({
    query: (credentials: UploadRequest) => ({
      url: `/place/${credentials.id}/upload-image`,
      method: 'POST',
      body: credentials.file,
    }),

    async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
      dispatch(setInfoMessage({ key: 'upload.info' }));
      try {
        const { data: updatePlace } = await queryFulfilled;
        if (updatePlace) {
          dispatch(setSuccessMessage({ key: 'upload.success' }));
        }
      } catch {
        dispatch(setErrorMessage({ message: 'upload.error' }));
      }
    },
    invalidatesTags: [TAG_PLACES, TAG_IN_PREPARATION_PLACES, TAG_UNVERIFIED_PLACES, TAG_PLACE],
  });

export type UploadDocumentPlaceDTO = {
  place?: string;
  uuid?: string;
};
