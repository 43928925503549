import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import {
  CountryCodeType,
  formDataInitialStatePhone,
  FormDataTypePhone,
} from 'features/user/constants/ChangePhoneNumber';
import { validationPhoneSchema } from 'features/user/constants/UserProfileSchema';
import { useChangePhoneNumberRequestMutation } from 'features/user/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useState } from 'react';

import ChangePhoneNumberCode from './ChangePhoneNumberCode';
import ChangePhoneNumberRequest from './ChangePhoneNumberRequest';

const ChangePhoneNumber = () => {
  const [isRequestDone, setIsRequestDone] = useState(false);
  const { displayError, displaySuccess } = useDisplayNotification();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataTypePhone>(
    formDataInitialStatePhone,
    validationPhoneSchema,
  );
  const { phoneNumber, phoneNumberCountryCode } = formData;
  const [changePhoneNumber] = useChangePhoneNumberRequestMutation();

  const onSubmitRequest = async () => {
    try {
      const { value } = phoneNumberCountryCode as CountryCodeType;
      const phoneNumberChangeDTO = {
        phoneNumber: phoneNumber.toString(),
        phoneNumberCountryCode: value,
      };
      await changePhoneNumber(phoneNumberChangeDTO).unwrap();
      setIsRequestDone(true);
      displaySuccess({ key: 'changePhoneNumber.phoneNumber' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <>
      <hr />
      {isRequestDone ? (
        <ChangePhoneNumberCode setIsRequestDone={setIsRequestDone} />
      ) : (
        <ChangePhoneNumberRequest
          errors={errors}
          formData={formData}
          handleChangeFormData={handleChangeFormData}
          handleSubmit={() => handleSubmit(onSubmitRequest)}
        />
      )}
    </>
  );
};

export default ChangePhoneNumber;
