import { ACTabs } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { TAB_FIRST, TAB_SECOND, TAB_ZERO } from 'constants/TabsConstants';
import PartnerAppSettings from 'features/maintenance/components/PartnerAppMaintenance/PartnerAppSettings/PartnerAppSettings';
import UserAppSettings from 'features/maintenance/components/UserAppMaintenance/UserAppSettings/UserAppSettings';
import WebAppSettings from 'features/maintenance/components/WebAppMaintenance/WebAppSettings/WebAppSettings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Maintenance.module.scss';

const Maintenance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/maintenance?value=${id}`);
  };

  const tabs = [
    {
      label: t('maintenance.userAppSettingsTab'),
      id: TAB_ZERO,
      content: <UserAppSettings />,
    },
    {
      label: t('maintenance.partnerAppSettingsTab'),
      id: TAB_FIRST,
      content: <PartnerAppSettings />,
    },
    {
      label: t('maintenance.webAppSettingsTab'),
      id: TAB_SECOND,
      content: <WebAppSettings />,
    },
  ];

  return (
    <>
      <h3 className={styles.title}>{t('maintenance.title')}</h3>
      <ACTabs
        defaultTab={defaultValueTab}
        onChange={handleChange}
        style={style.ACTabs}
        tabs={tabs}
      />
    </>
  );
};

export default Maintenance;
