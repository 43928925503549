import {
  ACAutocompleteV2,
  ACCheckBox,
  ACInput,
  ACSpinner,
  ACToolTip,
} from '@antech-consulting/components';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  BOTTOM_SCROLL_OFFSET,
  FORM_ELEMENT_APARTMENT,
  FORM_ELEMENT_CATEGORY,
  FORM_ELEMENT_COMPANY,
  FORM_ELEMENT_DESCRIPTION,
  FORM_ELEMENT_FLOOR,
  FORM_ELEMENT_LAST_ORDER_AT,
  FORM_ELEMENT_LAST_STOCK_CHANGED,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_PHONE_NUMBER,
  FORM_ELEMENT_RATING,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps, OptionsType } from 'constants/TypeConstants';
import {
  FORM_ELEMENT_BENEFICIARY_ACCOUNT,
  FORM_ELEMENT_CHOSEN_LOCATION,
  FORM_ELEMENT_FEE,
  FORM_ELEMENT_MINIMUM_ORDER_AMOUNT,
  FORM_ELEMENT_WEBSITE,
} from 'features/place/constants/PlaceContants';
import { InfoCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Country } from 'services/country';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { FormDataTypePlace } from '../../constants/FormConstants';
import MapForm from '../MapForm/MapForm';
import UploadForm from '../UploadForm/UploadForm';
import styles from './BaseForm.module.scss';

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type MapData = {
  addMarker: boolean;
  latitudeMap: number;
  longitudeMap: number;
};

type BaseFormPlaceProps = BaseFormProps<FormDataTypePlace> & {
  cities?: OptionsType[];
  companies: OptionsType[];
  coordinates: Coordinates;
  countries?: OptionsType[];
  file?: File;
  handleFileChange: (e: any, prev: File[] | File) => void;
  handleFileDelete: () => void;
  handleMapActions: (event: any, geolocate: boolean) => void;
  handleMapInputChange?: (event: string) => void;
  handleSearchMap?: (event: any) => void;
  handleViewportChange?: (viewport: any) => void;
  isFetching?: boolean;
  isFetchingLocation?: boolean;
  mapData: MapData;
  mapOptions?: OptionsType[];
  mapViewport?: any;
  onScrollCities?: () => void;
  onScrollCompanies?: (event) => void;
  placeCategoriesOptions: OptionsType[];
  placeId?: string;
  setFormData?: (formData: FormDataTypePlace) => void;
  system?: string;
};

const ACInputStyle = {
  label: {
    fontSize: 'var(--font-size-paragraph)',
    color: 'var(--text-transparent)',
  },
  addonWrapperAfter: {
    padding: '0',
  },
  wrapper: {
    width: '100%',
  },
};

const ACInputStyleFirstChild = {
  label: {
    fontSize: 'var(--font-size-paragraph)',
    color: 'var(--text-transparent)',
  },
  wrapper: {
    marginRight: '20px',
  },
};

const ACToolTipStyle = { width: '500px', zIndex: 1 };

const BaseForm = ({
  companies,
  coordinates,
  errors,
  formData,
  handleChange,
  handleFileChange,
  handleFileDelete,
  handleMapActions,
  handleMapInputChange,
  handleSearchMap,
  handleViewportChange,
  isFetching,
  isFetchingLocation,
  mapData,
  mapOptions,
  mapViewport,
  placeCategoriesOptions,
  placeId,
  setFormData,
  system,
}: BaseFormPlaceProps) => {
  const { t } = useTranslation();
  const {
    apartment,
    beneficiaryAccount,
    category,
    chosenLocation,
    company,
    country,
    description,
    fee,
    floor,
    lastOrderAt,
    lastStockChangeAt,
    minimumOrderAmount,
    minimumOrderAmountRoot,
    name,
    phoneNumber,
    rating,
    website,
  } = formData;
  const { isoCurrency, minimumOrderAmount: countryOrderAmount } = (country as Country) || {};
  const minimumOrder = countryOrderAmount ? `${countryOrderAmount} ${isoCurrency}` : '';
  const isoFormat = minimumOrder ? isoCurrency : '';
  const headerTitleSecondary = placeId ? t('form.editBaseSubtitle') : t('form.addBaseSubtitle');
  const headerTitle = placeId
    ? t('form.place.editSubtitleAddress')
    : t('form.place.addSubtitleAddress');

  const beneficiarySubtitle = system
    ? t('form.place.beneficiaryFormSubtitle')
    : t('form.place.beneficiarySubtitle');

  const productConfigurationTitle =
    system && placeId
      ? t('form.place.formTitleProductsInformation')
      : t('form.place.formTitleProductsConfiguration');
  const productConfigurationDescription =
    system && placeId
      ? t('form.place.formDescriptionProductsInformation')
      : t('form.place.formDescriptionProductsConfiguration');

  const locationError =
    !!errors?.city ||
    !!errors?.country ||
    !!errors?.streetNumber ||
    !!errors?.street ||
    !!errors?.zipCode;

  const handleShowWeight = (event) => {
    setFormData({ ...formData, showWeight: event?.target?.checked });
  };

  return (
    <form className={styles.editPlaceForm}>
      <div className={styles.formInformation}>
        <div className={styles.basicInfo}>
          <FormHeader primaryText={t('form.formBaseTitle')} secondaryText={headerTitleSecondary} />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='placeName'
            errorMessage={errors?.name}
            fullWidth
            hasError={!!errors?.name}
            id={FORM_ELEMENT_NAME}
            label={t('place.name')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_NAME}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyle}
            value={name}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='placeDescription'
            errorMessage={errors?.description}
            fullWidth
            hasError={!!errors?.description}
            id={FORM_ELEMENT_DESCRIPTION}
            isTextArea={true}
            label={t('place.description')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_DESCRIPTION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyle}
            value={description}
          />
          <ACAutocompleteV2
            closeOnSelect
            dataTestId='placeCategory'
            errorMessage={errors?.category}
            fullWidth
            getOptionLabel={(option) => option?.name}
            hasError={!!errors?.category}
            label={t('place.category')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_CATEGORY}
            onChange={(event) => {
              handleChange(event);
            }}
            options={placeCategoriesOptions}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyleFirstChild}
            value={category}
          />
          {placeId ? (
            <div className={styles.editInfo}>
              <ACInput
                autoComplete={AUTO_COMPLETE_OFF}
                dataTestId='placeCompany'
                disabled
                fullWidth
                id={FORM_ELEMENT_COMPANY}
                label={t('place.company')}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name='companyName'
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyleFirstChild}
                value={company}
              />
              <ACInput
                dataTestId='placeRating'
                disabled
                fullWidth
                id={FORM_ELEMENT_RATING}
                label={t('place.rating')}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name='placeRating'
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyle}
                value={rating}
              />
            </div>
          ) : (
            <ACAutocompleteV2
              bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
              closeOnSelect
              dataTestId='placeCompany'
              errorMessage={errors?.company}
              fullWidth
              getOptionLabel={(option) => option?.name}
              getSelectByProperty={(option) => option?.uuid}
              hasError={!!errors?.company}
              label={t('place.company')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_COMPANY}
              onChange={(event) => {
                handleChange(event);
              }}
              options={companies}
              renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyle}
              value={company}
            />
          )}
          <div className={styles.addressInformation}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='placePhoneNumber'
              errorMessage={errors?.phoneNumber}
              fullWidth
              hasError={!!errors?.phoneNumber}
              id={FORM_ELEMENT_PHONE_NUMBER}
              label={t('place.phoneNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_PHONE_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyleFirstChild}
              type='string'
              value={phoneNumber}
            />
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='placeWebsite'
              errorMessage={errors?.website}
              fullWidth
              hasError={!!errors?.website}
              id={FORM_ELEMENT_WEBSITE}
              label={t('place.website')}
              name={FORM_ELEMENT_WEBSITE}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyle}
              type='string'
              value={website}
            />
          </div>
          <FormHeader
            primaryText={t('form.place.beneficiaryFormTitle')}
            secondaryText={beneficiarySubtitle}
          />
          <div className={`${styles.addressInformation} ${styles.beneficiary}`}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='placeBeneficiaryAccount'
              errorMessage={errors?.beneficiaryAccount}
              fullWidth
              hasError={!!errors?.beneficiaryAccount}
              id={FORM_ELEMENT_BENEFICIARY_ACCOUNT}
              label={t('place.beneficiaryAccount')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_BENEFICIARY_ACCOUNT}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyleFirstChild}
              type='string'
              value={beneficiaryAccount}
            />
            {!!system && (
              <ACInput
                autoComplete={AUTO_COMPLETE_OFF}
                dataTestId='placeFee'
                errorMessage={errors?.fee}
                fullWidth
                hasError={!!errors?.fee}
                id={FORM_ELEMENT_FEE}
                label={t('place.fee')}
                name={FORM_ELEMENT_FEE}
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyle}
                type='string'
                value={fee}
              />
            )}
          </div>
          <FormHeader
            primaryText={productConfigurationTitle}
            secondaryText={productConfigurationDescription}
          />
          <div className={styles.showWeightWrapper}>
            <div className={styles.showWeightInfo}>
              <ACToolTip
                label={t('place.showWeightInfoTooltip')}
                position='bottomLeft'
                style={ACToolTipStyle}
              >
                <InfoCircle />
              </ACToolTip>
              <div className={styles.showWeightText}>{t('place.showWeight')}</div>
            </div>
            <ACCheckBox
              checked={formData?.showWeight}
              onChange={(event) => handleShowWeight(event)}
              selectedBgColor='var(--text-secondary)'
              selectedBorderColor='var(--text-transparent)'
              style={style.ACCheckBox}
            />
          </div>
          {system && placeId && (
            <div className={styles.activityInformation}>
              <ACInput
                disabled
                fullWidth
                id={FORM_ELEMENT_LAST_STOCK_CHANGED}
                label={t('place.lastStockChangeAt')}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name={FORM_ELEMENT_LAST_STOCK_CHANGED}
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyleFirstChild}
                value={lastStockChangeAt}
              />
              <ACInput
                disabled
                fullWidth
                id={FORM_ELEMENT_LAST_ORDER_AT}
                label={t('place.lastOrderAt')}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name={FORM_ELEMENT_LAST_ORDER_AT}
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyle}
                value={lastOrderAt}
              />
            </div>
          )}
        </div>
        <UploadForm
          formData={formData}
          handleChange={handleFileChange}
          handleFileDelete={handleFileDelete}
          placeId={placeId}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.formInformation}>
        <div className={styles.basicInfo}>
          <FormHeader primaryText={t('form.place.formTitleAddress')} secondaryText={headerTitle} />
          <ACAutocompleteV2
            bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
            closeOnSelect
            dataTestId='placeLocation'
            errorMessage={t('validation.searchLocationError')}
            fullWidth
            getOptionLabel={(option) => option?.name}
            hasError={locationError}
            isLoading={isFetchingLocation}
            label={t('place.searchLocation')}
            loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_CHOSEN_LOCATION}
            onChange={handleSearchMap}
            onInputChange={(value) => handleMapInputChange(value)}
            options={mapOptions}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={ACInputStyleFirstChild}
            value={!isFetching ? chosenLocation : ''}
          />
          <div className={styles.addressInformation}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='placeFloor'
              errorMessage={errors?.floor}
              fullWidth
              hasError={!!errors?.floor}
              id={FORM_ELEMENT_FLOOR}
              label={t('place.floor')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_FLOOR}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyleFirstChild}
              type='string'
              value={floor}
            />
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='placeApartment'
              errorMessage={errors?.apartment}
              fullWidth
              hasError={!!errors?.apartment}
              id={FORM_ELEMENT_APARTMENT}
              label={t('place.apartment')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_APARTMENT}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={ACInputStyle}
              type='string'
              value={apartment}
            />
          </div>
          <div className={styles.addressInformation}>
            {system ? (
              <ACInput
                addonAfter={isoFormat}
                autoComplete={AUTO_COMPLETE_OFF}
                dataTestId='placeMinimumOrderAmountRoot'
                errorMessage={errors?.minimumOrderAmountRoot}
                fullWidth
                hasError={!!errors?.minimumOrderAmountRoot}
                id='minimumOrderAmountRoot'
                label={`${t('place.minimumOrderAmount')}`}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name='minimumOrderAmountRoot'
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyle}
                value={minimumOrderAmountRoot}
              />
            ) : (
              <ACInput
                addonAfter={isoFormat}
                autoComplete={AUTO_COMPLETE_OFF}
                dataTestId='placeMinimumOrderAmount'
                errorMessage={errors?.minimumOrderAmount}
                fullWidth
                hasError={!!errors?.minimumOrderAmount}
                id={FORM_ELEMENT_MINIMUM_ORDER_AMOUNT}
                label={`${t('place.minimumOrderAmount')}: ${minimumOrder}`}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name={FORM_ELEMENT_MINIMUM_ORDER_AMOUNT}
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={ACInputStyle}
                value={minimumOrderAmount}
              />
            )}
          </div>
        </div>
        <MapForm
          coordinates={coordinates}
          formData={formData}
          handleMapActions={handleMapActions}
          handleViewportChange={handleViewportChange}
          mapData={mapData}
          mapViewport={mapViewport}
          placeId={placeId}
        />
      </div>
    </form>
  );
};

export default BaseForm;
