const API_URL =
  process.env.REACT_APP_IS_PRODUCTION !== '1'
    ? process.env.REACT_APP_CORE_DEV_URL
    : process.env.REACT_APP_CORE_PRODUCTION_URL;
const MAP_KEY = process.env.REACT_APP_MAP_KEY;

const Config = {
  API_URL: API_URL,
  MAP_KEY: MAP_KEY,
  MAP_URL: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
};
export default Config;
