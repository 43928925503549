import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { CityDTO, OptionsType } from 'constants/TypeConstants';
import { TAG_CITY } from 'services/TagsConstants';
import { createQueryString } from 'utils/helpers';

export type SortProps = {
  field: string;
  type: string;
};

const BASE_URL = '/cities';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CitiesResponse, CitiesParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const citiesResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const citiesData = citiesResponse.data as CitiesResponse;
      return {
        data: {
          content: citiesData?.content,
          totalElements: citiesData?.totalElements,
          totalPages: citiesData?.totalPages,
          currentPage: citiesData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_CITY],
  });

export type CitiesResponse = {
  content: CityDTO[];
  currentPage?: number;
  pageable?: {
    pageNumber?: number;
  };
  totalElements?: number;
  totalPages?: number;
};

export type CitiesParameters = {
  country?: string;
  page?: number;
  size?: number;
  sort?: SortProps;
};
