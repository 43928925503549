import { Country } from 'services/country';
import { CountryCodesResponse } from 'services/getCountryCodes';

export type CountryType = {
  name: string;
  uuid: string;
};
export type FormDataType = {
  confirmPassword?: string;
  country: string | Country;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  phoneNumberCountryCode: string | number | CountryCodesResponse;
};

export const formDataInitialState: FormDataType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phoneNumber: '',
  country: '',
  confirmPassword: '',
  phoneNumberCountryCode: '',
};
