import api from '../../../services/api';
import createInventory from './createInventory';
import deleteInventory from './deleteInventory';
import getInventories from './getInventories';
import getInventoryById from './getInventoryById';
import updateInventory from './updateInventory';

export const inventoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInventories: getInventories(build),
    getInventoryById: getInventoryById(build),
    createInventory: createInventory(build),
    updateInventory: updateInventory(build),
    deleteInventory: deleteInventory(build),
  }),
  overrideExisting: false,
});

export const {
  useCreateInventoryMutation,
  useDeleteInventoryMutation,
  useGetInventoriesQuery,
  useLazyGetInventoriesQuery,
  useLazyGetInventoryByIdQuery,
  useUpdateInventoryMutation,
} = inventoryApi;
