import { ACButton, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { FILE, UPLOAD_FILE } from 'constants/FormConstants';
import WorkingHoursForm from 'features/place/components/WorkingHoursForm/WorkingHoursForm';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { Archive, Trash } from 'iconsax-react';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style, styleFooterIcon } from '../../constants/StyleConstants';
import BaseForm from '../../features/product/components/BaseForm/BaseForm';
import UploadForm from '../../features/product/components/UploadForm/UploadForm';
import { formDataInitialState, FormDataType } from '../../features/product/constants/FormConstants';
import { validationSchema } from '../../features/product/constants/ProductSchema';
import useProductsForm, {
  transformToDTOFormat,
} from '../../features/product/hooks/useProductsForm';
import {
  useArchiveProductMutation,
  useDeleteProductMutation,
  useRemoveProductImageMutation,
  useUpdateProductMutation,
  useUpdateProductStockMutation,
  useUploadProductImageMutation,
} from '../../features/product/services';
import { ProductDTO } from '../../features/product/services/getProductById';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useForm } from '../../hooks/useForm';
import useToggle from '../../hooks/useToggle';
import styles from './EditProduct.module.scss';

const flexPage = { flex: 1 };

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [stateError] = useState<boolean>(false);
  const [isDocumentRemoved, setIsDocumentRemove] = useState<boolean>(false);

  const [update, { isLoading: isLoadingUpdateProduct }] = useUpdateProductMutation();
  const [uploadDocument] = useUploadProductImageMutation();
  const [removeDocument, { isLoading: isLoadingRemove, isSuccess: isSuccessRemoveDocument }] =
    useRemoveProductImageMutation();
  const [updateProductStock, { isLoading: isLoadingUpdateStock }] = useUpdateProductStockMutation();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const [file, error, handleFileChange, lastUploadAction] = useFileUpload();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useToggle();
  const [isDeleteDialogOpen, toggleDelete] = useToggle();
  const [deleteProduct, { isLoading: isLoadingDelete }] = useDeleteProductMutation();
  const [archiveProduct, { isLoading: isLoadingArchive }] = useArchiveProductMutation();

  const [isArchiveModalOpen, toggleArchive] = useToggle();

  const { placeData, product } = useProductsForm(formData, setFormData, { id }, file, stateError);

  const { categoryOptions, isSuccessEdit, placeOptions, shouldDisableSubmitButton } =
    useProductsForm(formData, setFormData, { id }, file, stateError);
  const buttonDisabled = shouldDisableSubmitButton && !isSuccessRemoveDocument;

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const {
      fridayFrom,
      fridayTo,
      mondayFrom,
      mondayTo,
      saturdayFrom,
      saturdayTo,
      sundayFrom,
      sundayTo,
      thursdayFrom,
      thursdayTo,
      tuesdayFrom,
      tuesdayTo,
      wednesdayFrom,
      wednesdayTo,
      ...restFormData
    } = trimmedData;
    const productUpdateDTO: ProductDTO = transformToDTOFormat(
      {
        ...restFormData,
        uuid: id,
      },
      id,
    );
    try {
      if (productUpdateDTO?.available !== product?.available) {
        await updateProductStock({
          uuid: productUpdateDTO?.uuid,
          amount: productUpdateDTO?.available,
        });
      }
      if (isDocumentRemoved) {
        await removeDocument(productUpdateDTO?.uuid);
      }
      if (lastUploadAction === UPLOAD_FILE) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        uploadDocument({
          file: dataImage,
          id: productUpdateDTO?.uuid,
        });
      }
      const productData = { ...product };
      delete productData?.document;
      delete productUpdateDTO?.document;
      if (!isEqual(productUpdateDTO, productData)) {
        await update(productUpdateDTO).unwrap();
        displaySuccess({ key: 'product.updateProduct' });
      }
      const path = location?.state?.previousLocation || `/products/${placeData?.uuid}`;
      navigate(path);
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const changeStateRemoveDialog = () => {
    setIsRemoveDialogOpen();
  };

  const onDiscard = () => {
    const path = location?.state?.previousLocation || `/products/${placeData?.uuid}`;
    navigate(path);
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const handleRemoveDocument = () => {
    setIsDocumentRemove(true);
    changeStateRemoveDialog();
    setFormData((prevFormData) => ({ ...prevFormData, document: undefined }));
  };

  const handleDeleteProduct = async () => {
    try {
      await deleteProduct(id).unwrap();
      toggleDelete();
      const path = location?.state?.previousLocation || `/products/${placeData?.uuid}/?value=0`;
      navigate(path);
      displaySuccess({ key: 'deleteModal.removeProduct' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const handleArchiveProduct = async () => {
    try {
      await archiveProduct(id).unwrap();
      toggleArchive();
      const path = location?.state?.previousLocation || `/products/${placeData?.uuid}/?value=0`;
      navigate(path);
      displaySuccess({ key: t('archiveModal.archiveModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleArchive();
      displayError({ key: code });
    }
  };

  const additionalFooterButtons = [
    <>
      <div>
        <ACButton
          key='archiveProduct'
          onClick={toggleArchive}
          style={styleFooterIcon.iconAction}
          variant={'text'}
        >
          <Archive data-testid='archiveButton' style={styleFooterIcon.iconAction} />
          {t('product.archive')}
        </ACButton>
      </div>
    </>,
  ];

  return isSuccessEdit && !isLoadingUpdateProduct && !isLoadingUpdateStock ? (
    <>
      <Divider />
      <>
        <div className={styles.wrapper}>
          <BaseForm
            errors={errors}
            formData={formData}
            handleChange={handleChangeFormData}
            placeName={placeData?.name}
            placesOptions={placeOptions}
            productCategoryOptions={categoryOptions}
            setFormData={setFormData}
          />
          <div style={flexPage}>
            <UploadForm
              file={file}
              formData={formData}
              handleChange={handleFileChange}
              handleFileDelete={changeStateRemoveDialog}
            />
          </div>
        </div>
        <WorkingHoursForm
          errors={errors}
          handleChange={handleChangeFormData}
          headerPrimaryText={t('product.pickupHours')}
          headerSecondaryText={t('product.editPickupHours')}
          hours={formData?.pickupHours}
        />
      </>
      {isArchiveModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleArchive}
          disabled={isLoadingArchive}
          handleSubmit={handleArchiveProduct}
          header={t('archiveModal.titleArchive')}
          icon={<Archive style={style.RedIconModal} />}
          isShow={isArchiveModalOpen}
          message={t('archiveModal.descriptionArchive')}
        />
      )}
      {isDeleteDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDeleteProduct}
          header={t('deleteModal.headerRemoveProduct')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteDialogOpen}
          message={t('deleteModal.messageRemoveProduct')}
        />
      )}
      {isRemoveDialogOpen ? (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={changeStateRemoveDialog}
          disabled={isLoadingRemove}
          handleSubmit={handleRemoveDocument}
          header={t('deleteModal.removeDocumentTitle')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isRemoveDialogOpen}
          message={t('deleteModal.removeDocumentMessage')}
        />
      ) : null}
      <FormStickyFooter
        additionalActionButtons={additionalFooterButtons}
        onDelete={toggleDelete}
        onDeleteText={t('footer.deleteButton')}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={buttonDisabled}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditProduct;
