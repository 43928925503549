import { ACCard } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { FormHeader } from 'components/FormHeader/FormHeader';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { Bag, Global, Home, Map, Mobile, Sms, User, WalletMoney } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { store } from 'store/store';
import { handleDateAndTime } from 'utils/helpers';

import styles from './UserDetailView.module.scss';

const iconStyle = {
  width: '30px',
  height: '30px',
  marginRight: '15px',
};

const grams = 'gr';

const UserDetailView = ({ appUser, userCountry }) => {
  const { t } = useTranslation();
  const verified = appUser?.phoneVerified ? t('appUsers.verified') : t('appUsers.notVerified');
  const countriesContent = store.getState().auth.userCountry;
  const { dateFormat } = countriesContent;
  const { language } = store.getState().language;

  const BodyContentBase = () => {
    return (
      <div className={styles.basicInfo}>
        <div className={styles.userInformation}>
          <User style={iconStyle} />
          <div>
            {appUser?.firstName} {appUser?.lastName}
          </div>
        </div>
        <Divider />
        <div className={styles.userInformation}>
          <Sms style={iconStyle} />
          <div>{appUser?.email}</div>
          <Divider />
        </div>
        <Divider />
        <div className={styles.userInformation}>
          <Mobile style={iconStyle} />
          <div>
            {appUser?.phoneNumber} ({verified})
          </div>
        </div>
      </div>
    );
  };

  const BodyContentAddress = () => {
    return (
      <div className={styles.basicInfo}>
        <div className={styles.userInformation}>
          <Home style={iconStyle} />
          <div>{appUser?.address}</div>
        </div>
        <Divider />
        <div className={styles.userInformation}>
          <Map style={iconStyle} />
          <div>{appUser?.city}</div>
        </div>
        <Divider />
        <div className={styles.userInformation}>
          <Global style={iconStyle} />
          <div>{appUser?.country}</div>
        </div>
      </div>
    );
  };

  const BodyContentStats = () => {
    return (
      <div className={styles.basicInfo}>
        <div className={styles.userInformation}>
          <WalletMoney style={iconStyle} />
          {t('appUsers.moneySaved')} {appUser?.totalMoneySaved} {userCountry?.isoCurrency}
        </div>
        <Divider />
        <div className={styles.userInformation}>
          <Bag style={iconStyle} />
          {t('appUsers.foodSaved')} {appUser?.totalWeightSaved} {grams}
        </div>
      </div>
    );
  };

  const UserActivity = () => {
    const createdAtFormatted = handleDateAndTime(appUser?.createdAt, dateFormat, language);
    const { formattedDate: formattedDateCreatedAt, formattedTime: formattedTimeCreatedAt } =
      createdAtFormatted;
    const lastActiveAtFormatted = handleDateAndTime(appUser?.lastActiveAt, dateFormat, language);
    const { formattedDate: formattedDateLastActiveAt, formattedTime: formattedTimeLastActiveAt } =
      lastActiveAtFormatted;
    return (
      <div className={styles.basicInfo}>
        <div className={styles.userActivity}>
          <div>{t('appUsers.createdAt')}:</div>
          <div>
            {appUser?.createdAt
              ? `${formattedDateCreatedAt}, ${formattedTimeCreatedAt}`
              : FORM_ELEMENT_EMPTY}
          </div>
        </div>
        <Divider />
        <div className={styles.userActivity}>
          <div>{t('appUsers.lastActiveAt')}:</div>
          <div>
            {appUser?.lastActiveAt
              ? `${formattedDateLastActiveAt}, ${formattedTimeLastActiveAt}`
              : FORM_ELEMENT_EMPTY}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.userCards}>
        <div className={styles.userCard}>
          <FormHeader
            primaryText={t('appUsers.baseTitle')}
            secondaryText={t('appUsers.baseDescription')}
          />
          <ACCard bodyChildren={<BodyContentBase />} />
        </div>
        <div className={styles.userCard}>
          <FormHeader
            primaryText={t('appUsers.addressTitle')}
            secondaryText={t('appUsers.addressDescription')}
          />
          <ACCard bodyChildren={<BodyContentAddress />} />
        </div>
        <div className={styles.userCard}>
          <FormHeader
            primaryText={t('appUsers.statsTitle')}
            secondaryText={t('appUsers.statsDescription')}
          />
          <ACCard bodyChildren={<BodyContentStats />} />
        </div>
        <div className={styles.userCard}>
          <FormHeader
            primaryText={t('appUsers.titleUserActivity')}
            secondaryText={t('appUsers.descriptionUserActivity')}
          />
          <ACCard bodyChildren={<UserActivity />} />
        </div>
      </div>
    </>
  );
};

export default UserDetailView;
