import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { useLazyGetPartnerUsersQuery } from 'features/partnerUser/services';
import { User } from 'features/partnerUser/services/getPartnerUserById';
import { useAppSelector } from 'hooks/hooks';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { CurrentUser } from 'store/Auth';
import { checkIfPropertyExistsInObject, trimObjectValues } from 'utils/helpers';

import { CountryOption } from '../../../services/country';
import { FormDataType } from '../constants/FormConstants';
import { useLazyGetCompanyRequestByIdQuery } from '../services';
import { CompanyRequestDTO } from '../services/updateCompanyRequest';

export const transformToDTOFormat = (formData: FormDataType, id?: string): CompanyRequestDTO => {
  const countryId = formData?.country as CountryOption;
  const CompanyRequestDTO: CompanyRequestDTO = {
    ...formData,
  };
  id && (CompanyRequestDTO.country = countryId?.uuid);
  return CompanyRequestDTO;
};

export const useCompanyRequestsForm = (
  formData?: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  companyRequestId?: string,
) => {
  const [fetchCompanyRequestById, { data: companyRequest, isSuccess: isSuccessCompanyRequest }] =
    useLazyGetCompanyRequestByIdQuery();
  const [fetchPartnerUsers, { data: partnerUsers }] = useLazyGetPartnerUsersQuery();
  const partnerUser = partnerUsers?.content[0] as User;

  const { countries } = useAppSelector(({ globalData }) => globalData);

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const systemAdminIsLoggedIn = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);

  const initialFormValues = () => {
    const country = countries?.find((country) => country.uuid === companyRequest?.country);
    setFormData({
      ...companyRequest,
      country: {
        ...country,
        name: country?.shortName,
        value: country?.uuid,
      },
    });
  };

  useEffect(() => {
    if (isSuccessCompanyRequest && systemAdminIsLoggedIn) {
      const ids = companyRequest?.createdBy;
      fetchPartnerUsers(
        {
          ids,
        },
        true,
      );
    }
    if (isSuccessCompanyRequest) initialFormValues();
  }, [isSuccessCompanyRequest, companyRequest]);

  useEffect(() => {
    if (companyRequestId) {
      fetchCompanyRequestById(companyRequestId, true);
    }
  }, []);

  const countriesOptions: CountryOption[] = useMemo(() => {
    return countries?.map((country) => {
      return {
        ...country,
        name: country.shortName,
        value: country.uuid,
      };
    });
  }, [countries]);

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedFormData: CompanyRequestDTO = transformToDTOFormat(formData, companyRequestId);
    const trimmedData = trimObjectValues<FormDataType>(transformedFormData);
    const disabled = _.isEqual(trimmedData, companyRequest);
    return disabled;
  }, [formData, companyRequest]);

  return {
    companyRequest,
    isSuccessCompanyRequest,
    countriesOptions,
    shouldDisableSubmitButton,
    currentUserData: partnerUser,
  };
};
