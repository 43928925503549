import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  name: {
    isRequired: true,
    max: 50,
  },
  description: {
    max: 500,
  },
  category: {
    isRequired: true,
  },
  place: {
    isRequired: true,
  },
  weight: {
    isRequired: true,
    min: 0,
    onlyNumbers: true,
  },
  basePrice: {
    isRequired: true,
    min: 0,
    isPriceNumber: true,
  },
  discountedPrice: {
    isRequired: true,
    min: 0,
    isPriceNumber: true,
  },
  available: {
    isRequired: true,
    min: 0,
    onlyNumbers: true,
  },
  initialDailyAvailable: {
    min: 0,
    onlyNumbers: true,
  },
  code: {
    max: 20,
  },
  mondayFrom: {
    isDateGreaterThan: 'mondayTo',
  },
  tuesdayFrom: {
    isDateGreaterThan: 'tuesdayTo',
  },
  wednesdayFrom: {
    isDateGreaterThan: 'wednesdayTo',
  },
  thursdayFrom: {
    isDateGreaterThan: 'thursdayTo',
  },
  fridayFrom: {
    isDateGreaterThan: 'fridayTo',
  },
  saturdayFrom: {
    isDateGreaterThan: 'saturdayTo',
  },
  sundayFrom: {
    isDateGreaterThan: 'sundayTo',
  },
};
