import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_WEB_APPLICATION_SETTINGS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<WebApplicationSettingsResponse, void>({
    query: () => '/system/web-application-setting',
    providesTags: [TAG_WEB_APPLICATION_SETTINGS],
  });

type WebApplicationSettingsResponse = {
  imageQualitySettings: {
    placeDetail: string;
    placeList: string;
    productCategoryAll: string;
    productDetail: string;
    productList: string;
    storyDetail: string;
    storyDetailHeader: string;
    storyList: string;
  };
  loginTypes: {
    apple: boolean;
    facebook: boolean;
    google: boolean;
    magicLink: boolean;
  };
  maintenanceMode: boolean;
  maintenanceModeMessage: string;
  maintenanceModeTitle: string;
  placeDetail: string;
  placeList: string;
  productCategoryAll: string;
  productDetail: string;
  productList: string;
  showProductCategories: boolean;
  storyDetail: string;
  storyDetailHeader: string;
  storyList: string;
};
