import { Logout as LogoutIcon } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { removeGlobalData } from 'store/GlobalData';

import { useAppSelector } from '../../../../hooks/hooks';
import { logout as logoutUser } from '../../../../store/Auth';
import { useLogoutMutation } from '../../services/index';
import styles from './Logout.module.scss';

const Logout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const { refreshToken } = useAppSelector(({ auth }) => auth);
  const logoutCurrentUser = () => {
    logout({ refreshToken: refreshToken });
    dispatch(logoutUser());
    navigate('/');
    dispatch(api.util.resetApiState());
    dispatch(removeGlobalData());
    localStorage.clear();
  };
  return (
    <div className={styles.cardOption} onClick={logoutCurrentUser}>
      <div className={styles.icon}>
        <LogoutIcon />
      </div>
      <div className={styles.buttonLogout} placeholder='buttonLogout'>
        {t('profileCard.buttonLogout')}
      </div>
    </div>
  );
};

export default Logout;
