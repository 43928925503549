import {
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_ID_NUMBER,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_STATUS,
  FORM_ELEMENT_VAT_NUMBER,
} from 'constants/FormConstants';
import { TABLE_ELEMENT_CREATED_AT, TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const tableCompanyRequestSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'companyRequestsTable',
  filter: [
    {
      field: FORM_ELEMENT_NAME,
      title: 'company.nameColumn',
      filterType: 'text',
    },
    {
      field: FORM_ELEMENT_CITY,
      title: 'company.cityColumn',
      filterType: 'text',
    },
  ],
  columns: [
    {
      title: 'company.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'company.idNumberColumn',
      field: FORM_ELEMENT_ID_NUMBER,
    },
    {
      title: 'company.vatNumberColumn',
      field: FORM_ELEMENT_VAT_NUMBER,
    },
    {
      title: 'company.cityColumn',
      field: FORM_ELEMENT_CITY,
    },
    {
      title: 'company.countryColumn',
      field: FORM_ELEMENT_COUNTRY,
    },
    {
      title: 'companyRequest.status',
      field: FORM_ELEMENT_STATUS,
    },
    {
      title: 'companyRequest.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
  ],
};
export default tableCompanyRequestSettings;
