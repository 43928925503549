import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PARTNER_USER, TAG_PARTNER_USERS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/partner-user/${id}/block`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_PARTNER_USERS, TAG_PARTNER_USER],
  });
