import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { BestSellingProducts } from '../../../services/getPlaceStatistics';
import styles from './PieChartStatistics.module.scss';

Chart.register(CategoryScale);

const styleColor = [
  '#014342',
  '#346968',
  '#558180',
  '#8aa9a8',
  '#b0c5c4',
  '#e6ecec',
  '#8aa9a8',
  '#68B393',
  '#509e7e',
  '#3e7a61',
];

const PieChartStatistics = ({
  bestSellingProducts,
}: {
  bestSellingProducts: Array<BestSellingProducts>;
}) => {
  const { t } = useTranslation();

  const name = useMemo(() => {
    return bestSellingProducts?.map((name) => {
      return name.name;
    });
  }, [bestSellingProducts]);

  const count = useMemo(() => {
    return bestSellingProducts?.map((count) => {
      return count.count;
    });
  }, [bestSellingProducts]);

  const labels = name;

  const data = {
    labels: labels,
    datasets: [
      {
        label: t('placeStatistics.number'),
        backgroundColor: styleColor,
        borderColor: styleColor,
        data: count,
      },
    ],
  };

  return (
    <div>
      {bestSellingProducts?.length !== 0 ? (
        <Pie data={data} />
      ) : (
        <div className={styles.noResultsText}>{t('product.noResultsText')}</div>
      )}
    </div>
  );
};
export default PieChartStatistics;
