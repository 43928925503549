import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_COMPANY_REQUEST, TAG_COMPANY_REQUESTS } from 'services/TagsConstants';

import { CountryOption } from '../../../services/country';
import { NewCompanyRequest } from './createCompanyRequest';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, CompanyRequestDTO>({
    query: ({ uuid, ...tempPatch }) => {
      return {
        url: `/company-request/${uuid}`,
        method: 'PATCH',
        body: tempPatch,
      };
    },
    invalidatesTags: [TAG_COMPANY_REQUESTS, TAG_COMPANY_REQUEST],
  });

export type CompanyRequestDTO = Omit<NewCompanyRequest, 'country'> & {
  country: string | CountryOption;
  status?: string;
};
