import { ACSnackbar } from '@antech-consulting/components';
import { NOTIFICATION_TYPE } from 'constants/NotificationConstants';
import useNotification from 'hooks/useNotificationHook';
import { Slash, TickCircle, Warning2 } from 'iconsax-react';
import { FC } from 'react';
import { NotificationState } from 'store/Notification';
import { handleNotificationColor } from 'utils/helpers';

import styles from './Notification.module.scss';

const iconErrorStyle = {
  height: ' 30px',
  width: '30px',
  color: 'var(--error)',
};

const iconWarningStyle = {
  height: ' 30px',
  width: '30px',
  color: 'var(--warning)',
};

type NotificationType = {
  notification: NotificationState;
};

const alertIcon = (type) => {
  const { ERROR, SUCCESS, WARNING } = NOTIFICATION_TYPE;
  let icon;
  if (type === ERROR) {
    return (icon = <Slash data-testid='errorIcon' style={iconErrorStyle} />);
  } else if (type === SUCCESS) {
    return (icon = <TickCircle className={styles.icon} data-testid='successIcon' />);
  } else if (type === WARNING) {
    return (icon = <Warning2 data-testid='warningIcon' style={iconWarningStyle} />);
  }
  return icon as string;
};

const NOTIFICATION_TIMEOUT = 7000;

export const Notification: FC<NotificationType> = ({ notification }): JSX.Element => {
  const { closeMessage, message, open, type } = useNotification(notification);
  const { color } = handleNotificationColor(type);
  const notificationIcon = alertIcon(type);

  //TODO: remove inline css when ACComponents are updated
  return (
    <ACSnackbar
      autoHideDuration={NOTIFICATION_TIMEOUT}
      icon={notificationIcon}
      onClose={closeMessage}
      open={open}
      position='top-center'
      style={{
        container: {
          zIndex: '100',
          position: 'fixed',
          padding: '10px',
          background: 'var(--background)',
          color: 'var(--text-primary)',
          display: 'flex',
          alignItems: 'center',
          border: `1px solid ${color}`,
          fontFamily:
            'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
        },
        icon: {
          color: 'var(--success)',
        },
        closeIcon: {
          margin: '0 0 5px 5px',
          color: 'var(--text-primary)',
          padding: '10px',
        },
        text: {
          fontFamily:
            'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
          fontSize: 'var(--font-size14)',
        },
      }}
      text={<div className={styles.alertDescription}>{message}</div>}
    />
  );
};
