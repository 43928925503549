export const isRefreshTokenAboutToExpire = (refreshTokenExpireDate: string): boolean => {
  const currentTime = new Date();
  const expirationTime = new Date(refreshTokenExpireDate);

  // Define the threshold for checking if it's about to expire (30 days before)
  const expirationThreshold = 30 * 24 * 60 * 60 * 1000;

  const isAboutToExpire = expirationTime.getTime() - currentTime.getTime() <= expirationThreshold;

  return isAboutToExpire;
};
