export type FormDataType = {
  country?: string;
  email?: string;
  firstName: string;
  lastName: string;
};

export const formDataInitialState: FormDataType = {
  firstName: '',
  lastName: '',
  email: '',
  country: '',
};
