import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, UpdateProductStock>({
    query: (credentials: UpdateProductStock) => {
      const { amount, uuid } = credentials;
      return {
        url: `/product/${uuid}/change-stock`,
        method: 'POST',
        body: { amount: amount },
      };
    },
  });

export type UpdateProductStock = { amount: number; uuid: string };
