import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { PartnerUser, PartnerUserResponse } from 'features/partnerUser/services/getPartnerUsers';
import moment from 'moment';
import { TAG_PRODUCT_CATEGORY_REQUEST } from 'services/TagsConstants';
import { store } from 'store/store';
import { transformSnakeCaseToText } from 'utils/helpers';

import { ProductCategoryRequest } from './getProductCategoryRequests';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductCategory, ProductCategoryParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id } = _arg;
      const countriesContent = store.getState().auth.userCountry;
      const { dateFormat } = countriesContent;
      const roles = store.getState().auth.roles;
      const system = roles[ROLE_SYSTEM as never];
      const productCategoryRequestResponse = await fetchWithBQ(`/product-category-request/${id}`);
      const productCategoryRequestData = productCategoryRequestResponse.data as ProductCategory;
      const { createdAt, createdBy, status } = productCategoryRequestData;
      const formatCreatedAt = moment(createdAt).format(dateFormat.toUpperCase());
      const requestersResponse = system
        ? await fetchWithBQ(`/partner-users?ids=${createdBy}`)
        : null;
      const requestersData = requestersResponse
        ? (requestersResponse?.data as PartnerUserResponse)
        : null;
      const matchedRequester = requestersData?.content?.find((requester) => {
        return requester.uuid === createdBy;
      });
      const productCategoryRequest = {
        ...productCategoryRequestData,
        createdAt: formatCreatedAt,
        userInformation: matchedRequester,
        status: transformSnakeCaseToText(status),
      };
      return {
        data: productCategoryRequest,
      };
    },
    providesTags: [TAG_PRODUCT_CATEGORY_REQUEST],
  });

export type ProductCategory = ProductCategoryRequest & {
  document?: string;
  userInformation?: PartnerUser;
};

export type ProductCategoryParameters = {
  id?: string;
};
