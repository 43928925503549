import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { getTablePlacesConstants } from '../../constants/TableStatisticsConstants';
import { PlacesRevenue } from '../../services/getCompanyStatistics';

const TablePlacesRevenues = ({
  header,
  places,
}: {
  header: string;
  places: Array<PlacesRevenue>;
}) => {
  const { t } = useTranslation();
  const tableSettings = getTablePlacesConstants(t);
  const rows = useMemo(() => {
    return places?.map((data) => {
      return {
        rawData: {
          ...places,
          name: data.name,
          gross: data.gross,
          net: data.net,
        },
        render: {
          ...places,
          name: data.name,
          gross: data.gross,
          net: data.net,
        },
      };
    });
  }, [places]);

  return (
    <StyledACTable
      {...tableSettings}
      headerTitle={header}
      rows={rows}
      statistics
      tableFooter={false}
    />
  );
};
export default TablePlacesRevenues;
