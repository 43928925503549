import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { WorkingHoursType } from 'features/place/services/updatePlace';
import { TAG_PRODUCT } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductDTO, ProductParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id } = _arg;
      const productResponse = await fetchWithBQ(`/product/${id}`);
      const productData = productResponse.data as ProductDTO;
      const documentResponse = productData?.document
        ? await fetchWithBQ(`/documents/download-links?ids=${productData?.document}`)
        : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const product = {
        ...productData,
        document: documentData ? documentData[productData?.document] : '',
      };
      return {
        data: product,
      };
    },
    providesTags: [TAG_PRODUCT],
  });

export type ProductParameters = {
  id?: string;
};

export type ProductDTO = {
  available?: number;
  basePrice: number | string;
  category: string;
  code?: string;
  description?: string;
  discountedPrice: number | string;
  document?: string;
  initialDailyAvailable?: number;
  name?: string;
  pickupHours?: WorkingHoursType;
  place: string;
  uuid?: string;
  weight?: number | string;
};
