import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_NAME } from 'constants/FormConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { FilterProps, iconStyle } from 'features/product/constants/TableConstants';
import { Product } from 'features/product/services/getProducts';
import { useGetProductCategoriesRecursiveQuery } from 'features/productCategory/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useObjectTranslation from 'hooks/useObjectTranslation';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { ArchiveSlash } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import TastyLogo from '../../../../images/TastyLogo.jpg';
import { handleRating, transformACState } from '../../../../utils/helpers';
import { useLazyGetArchivedProductsQuery, useUnarchiveProductMutation } from '../../services/index';
import styles from '../TableProduct/TableProduct.module.scss';
import archivedProductTableSettings from './archivedProductsTableSettings';

const TableArchivedProducts = () => {
  const { id: placeId } = useParams();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const tableSettings = useTable(archivedProductTableSettings);
  const { selectedRow, setSelectedRow } = tableSettings;
  const [unarchiveProduct, { isLoading: isLoadingArchive }] = useUnarchiveProductMutation();
  const [isUnArchiveProductModalOpen, toggleUnarchive] = useToggle();

  const [fetchArchivedProducts, { data: products, isFetching }] = useLazyGetArchivedProductsQuery();
  const { getNameTranslation } = useObjectTranslation();

  const { data } = useGetPartnerApplicationSettingsQuery();
  const { data: allProductCategories, isSuccess: isSuccessAllProductCategories } =
    useGetProductCategoriesRecursiveQuery();

  const productsImageQuality = data?.dashboardImageQualitySettings?.productList;

  const handleChangeTableData = (tableData: TableStateProps<FilterProps>) => {
    fetchArchivedProducts(transformACState({ ...tableData, place: placeId }, FORM_ELEMENT_NAME));
  };

  const handleUnarchive = ({ rowData }: { rowData: Product }) => [
    setSelectedRow(rowData),
    toggleUnarchive(),
  ];

  const handelUnarchiveProduct = async () => {
    try {
      await unarchiveProduct(selectedRow?.uuid).unwrap();
      toggleUnarchive();
      displaySuccess({ key: t('archiveModal.activeModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleUnarchive();
      displayError({ key: code });
    }
  };

  const additionalActions = [
    {
      icon: <ArchiveSlash data-testid='unarchiveButton' />,
      tooltip: { text: t('product.unarchive') },
      onClick: handleUnarchive,
    },
  ];

  const productTableData = useMemo(() => {
    return products?.content.map((product) => {
      const rating = handleRating(product);
      const matchedCategory =
        isSuccessAllProductCategories &&
        allProductCategories?.find((el) => el.uuid === product?.category);
      const categoryName = getNameTranslation(matchedCategory);
      const imageUrl = product?.document && product?.document[productsImageQuality];

      return {
        rawData: {
          ...product,
          document: imageUrl || '',
        },
        render: {
          ...product,
          document: (
            <div className={styles.documentColumn}>
              <img className={styles.image} src={imageUrl || TastyLogo} style={iconStyle} />
              <div className={styles.productName}>{product?.name}</div>
            </div>
          ),
          rating: <span className='star'>{rating}</span>,
          category: categoryName,
        },
      };
    });
  }, [products?.content, document, isSuccessAllProductCategories]);

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={products?.totalElements}
          defaultSort={{
            field: FORM_ELEMENT_NAME,
            type: 'asc',
          }}
          headerTitle={`${t('product.tableNameArchived')} ${products?.place}`}
          onTableStateChange={handleChangeTableData}
          pagesCount={products?.totalPages}
          rows={productTableData}
        />
      </ACLoading>
      {isUnArchiveProductModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleUnarchive}
          disabled={isLoadingArchive}
          handleSubmit={handelUnarchiveProduct}
          header={t('archiveModal.titleUnarchive')}
          icon={<ArchiveSlash style={style.BlueIconModal} />}
          isShow={isUnArchiveProductModalOpen}
          message={t('archiveModal.descriptionUnarchive')}
        />
      )}
    </>
  );
};
export default TableArchivedProducts;
