export const PENDING = 'Pending';
export const ACCEPTED = 'Accepted';
export const READY = 'Ready';
export const REJECTED = 'Rejected';
export const CANCELED = 'Canceled';
export const PICKED_UP = 'Picked up';
export const PAYMENT_FAILED = 'Payment failed';

export const wrapperStyle = {
  height: 'auto',
};

export const dotStyle = {
  width: '10px',
  height: '10px',
  borderRadius: '50%',
};

export const activityColor = {
  active: '#37ae39',
  inactive: '#c51717',
  border: '#e5e7eb',
};
export const iconStyle = {
  width: '25px',
  height: '25px',
  marginRight: '5px',
};

export const TOTAL_DISCOUNTED_PRICE = 'totalDiscountedPrice';
export const BASE_PRODUCT_PRICE = 'baseProductPrice';
export const PRODUCT_PRICE = 'productPrice';
export const QUANTITY = 'quantity';
