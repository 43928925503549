import { ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import WebAppSettingsBaseForm from 'features/maintenance/components/WebAppMaintenance/BaseForm/BaseForm';
import {
  formDataInitialState as WebAppSettingsFormDataInitialState,
  FormDataType as WebAppSettingsFormDataType,
} from 'features/maintenance/constants/WebAppSettingsFormConstants';
import { validationSchema as WebAppSettingsValidationSchema } from 'features/maintenance/constants/WebAppSettingsFormSchema';
import { useWebAppSettingsForm } from 'features/maintenance/hooks/useWebAppSettingsForm';
import { useUpdateWebApplicationSettingsMutation } from 'features/maintenance/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { trimObjectValues } from 'utils/helpers';

const WebAppSettings = () => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { displayError, displaySuccess } = useDisplayNotification();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<WebAppSettingsFormDataType>(
      WebAppSettingsFormDataInitialState,
      WebAppSettingsValidationSchema,
    );
  const { data: initialWebAppSettingsFormData } = useWebAppSettingsForm(setFormData);

  const [updateWebApplicationSettingsRequest] = useUpdateWebApplicationSettingsMutation();

  useEffect(() => {
    const {
      placeDetail,
      placeList,
      productCategoryAll,
      productDetail,
      productList,
      storyDetail,
      storyDetailHeader,
      storyList,
      ...restData
    } = formData;
    const newData = {
      ...restData,
      imageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
        storyDetail,
        storyDetailHeader,
        storyList,
      },
    };
    const isWebFormDirty = !isEqual(initialWebAppSettingsFormData, newData);
    setIsFormDirty(isWebFormDirty);
  }, [initialWebAppSettingsFormData, formData]);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<WebAppSettingsFormDataType>(formData);
    const {
      placeDetail,
      placeList,
      productCategoryAll,
      productDetail,
      productList,
      storyDetail,
      storyDetailHeader,
      storyList,
      ...restData
    } = trimmedData;
    const webSettingsData = {
      ...restData,
      imageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
        storyDetail,
        storyDetailHeader,
        storyList,
      },
    };
    try {
      await updateWebApplicationSettingsRequest(webSettingsData).unwrap();
      displaySuccess({ key: 'maintenance.updateWebAppSettings' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <>
      {initialWebAppSettingsFormData ? (
        <WebAppSettingsBaseForm
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          setFormData={setFormData}
        />
      ) : (
        <ACSpinner background='var(--text-secondary)' size='xxs' />
      )}

      <FormStickyFooter
        onSubmit={() => {
          handleSubmit(onSubmit);
        }}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={!isFormDirty}
      />
    </>
  );
};

export default WebAppSettings;
