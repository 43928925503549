import {
  ACAutocompleteV2,
  ACButton,
  ACInput,
  ACLoading,
  ACSpinner,
} from '@antech-consulting/components';
import CountryLabel from 'components/CountryLabel/CountryLabel';
import { AUTO_COMPLETE_OFF, LANGUAGES_OPTIONS_LOGIN } from 'constants/FormConstants';
import { TOKEN_TYPE_PARTNER_VERIFY_PHONE } from 'constants/RolesConstants';
import { authDescription, authTitle } from 'features/auth/constants/StyleConstants';
import { RequestParameters } from 'features/auth/services/login';
import useDisplayNotification from 'hooks/useDisplayNotification';
import jwt_decode from 'jwt-decode';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { changeLanguage } from 'store/Language';

import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style } from '../../constants/StyleConstants';
import LoginForm from '../../features/auth/components/LoginForm/LoginForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/auth/constants/LoginFormConstants';
import { validationSchema } from '../../features/auth/constants/LoginSchema';
import { useLoginMutation } from '../../features/auth/services';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useForm } from '../../hooks/useForm';
import { setCurrentUser } from '../../store/Auth';
import styles from './Login.module.scss';

type Decoded = {
  type: string;
};

type autocompleteOptionType = {
  isoName: string;
  label: string;
  value: string;
};

const errorCodeNonVerifyEmail = 'PU003';

const Login = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = useAppSelector(({ language }) => language);

  const [login, { isLoading }] = useLoginMutation();
  const { displayError } = useDisplayNotification();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );

  const [inputValue] = useMemo(() => {
    return LANGUAGES_OPTIONS_LOGIN?.filter(({ label, value }) => {
      return (
        value === language && {
          label,
          value,
        }
      );
    });
  }, [language]);

  const [changeLanguageState, setChangeLanguageState] = useState(inputValue);

  const changeAppLanguage = (_event: Event, autocompleteOption: autocompleteOptionType) => {
    setChangeLanguageState(autocompleteOption);
    dispatch(changeLanguage({ language: autocompleteOption?.value }));
    i18n.changeLanguage(autocompleteOption?.value);
  };

  const handleNonVerifiedUserEmail = useCallback((email: string, password: string) => {
    localStorage.setItem('LoginUserData', JSON.stringify({ email, password }));
    return navigate('/verify-email');
  }, []);

  const handleNonVerifiedUserPhoneNumber = useCallback((email: string, password: string) => {
    localStorage.setItem('LoginUserData', JSON.stringify({ email, password }));
    return navigate('/verify-phone-number');
  }, []);

  const onSubmit = async () => {
    const LoginRequestDTO: RequestParameters = {
      ...formData,
    };
    try {
      const userData = await login({
        email: LoginRequestDTO.email.trim(),
        password: LoginRequestDTO.password,
      }).unwrap();
      const decoded: Decoded = jwt_decode(userData?.accessToken);
      const typeUser = decoded?.type;
      if (typeUser === TOKEN_TYPE_PARTNER_VERIFY_PHONE) {
        dispatch(setCurrentUser(userData));
        handleNonVerifiedUserPhoneNumber(LoginRequestDTO.email, LoginRequestDTO.password);
      } else {
        dispatch(setCurrentUser(userData));
      }
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      if (code === errorCodeNonVerifyEmail) {
        handleNonVerifiedUserEmail(LoginRequestDTO.email, LoginRequestDTO.password);
      } else {
        displayError({ key: code });
      }
    }
  };

  const handleLoginForm = (event) => {
    event.preventDefault();
    handleSubmit(onSubmit);
  };

  return (
    <ACLoading
      isLoading={isLoading}
      loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
    >
      <form className={styles.form} id='formLogin' noValidate>
        <div className={styles.languageWrapper}>
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='loginLanguage'
            fullWidth
            getCustomRender={(option) => (
              <CountryLabel isoName={option?.isoName} name={option?.label} />
            )}
            getOptionDisabled={(option) => option === changeLanguageState}
            getOptionLabel={(option) => option?.label}
            onChange={changeAppLanguage}
            options={LANGUAGES_OPTIONS_LOGIN}
            renderInput={(params) => (
              <ACInput autoComplete={AUTO_COMPLETE_OFF} fullWidth {...params} />
            )}
            value={changeLanguageState}
          />
        </div>
        <h3 style={authTitle}>{t('loginForm.title')}</h3>
        <p style={authDescription}>{t('loginForm.description')}</p>
        <LoginForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
        <div className={styles.passwordLink}>
          <div>
            <input type='checkbox' />
            <span className={styles.textCheckbox}>{t('loginForm.checkBox')}</span>
          </div>
          <Link className={styles.link} placeholder='ForgotPassLink' to='/forgot-password'>
            {t('loginForm.linkForgotPassword')}
          </Link>
        </div>
        <div className={styles.buttonsWrapper} id='ButtonLoginForm'>
          <ACButton
            dataTestid='ButtonLogin'
            fullWidth
            onClick={(event) => handleLoginForm(event)}
            style={style.Button}
            type='submit'
          >
            {t('loginForm.buttonSignIn')}
          </ACButton>
        </div>
        <div className={styles.regLink}>
          <span className={styles.description}>{t('loginForm.textSignUp')} </span>
          <Link className={styles.link} placeholder='RegistrationLink' to='/registration'>
            {t('loginForm.linkSignUp')}
          </Link>
        </div>
      </form>
    </ACLoading>
  );
};
export default Login;
