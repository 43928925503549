import { FORM_ELEMENT_NAME } from 'constants/FormConstants';
import {
  TABLE_ALL_ITEMS_COUNT,
  TABLE_ELEMENT_PERCENTAGE,
  TABLE_ELEMENT_SOLD,
  TABLE_INITIAL_ROWS_NUMBER,
  TABLE_PAGES_COUNT,
} from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const statisticsTableProductsSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  allItemsCount: TABLE_ALL_ITEMS_COUNT,
  pagesCount: TABLE_PAGES_COUNT,
  dataTestId: 'tablePlaceStatistics',
  columns: [
    {
      title: 'placeStatistics.name',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'placeStatistics.sold',
      field: TABLE_ELEMENT_SOLD,
    },
    {
      title: 'placeStatistics.percentage',
      field: TABLE_ELEMENT_PERCENTAGE,
    },
  ],
};

export default statisticsTableProductsSettings;
