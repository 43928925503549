import { ACTabs } from '@antech-consulting/components';
import { TAB_FIRST, TAB_SECOND, TAB_ZERO } from 'constants/TabsConstants';
import TableArchivedProducts from 'features/product/components/TableArchivedProducts/TableArchivedProducts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';
import { TableProduct } from '../../features/product';
import ProductsStock from '../../features/product/components/ProductsStock/ProductsStock';
import styles from './ProductsList.module.scss';

const ProductsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaulValueTab, setDefaulValueTab] = useState<string | number>(tabId || 0);
  const { id: idPlace } = useParams();

  const handleChange = (id: number | string) => {
    setDefaulValueTab(id);
    navigate(`/products/${idPlace}?value=${id}`);
  };

  const tabs = [
    {
      label: t('sideBar.productList'),
      id: TAB_ZERO,
      content: <TableProduct />,
    },
    {
      label: t('product.productStock'),
      id: TAB_FIRST,
      content: <ProductsStock />,
    },
    {
      label: t('product.archiveProductTab'),
      id: TAB_SECOND,
      content: <TableArchivedProducts />,
    },
  ];
  return (
    <div>
      <ACTabs
        defaultTab={defaulValueTab}
        onChange={handleChange}
        style={style.ACTabs}
        tabs={tabs}
      />
    </div>
  );
};

export default ProductsList;
