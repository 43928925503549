import { OptionsType, OptionsTypePlace } from 'constants/TypeConstants';
import { WorkingHoursType } from 'features/place/services/updatePlace';

export type FormDataType = {
  automaticPickupStockReset?: boolean;
  automaticWorkingHoursStockReset?: boolean;
  available?: number;
  basePrice: number | string;
  category: string | OptionsType;
  code?: string;
  description?: string;
  discountedPrice: number | string;
  document?: string;
  fridayFrom?: string;
  fridayTo?: string;
  initialDailyAvailable?: number;
  mondayFrom?: string;
  mondayTo?: string;
  name?: string;
  pickupHours?: WorkingHoursType;
  place: OptionsTypePlace;
  saturdayFrom?: string;
  saturdayTo?: string;
  showWeight?: boolean;
  sundayFrom?: string;
  sundayTo?: string;
  thursdayFrom?: string;
  thursdayTo?: string;
  tuesdayFrom?: string;
  tuesdayTo?: string;
  uuid?: string;
  wednesdayFrom?: string;
  wednesdayTo?: string;
  weight?: number | string;
};

export const formDataInitialState: FormDataType = {
  uuid: '',
  name: '',
  category: '',
  description: '',
  basePrice: '',
  discountedPrice: '',
  available: 0,
  pickupHours: {},
  place: null,
  weight: '',
  automaticPickupStockReset: false,
  automaticWorkingHoursStockReset: false,
  showWeight: false,
  initialDailyAvailable: 0,
};

export const FORM_ELEMENT_AVAILABLE = 'available';
export const FORM_ELEMENT_INITIAL_DAILY_AVAILABLE = 'initialDailyAvailable';
export const FORM_ELEMENT_WEIGHT = 'weight';
export const FORM_ELEMENT_BASE_PRICE = 'basePrice';
export const FORM_ELEMENT_DISCOUNTED_PRICE = 'discountedPrice';
export const FORM_ELEMENT_CATEGORIES = 'categories';
export const FORM_ELEMENT_SHOW_WEIGHT = 'showWeight';
export const FORM_ELEMENT_AUTOMATIC_PICKUP_STOCK_RESET = 'automaticPickupStockReset';
export const FORM_ELEMENT_AUTOMATIC_WORKING_HOURS_STOCK_RESET = 'automaticWorkingHoursStockReset';
