import { CSSProperties } from 'react';

const modalIcon = {
  height: '40px',
  width: '40px',
};

export const styleFooterIcon = {
  iconApprove: {
    color: 'var(--success)',
    marginRight: '5px',
  },
  iconReject: {
    color: 'var(--error)',
    marginRight: '5px',
  },
  iconAction: {
    color: 'var(--text-transparent)',
    marginRight: '5px',
  },
};

export const style = {
  ACButton: {
    backgroundColor: 'var(--primary)',
    color: 'var(--text-primary)',
  },
  Button: {
    width: '100%',
    justifyContent: 'center',
    backgroundColor: 'var(--text-secondary)',
    color: 'var(--background)',
  },
  ButtonStatistics: {
    height: '42px',
    justifyContent: 'center',
    backgroundColor: 'var(--text-secondary)',
    color: 'var(--background)',
    borderRadius: '6px',
    fontWeight: '600',
    fontSize: 'var(--font-size14)',
    padding: '5px 10px',
  },
  ACInput: {
    affixWrapper: {
      color: 'var(--text-light-gray)',
      background: 'var(--background)',
      borderRadius: '8px',
      height: '44px',
    },
    input: { color: 'var(--text-light-gray)', background: 'var(--background)' },
  },
  ACInputProfile: {
    affixWrapper: {
      color: 'var(--text-light-gray)',
      background: 'var(--background)',
      borderRadius: '8px',
      height: '44px',
    },
    input: { background: 'var(--background)' },
  },
  ACInputDisable: {
    affixWrapper: {
      borderRadius: '8px',
      height: '44px',
    },
    label: {
      fontSize: 'var(--font-size-paragraph)',
      color: 'var(--text-transparent)',
    },
    input: { color: 'var(--text-light-gray)' },
  },
  ACTabs: {
    header: { background: 'var(--background)' },
    headerLabel: {
      color: 'var(--text-transparent)',
      fontSize: 'var(--font-size-label)',
      fontFamily:
        'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
      fontWeight: '600',
      padding: '12px 12px 12px 12px',
    },
  },
  ACInputAuth: {
    label: {
      fontSize: 'var(--font-size-label)',
      fontWeight: '600',
      color: 'var(--text-transparent)',
      marginTop: '8px',
      marginBottom: '8px',
    },
  },
  ACDrawer: {
    wrapper: {
      borderTop: 'none',
      borderLeft: 'none',
      borderBottom: 'none',
      borderRight: 'solid',
      borderColor: '#e5e7eb',
      paddingBottom: '16px',
      height: '100vh',
      fontSize: 'var(--font-size-label)',
      backgroundColor: 'var(--background-secondary)',
      fontFamily:
        'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
    },
    listItem: {
      padding: '0 0 10px 0',
      color: 'var(--text-silver)',
      fontSize: 'var(--font-size-label)',
      fontFamily:
        'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
    },
    subNavItem: {
      color: 'var(--text-silver)',
      fontFamily:
        'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto',
      fontSize: 'var(--font-size-label)',
      paddingTop: '0',
    },
    lessSubMenuWrapper: {
      background: '#c0cdd62f',
      borderRadius: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingRight: '10px',
      paddingLeft: '0',
    },
  },
  ACModal: {
    ButtonCancel: {
      width: '100%',
      justifyContent: 'center',
      backgroundColor: 'var(--background)',
      color: 'var(--text-primary)',
      borderRadius: '6px',
      fontWeight: '600',
      fontSize: 'var(--font-size14)',
      marginTop: '8px',
      position: 'relative',
    } as CSSProperties,
    ButtonConfirm: {
      width: '100%',
      justifyContent: 'center',
      backgroundColor: 'var(--text-secondary)',
      color: 'var(--background)',
      borderRadius: '6px',
      fontWeight: '600',
      fontSize: 'var(--font-size14)',
      marginTop: '8px',
      position: 'relative',
    } as CSSProperties,
  },
  ACTable: {
    tableWrapper: {
      color: 'var(--text-primary)',
      background: 'var(--text-background)',
      border: 'none',
    },
    tableHeaderStyle: {
      color: 'var(--text-primary)',
      background: 'var(--text-background)',
      fontSize: 'var(--font-size-header)',
      fontWeight: '600',
    },
    tableColumnHeadStyle: {
      color: 'var(--text-transparent)',
      background: 'var(--background-secondary)',
      fontSize: 'var(--font-size-small)',
      fontWeight: '600',
      padding: '20px',
    },
    tableRowStyle: {
      color: 'var(--text-transparent)',
      fontSize: 'var(--font-size-label)',
    },
    headerActionsClassName: 'headerActionsButtons',
  },
  ACMenuBtn: {
    btnWrap: {
      backgroundColor: 'transparent',
    },
    btnline: {
      backgroundColor: 'var(--text-primary)',
    },
  },
  ACUpload: {
    wrapper: {
      background: '#a8a3a4',
      backgroundHigherOpacity: '#6d6063',
      mainColor: '#393939',
      mainColorLowerOpacity: '#131313',
      height: '100px',
      width: '100px',
    },
    listContainer: {
      textButton: true,
    },
  },
  ACAppBar: {
    background: 'var(--background)',
    zIndex: '1',
    height: '65px',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'unset',
    top: 'unset',
    left: 'unset',
    boxShadow: '0px 1px #e5e7eb',
  } as CSSProperties,
  BlueIconModal: {
    ...modalIcon,
    color: 'rgb(38, 38, 220)',
  },
  RedIconModal: {
    ...modalIcon,
    color: 'rgb(220 38 38)',
  },
  ACAutocomplete: {
    inputWrapper: { backgroundColor: 'var(--background)', height: '40px' },
  },
  ACInputForm: {
    label: {
      fontSize: 'var(--font-size-paragraph)',
      color: 'var(--text-transparent)',
    },
  },
  ACCheckBox: {
    checkBox: {
      minWidth: '30px',
      minHeight: '30px',
      cursor: 'pointer',
    },
  },
  ModalButtons: {
    backgroundColor: 'var(--background-secondary)',
    textAlign: 'right',
    height: '61px',
    display: 'flex',
    justifyContent: 'flex-end',
  } as CSSProperties,
  FormInputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    marginBottom: '15px',
  } as CSSProperties,
  DescriptionCardStatistics: {
    textAlign: 'center',
    color: 'var(--text-transparent)',
    fontWeight: 'bold',
    margin: '15px 5px 10px',
    fontSize: 'var(--font-size-header)',
  } as CSSProperties,
  IconStatistics: {
    display: 'flex',
    width: '28px',
    height: '28px',
    color: 'var(--text-silver)',
    margin: '15px 0px 10px',
  } as CSSProperties,
  StatisticHeader: {
    fontSize: 'var(--font-size-label)',
    color: 'var(--text-primary)',
    fontWeight: 'bold',
    margin: '15px 0px 10px',
  } as CSSProperties,
};
