import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

let lang = 'en';
if (typeof window !== 'undefined') {
  lang =
    localStorage.getItem('persist:root') && // eslint-disable-line no-undef
    JSON.parse(JSON.parse(localStorage.getItem('persist:root'))?.language)?.language; // eslint-disable-line no-undef
}

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: lang || 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    srb: {
      translations: require('./locales/srb/translations.json'),
    },
    hr: {
      translations: require('./locales/hr/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'srb', 'hr'];

export default i18n;
