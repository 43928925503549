import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Country } from 'services/country';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<RegistrationResponse, RegistrationRequest>({
    query: (credentials: RegistrationRequest) => ({
      url: '/partner-users',
      method: 'POST',
      body: credentials,
    }),
  });

export type RegistrationRequest = {
  country: string | Country;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
};

export type RegistrationResponse = {
  blocked: boolean;
  country: string;
  createdAt: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  phoneVerified: boolean;
  uuid: string;
};
