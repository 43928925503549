import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import { AUTO_COMPLETE_OFF, LANGUAGES_OPTIONS } from 'constants/FormConstants';
import { useTranslation } from 'react-i18next';

import styles from './Languages.module.scss';

export type autocompleteOptionType = {
  label: string;
  value: string;
};

export type NavBarProps = {
  setIsMobileOpen: (prev: boolean) => void;
};

export const style = {
  Autocomplete: {
    wrapper: { width: '100%' },
    inputWrapper: {
      backgroundColor: 'var(--background)',
      color: 'var(--text-light-gray)',
      width: '100%',
    },
    dropdown: { backgroundColor: 'var(--background)', color: 'var(--text-light-gray)' },
  },
  Input: {
    input: { backgroundColor: 'var(--background)' },
    affixWrapper: { backgroundColor: 'var(--background)', color: 'var(--text-light-gray)' },
  },
};

type LanguagesProps = {
  changeAppLanguage: (_event: Event) => void;
  inputValue: autocompleteOptionType;
};

const Languages = ({ changeAppLanguage, inputValue }: LanguagesProps) => {
  const { t } = useTranslation();

  return (
    <form className={styles.languageForm}>
      <h5 className={styles.titlePreferences}>{t('profile.titlePreferences')}</h5>
      <p className={styles.description}>{t('profile.descriptionPreferences')}</p>
      <div className={styles.textFieldPerformances}>
        <p className={styles.text}>{t('profile.language')}</p>
        <div className={styles.inputTextField}>
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='profileLanguage'
            fullWidth
            getOptionDisabled={(option) => option === inputValue}
            onChange={changeAppLanguage}
            options={LANGUAGES_OPTIONS}
            renderInput={(params) => (
              <ACInput autoComplete={AUTO_COMPLETE_OFF} fullWidth style={style.Input} {...params} />
            )}
            style={style.Autocomplete}
            value={inputValue}
          />
        </div>
      </div>
    </form>
  );
};
export default Languages;
