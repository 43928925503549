import api from '../../../services/api';
import changePassword from './changePassword';
import changePhoneNumber from './changePhoneNumber';
import changePhoneNumberRequest from './changePhoneNumberRequest';
import getCurrent from './getCurrent';
import getUserById from './getUserById';
import updateCurrent from './updateCurrent';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserById: getUserById(build),
    getCurrent: getCurrent(build),
    changePhoneNumberRequest: changePhoneNumberRequest(build),
    changePhoneNumberCode: changePhoneNumber(build),
    changePassword: changePassword(build),
    updateCurrent: updateCurrent(build),
  }),
  overrideExisting: false,
});

export const {
  useChangePasswordMutation,
  useChangePhoneNumberCodeMutation,
  useChangePhoneNumberRequestMutation,
  useGetCurrentQuery,
  useLazyGetCurrentQuery,
  useLazyGetUserByIdQuery,
  useUpdateCurrentMutation,
} = userApi;
