import { ACButton, ACCard } from '@antech-consulting/components';
import { CityDTO } from 'constants/TypeConstants';
import { cardTitle } from 'features/orders/constants/StyleConstants';
import { wrapperStyle } from 'features/orders/constants/TableConstants';
import { useGetOrderByIdQuery } from 'features/orders/services';
import { PlaceUpdateDTO } from 'features/place/services/updatePlace';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleNavigateLink } from 'utils/helpers';

import styles from './DetailViewPlace.module.scss';

export const buttonStyle = {
  background: 'none',
  color: 'var(--text-secondary)',
  fontSize: 'var(--font-size-label)',
  border: 'none',
  boxShadow: 'none',
  padding: 0,
};

const DetailViewPlace = ({ orderId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: order, isSuccess } = useGetOrderByIdQuery(orderId);

  const handleNavigate = () => {
    handleNavigateLink();
    navigate(`/place/${order?.place?.uuid}`, { state: { previousLocation: location.pathname } });
  };

  const HeaderContent = () => {
    return (
      <div className={styles.userCardWrapper}>
        <div style={cardTitle}>{t('orders.place')}:</div>
        <ACButton className={styles.link} onClick={handleNavigate} style={buttonStyle}>
          {t('orders.linkPlaceDetailView')}
        </ACButton>
      </div>
    );
  };

  const BodyContent = () => {
    const { name: cityName } = order?.place?.city as CityDTO;
    const { name, street, streetNumber, zipCode } = order?.place as PlaceUpdateDTO;
    return (
      <>
        <div className={styles.placeInfo}>
          <div>{t('orders.name')}:</div>
          <div>{name}</div>
        </div>
        <div className={styles.placeInfo}>
          <div>{t('orders.address')}:</div>
          <div>
            {street} {streetNumber}, {zipCode} {cityName}
          </div>
        </div>
      </>
    );
  };
  return (
    isSuccess && (
      <ACCard
        bodyChildren={<BodyContent />}
        className={styles.ordersPlaceCard}
        headerChildren={<HeaderContent />}
        style={{ wrapper: wrapperStyle }}
      />
    )
  );
};

export default DetailViewPlace;
