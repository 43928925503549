import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { FORM_ELEMENT_NAME_EN, FORM_ELEMENT_NAME_SR, LANGUAGES } from 'constants/FormConstants';
import { store } from 'store/store';

import { ProductCategories, ProductCategory } from './getProductCategories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductCategory[], void>({
    async queryFn(_queryParams, _queryApi, _extraOptions, fetchWithBQ) {
      const language = store.getState().language.language;
      const sortName = language === LANGUAGES.EN ? FORM_ELEMENT_NAME_EN : FORM_ELEMENT_NAME_SR;
      let page = 0;
      const productCategories: ProductCategory[] = [];
      const fetchProductCategories = async () => {
        const categoriesFromBackend = await fetchWithBQ(
          `/product-categories?page=${page}&sort=${sortName}`,
        );
        const {
          content: productCategoriesContent,
          totalElements,
          totalPages,
        } = categoriesFromBackend.data as ProductCategories;
        if (totalElements > 0) {
          productCategories.push(...productCategoriesContent);
          page = page + 1;
          if (totalPages > page) {
            await fetchProductCategories();
          }
        }
      };
      await fetchProductCategories();
      return { data: productCategories };
    },
    keepUnusedDataFor: 0,
  });
