import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const BASE_URL = '/helper/place-categories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PlaceCategories[], void>({
    query: () => `${BASE_URL}`,
  });

export type PlaceCategories = {
  category: string;
  nameEn: string;
  nameHr: string;
  nameSr: string;
  showInUserApp: boolean;
  uuid?: string;
};
