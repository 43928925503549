export type FormDataTypeDefault = {
  from: string;
  interval: string;
  to: string;
};

export type FormDataTypeHourly = {
  from: string;
  interval: string;
};

export const formDataInitialState: FormDataTypeDefault = {
  from: '',
  interval: '',
  to: '',
};

export const formDataInitialStateHourly: FormDataTypeHourly = {
  from: '',
  interval: '',
};
