import { ACCard, ACSpinner } from '@antech-consulting/components';
import BarChart from 'features/systemStatistics/components/BarChart/BarChart';
import { StatisticsParameters } from 'features/systemStatistics/services/getSystemStatistics';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { Building, Money, Shop, User, UserTick } from 'iconsax-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/systemStatistics/components/BaseForm/BaseForm';
import CardStatistics from '../../features/systemStatistics/components/CardStatistics/CardStatistics';
import LoginTypeChartStatistics from '../../features/systemStatistics/components/LoginTypeChartStatistics/LoginTypeChartStatistics';
import TableBestRevenues from '../../features/systemStatistics/components/TableBestRevenues/TableBestRevenues';
import { formDataInitialState } from '../../features/systemStatistics/constants/FormConstants';
import { validationSchema } from '../../features/systemStatistics/constants/SystemStatisticsSchema';
import { useLazyGetSystemStatisticsQuery } from '../../features/systemStatistics/services';
import styles from './SystemStatistics.module.scss';

const styleHeader = { marginBottom: '10px' };

const SystemStatistics = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { displayError } = useDisplayNotification();
  const [fetchSystem, { data: system, isFetching, isSuccess }] = useLazyGetSystemStatisticsQuery();
  const [isSubmitable, setIsSubmitable] = useState<boolean>(false);
  const [currentRange, setCurrentRange] = useState<StatisticsParameters | null>(null);

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<StatisticsParameters>(formDataInitialState, validationSchema);

  useEffect(() => {
    formData.from && formData.to ? setIsSubmitable(true) : setIsSubmitable(false);
  }, [formData]);

  const fetchUrlParams = useCallback(async () => {
    if (location?.search !== '') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const fromDate = urlSearchParams.get('from');
      const toDate = urlSearchParams.get('to');
      const systemStatisticsDTO: StatisticsParameters = {
        ...formData,
        from: fromDate,
        to: toDate,
      };
      try {
        await fetchSystem(systemStatisticsDTO).unwrap();
        setCurrentRange(systemStatisticsDTO);
        setFormData(formDataInitialState);
      } catch (error) {
        const {
          data: { code },
        } = error as ErrorMessage;
        displayError({ key: code });
      }
    }
  }, []);

  useEffect(() => {
    fetchUrlParams();
  }, []);

  const onSubmit = async () => {
    const systemStatisticsDTO: StatisticsParameters = {
      from: formData?.from,
      to: formData?.to,
    };
    try {
      await fetchSystem(systemStatisticsDTO).unwrap();
      setCurrentRange(systemStatisticsDTO);
      setFormData(formDataInitialState);
      navigate(
        `/system-statistics?from=${systemStatisticsDTO?.from}&to=${systemStatisticsDTO?.to}`,
      );
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const CardBodyAppUsers = () => {
    return (
      <>
        <PageHeader primaryText={t('statistics.userTypeTitle')} style={{ marginBottom: '10px' }} />
        <LoginTypeChartStatistics
          numberOfAppUsersPerLoginType={system?.numberOfAppUsersPerLoginType}
        />
      </>
    );
  };

  const CardBodyOrdersDistribution = () => {
    return (
      <>
        <PageHeader primaryText={t('statistics.ordersPriceDistribution')} style={styleHeader} />
        <BarChart ordersPriceDistribution={system?.ordersPriceDistribution} />
      </>
    );
  };

  return !isFetching ? (
    <>
      <PageHeader primaryText={t('statistics.systemStatisticsTitle')} />
      {currentRange && isSuccess && params.get('from') !== null && (
        <p className={styles.subtitle}>
          {currentRange?.from} - {currentRange?.to}
        </p>
      )}
      <BaseForm
        errors={errors as StatisticsParameters}
        handleChange={handleChangeFormData}
        isFetching={isFetching}
        isSubmitable={isSubmitable}
        onSubmit={() => handleSubmit(onSubmit)}
      />
      {isSuccess && params.get('from') !== null && (
        <div className={styles.form}>
          <h2 className={styles.title}>{t('statistics.userStatistics')}</h2>
          <div className={styles.userInformation}>
            <div className={styles.cards}>
              <CardStatistics
                header={t('statistics.registeredAppUsers')}
                icon={<User />}
                statisticsData={system?.numberOfRegisteredAppUsers}
              />
              <CardStatistics
                header={t('statistics.verifiedAppUsers')}
                icon={<UserTick />}
                statisticsData={system?.numberOfVerifiedAppUsers}
              />
              <CardStatistics
                header={t('statistics.registeredPartnerUsers')}
                icon={<User />}
                statisticsData={system?.numberOfRegisteredPartnerUsers}
              />
              <CardStatistics
                header={t('statistics.verifiedPartnerUsers')}
                icon={<UserTick />}
                statisticsData={system?.numberOfVerifiedPartnerUsers}
              />
            </div>
            <div className={styles.pieChartSegment}>
              <ACCard bodyChildren={<CardBodyAppUsers />} className={styles.pieChart} />
            </div>
          </div>
          <h2 className={styles.title}>{t('statistics.companiesAndPlacesStatistics')}</h2>
          <div className={styles.cardsTable}>
            <CardStatistics
              header={t('statistics.numberOfCompanies')}
              icon={<Building />}
              statisticsData={system?.numberOfCompanies}
            />
            <CardStatistics
              header={t('statistics.numberOfPlaces')}
              icon={<Shop />}
              statisticsData={system?.numberOfPlaces}
            />
          </div>
          <div className={styles.cardsTable}>
            <ACCard
              bodyChildren={
                <TableBestRevenues
                  bestData={system?.bestCompaniesRevenues}
                  header={t('statistics.bestCompaniesRevenues')}
                />
              }
              className={styles.table}
            />
            <ACCard
              bodyChildren={
                <TableBestRevenues
                  bestData={system?.bestPlacesRevenues}
                  header={t('statistics.bestPlacesRevenues')}
                />
              }
              className={styles.table}
            />
          </div>
          <h2 className={styles.title}>{t('statistics.orderStatistics')}</h2>
          <div className={styles.orderStatisticsCard}>
            <CardStatistics
              header={t('statistics.averageOrderPrice')}
              icon={<Money />}
              statisticsData={system?.averageOrderPrice}
            />
          </div>
          <div className={styles.barChartSegment}>
            <ACCard bodyChildren={<CardBodyOrdersDistribution />} className={styles.barChart} />
          </div>
        </div>
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' />
  );
};
export default SystemStatistics;
