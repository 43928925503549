import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Places } from 'features/place/services/getPlaces';
import moment from 'moment';
import { TAG_INVENTORIES, TAG_INVENTORY } from 'services/TagsConstants';
import { store } from 'store/store';

import { createQueryString, handleDuplicates } from '../../../utils/helpers';

const BASE_URL = '/inventories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Inventories, InventoryParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const inventoryResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const inventoriesData = inventoryResponse.data as Inventories;
      const placeIds: string[] = inventoriesData.content
        .map((inventory) => {
          return inventory?.place ? inventory?.place : null;
        })
        .filter((el) => el);
      const ids = handleDuplicates(placeIds);
      const requestersResponse = await fetchWithBQ(
        `/places?ids=${ids}&size=${ids?.length}&statuses=IN_PREPARATION,ACTIVE,ARCHIVED`,
      );
      const requestersData = requestersResponse?.data as Places;
      const countriesContent = store.getState().auth.userCountry;
      const { dateFormat } = countriesContent;
      const countries = store.getState().globalData.countries;
      const inventories = inventoriesData.content.map((inventory: Inventory) => {
        const matchedPlace = requestersData.content.find((place) => {
          return place.uuid === inventory?.place;
        });
        const matchedCountry = countries.find((country) => {
          return country.uuid === inventory?.country;
        });
        const formattedDate = moment(inventory.createdAt).format(dateFormat.toUpperCase());
        return {
          ...inventory,
          place: matchedPlace?.name,
          createdAt: formattedDate,
          country: matchedCountry?.shortName,
        };
      });
      return {
        data: {
          content: inventories,
          totalElements: inventoriesData?.totalElements,
          totalPages: inventoriesData?.totalPages,
          currentPage: inventoriesData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_INVENTORIES],
  });

export type InventoryParameters = {
  ids?: string;
  place?: string;
};

export type Inventory = {
  country?: string;
  createdAt?: string;
  createdBy?: string;
  description: string;
  name: string;
  place: string;
  serialNumber?: string;
  type: string;
  updatedAt?: string;
  updatedBy?: string;
  uuid: string;
};

export type Inventories = {
  content: Inventory[];
  currentPage?: number;
  pageable?: {
    pageNumber?: number;
  };
  place?: string;
  totalElements: number;
  totalPages: number;
};
