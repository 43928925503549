import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { OptionsType } from 'constants/TypeConstants';
import { TAG_COMPANIES, TAG_COMPANY } from 'services/TagsConstants';

import { CountryOption } from '../../../services/country';
import { Owner } from '../constants/FormConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, CompanyDTO>({
    query: (patch) => {
      const { uuid, ...tempPatch } = patch;
      return {
        url: `/company/${uuid}`,
        method: 'PATCH',
        body: tempPatch,
      };
    },
    invalidatesTags: [TAG_COMPANIES, TAG_COMPANY],
  });

export type CompanyDTO = {
  apartment?: number | string;
  city?: string | OptionsType;
  country?: string | CountryOption;
  floor?: number | string;
  idNumber: string;
  name: string;
  owner: string | Owner;
  street: string;
  streetNumber: string;
  uuid?: string;
  vatNumber: string;
  zipCode: string;
};
