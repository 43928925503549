import { ACButton, ACLoading, ACModal, ACSpinner } from '@antech-consulting/components';
import DetailViewModal from 'components/DetailViewModal/DetailViewModal';
import { Divider } from 'components/Divider/Divider';
import StyledACTable from 'components/StyledACTable/StyledACTable';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { buttonStyle } from 'features/orders/components/DetailViewPlace/DetailViewPlace';
import { useLazyGetProductsRatingQuery } from 'features/product/services';
import { Rating } from 'features/product/services/getProductsRating';
import { useAppSelector } from 'hooks/hooks';
import useTable from 'hooks/useTable';
import { InfoCircle } from 'iconsax-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { handleNavigateLink, transformACState } from 'utils/helpers';

import styles from '../PlaceReviews.module.scss';
import tablePlaceReviewsSettings from '../tablePlaceReviewsSettings';

const ProductReviews = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableSettings = useTable(tablePlaceReviewsSettings);

  const [fetchProductsRating, { data: productsRating, isFetching }] =
    useLazyGetProductsRatingQuery();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Rating>(null);

  const handleChangeTableState = (tableState: TableStateProps<void>) => {
    fetchProductsRating(transformACState({ ...tableState, place: id }, TABLE_ELEMENT_CREATED_AT));
  };

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const isSystemRoot = roles[ROLE_SYSTEM];

  const productsRatingTableData = productsRating?.content?.map((product) => {
    const { comment, createdBy, user } = product;
    const ratingComment = comment?.length > 20 ? comment.substring(0, 20) + '...' : comment;
    return {
      rawData: product,
      render: {
        ...product,
        comment: ratingComment,
        createdBy: (
          <>
            <div>{createdBy}</div>
            <div>{user?.email}</div>
          </>
        ),
      },
    };
  });

  const handleInfo = ({ rowData }) => {
    setSelectedRow(rowData);
    setIsInfoOpen(true);
  };

  const handleCloseModal = () => {
    setIsInfoOpen(false);
  };

  const additionalActions = [
    {
      icon: <InfoCircle data-testid='reviewInfo' />,
      tooltip: {
        text: t('place.productsRatings.moreDetails'),
      },
      onClick: handleInfo,
    },
  ];

  const ModalBody = () => {
    const { comment, createdAt, createdBy, product, rating } = selectedRow;

    const handleNavigate = () => {
      handleNavigateLink();
      navigate(`/app-user/${selectedRow?.user?.uuid}`, {
        state: { previousLocation: `${location.pathname}?value=4` },
      });
    };
    return (
      <div className={styles.modalInfo}>
        <div className={styles.linkSection}>
          <div className={styles.labelInfo}>
            {t('place.productsRatings.product')}
            <span className={styles.ratingInfo}>{product}</span>
          </div>
          {isSystemRoot && (
            <ACButton className={styles.link} onClick={handleNavigate} style={buttonStyle}>
              {t('orders.linkUserDetailView')}
            </ACButton>
          )}
        </div>
        <div className={styles.labelInfo}>
          {t('place.productsRatings.reviewed')}
          <span className={styles.ratingInfo}>{createdBy}</span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.productsRatings.createdBy')}
          <span className={styles.ratingInfo}>{createdAt}</span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.productsRatings.rating')}
          <span className={styles.ratingInfo}>{rating}</span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.productsRatings.comment')}
          <span className={styles.ratingInfo}>{comment}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={productsRating?.totalElements}
          defaultSort={DEFAULT_SORT}
          onTableStateChange={handleChangeTableState}
          pagesCount={productsRating?.totalPages}
          rows={productsRatingTableData}
        />
      </ACLoading>
      {isInfoOpen && (
        <ACModal className={styles.modal} isShow={isInfoOpen} onClose={handleCloseModal}>
          <DetailViewModal
            headerMessage={t('place.headerModalProductRating')}
            message={t('place.messageModalProductRating')}
            setIsDetailViewDialogOpen={handleCloseModal}
          >
            <ModalBody />
          </DetailViewModal>
        </ACModal>
      )}
    </>
  );
};

export default ProductReviews;
