import { ACButton, ACSpinner } from '@antech-consulting/components';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { styleFooterIcon } from 'constants/StyleConstants';
import { CityDTO } from 'constants/TypeConstants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { Diagram } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CountryOption } from 'services/country';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/company/components/BaseForm/BaseForm';
import { validationSchema } from '../../features/company/constants/CompanySchema';
import {
  formDataInitialState,
  FormDataType,
  Owner,
} from '../../features/company/constants/FormConstants';
import { useCompanyForm } from '../../features/company/hooks/useCompanyForm';
import { useUpdateCompanyMutation } from '../../features/company/services';
import { CompanyDTO } from '../../features/company/services/updateCompany';
import { useAppSelector } from '../../hooks/hooks';
import { useForm } from '../../hooks/useForm';
import { CurrentUser } from '../../store/Auth';
import { checkIfPropertyExistsInObject, trimObjectValues } from '../../utils/helpers';
import styles from './EditCompany.module.scss';

const EditCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [update, { isLoading }] = useUpdateCompanyMutation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const {
    citiesByCountryOptions,
    countriesOptions,
    isSuccessEdit,
    isSuccessOwner,
    partnerUsersOptions,
    shouldDisableSubmitButton,
  } = useCompanyForm(formData, setFormData, id);
  const systemRoot = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);

  const onSubmit = async () => {
    try {
      const { uuid } = formData?.owner as Owner;
      const { uuid: countryId } = formData?.country as CountryOption;
      const { uuid: cityId } = formData?.city as CityDTO;
      const trimmedData = trimObjectValues<FormDataType>(formData);

      const companyData: CompanyDTO = {
        ...trimmedData,
        country: countryId,
        owner: systemRoot && uuid,
        city: cityId,
      };
      await update(companyData).unwrap();
      navigate('/companies');
      displaySuccess({ key: 'company.updateCompany' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = () => {
    navigate('/companies');
  };

  const handleStatistics = () => {
    navigate(`/company-statistics/${id}`, { state: { companyID: id } });
  };

  const additionalFooterButtons = [
    <>
      <div>
        <ACButton
          key='companyStatistics'
          onClick={handleStatistics}
          style={styleFooterIcon.iconAction}
          variant={'text'}
        >
          <Diagram style={styleFooterIcon.iconAction} />
          {t('companyStatistics.title')}
        </ACButton>
      </div>
    </>,
  ];

  return isSuccessEdit && isSuccessOwner && !isLoading ? (
    <>
      <PageHeader primaryText={t('company.titleUpdate')} />
      <div className={styles.wrapper}>
        <BaseForm
          cities={citiesByCountryOptions}
          companyId={id}
          countriesAutocompleteOptions={countriesOptions}
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          isSystemRoot={systemRoot}
          partnerUsersAutocompleteOptions={partnerUsersOptions}
          setFormData={setFormData}
        />
      </div>
      <FormStickyFooter
        additionalActionButtons={additionalFooterButtons}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={shouldDisableSubmitButton}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditCompany;
