import { ACTabs } from '@antech-consulting/components';
import { TAB_FIRST, TAB_ZERO } from 'constants/TabsConstants';
import SettingsTab from 'features/user/components/SettingsTab/SettingsTab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';
import Profile from '../../features/user/components/ProfileTab/ProfileTab';
import styles from './UserSettings.module.scss';

const UserSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/user-profile?value=${id}`);
  };

  const tabs = [
    {
      label: t('settings.profile'),
      id: TAB_ZERO,
      content: <Profile />,
    },
    {
      label: t('settings.settingsTab'),
      id: TAB_FIRST,
      content: <SettingsTab />,
    },
  ];
  return (
    <div className={styles.box}>
      <h3 className={styles.settingsTitle}>{t('settings.title')}</h3>
      <ACTabs
        defaultTab={defaultValueTab}
        onChange={handleChange}
        style={style.ACTabs}
        tabs={tabs}
      />
    </div>
  );
};
export default UserSettings;
