import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_EMAIL } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import {
  blockUserTooltip,
  detailViewTooltip,
  FilterProps,
  INCLUDE_NOT_VERIFIED,
} from 'features/appUser/constants/AppUserConstants';
import {
  useLazyGetBlockedAppUsersQuery,
  useUnblockAppUserMutation,
} from 'features/appUser/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { InfoCircle, UserTick } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { transformACState } from '../../../../utils/helpers';
import { AppUser } from '../../services/getAppUsers';
import { blockedAppUsersTableSettings } from './blockedAppUsersTableSettings';

const TableBlockedAppUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [selectedRow, setSelectedRow] = useState<AppUser>(null);
  const [isUnblockModalOpen, toggleUnblock] = useToggle();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const tableSettings = useTable(blockedAppUsersTableSettings);
  const system = roles[ROLE_SYSTEM as never];

  const [fetchBlockedAppUsers, { data: blockedAppUsers, isFetching }] =
    useLazyGetBlockedAppUsersQuery();

  const handleTableStateChange = (tableState: TableStateProps<FilterProps>) => {
    const systemState = { ...tableState, [INCLUDE_NOT_VERIFIED]: 'true' };
    const tableData = system ? systemState : tableState;
    fetchBlockedAppUsers(transformACState(tableData, FORM_ELEMENT_EMAIL));
  };

  const [unblockRequest, { isLoading }] = useUnblockAppUserMutation();

  const appBlockedUsersTableData = useMemo(() => {
    return blockedAppUsers?.content?.map((appUser) => {
      return {
        rawData: appUser,
        render: appUser,
      };
    });
  }, [blockedAppUsers?.content]);

  const handleUnblock = ({ rowData }: { rowData: AppUser }) => {
    toggleUnblock();
    setSelectedRow(rowData);
  };

  const handleDetailView = ({ rowData }: { rowData: AppUser }) => {
    navigate(`/app-user/${rowData?.uuid}`, {
      state: { previousLocation: `${location.pathname}?value=1` },
    });
    setSelectedRow(rowData);
  };

  const unblockAppUser = {
    icon: <UserTick data-testid='unblockButton' />,
    tooltip: {
      text: t('appUsers.unblockUser'),
      style: blockUserTooltip,
    },
    onClick: handleUnblock,
  };

  const detailViewAppUser = {
    icon: <InfoCircle data-testid='detailViewButton' />,
    tooltip: {
      text: t('appUsers.detailView'),
      style: detailViewTooltip,
    },
    onClick: handleDetailView,
  };

  const additionalActions = system && [detailViewAppUser, unblockAppUser];

  const handleUnblockAppUser = async () => {
    try {
      await unblockRequest(selectedRow?.uuid).unwrap();
      toggleUnblock();
      displaySuccess({
        key: t('blockModal.unblockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleUnblock();
      displayError({ key: code });
    }
  };

  return (
    <>
      <ACLoading
        isLoading={isFetching || isLoading}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={blockedAppUsers?.totalElements}
          onItemClicked={(rowData) => handleDetailView({ rowData })}
          onTableStateChange={handleTableStateChange}
          pagesCount={blockedAppUsers?.totalPages}
          rows={appBlockedUsersTableData}
        />
      </ACLoading>
      {isUnblockModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleUnblock}
          disabled={isLoading}
          handleSubmit={handleUnblockAppUser}
          header={t('blockModal.titleUnblock')}
          icon={<UserTick style={style.BlueIconModal} />}
          isShow={isUnblockModalOpen}
          message={t('blockModal.descriptionUnblock')}
        />
      )}
    </>
  );
};
export default TableBlockedAppUsers;
