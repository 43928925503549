import api from '../../../services/api';
import changePhoneNumber from './changePhoneNumber';
import forgotPassword from './forgotPassword';
import forgotPasswordRequest from './forgotPasswordRequest';
import issueSmsCode from './issueSmsCode';
import login from './login';
import logout from './logout';
import registration from './registration';
import resendVerifyEmail from './resendVerifyEmail';
import verifyEmail from './verifyEmail';
import verifySmsCode from './verifySmsCode';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: login(build),
    logout: logout(build),
    registration: registration(build),
    issueSmsCode: issueSmsCode(build),
    verifySmsCode: verifySmsCode(build),
    verifyEmail: verifyEmail(build),
    resendVerifyEmail: resendVerifyEmail(build),
    forgotPasswordRequest: forgotPasswordRequest(build),
    forgotPassword: forgotPassword(build),
    changePhoneNumber: changePhoneNumber(build),
  }),
  overrideExisting: false,
});

export const {
  useChangePhoneNumberMutation,
  useForgotPasswordMutation,
  useForgotPasswordRequestMutation,
  useIssueSmsCodeMutation,
  useLoginMutation,
  useLogoutMutation,
  useRegistrationMutation,
  useResendVerifyEmailMutation,
  useVerifyEmailMutation,
  useVerifySmsCodeMutation,
} = authApi;
