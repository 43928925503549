import { ACButton, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import {
  STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED,
  STATUS_ELEMENT_PENDING,
} from 'constants/StatusConstants';
import {
  useApproveCompanyRequestMutation,
  useDeleteCompanyRequestMutation,
  useRejectCompanyRequestMutation,
  useRequestAdditionalInfoMutation,
  useUpdateCompanyRequestMutation,
} from 'features/company-request/services';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import useToggle from 'hooks/useToggle';
import { CloseCircle, MessageQuestion, TickCircle, Trash } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { checkIfPropertyExistsInObject, trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style, styleFooterIcon } from '../../constants/StyleConstants';
import BaseForm from '../../features/company-request/components/BaseForm/BaseForm';
import { validationSchema } from '../../features/company-request/constants/CompanyRequestSchema';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/company-request/constants/FormConstants';
import { useCompanyRequestsForm } from '../../features/company-request/hooks/useCompanyRequestsForm';
import { CompanyRequestDTO } from '../../features/company-request/services/updateCompanyRequest';
import { CountryOption } from '../../services/country';
import styles from './EditCompanyRequest.module.scss';

const EditCompanyRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { displayError, displaySuccess } = useDisplayNotification();

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const systemAdminIsLoggedIn = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const {
    companyRequest,
    countriesOptions,
    currentUserData,
    isSuccessCompanyRequest,
    shouldDisableSubmitButton,
  } = useCompanyRequestsForm(formData, setFormData, id);

  const [updateCompanyRequest, { isLoading }] = useUpdateCompanyRequestMutation();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteCompanyRequestMutation();
  const [rejectRequest, { isLoading: isLoadingReject }] = useRejectCompanyRequestMutation();
  const [approveRequest, { isLoading: isLoadingApprove }] = useApproveCompanyRequestMutation();
  const [requestAddInfo, { isLoading: isLoadingAddInfo }] = useRequestAdditionalInfoMutation();

  const [isDeleteDialogOpen, toggleDelete] = useToggle();
  const [isApproveDialogOpen, toggleApprove] = useToggle();
  const [isRejectDialogOpen, toggleReject] = useToggle();
  const [isAddInfoDialogOpen, toggleAddInfo] = useToggle();

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const { uuid } = formData?.country as CountryOption;
    const companyRequestData: CompanyRequestDTO = { ...trimmedData, country: uuid };
    try {
      await updateCompanyRequest(companyRequestData).unwrap();
      navigate('/company-requests');
      displaySuccess({ key: 'companyRequest.updateCompanyRequests' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = () => {
    navigate('/company-requests');
  };

  const handleApprove = async () => {
    try {
      await approveRequest(id).unwrap();
      toggleApprove();
      navigate('/company-requests');
      displaySuccess({ key: 'companyRequest.approveModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleApprove();
      displayError({ key: code });
    }
  };

  const handleReject = async () => {
    try {
      await rejectRequest(id).unwrap();
      toggleReject();
      navigate('/company-requests');
      displaySuccess({ key: 'companyRequest.rejectModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleReject();
      displayError({ key: code });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRequest(formData?.uuid).unwrap();
      toggleDelete();
      navigate('/company-requests');
      displaySuccess({ key: 'companyRequest.deleteModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const handleRequestAddInfo = async () => {
    try {
      await requestAddInfo(formData?.uuid).unwrap();
      toggleAddInfo();
      navigate('/company-requests');
      displaySuccess({ key: 'companyRequest.requestAddInfo' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleAddInfo();
      displayError({ key: code });
    }
  };

  const companyRequestIsEditable =
    companyRequest?.status === STATUS_ELEMENT_PENDING ||
    companyRequest?.status === STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED;

  const approveRejectActionButtons = [
    <>
      <div className={styles.actions}>
        <ACButton
          dataTestid='approveButton'
          key='approveButton'
          onClick={toggleApprove}
          style={styleFooterIcon.iconApprove}
          variant={'text'}
        >
          <TickCircle style={styleFooterIcon.iconApprove} />
          {t('companyRequest.buttonApprove')}
        </ACButton>
      </div>
    </>,
    <>
      <div>
        <ACButton
          dataTestid='rejectButton'
          key='rejectButton'
          onClick={toggleReject}
          style={styleFooterIcon.iconReject}
          variant={'text'}
        >
          <CloseCircle style={styleFooterIcon.iconReject} />
          {t('companyRequest.buttonReject')}
        </ACButton>
      </div>
    </>,
    companyRequest?.status === STATUS_ELEMENT_PENDING && (
      <>
        <div>
          <ACButton
            dataTestid='requestAddInfoButton'
            key='requestAddInfoButton'
            onClick={toggleAddInfo}
            style={styleFooterIcon.iconAction}
            variant={'text'}
          >
            <MessageQuestion style={styleFooterIcon.iconAction} />
            {t('companyRequest.addInfoCompanyRequest')}
          </ACButton>
        </div>
      </>
    ),
  ];

  return (
    <>
      {companyRequest && isSuccessCompanyRequest && !isLoading ? (
        <>
          {companyRequestIsEditable ? (
            <PageHeader primaryText={t('companyRequest.titleUpdate')} />
          ) : (
            <PageHeader primaryText={t('companyRequest.titleDetailView')} />
          )}
          <div className={styles.wrapper}>
            <BaseForm
              companyRequestId={id}
              companyRequestIsEditable={companyRequestIsEditable}
              countriesAutocompleteOptions={countriesOptions}
              currentUserData={currentUserData}
              errors={errors}
              formData={formData}
              handleChange={handleChangeFormData}
              onDiscard={onDiscard}
              setFormData={setFormData}
              systemAdminIsLoggedIn={systemAdminIsLoggedIn}
            />
          </div>
          {companyRequestIsEditable && (
            <FormStickyFooter
              additionalActionButtons={systemAdminIsLoggedIn && approveRejectActionButtons}
              onDelete={toggleDelete}
              onDeleteText={t('footer.deleteButton')}
              onDiscard={onDiscard}
              onDiscardText={t('footer.discardButton')}
              onSubmit={() => handleSubmit(onSubmit)}
              onSubmitText={t('footer.saveButton')}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
            />
          )}
          {isDeleteDialogOpen && (
            <Modal
              buttonText={t('blockModal.buttonDelete')}
              closeModal={toggleDelete}
              disabled={isLoadingDelete}
              handleSubmit={handleDelete}
              header={t('companyRequest.titleDelete')}
              icon={<Trash style={style.RedIconModal} />}
              isShow={isDeleteDialogOpen}
              message={t('companyRequest.descriptionDelete')}
            />
          )}
          {isApproveDialogOpen && (
            <Modal
              buttonText={t('blockModal.buttonConfirm')}
              closeModal={toggleApprove}
              disabled={isLoadingApprove}
              handleSubmit={handleApprove}
              header={t('companyRequest.titleApprove')}
              icon={<TickCircle style={style.BlueIconModal} />}
              isShow={isApproveDialogOpen}
              message={t('companyRequest.descriptionApprove')}
            />
          )}
          {isRejectDialogOpen && (
            <Modal
              buttonText={t('blockModal.buttonConfirm')}
              closeModal={toggleReject}
              disabled={isLoadingReject}
              handleSubmit={handleReject}
              header={t('companyRequest.titleReject')}
              icon={<CloseCircle style={style.RedIconModal} />}
              isShow={isRejectDialogOpen}
              message={t('companyRequest.descriptionReject')}
            />
          )}
          {isAddInfoDialogOpen && (
            <Modal
              buttonText={t('blockModal.buttonConfirm')}
              closeModal={toggleAddInfo}
              disabled={isLoadingAddInfo}
              handleSubmit={handleRequestAddInfo}
              header={t('companyRequest.titleAddInfo')}
              icon={<MessageQuestion style={style.BlueIconModal} />}
              isShow={isAddInfoDialogOpen}
              message={t('companyRequest.descriptionAddInfo')}
            />
          )}
        </>
      ) : (
        <ACSpinner background='var(--text-secondary)' size='xs' />
      )}
    </>
  );
};

export default EditCompanyRequest;
