import { ACAutocompleteV2, ACButton, ACInput, ACSpinner } from '@antech-consulting/components';
import {
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_FROM,
  FORM_ELEMENT_TO,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { StatisticsParameters } from 'features/systemStatistics/services/getSystemStatistics';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  FormDataTypeDefault,
  FormDataTypeHourly,
} from '../../../constants/FormConstantsStatistics';
import styles from './StatisticsBaseForm.module.scss';

export const GROUP_INTERVAL = [
  t('placeStatistics.interval.hourly'),
  t('placeStatistics.interval.daily'),
  t('placeStatistics.interval.weekly'),
  t('placeStatistics.interval.monthly'),
];

type BaseFormPlaceStatisticsProps = Omit<
  BaseFormProps<FormDataTypeDefault | FormDataTypeHourly>,
  'errors' | 'handleChange'
> & {
  errors?: StatisticsParameters;
  handleChange: (event: any, date?: string) => void;
  isFetching: boolean;
  isSubmitable: boolean;
  onSubmit: () => void;
};

const StatisticsBaseForm = ({
  errors,
  formData,
  handleChange,
  isFetching,
  isSubmitable,
  onSubmit,
}: BaseFormPlaceStatisticsProps) => {
  const { t } = useTranslation();
  const { interval } = formData;

  const GROUP_INTERVAL = [
    t('placeStatistics.interval.hourly'),
    t('placeStatistics.interval.daily'),
    t('placeStatistics.interval.weekly'),
    t('placeStatistics.interval.monthly'),
  ];

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <ACAutocompleteV2
        className={styles?.autocomplete}
        closeOnSelect
        dataTestId='intervalGroup'
        fullWidth
        name='interval'
        onChange={(event) => {
          handleChange(event);
        }}
        options={GROUP_INTERVAL}
        renderInput={(params) => {
          return <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} placeholder='interval' />;
        }}
        rounded={AC_INPUT_ROUND}
        value={interval}
      />
      {(interval === t('placeStatistics.interval.daily') ||
        interval === t('placeStatistics.interval.weekly') ||
        interval === t('placeStatistics.interval.monthly')) && (
        <>
          <div className={styles.input}>
            <input
              className={`${styles.inputField} ${errors && errors?.from ? styles.inputError : ''}`}
              id='fromDatePlaceStatistics'
              key={FORM_ELEMENT_FROM}
              name={FORM_ELEMENT_FROM}
              onChange={(event) => handleChange(event, FORM_ELEMENT_FROM)}
              placeholder='fromDate'
              type='date'
            />
            {errors && errors?.from && <p className={styles.errorMessage}>{errors?.from}</p>}
          </div>
          <div className={styles.input}>
            <input
              className={`${styles.inputField} ${errors && errors?.to ? styles.inputError : ''}`}
              id='toDatePlaceStatistics'
              key={FORM_ELEMENT_TO}
              name={FORM_ELEMENT_TO}
              onChange={(event) => handleChange(event, FORM_ELEMENT_TO)}
              placeholder='toDate'
              type='date'
            />
            {errors && errors?.to && <p className={styles.errorMessage}>{errors?.to}</p>}
          </div>
        </>
      )}
      {interval === t('placeStatistics.interval.hourly') && (
        <div className={styles.input}>
          <input
            className={`${styles.inputField} ${errors && errors?.from ? styles.inputError : ''}`}
            id='fromDatePlaceStatistics'
            key={FORM_ELEMENT_FROM}
            name={FORM_ELEMENT_FROM}
            onChange={(event) => handleChange(event, FORM_ELEMENT_FROM)}
            placeholder='fromDate'
            type='date'
          />
          {errors && errors?.from && <p className={styles.errorMessage}>{errors?.from}</p>}
        </div>
      )}
      <ACButton
        className={styles.button}
        dataTestid='showButton'
        disabled={!isSubmitable}
        fullWidth
        onClick={onSubmit}
        style={style.ButtonStatistics}
      >
        {isFetching ? (
          <ACSpinner background='var(--background)' className={styles.buttonSpinner} size='xs' />
        ) : (
          <span> {t('placeStatistics.buttonFilter')}</span>
        )}
      </ACButton>
    </form>
  );
};

export default StatisticsBaseForm;
