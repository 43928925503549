import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPE } from 'constants/NotificationConstants';
import { readErrorCode } from 'utils/readErrorCode';

export interface NotificationState {
  key?: string;
  message?: string;
  open?: boolean;
  timeout?: number | null;
  type?: string;
}

export const notificationInitialState: NotificationState = {
  open: false,
  type: NOTIFICATION_TYPE.INFO,
  key: '',
  message: '',
  timeout: 7000,
};

const slice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    setErrorMessage: (
      state,
      { payload: { key, message, timeout } }: PayloadAction<NotificationState>,
    ) => {
      state.type = 'error';
      state.key = key ? `errorAlertMessage.${readErrorCode(key)}` : `errorAlertMessage.${message}`;
      state.open = true;
      if (timeout) state.timeout = timeout;
    },
    setSuccessMessage: (state, { payload: { key, timeout } }: PayloadAction<NotificationState>) => {
      state.type = 'success';
      state.key = `successAlertMessage.${key}`;
      state.open = true;
      if (timeout) state.timeout = timeout;
    },
    setInfoMessage: (state, { payload: { key, timeout } }: PayloadAction<NotificationState>) => {
      state.type = 'info';
      state.key = `infoMessage.${key}`;
      state.open = true;
      if (timeout) state.timeout = timeout;
    },
    setWarningMessage: (
      state,
      { payload: { key, message, timeout } }: PayloadAction<NotificationState>,
    ) => {
      state.type = 'warning';
      state.key = key
        ? `warningAlertMessage.${readErrorCode(key)}`
        : `warningAlertMessage.${message}`;
      state.open = true;
      if (timeout) state.timeout = timeout;
    },

    clearNotification: () => notificationInitialState,
  },
});

export const {
  clearNotification,
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage,
  setWarningMessage,
} = slice.actions;

export default slice.reducer;
