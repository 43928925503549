import React from 'react';

import Logo from '../../images/logo.png';
import styles from './SplashScreen.module.scss';

const SplashScreen: React.FC = () => {
  return (
    <div className={styles.overlay}>
      <img alt='Logo' className={styles.logo} src={Logo} />
    </div>
  );
};

export default SplashScreen;
