import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { OptionsType } from 'constants/TypeConstants';
import { TAG_PRODUCT, TAG_PRODUCTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, ProductUpdate>({
    query: (data) => {
      const { uuid, ...payload } = data;
      return {
        url: `/product/${uuid}`,
        method: 'PATCH',
        body: payload,
      };
    },
    invalidatesTags: [TAG_PRODUCTS, TAG_PRODUCT],
  });

export type ProductUpdate = {
  available?: number;
  basePrice?: number | string;
  category?: OptionsType | string;
  description?: string;
  discountedPrice?: number | string;
  initialDailyAvailable?: number;
  name?: string;
  uuid?: string;
};
