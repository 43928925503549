import BakeryImage from '../../../images/placeholder-bakery.jpg';
import ButcheryImage from '../../../images/placeholder-butchery-shop.png';
import CaffeImage from '../../../images/placeholder-caffe.jpg';
import CateringImage from '../../../images/placeholder-catering.jpg';
import FastFoodImage from '../../../images/placeholder-fast-food.png';
import FlowerShopImage from '../../../images/placeholder-flower-shop.jpg';
import GasStationImage from '../../../images/placeholder-gas-station.png';
import GreenGroceryImage from '../../../images/placeholder-green-grocery.png';
import GroceryImage from '../../../images/placeholder-grocery.jpg';
import HotelImage from '../../../images/placeholder-hotel.png';
import PastryShopImage from '../../../images/placeholder-pastry-shop.png';
import RestaurantImage from '../../../images/placeholder-restaurant.jpg';

export const PLACE_DEFAULT_IMAGE = {
  defaultBakeryImage: BakeryImage,
  defaultCafeImage: CaffeImage,
  defaultGroceryImage: GroceryImage,
  defaultRestaurantImage: RestaurantImage,
  defeaultButcheryImage: ButcheryImage,
  defaultFastFoodImage: FastFoodImage,
  defaultPastryShopImage: PastryShopImage,
  defaultHotelImage: HotelImage,
  defaultGreenGroceryImage: GreenGroceryImage,
  defaultCateringImage: CateringImage,
  defaultFlowerShopImage: FlowerShopImage,
  defaultGasStationImage: GasStationImage,
};

export const PLACE_CATEGORIES = {
  RESTAURANT: 'RESTAURANT',
  BAKERY: 'BAKERY',
  CAFFE: 'CAFFE',
  GROCERY: 'GROCERY',
  BUTCHERY_SHOP: 'BUTCHERY_SHOP',
  FAST_FOOD: 'FAST_FOOD',
  PASTRY_SHOP: 'PASTRY_SHOP',
  HOTEL: 'HOTEL',
  GREEN_GROCERY: 'GREEN_GROCERY',
  CATERING: 'CATERING',
  FLOWER_SHOP: 'FLOWER_SHOP',
  GAS_STATION: 'GAS_STATION',
};

export const PLACE_STATUSES = {
  ACTIVE: 'ACTIVE',
  IN_PREPARATION: 'IN_PREPARATION',
};

export const INCLUDE_EMPLOYEES = 'include-employees';

export const FORM_ELEMENT_WEBSITE = 'website';
export const FORM_ELEMENT_BENEFICIARY_ACCOUNT = 'beneficiaryAccount';
export const FORM_ELEMENT_CHOSEN_LOCATION = 'chosenLocation';
export const FORM_ELEMENT_MINIMUM_ORDER_AMOUNT = 'minimumOrderAmount';
export const FORM_ELEMENT_ROLE = 'role';
export const PLACE_STATUS_ACTIVE = 'active';
export const PLACE_STATUS_IN_PREPARATION = 'in-preparation';
export const PLACE_STATUS_VERIFY = 'verify';
export const FORM_ELEMENT_FEE = 'fee';
