import { ROLE_PARTNER_MANAGER, ROLE_PARTNER_OWNER, ROLE_SYSTEM } from 'constants/RolesConstants';
import AddInventory from 'pages/AddInventory/AddInventory';
import AddProductCategoryRequest from 'pages/AddProductCategoryRequest/AddProductCategoryRequest';
import AppUserDetailView from 'pages/AppUserDetailView/AppUserDetailView';
import AppUsers from 'pages/AppUsers/AppUsers';
import CompanyStatistics from 'pages/CompanyStatistics/CompanyStatistics';
import EditInventory from 'pages/EditInventory/EditInventory';
import EditProductCategoryRequest from 'pages/EditProductCategoryRequest/EditProductCategoryRequest';
import Inventory from 'pages/Inventory/Inventory';
import Maintenance from 'pages/Maintenance/Maintenance';
import PartnerUserDetailView from 'pages/PartnerUserDetailView/PartnerUserDetailView';
import PartnerUsers from 'pages/PartnerUsers/PartnerUsers';
import PlacesList from 'pages/PlacesList/PlacesList';
import PlaceStatistics from 'pages/PlaceStatistics/PlaceStatistics';
import ProductDetailView from 'pages/ProductDetailView/ProductDetailView';
import RegistrationPartnerUsers from 'pages/RegistrationPartnerUsers/RegistrationPartnerUsers';
import SystemStatistics from 'pages/SystemStatistics/SystemStatistics';
import { Navigate, Route, Routes } from 'react-router-dom';
import { store } from 'store/store';

import AddCompany from '../pages/AddCompany/AddCompany';
import AddCompanyRequest from '../pages/AddCompanyRequest/AddCompanyRequest';
import AddPlace from '../pages/AddPlace/AddPlace';
import AddProduct from '../pages/AddProduct/AddProduct';
import AddProductCategory from '../pages/AddProductCategory/AddProductCategory';
import Company from '../pages/Company/Company';
import CompanyRequest from '../pages/CompanyRequest/CompanyRequest';
import EditCompany from '../pages/EditCompany/EditCompany';
import EditCompanyRequest from '../pages/EditCompanyRequest/EditCompanyRequest';
import EditProductCategory from '../pages/EditProductCategory/EditProductCategory';
import Orders from '../pages/Order/Order';
import OrderDetailView from '../pages/OrderDetailView/OrderDetailView';
import Place from '../pages/Place/Place';
import PlaceDetailView from '../pages/PlaceDetailView/PlaceDetailView';
import ProductCategory from '../pages/ProductCategory/ProductCategory';
import ProductCategoryRequest from '../pages/ProductCategoryRequest/ProductCategoryRequest';
import ProductsList from '../pages/ProductsList/ProductsList';
import UserSettings from '../pages/UserSettings/UserSettings';

const MainRouter = () => {
  const roles = store.getState().auth.roles;
  const system = roles[ROLE_SYSTEM as never];
  const partnerManager = roles[ROLE_PARTNER_MANAGER as never];
  const partnerOwner = roles[ROLE_PARTNER_OWNER as never];
  const partners = partnerManager || partnerOwner || system;

  return (
    <Routes>
      {system && (
        <>
          <Route element={<PartnerUserDetailView />} path='/partner-user/:id' />
          <Route element={<AppUsers />} path='/app-users' />
          <Route element={<AppUserDetailView />} path='/app-user/:id' />
          <Route element={<Maintenance />} path='/maintenance' />
          <Route element={<RegistrationPartnerUsers />} path='/partner-user/add' />
          <Route element={<Inventory />} path='/inventories' />
          <Route element={<EditInventory />} path='/inventory/:id' />
          <Route element={<AddInventory />} path='/inventory/add' />
          <Route element={<SystemStatistics />} path='/system-statistics' />
          <Route element={<AddProductCategory />} path='/product-category/add' />
          <Route element={<EditProductCategory />} path='/product-category/:id' />
          <Route element={<AddCompany />} path='/company/add' />
        </>
      )}
      {partners && (
        <>
          <Route element={<PartnerUsers />} path='/partner-users' />
          <Route element={<Place />} path='/places' />
          <Route element={<PlaceDetailView />} path='/place/:id' />
          <Route element={<ProductDetailView />} path='/product/:id' />
          <Route element={<PlacesList />} path='/choose-place' />
          <Route element={<ProductsList />} path='/products/:id' />
          <Route element={<ProductCategory />} path='/product-categories' />
          <Route element={<ProductCategoryRequest />} path='/product-category-requests' />
          <Route element={<AddProduct />} path='/product/add/:id' />
          <Route element={<AddProduct />} path='/product/add' />
          <Route element={<EditCompany />} path='/company/:id' />
          <Route element={<Company />} path='/companies' />
          <Route element={<Orders />} path='/orders' />
          <Route element={<OrderDetailView />} path='/order/:id' />
          <Route element={<AddPlace />} path='/place/add' />
          <Route element={<PlaceStatistics />} path='/place-statistics/:id' />
          <Route element={<CompanyStatistics />} path='/company-statistics/:id' />
          <Route element={<AddProductCategoryRequest />} path='/product-category-request/add' />
          <Route element={<EditProductCategoryRequest />} path='/product-category-request/:id' />
        </>
      )}
      <Route element={<UserSettings />} path='/user-profile' />
      <Route element={<CompanyRequest />} path='/company-requests' />
      <Route element={<AddCompanyRequest />} path='/company-request/add' />
      <Route element={<EditCompanyRequest />} path='/company-request/:id' />
      {partners ? (
        <Route element={<Navigate replace to='/places' />} path='*' />
      ) : (
        <Route element={<Navigate replace to='/company-request/add' />} path='*' />
      )}
    </Routes>
  );
};

export default MainRouter;
