import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AppUser, AppUsersResponse } from 'features/appUser/services/getAppUsers';
import moment from 'moment';
import { TAG_PRODUCT } from 'services/TagsConstants';
import { store } from 'store/store';
import { createQueryString, handleDuplicates } from 'utils/helpers';

import { Country } from '../../../services/country';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductRatingsDTO, ProductRatingsParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id, ...params } = _arg;
      const productResponse = await fetchWithBQ(
        createQueryString(params, `/product/${id}/ratings`),
      );
      const productData = productResponse.data as ProductRatingsDTO;
      const users = productData.content.map((rating: ProductRatingsContentDTO) => {
        return rating?.createdBy;
      });
      const usersIds = handleDuplicates(users);
      const usersResponse = await fetchWithBQ(`/app-users?size=${usersIds.length}&ids=${usersIds}`);
      const userData = usersResponse.data as AppUsersResponse;
      const countries = store.getState().globalData.countries;
      const products = productData.content.map((product: ProductRatingsContentDTO) => {
        const { createdAt } = product;
        const { dateFormat } = countries.find((country: Country) => country.uuid);
        const formatDateCreate = moment(createdAt).format(dateFormat.toUpperCase());
        const matchedUser = userData.content.find((user) => user?.uuid === product?.createdBy);
        return {
          ...product,
          createdAt: formatDateCreate,
          createdBy: matchedUser?.firstName + ' ' + matchedUser?.lastName,
          user: matchedUser,
        };
      });
      return {
        data: {
          content: products,
          totalElements: productData?.totalElements,
          totalPages: productData?.totalPages,
        },
      };
    },
    providesTags: [TAG_PRODUCT],
  });

export type ProductRatingsParameters = {
  id?: string;
};

export type ProductRatingsDTO = {
  content: ProductRatingsContentDTO[];
  totalElements: number;
  totalPages: number;
};

export type ProductRatingsContentDTO = {
  comment: string;
  createdAt: string;
  createdBy: string;
  rating: number;
  user?: AppUser;
};
