import { t } from 'i18next';

export const convertACAutocompleteEventValueFromStringToBoolean = (e: any) => {
  if (e.ac.value === t('maintenance.yes')) {
    e.ac.value = true;
  } else {
    e.ac.value = false;
  }

  return e;
};

export const getYesNoOptionsArray = () => {
  return [t('maintenance.yes'), t('maintenance.no')];
};
