export const formDataInitialState: FormDataType = {
  currentAndroidVersion: '',
  dashboardMaintenanceMode: false,
  minimalRequiredAndroidVersion: '',
  mobileMaintenanceMode: false,
  maintenanceModeMessage: '',
  maintenanceModeTitle: '',
  firebaseTokenValidity: '',
  dashboardImageQualitySettings: {
    placeDetail: '',
    placeList: '',
    productCategoryAll: '',
    productDetail: '',
    productList: '',
  },
  placeDetail: '',
  placeList: '',
  productCategoryAll: '',
  productDetail: '',
  productList: '',
};

export type FormDataType = {
  currentAndroidVersion: string;
  dashboardImageQualitySettings: {
    placeDetail: string;
    placeList: string;
    productCategoryAll: string;
    productDetail: string;
    productList: string;
  };
  dashboardMaintenanceMode: boolean;
  firebaseTokenValidity: string;
  maintenanceModeMessage: string;
  maintenanceModeTitle: string;
  minimalRequiredAndroidVersion: string;
  mobileMaintenanceMode: boolean;
  placeDetail?: string;
  placeList?: string;
  productCategoryAll?: string;
  productDetail?: string;
  productList?: string;
};

export const FORM_ELEMENT_CURRENT_ANDROID_VERSION = 'currentAndroidVersion';
export const FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION = 'minimalRequiredAndroidVersion';
export const FORM_ELEMENT_MOBILE_MAINTENANCE_MODE = 'mobileMaintenanceMode';
export const FORM_ELEMENT_DASHBOARD_MAINTENANCE_MODE = 'dashboardMaintenanceMode';
export const FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY = 'firebaseTokenValidity';
export const FORM_ELEMENT_MAINTENANCE_MODE_TITLE = 'maintenanceModeTitle';
export const FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE = 'maintenanceModeMessage';
export const FORM_ELEMENT_PLACE_LIST = 'placeList';
export const FORM_ELEMENT_PLACE_DETAIL = 'placeDetail';
export const FORM_ELEMENT_PRODUCT_LIST = 'productList';
export const FORM_ELEMENT_PRODUCT_DETAIL = 'productDetail';
export const FORM_ELEMENT_PRODUCT_CATEGORY_ALL = 'productCategoryAll';
