import { FORM_ELEMENT_RATING } from 'constants/FormConstants';
import {
  TABLE_ELEMENT_COMMENT,
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_CREATED_BY,
  TABLE_INITIAL_ROWS_NUMBER,
} from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import { TableSettingsType } from 'hooks/useTable';
import { InfoCircle } from 'iconsax-react';

const { DETAIL_VIEW } = tableActionTypes;

const productTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'productReviewTable',
  columns: [
    {
      title: 'productReview.createdBy',
      field: TABLE_ELEMENT_CREATED_BY,
    },
    {
      title: 'productReview.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
    {
      title: 'productReview.rating',
      field: FORM_ELEMENT_RATING,
    },
    {
      title: 'productReview.comment',
      field: TABLE_ELEMENT_COMMENT,
    },
  ],
  actions: [
    {
      redirect: 'modal',
      name: DETAIL_VIEW,
      icon: <InfoCircle data-testid='detailView' />,
      tooltip: 'productReview.detailView',
      header: false,
    },
  ],
};

export default productTableSettings;
