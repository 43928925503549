import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Country } from 'services/country';
import { TAG_USER } from 'services/TagsConstants';
import { setUserBasicInfo } from 'store/Auth';
import { store } from 'store/store';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<User, void>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const userResponse = await fetchWithBQ('/partner-user/current');
      const userData = userResponse.data as User;
      const basicInfo = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: userData.phoneNumber,
        phoneNumberCountryCode: userData.phoneNumberCountryCode,
        email: userData.email,
        roles: userData.roles,
        userCountry: store.getState().auth.userCountry,
      };

      store.dispatch(setUserBasicInfo(basicInfo));
      return {
        data: {
          ...userData,
        },
      };
    },
    providesTags: [TAG_USER],
    keepUnusedDataFor: 0,
  });

export type User = {
  country: Country | string;
  createdAt: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  phoneVerified: boolean;
  roles: string[];
  settings?: {
    dashboard?: {
      language?: string;
    };
  };
  uuid: string;
};
