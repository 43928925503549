import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { SortProps } from 'constants/TypeConstants';
import { PartnerUserResponse } from 'features/partnerUser/services/getPartnerUsers';
import moment from 'moment';
import { TAG_COMPANIES } from 'services/TagsConstants';
import { store } from 'store/store';

import { Country } from '../../../services/country';
import { createQueryString, handleDuplicates } from '../../../utils/helpers';
import { FilterProps } from '../components/TableCompany/TableCompany';

const BASE_URL = '/companies';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CompaniesResponse, CompanyParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      let queryString = createQueryString(_arg, BASE_URL);
      queryString = queryString.replace('&owner', '&owners');
      const roles = store.getState().auth.roles;
      const system = roles[ROLE_SYSTEM as never];
      const companiesResponse = await fetchWithBQ(queryString);
      const companiesData = companiesResponse.data as CompaniesResponse;
      const countries = store.getState().globalData.countries;
      const { dateFormat } = store.getState().auth.userCountry;
      const ownerIds = companiesData.content.map((company) => {
        return company?.owner ? company?.owner : null;
      });
      const ids = handleDuplicates(ownerIds);
      const requestersResponse = system
        ? await fetchWithBQ(`/partner-users?size=${ids?.length}&ids=${ids}`)
        : null;
      const requestersData = requestersResponse
        ? (requestersResponse?.data as PartnerUserResponse)
        : null;
      const { cities } = store.getState().globalData;
      const companies = companiesData.content.map((company: Company) => {
        const { createdAt } = company;
        const formatDate = moment(createdAt).format(dateFormat.toUpperCase());
        const country = countries.find((country) => country.uuid === company.country);
        const countryName = country?.shortName;
        const matchedOwner = requestersData
          ? requestersData.content?.find((user) => {
              return user.uuid === company?.owner;
            })
          : null;
        const matchedCity = cities.find((city) => {
          return city?.uuid === company?.city;
        });
        return {
          ...company,
          createdAt: formatDate,
          country: countryName,
          createdBy: matchedOwner ? matchedOwner?.email : null,
          city: matchedCity?.name,
        };
      });
      return {
        data: {
          content: companies,
          totalElements: companiesData.totalElements,
          totalPages: companiesData.totalPages,
          countries: countries,
          currentPage: companiesData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_COMPANIES],
  });

export type CompaniesResponse = {
  content: Company[];
  countries: Country[];
  currentPage?: number;
  pageable?: {
    pageNumber?: number;
  };
  totalElements?: number;
  totalPages?: number;
};

export type Company = {
  apartment?: number | string;
  city?: string;
  country: Country | string;
  createdAt: string;
  createdBy: string;
  flag?: string;
  floor?: number | string;
  id: any;
  idNumber: string;
  name: string;
  owner: string;
  ownerEmail?: string;
  street: string;
  streetNumber: string;
  updatedAt: string;
  updatedBy: string;
  uuid: string;
  value?: string;
  vatNumber: string;
  zipCode: string;
};

export type CompanyParameters = {
  city?: string;
  country?: string;
  filter?: FilterProps;
  ids?: string;
  name?: string;
  owners?: string;
  page?: number;
  size?: number;
  sort?: SortProps;
};
