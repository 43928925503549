import { ACButton } from '@antech-consulting/components';
import { FORM_ELEMENT_FROM, FORM_ELEMENT_TO } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { StatisticsParameters } from 'features/systemStatistics/services/getSystemStatistics';
import { useTranslation } from 'react-i18next';

import styles from './StatisticsBaseForm.module.scss';

type BaseFormStatisticsProps = {
  errors?: StatisticsParameters;
  handleChange: (event: any, date?: string) => void;
  isSubmitable: boolean;
  onSubmit: () => void;
};

const StatisticsBaseForm = ({
  errors,
  handleChange,
  isSubmitable,
  onSubmit,
}: BaseFormStatisticsProps) => {
  const { t } = useTranslation();
  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <div className={styles.input}>
        <input
          className={`${styles.inputField} ${errors && errors?.from ? styles.inputError : ''}`}
          id='fromDateCompanyStatistics'
          key={FORM_ELEMENT_FROM}
          name={FORM_ELEMENT_FROM}
          onChange={(event) => handleChange(event, FORM_ELEMENT_FROM)}
          placeholder='fromDate'
          type='date'
        />
        {errors && errors?.from && <p className={styles.errorMessage}>{errors?.from}</p>}
      </div>
      <div className={styles.input}>
        <input
          className={`${styles.inputField} ${errors && errors?.to ? styles.inputError : ''}`}
          id='toDateCompanyStatistics'
          key={FORM_ELEMENT_TO}
          name={FORM_ELEMENT_TO}
          onChange={(event) => handleChange(event, FORM_ELEMENT_TO)}
          placeholder='toDate'
          type='date'
        />
        {errors && errors?.to && <p className={styles.errorMessage}>{errors?.to}</p>}
      </div>
      <div className={styles.button}>
        <ACButton
          dataTestid='showButton'
          disabled={!isSubmitable}
          fullWidth
          onClick={onSubmit}
          style={style.ButtonStatistics}
        >
          {t('companyStatistics.buttonFilter')}
        </ACButton>
      </div>
    </form>
  );
};

export default StatisticsBaseForm;
