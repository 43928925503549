import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USER } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, UpdateUser>({
    query: (credentials: UpdateUser) => ({
      url: 'partner-user/current',
      method: 'PATCH',
      body: credentials,
    }),
    invalidatesTags: [TAG_USER],
  });

export type UpdateUser = {
  firstName?: string;
  lastName?: string;
  settings?: {
    dashboard: {
      language: string;
    };
  };
};
