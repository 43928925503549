import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_APP_USER, TAG_BLOCKED_APP_USERS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/app-user/${id}/unblock`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_BLOCKED_APP_USERS, TAG_APP_USER],
  });
