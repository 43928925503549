import DetailViewPlace from 'features/orders/components/DetailViewPlace/DetailViewPlace';
import { useParams } from 'react-router-dom';

import DetailViewPayment from '../../features/orders/components/DetailViewPayment/DetailViewPayment';
import DetailViewUser from '../../features/orders/components/DetailViewUser/DetailViewUser';
import OrderActivity from '../../features/orders/components/OrderActivity/OrderActivity';
import OrderDetailViewTable from '../../features/orders/components/OrderDetailViewTable/OrderDetailViewTable';
import OrderReview from '../../features/orders/components/OrderReview/OrderReview';
import styles from './OrderDetailView.module.scss';

const OrderDetailView = () => {
  const { id } = useParams();

  return (
    <>
      <OrderDetailViewTable orderId={id} />
      <div className={styles.userInfo}>
        <DetailViewUser orderId={id} />
        <DetailViewPayment orderId={id} />
      </div>
      <div className={styles.userInfo}>
        <DetailViewPlace orderId={id} />
        <OrderReview orderId={id} />
      </div>
      <OrderActivity orderId={id} />
    </>
  );
};

export default OrderDetailView;
