import { ACButton, ACInput } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF, FORM_ELEMENT_PASSWORD } from 'constants/FormConstants';
import { validationSchemaNewPassword } from 'features/auth/constants/ForgotPasswordFormSchema';
import { useForgotPasswordMutation } from 'features/auth/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { style } from '../../../../constants/StyleConstants';
import { useForm } from '../../../../hooks/useForm';
import {
  formDataInitialStateNewPassword,
  FormDataTypeNewPassword,
} from '../../constants/ForgotPasswordFormConstants';
import styles from '../ForgotPasswordForm/ForgotPasswordForm.module.scss';

type BaseFormForgotPasswordProps = {
  token?: string;
};

const ForgotPasswordNewPassword = ({ token }: BaseFormForgotPasswordProps) => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataTypeNewPassword>(
    formDataInitialStateNewPassword,
    validationSchemaNewPassword,
  );
  const { password } = formData;

  const onSubmit = async () => {
    const ForgotPasswordDTO = {
      token,
      password,
    };
    try {
      await forgotPassword(ForgotPasswordDTO).unwrap();
      navigate('/');
      displaySuccess({ key: 'forgotPassword.passwordChanged' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <form>
      <div className={styles.textField}>
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          errorMessage={errors?.password}
          fullWidth
          hasError={!!errors?.password}
          id={FORM_ELEMENT_PASSWORD}
          label={t('forgotPassword.password')}
          name={FORM_ELEMENT_PASSWORD}
          onChange={handleChangeFormData}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputAuth}
          type={'password'}
          value={password}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <ACButton
          dataTestid='ButtonForgotPassword'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
        >
          {t('forgotPassword.buttonChangePassword')}
        </ACButton>
        <div className={styles.regLink}>
          <span className={styles.text}>{t('forgotPassword.textBackTo')} </span>
          <Link className={styles.link} to='/'>
            {t('forgotPassword.linkSignIn')}
          </Link>
        </div>
      </div>
    </form>
  );
};
export default ForgotPasswordNewPassword;
