import { ACLoading, ACModal, ACSpinner, IACTableRow } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { ROLE_ELEMENT_OWNER, ROLE_TERMINAL } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { INCLUDE_EMPLOYEES } from 'features/place/constants/PlaceContants';
import placeEmployeesTableSettings from 'features/place/constants/placeEmployeesTableSettings';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import { Edit2, Trash } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { transformACState } from '../../../../utils/helpers';
import { useLazyGetPlaceByIdQuery, useRemovePlaceEmployeeMutation } from '../../services';
import EditPlaceEmployee from '../EditPlaceEmployee/EditPlaceEmployee';
import { FilterPropsPlace } from '../TablePlace/TablePlace';
import AddEmployee from './AddEmployee';
import styles from './PlaceEmployees.module.scss';

const ROLES_KEY = 0;

export type TableStatePropsEmployees = TableStateProps<FilterPropsPlace> & {
  code: string;
  size: number;
  statuses: string;
};

const PlaceEmployees = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [fetchPlace, { data: place, isFetching }] = useLazyGetPlaceByIdQuery();
  const tableSettings = useTable(placeEmployeesTableSettings);
  const {
    actions,
    headerActions,
    isAddEnabled,
    isDeleteEnabled,
    isEditEnabled,
    selectedRow,
    setSelectedRow,
    toggleAdd,
    toggleDelete,
    toggleEdit,
  } = tableSettings;
  const [removeEmployee] = useRemovePlaceEmployeeMutation();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const isTerminalUser =
    roles[ROLE_TERMINAL as never] && roles[ROLE_TERMINAL as never][ROLES_KEY] === id;
  const headerTableActions = !isTerminalUser ? headerActions : undefined;
  const actionsTable = !isTerminalUser ? actions : undefined;

  const usersPlacesTableData = useMemo(() => {
    return place?.employees?.map((employee) => {
      return {
        rawData: employee,
        render: employee,
      };
    });
  }, [place?.employees, isEditEnabled]);

  const handleTableChange = (tableState: TableStatePropsEmployees) => {
    const tableData = transformACState({ ...tableState, [INCLUDE_EMPLOYEES]: 'true' }, '');
    const placeParams = { id, ...tableData } as {
      [INCLUDE_EMPLOYEES]: string;
      id: string;
    };
    fetchPlace(placeParams);
  };

  const handleDeletePlaceEmployee = async () => {
    try {
      await removeEmployee({ employee: { email: selectedRow?.email }, id }).unwrap();
      toggleDelete();
      displaySuccess({ key: 'deleteModal.removeImage' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const handleEdit = ({ rowData }: { rowData: TableStatePropsEmployees }) => {
    toggleEdit();
    setSelectedRow(rowData);
  };

  const handleDelete = ({ rowData }: { rowData: TableStatePropsEmployees }) => {
    toggleDelete();
    setSelectedRow(rowData);
  };

  const editPlaceEmployee = {
    icon: <Edit2 data-testid='editButton' />,
    tooltip: {
      text: t('place.editEmployee'),
      style: {
        width: '120px',
      },
    },
    onClick: handleEdit,
    isHidden: ({ rawData }: IACTableRow) => {
      return rawData.role.toLowerCase() === ROLE_ELEMENT_OWNER;
    },
  };

  const deletePlaceEmployee = {
    icon: <Trash data-testid='deleteButton' />,
    tooltip: {
      text: t('place.removeEmployee'),
      style: {
        left: 0,
      },
    },
    onClick: handleDelete,
    isHidden: ({ rawData }: IACTableRow) => {
      return rawData.role.toLowerCase() === ROLE_ELEMENT_OWNER;
    },
  };

  const additionalActions = [editPlaceEmployee, deletePlaceEmployee];

  return (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={[...additionalActions, ...actionsTable]}
          allItemsCount={place?.employeesTotalElements}
          headerActions={headerTableActions}
          onTableStateChange={handleTableChange}
          pagesCount={place?.employeesTotalPages}
          rows={usersPlacesTableData}
        />
      </ACLoading>
      {isAddEnabled && (
        <ACModal className={styles.modalAdd} isShow={isAddEnabled} onClose={toggleAdd}>
          <AddEmployee placeId={id} refetchItem={fetchPlace} setIsAddDialogOpen={toggleAdd} />
        </ACModal>
      )}
      {isDeleteEnabled && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          handleSubmit={handleDeletePlaceEmployee}
          header={t('place.deleteModalHeader')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteEnabled}
          message={t('place.deleteModalBody')}
        />
      )}
      {isEditEnabled && (
        <ACModal className={styles.modalAdd} isShow={isEditEnabled} onClose={toggleEdit}>
          <EditPlaceEmployee
            editEmployee={isEditEnabled}
            placeId={id}
            selectedEmployee={selectedRow}
            setEditEmployee={toggleEdit}
          />
        </ACModal>
      )}
    </>
  );
};

export default PlaceEmployees;
