import { ACCard } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { ReactElement } from 'react';

import styles from './CardStatistics.module.scss';

const CardStatistics = ({
  dataStatistics,
  header,
  icon,
}: {
  dataStatistics: number;
  header: string;
  icon: ReactElement;
}) => {
  return (
    <ACCard
      bodyChildren={
        <>
          <div style={style.StatisticHeader}>{header}</div>
          <div className={styles.description}>
            <div style={style.IconStatistics}>{icon}</div>
            <div style={style.DescriptionCardStatistics}>{dataStatistics}</div>
          </div>
        </>
      }
      className={styles.statisticsCard}
      dataTestiId='cardCompanyStatistic'
    />
  );
};

export default CardStatistics;
