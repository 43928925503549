import api from '../../../services/api';
import acceptOrder from './acceptOrder';
import getOrderById from './getOrderById';
import getOrders from './getOrders';
import getOrdersRatings from './getOrdersRatings';
import pickUpOrder from './pickUpOrder';
import readyOrder from './readyOrder';
import rejectOrder from './rejectOrder';
import seenOrder from './seenOrder';

export const ordersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrders: getOrders(build),
    getOrderById: getOrderById(build),
    getOrdersRatings: getOrdersRatings(build),
    seenOrder: seenOrder(build),
    acceptOrder: acceptOrder(build),
    rejectOrder: rejectOrder(build),
    readyOrder: readyOrder(build),
    pickUpOrder: pickUpOrder(build),
  }),
  overrideExisting: false,
});

export const {
  useAcceptOrderMutation,
  useGetOrderByIdQuery,
  useLazyGetOrdersQuery,
  useLazyGetOrdersRatingsQuery,
  usePickUpOrderMutation,
  useReadyOrderMutation,
  useRejectOrderMutation,
  useSeenOrderMutation,
} = ordersApi;
