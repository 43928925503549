import { ACButton } from '@antech-consulting/components';
import { styleFooterIcon } from 'constants/StyleConstants';
import { PLACE_STATUSES } from 'features/place/constants/PlaceContants';
import { Additem, Archive, Diagram, ExportSquare, Verify } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

const DetailViewPlaceActions = ({
  handleStatistics,
  place,
  toggleActive,
  toggleArchive,
  togglePreparation,
  toggleVerified,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {place?.status === PLACE_STATUSES.ACTIVE && (
        <>
          <ACButton
            dataTestid='inPreparationButton'
            key='putInPreparation'
            onClick={togglePreparation}
            style={styleFooterIcon.iconAction}
            variant={'text'}
          >
            <ExportSquare style={styleFooterIcon.iconAction} />
            {t('place.putInPreparation')}
          </ACButton>
          <ACButton
            dataTestid='archiveButton'
            key='archivePlace'
            onClick={toggleArchive}
            style={styleFooterIcon.iconAction}
            variant={'text'}
          >
            <Archive style={styleFooterIcon.iconAction} />
            {t('place.archivePlace')}
          </ACButton>
          <ACButton
            dataTestid='placeStatistics'
            key='placeStatistics'
            onClick={handleStatistics}
            style={styleFooterIcon.iconAction}
            variant={'text'}
          >
            <Diagram style={styleFooterIcon.iconAction} />
            {t('placeStatistics.title')}
          </ACButton>
        </>
      )}
      {place?.status === PLACE_STATUSES.IN_PREPARATION && (
        <>
          {place?.verified && (
            <>
              <ACButton
                dataTestid='activatePlace'
                key='activatePlace'
                onClick={toggleActive}
                style={styleFooterIcon.iconAction}
                variant={'text'}
              >
                <Additem style={styleFooterIcon.iconAction} />
                {t('place.activatePlace')}
              </ACButton>
              <ACButton
                dataTestid='archivePlace'
                key='archivePlace'
                onClick={toggleArchive}
                style={styleFooterIcon.iconAction}
                variant={'text'}
              >
                <Archive style={styleFooterIcon.iconAction} />
                {t('place.archivePlace')}
              </ACButton>
            </>
          )}
          {!place?.verified && (
            <>
              <ACButton
                dataTestid='verifyPlace'
                key='verifyPlace'
                onClick={toggleVerified}
                style={styleFooterIcon.iconAction}
                variant={'text'}
              >
                <Verify style={styleFooterIcon.iconAction} />
                {t('place.titleVerify')}
              </ACButton>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DetailViewPlaceActions;
