import {
  FORM_ELEMENT_ADDRESS,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_RATING,
} from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import { FORM_ELEMENT_FEE } from 'features/place/constants/PlaceContants';
import { TableSettingsType } from 'hooks/useTable';
import { Edit2 } from 'iconsax-react';

const { EDIT } = tableActionTypes;

const tablePlaceInPreparationSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'placeTable',
  columns: [
    {
      title: 'place.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'place.address',
      field: FORM_ELEMENT_ADDRESS,
    },
    {
      title: 'place.countryColumn',
      field: FORM_ELEMENT_COUNTRY,
    },
    {
      title: 'place.rating',
      field: FORM_ELEMENT_RATING,
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_RATING] },
  actions: [
    {
      icon: <Edit2 data-testid='editButton' />,
      tooltip: 'place.titleUpdate',
      name: EDIT,
      redirect: '/place',
    },
  ],
};

export default tablePlaceInPreparationSettings;
