import { ACCard } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import { UniqueUsersInOrders } from 'features/place/services/getPlaceStatistics';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './UniqueUsersInOrdersCard.module.scss';

const UniqueUsersInOrdersCard = ({
  data,
  header,
}: {
  data: UniqueUsersInOrders;
  header: string;
}) => {
  const { t } = useTranslation();
  const name = Object.keys(data);

  const uniqueUsersInOrders = useMemo(() => {
    return name?.map((objectKey) => {
      const usersCount = 'usersCount';
      const ordersCount = 'ordersCount';
      const nonUniqueUsersCount = 'nonUniqueUsersCount';
      const uniqueUsersInOrdersName =
        (objectKey === usersCount && t('placeStatistics.usersCount')) ||
        (objectKey === ordersCount && t('placeStatistics.ordersCount')) ||
        (objectKey === nonUniqueUsersCount && t('placeStatistics.usersCountMoreTime'));
      return (
        <div className={styles.infoCard} key={'uniqueUsersInOrders'}>
          <div>
            {uniqueUsersInOrdersName}
            {':'} {data[objectKey]}
          </div>
        </div>
      );
    });
  }, [name]);

  return (
    <ACCard
      bodyChildren={
        <div>
          <div style={style.StatisticHeader}>{header}</div>
          <div className={styles.description}>
            <div className={styles.descriptionBody}>{uniqueUsersInOrders}</div>
          </div>
        </div>
      }
      className={styles.statisticsCard}
      dataTestiId='cardUniqueUsersInOrders'
    />
  );
};

export default UniqueUsersInOrdersCard;
