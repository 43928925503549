import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  nameEn: {
    isRequired: true,
    max: 50,
  },
  nameSr: {
    isRequired: true,
    max: 50,
  },
  nameHr: {
    isRequired: true,
    max: 50,
  },
};
