import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import CountryLabel from 'components/CountryLabel/CountryLabel';
import { FormHeader } from 'components/FormHeader/FormHeader';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  BOTTOM_SCROLL_OFFSET,
  FORM_ELEMENT_APARTMENT,
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_FLOOR,
  FORM_ELEMENT_ID_NUMBER,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_STREET,
  FORM_ELEMENT_STREET_NUMBER,
  FORM_ELEMENT_VAT_NUMBER,
  FORM_ELEMENT_ZIP_CODE,
} from 'constants/FormConstants';
import { ROLE_ELEMENT_OWNER } from 'constants/RolesConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps, OptionsType } from 'constants/TypeConstants';
import { useTranslation } from 'react-i18next';

import { FormDataType } from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

type BaseFormCompanyProps = BaseFormProps<FormDataType> & {
  cities?: OptionsType[];
  companyId?: string;
  countriesAutocompleteOptions: OptionsType[];
  isSystemRoot?: boolean;
  partnerUsersAutocompleteOptions: OptionsType[];
  setFormData?: (data: FormDataType) => void;
};

const BaseForm = ({
  cities,
  companyId,
  countriesAutocompleteOptions,
  errors,
  formData,
  handleChange,
  isSystemRoot,
  partnerUsersAutocompleteOptions,
  setFormData,
}: BaseFormCompanyProps) => {
  const { t } = useTranslation();
  const {
    apartment,
    city,
    country,
    floor,
    idNumber,
    name,
    owner,
    street,
    streetNumber,
    vatNumber,
    zipCode,
  } = formData;

  const isDisabled = !companyId;
  const isDisplayOwner = !companyId || isSystemRoot;
  const baseSecondaryText = companyId ? t('form.editBaseSubtitle') : t('form.addBaseSubtitle');
  const addressSecondaryText = companyId
    ? t('form.place.editSubtitleAddress')
    : t('form.place.addSubtitleAddress');

  const handleCountryChange = (event) => {
    const {
      ac: { value },
    } = event;
    setFormData({
      ...formData,
      country: value,
      city: '',
    });
  };

  const handleCityChange = (event) => {
    const {
      ac: { value },
    } = event;
    setFormData({
      ...formData,
      city: value,
      zipCode: '',
      street: '',
      streetNumber: '',
      floor: '',
      apartment: '',
    });
  };

  return (
    <>
      <form className={styles.formWrapper}>
        <FormHeader primaryText={t('form.formBaseTitle')} secondaryText={baseSecondaryText} />
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyName'
              errorMessage={errors?.name}
              fullWidth
              hasError={!!errors?.name}
              id={FORM_ELEMENT_NAME}
              label={t('company.name')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_NAME}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={name}
            />
          </div>
          {isDisplayOwner && (
            <div className={styles.inputHalfWidth}>
              <ACAutocompleteV2
                closeOnSelect
                dataTestId='companyOwner'
                errorMessage={errors?.owner}
                fullWidth
                getOptionLabel={(option) => option?.email}
                getSelectByProperty={(option) => option?.uuid}
                hasError={!!errors?.owner}
                id={ROLE_ELEMENT_OWNER}
                label={t('company.owner')}
                name={ROLE_ELEMENT_OWNER}
                onChange={(event) => {
                  handleChange(event);
                }}
                options={partnerUsersAutocompleteOptions}
                renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
                rounded={AC_INPUT_ROUND}
                style={style.ACInputForm}
                value={owner}
              />
            </div>
          )}
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='idNumber'
              errorMessage={errors?.idNumber}
              fullWidth
              hasError={!!errors?.idNumber}
              id={FORM_ELEMENT_ID_NUMBER}
              label={t('company.idNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_ID_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={idNumber}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='vatNumber'
              errorMessage={errors?.vatNumber}
              fullWidth
              hasError={!!errors?.vatNumber}
              id={FORM_ELEMENT_VAT_NUMBER}
              label={t('company.vatNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_VAT_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={vatNumber}
            />
          </div>
        </div>
        <FormHeader
          primaryText={t('form.place.formTitleAddress')}
          secondaryText={addressSecondaryText}
        />
        <ACAutocompleteV2
          bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
          closeOnSelect
          dataTestId='companyCountry'
          disabled={!isDisabled}
          errorMessage={errors?.country}
          fullWidth
          getCustomRender={(country) => (
            <CountryLabel isoName={country?.isoName} name={country?.name} />
          )}
          getOptionLabel={(option) => option?.name}
          hasError={!!errors?.country}
          id={FORM_ELEMENT_COUNTRY}
          label={t('company.country')}
          name={FORM_ELEMENT_COUNTRY}
          onChange={(event) => {
            handleCountryChange(event);
          }}
          options={countriesAutocompleteOptions}
          renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputForm}
          value={country}
        />
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACAutocompleteV2
              bottomScrollOffset={BOTTOM_SCROLL_OFFSET}
              closeOnSelect
              dataTestId='companyCity'
              disabled={!country}
              errorMessage={errors?.city}
              fullWidth
              getOptionLabel={(option) => option?.name}
              hasError={!!errors?.city}
              label={t('company.city')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_CITY}
              onChange={(event) => {
                handleCityChange(event);
              }}
              options={cities || []}
              renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? city : []}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyZipCode'
              disabled={!city}
              errorMessage={errors?.zipCode}
              fullWidth
              hasError={!!errors?.zipCode}
              id={FORM_ELEMENT_ZIP_CODE}
              label={t('company.zipCode')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_ZIP_CODE}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={city ? zipCode : ''}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyStreet'
              disabled={!city}
              errorMessage={errors?.street}
              fullWidth
              hasError={!!errors?.street}
              id={FORM_ELEMENT_STREET}
              label={t('company.street')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_STREET}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={city ? street : ''}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyStreetNumber'
              disabled={!city}
              errorMessage={errors?.streetNumber}
              fullWidth
              hasError={!!errors?.streetNumber}
              id={FORM_ELEMENT_STREET_NUMBER}
              label={t('company.number')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_STREET_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={city ? streetNumber : ''}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyFloor'
              disabled={!city}
              errorMessage={errors?.floor}
              fullWidth
              hasError={!!errors?.floor}
              id={FORM_ELEMENT_FLOOR}
              label={t('company.floor')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_FLOOR}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={city ? floor : ''}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyApartment'
              disabled={!city}
              errorMessage={errors?.apartment}
              fullWidth
              hasError={!!errors?.apartment}
              id={FORM_ELEMENT_APARTMENT}
              label={t('company.apartment')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_APARTMENT}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={city ? apartment : ''}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default BaseForm;
