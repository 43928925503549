import { OptionsType } from 'constants/TypeConstants';

export type FormDataType = {
  country?: OptionsType | string;
  description: string;
  name: string;
  place?: OptionsType | string;
  serialNumber?: string;
  type: string;
  uuid: string;
};

export const formDataInitialState: FormDataType = {
  description: '',
  name: '',
  place: '',
  serialNumber: '',
  type: 'TABLET',
  uuid: '',
  country: '',
};

export const TABLE_INVENTORY_INITIAL_ROWS_NUMBER = 50;
