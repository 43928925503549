import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCT_CATEGORIES, TAG_PRODUCT_CATEGORY } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, UpdateProductCategoryFormType>({
    query: (patch) => {
      const { uuid, ...tempPatch } = patch;
      return {
        url: `/product-category/${uuid}`,
        method: 'PATCH',
        body: tempPatch,
      };
    },
    invalidatesTags: [TAG_PRODUCT_CATEGORIES, TAG_PRODUCT_CATEGORY],
  });

export type UpdateProductCategoryFormType = {
  nameEn?: string;
  nameHr?: string;
  nameSr?: string;
  uuid: string;
};
