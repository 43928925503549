import {
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_FIRST_NAME,
  FORM_ELEMENT_LAST_NAME,
  FORM_ELEMENT_PHONE_NUMBER,
} from 'constants/FormConstants';
import {
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_LAST_ACTIVE_AT,
  TABLE_ELEMENT_SEARCH_TERM,
  TABLE_INITIAL_ROWS_NUMBER,
} from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';
import { validateNumberField } from 'utils/helpers';

const appUsersTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'appUsersTable',
  filter: [
    {
      field: TABLE_ELEMENT_SEARCH_TERM,
      title: 'filter.search',
      filterType: 'text',
    },
    {
      field: FORM_ELEMENT_PHONE_NUMBER,
      title: 'phoneNumber.phoneNumber',
      filterType: 'text',
      errorMessage: 'validation.onlyNumbers',
      isValid: (value) => validateNumberField(value),
    },
  ],
  columns: [
    {
      title: 'appUsers.firstName',
      field: FORM_ELEMENT_FIRST_NAME,
    },
    {
      title: 'appUsers.lastName',
      field: FORM_ELEMENT_LAST_NAME,
    },
    {
      title: 'appUsers.email',
      field: FORM_ELEMENT_EMAIL,
    },
    {
      title: 'appUsers.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
    {
      title: 'appUsers.lastActiveAt',
      field: TABLE_ELEMENT_LAST_ACTIVE_AT,
    },
  ],
};

export default appUsersTableSettings;
