import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CountryCodesResponse[], void>({
    query: () => '/countries/calling-code',
    transformResponse: (response: CountryCodesResponse[]) => {
      return response.map((codes) => {
        return {
          ...codes,
          label: codes?.fullName,
          value: codes?.isoName,
          code: codes?.callingCode,
          codeLabel: '+' + codes?.callingCode + ' ' + codes?.fullName,
        };
      });
    },
  });

export type CountryCodesResponse = {
  callingCode: string;
  fullName: string;
  isoName: string;
};
