import { ACAppBar } from '@antech-consulting/components';
import { HambergerMenu } from 'iconsax-react';
import { CurrentUser } from 'store/Auth';

import { style } from '../../constants/StyleConstants';
import styles from './Navbar.module.scss';
import ProfileCard from './ProfileCard';

export type NavBarProps = {
  currentUser: CurrentUser;
  handleChangeOpen: () => void;
};

const Navbar = ({ currentUser, handleChangeOpen }: NavBarProps) => {
  return (
    <ACAppBar style={style.ACAppBar}>
      <HambergerMenu
        className={styles.drawerButton}
        onClick={handleChangeOpen}
        role='hamburgerButton'
      />
      <ProfileCard currentUser={currentUser} />
    </ACAppBar>
  );
};
export default Navbar;
