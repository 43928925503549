import { ACInput } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import {
  FORM_ELEMENT_NEW_PASSWORD,
  FORM_ELEMENT_OLD_PASSWORD,
  FormDataType,
} from 'features/user/constants/ChangePasswordFormConstants';
import { useTranslation } from 'react-i18next';

import { style } from '../../../../constants/StyleConstants';
import styles from './PasswordBaseForm.module.scss';

const PasswordBaseForm = ({ errors, formData, handleChange }: BaseFormProps<FormDataType>) => {
  const { t } = useTranslation();
  const { newPassword, oldPassword } = formData;

  return (
    <>
      <Divider />
      <form className={styles.passwordForm}>
        <h5 className={styles.titlePassword}>{t('password.title')}</h5>
        <p className={styles.description}>{t('password.description')}</p>
        <div className={styles.textFieldCurrentPass}>
          <p className={styles.text}>{t('password.currentPassword')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='usersOldPassword'
              errorMessage={errors?.oldPassword}
              fullWidth
              hasError={!!errors?.oldPassword}
              id={FORM_ELEMENT_OLD_PASSWORD}
              name={FORM_ELEMENT_OLD_PASSWORD}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputProfile}
              type='password'
              value={oldPassword}
            />
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.textField}>
          <p className={styles.text}>{t('password.newPassword')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='usersNewPassword'
              errorMessage={errors?.newPassword}
              fullWidth
              hasError={!!errors?.newPassword}
              id={FORM_ELEMENT_NEW_PASSWORD}
              name={FORM_ELEMENT_NEW_PASSWORD}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputProfile}
              type='password'
              value={newPassword}
            />
          </div>
        </div>
      </form>
    </>
  );
};
export default PasswordBaseForm;
