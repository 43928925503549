import { FORM_ELEMENT_NAME } from 'constants/FormConstants';
import {
  TABLE_ALL_ITEMS_COUNT,
  TABLE_ELEMENT_GROSS,
  TABLE_ELEMENT_NET,
  TABLE_INITIAL_ROWS_NUMBER,
  TABLE_PAGES_COUNT,
} from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const bestRevenuesTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  allItemsCount: TABLE_ALL_ITEMS_COUNT,
  pagesCount: TABLE_PAGES_COUNT,
  dataTestId: 'bestRevenuesTable',
  columns: [
    {
      title: 'statistics.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'statistics.grossColumn',
      field: TABLE_ELEMENT_GROSS,
    },
    {
      title: 'statistics.netColumn',
      field: TABLE_ELEMENT_NET,
    },
  ],
};

export default bestRevenuesTableSettings;
