import { ACInput } from '@antech-consulting/components';
import { style } from 'constants/StyleConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../ChangeOrderStatus/ChangeOrderStatus.module.scss';

const INPUT_STYLE = {
  wrapper: {
    width: '100%',
  },
};

const BodyRejectMessage = ({ handleChangeFormData, message }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.reasonMessage}>
      <div className={styles.reasonTitle}>{t('orders.reasonTitle')}</div>
      <ACInput
        autoComplete='off'
        fullWidth
        id='message'
        isTextArea
        name='rejectMessage'
        onChange={handleChangeFormData}
        rounded='md'
        style={{ ...style.ACInput, ...INPUT_STYLE }}
        type='text'
        value={message}
      />
    </div>
  );
};

export default BodyRejectMessage;
