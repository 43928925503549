import { Dispatch, SetStateAction, useEffect } from 'react';

import { FormDataType } from '../constants/PartnerAppSettingsFormConstants';
import { useGetPartnerApplicationSettingsQuery } from '../services';

export const usePartnerAppSettingsForm = (setFormData: Dispatch<SetStateAction<FormDataType>>) => {
  const { data, isSuccess } = useGetPartnerApplicationSettingsQuery();

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        ...data,
        placeList: data.dashboardImageQualitySettings.placeList,
        placeDetail: data.dashboardImageQualitySettings.placeDetail,
        productCategoryAll: data.dashboardImageQualitySettings.productCategoryAll,
        productDetail: data.dashboardImageQualitySettings.productDetail,
        productList: data.dashboardImageQualitySettings.productList,
      });
    }
  }, [isSuccess]);

  return { data };
};
