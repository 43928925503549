import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import CountryLabel from 'components/CountryLabel/CountryLabel';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_DESCRIPTION,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_PLACE,
  FORM_ELEMENT_SERIALNUMBER,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps, OptionsType } from 'constants/TypeConstants';
import { ACEventType } from 'hooks/useForm';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { FormDataType } from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

type BaseFormInventoryProps = BaseFormProps<FormDataType> & {
  countriesOptions: OptionsType[];
  placesOptions: OptionsType[];
  setFormData?: (data: FormDataType) => void;
};

type ChangedValueTypeInventory = {
  target: {
    value: string | OptionsType;
  };
};

type HandleChangeTypeInventory = ACEventType<
  ChangeEvent<HTMLInputElement> | ChangedValueTypeInventory,
  string | OptionsType
>;

const BaseForm = ({
  countriesOptions,
  errors,
  formData,
  handleChange,
  placesOptions,
  setFormData,
}: BaseFormInventoryProps) => {
  const { t } = useTranslation();
  const { country, description, name, place, serialNumber } = formData;

  const handleCountryChange = (event: HandleChangeTypeInventory) => {
    const {
      ac: { value },
    } = event;
    setFormData({ ...formData, country: value, place: '' });
  };

  return (
    <form className={styles.basicInformationSection}>
      <FormHeader
        primaryText={t('inventory.subtitleBaseForm')}
        secondaryText={t('inventory.descriptionBaseForm')}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        dataTestId='inventoryName'
        errorMessage={errors?.name}
        fullWidth
        hasError={!!errors?.name}
        id={FORM_ELEMENT_NAME}
        label={t('inventory.addInventory.name')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_NAME}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={name}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        dataTestId='inventoryDescription'
        errorMessage={errors?.description}
        fullWidth
        hasError={!!errors?.description}
        id={FORM_ELEMENT_DESCRIPTION}
        label={t('inventory.addInventory.description')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_DESCRIPTION}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={description}
      />
      <div className={styles.divider} />
      <div className={styles.pricing}>
        <FormHeader
          primaryText={t('inventory.subtitleOrganizations')}
          secondaryText={t('inventory.descriptionOrganizations')}
        />
        <ACAutocompleteV2
          closeOnSelect
          dataTestId='inventoryCountry'
          errorMessage={errors?.country}
          fullWidth
          getCustomRender={(country) => (
            <CountryLabel isoName={country?.isoName} name={country?.name} />
          )}
          getOptionLabel={(option) => option?.name}
          getSelectByProperty={(option) => option?.uuid}
          hasError={!!errors?.country}
          id={FORM_ELEMENT_COUNTRY}
          label={t('inventory.countryInput')}
          marginBottom={AC_INPUT_MARGIN_BOTTOM}
          name={FORM_ELEMENT_COUNTRY}
          onChange={(event) => {
            handleCountryChange(event);
          }}
          options={countriesOptions}
          renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputForm}
          value={country}
        />
        <ACAutocompleteV2
          closeOnSelect
          dataTestId='inventoryPlace'
          disabled={!country}
          errorMessage={errors?.place}
          fullWidth
          getOptionLabel={(option) => option?.name}
          getSelectByProperty={(option) => option?.uuid}
          hasError={!!errors?.place}
          id={FORM_ELEMENT_PLACE}
          label={t('inventory.place')}
          name={FORM_ELEMENT_PLACE}
          onChange={(event) => {
            handleChange(event);
          }}
          options={placesOptions || []}
          renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputForm}
          value={place}
        />
        <div className={styles.serialNumber}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='inventorySerialNumber'
            errorMessage={errors?.serialNumber}
            fullWidth
            hasError={!!errors?.serialNumber}
            id={FORM_ELEMENT_SERIALNUMBER}
            label={t('inventory.addInventory.serialNumber')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_SERIALNUMBER}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={serialNumber}
          />
        </div>
      </div>
    </form>
  );
};

export default BaseForm;
