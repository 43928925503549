import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { OrdersDistribution } from '../../../services/getPlaceStatistics';

Chart.register(CategoryScale);

const styleColor = {
  totalCreated: ['rgb(52, 105, 104)'],
  totalPickedUp: ['rgb(138, 169, 168)'],
};

const TOTAL_CREATED = 'totalCreated';
const TOTAL_PICKED_UP = 'totalPickedUp';

const BarChartOrdersDistribution = ({
  ordersDistribution,
}: {
  ordersDistribution: Array<OrdersDistribution>;
}) => {
  const { t } = useTranslation();

  const labelData = useMemo(() => {
    return ordersDistribution.map((label) => {
      return label?.hour;
    });
  }, [ordersDistribution]);

  const orderDistribution = Object.keys(ordersDistribution).map(
    (property) => ordersDistribution[property],
  );

  const statusKeys = [TOTAL_CREATED, TOTAL_PICKED_UP];

  const statusData = useMemo(() => {
    return statusKeys.map((status) => {
      return orderDistribution.map((interval) => interval && interval[status]);
    });
  }, [orderDistribution]);

  const labels = labelData;

  const datasets = statusKeys.map((status, index) => {
    return {
      label: t(`placeStatistics.${status}`),
      backgroundColor: styleColor[status],
      borderColor: styleColor[status],
      data: statusData[index],
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: t('placeStatistics.ordersDistributionXAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          marginLeft: '100px',
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: t('placeStatistics.ordersDistributionYAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};
export default BarChartOrdersDistribution;
