import { FORM_ELEMENT_FROM } from 'constants/FormConstants';

import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  from: {
    isRequired: true,
    isDateCurrentOrBefore: true,
  },
  to: {
    isRequired: true,
    isDateCurrentOrBefore: true,
    isDateLessThen: FORM_ELEMENT_FROM,
  },
};
