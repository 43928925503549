import { ACButton, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { STATUS_ELEMENT_PENDING } from 'constants/StatusConstants';
import { style, styleFooterIcon } from 'constants/StyleConstants';
import BaseForm from 'features/productCategoryRequest/components/BaseForm/BaseForm';
import { useProductCategoryRequest } from 'features/productCategoryRequest/hooks/useProductCategoryRequest';
import {
  useApproveProductCategoryRequestMutation,
  useDeleteProductCategoryRequestMutation,
  useRejectProductCategoryRequestMutation,
  useUpdateProductCategoryRequestMutation,
} from 'features/productCategoryRequest/services';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import useToggle from 'hooks/useToggle';
import { CloseCircle, TickCircle, Trash } from 'iconsax-react';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { transformSnakeCaseToText, trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/productCategoryRequest/constants/FormConstants';
import { validationSchema } from '../../features/productCategoryRequest/constants/ProductCategoryRequestSchema';
import styles from './EditProductCategoryRequest.module.scss';

const EditProductCategoryRequest = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [updateRequest] = useUpdateProductCategoryRequestMutation();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const [isApproveDialogOpen, toggleApprove] = useToggle();
  const [isRejectDialogOpen, toggleReject] = useToggle();
  const [approveRequest, { isLoading: isLoadingApprove }] =
    useApproveProductCategoryRequestMutation();
  const [rejectRequest, { isLoading: isLoadingReject }] = useRejectProductCategoryRequestMutation();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteProductCategoryRequestMutation();
  const requestIsEditable = formData.status === transformSnakeCaseToText(STATUS_ELEMENT_PENDING);
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [isDeleteDialogOpen, toggleDelete] = useToggle();

  const isLoadingForm = isLoadingApprove || isLoadingReject || isLoadingDelete;

  const system = roles[ROLE_SYSTEM as never];
  const { productCategoryRequest, shouldDisableSubmitButton } = useProductCategoryRequest(
    formData,
    setFormData,
    id,
  );

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const { name } = trimmedData;
    const requestBody = { payload: { name }, requestId: id };
    try {
      await updateRequest(requestBody).unwrap();
      navigate('/product-category-requests');
      displaySuccess({ key: 'productCategoryRequests.updateProductCategoryRequest' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = useCallback(() => {
    navigate('/product-category-requests');
  }, []);

  const onDelete = () => {
    toggleDelete();
  };

  const handleSubmitApproveProductCategoryRequest = async () => {
    try {
      await approveRequest(id).unwrap();
      toggleApprove();
      navigate('/product-category-requests');
      displaySuccess({
        key: t('productCategoryRequests.approveModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleApprove();
      displayError({ key: code });
    }
  };

  const handleSubmitDeleteProductCategoryRequest = async () => {
    try {
      await deleteRequest(id).unwrap();
      toggleDelete();
      navigate('/product-category-requests');
      displaySuccess({ key: t('productCategoryRequests.deleteModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const handleSubmitRejectProductCategoryRequest = async () => {
    try {
      await rejectRequest(id).unwrap();
      toggleReject();
      navigate('/product-category-requests');
      displaySuccess({ key: t('productCategoryRequests.rejectModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleReject();
      displayError({ key: code });
    }
  };

  const approveRejectActionButtons = system
    ? [
        <>
          <div>
            <ACButton
              key='approveButton'
              onClick={toggleApprove}
              style={styleFooterIcon.iconApprove}
              variant={'text'}
            >
              <TickCircle data-testid='approveButton' style={styleFooterIcon.iconApprove} />
              {t('productCategoryRequests.approve')}
            </ACButton>
          </div>
          <div>
            <ACButton
              key='rejectButton'
              onClick={toggleReject}
              style={styleFooterIcon.iconReject}
              variant={'text'}
            >
              <CloseCircle data-testid='rejectButton' style={styleFooterIcon.iconReject} />
              {t('productCategoryRequests.reject')}
            </ACButton>
          </div>
        </>,
      ]
    : [];

  return !isLoadingForm && productCategoryRequest ? (
    <>
      {requestIsEditable ? (
        <PageHeader primaryText={t('productCategoryRequests.titleUpdate')} />
      ) : (
        <PageHeader primaryText={t('productCategoryRequests.titleView')} />
      )}
      <BaseForm
        errors={errors}
        formData={formData}
        handleChange={handleChangeFormData}
        requestId={!!id}
        requestIsEditable={requestIsEditable}
        system={!!system}
      />
      {requestIsEditable && (
        <FormStickyFooter
          additionalActionButtons={approveRejectActionButtons}
          onDelete={onDelete}
          onDeleteText={t('footer.deleteButton')}
          onDiscard={onDiscard}
          onDiscardText={t('footer.discardButton')}
          onSubmit={() => handleSubmit(onSubmit)}
          onSubmitText={t('footer.saveButton')}
          shouldDisableSubmitButton={shouldDisableSubmitButton}
        />
      )}
      {isApproveDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleApprove}
          disabled={isLoadingApprove}
          handleSubmit={handleSubmitApproveProductCategoryRequest}
          header={t('productCategoryRequests.approveModal.titleApprove')}
          icon={<TickCircle style={style.BlueIconModal} />}
          isShow={isApproveDialogOpen}
          message={t('productCategoryRequests.approveModal.descriptionApprove')}
        />
      )}
      {isRejectDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleReject}
          disabled={isLoadingReject}
          handleSubmit={handleSubmitRejectProductCategoryRequest}
          header={t('productCategoryRequests.rejectModal.titleReject')}
          icon={<CloseCircle style={style.RedIconModal} />}
          isShow={isRejectDialogOpen}
          message={t('productCategoryRequests.rejectModal.descriptionReject')}
        />
      )}
      {isDeleteDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleSubmitDeleteProductCategoryRequest}
          header={t('productCategoryRequests.deleteModalTitle')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteDialogOpen}
          message={t('productCategoryRequests.deleteModalDescription')}
        />
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditProductCategoryRequest;
