import {
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_ID_NUMBER,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_VAT_NUMBER,
} from 'constants/FormConstants';
import {
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_CREATED_BY,
  TABLE_INITIAL_ROWS_NUMBER,
} from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const companyTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'companiesTable',
  filter: [
    {
      field: FORM_ELEMENT_NAME,
      title: 'company.nameColumn',
      filterType: 'text',
    },
  ],
  columns: [
    {
      title: 'company.idNumberColumn',
      field: FORM_ELEMENT_ID_NUMBER,
    },
    {
      title: 'company.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'company.cityColumn',
      field: FORM_ELEMENT_CITY,
    },
    {
      title: 'company.countryColumn',
      field: FORM_ELEMENT_COUNTRY,
    },
    {
      title: 'company.vatNumberColumn',
      field: FORM_ELEMENT_VAT_NUMBER,
    },
    {
      title: 'company.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
  ],
  disableSorting: { columns: [TABLE_ELEMENT_CREATED_BY] },
};

export default companyTableSettings;
