import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store/store';

import { Notification } from '../Alert/Notification';
import AppLayout from '../layouts/AppLayout/AppLayout';
import styles from './App.module.scss';
import AuthRouter from './AuthRouter';

const AuthApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notification = useSelector((state: RootState) => state.notification);

  useEffect(() => {
    const id = location.search.split('=')[1];
    if (location.search.includes('verify-email') && id) {
      navigate(`/verify-email/${id}`);
    }
  }, [location]);

  return (
    <div className={styles.authLayout}>
      <AppLayout />
      <div className={styles.appContent}>
        <AuthRouter />
        {notification.open && <Notification notification={notification} />}
      </div>
    </div>
  );
};
export default AuthApp;
