import 'flag-icons/css/flag-icons.min.css';

import { FC } from 'react';

import styles from './CountryLabel.module.scss';

export const Icon = {
  width: 20,
  height: 20,
  borderRadius: '50%',
};

export type CountryTypeProps = {
  isoName: string;
  name: string;
};

const CountryLabel: FC<CountryTypeProps> = ({ isoName, name }) => {
  return (
    <div className={styles.options}>
      <div className={`fis fi-${isoName.toLowerCase()}`} style={Icon} />
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default CountryLabel;
