import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCT_CATEGORY_REQUESTS } from 'services/TagsConstants';

import { ProductCategoryRequest } from './getProductCategoryRequests';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<ProductCategoryRequest, NewProductCategoryRequest>({
    query: (credentials) => ({
      url: '/product-category-requests',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_PRODUCT_CATEGORY_REQUESTS],
  });

export type NewProductCategoryRequest = {
  name: string;
};
