import { ACButton, ACModal, ACSpinner } from '@antech-consulting/components';
import { CloseCircle } from 'iconsax-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { style } from '../../constants/StyleConstants';
import styles from './Modal.module.scss';

type ModalProps = {
  body?: ReactNode;
  buttonText: string;
  closeModal: () => void;
  disabled?: boolean;
  handleSubmit: () => void | Promise<void>;
  header: string;
  icon: ReactNode;
  isRejectOrder?: boolean;
  isShow: boolean;
  message: string;
};

const Modal: FC<ModalProps> = ({
  body,
  buttonText,
  closeModal,
  disabled,
  handleSubmit,
  header,
  icon,
  isRejectOrder,
  isShow,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <ACModal isShow={isShow} onClose={closeModal}>
      <div className={`${isRejectOrder ? styles.contentWrapperReject : styles.contentWrapper}`}>
        <CloseCircle className={styles.iconClose} onClick={closeModal} />
        <div className={`${isRejectOrder ? styles.infoWrapperReject : styles.infoWrapper}`}>
          {icon}
          <div className={styles.mainWrapper}>
            <div className={styles.titleWrapper}>
              <h5 className={styles.title}>{header}</h5>
            </div>
            <p className={styles.text}>{message}</p>
            <div className={styles.inputWrapper}></div>
          </div>
        </div>
        {body}
        <div style={style.ModalButtons}>
          <div className={styles.buttonCancel}>
            <ACButton
              dataTestid='Cancel'
              disabled={disabled}
              onClick={closeModal}
              style={style.ACModal.ButtonCancel}
            >
              {disabled ? (
                <div className={styles.spinnerCancel}>
                  <ACSpinner background='var(--text-secondary)' size='xxs' />
                  {t('blockModal.buttonCancel')}
                </div>
              ) : (
                t('blockModal.buttonCancel')
              )}
            </ACButton>
          </div>
          <div className={styles.buttonConfirm}>
            <ACButton
              dataTestid='Confirm'
              disabled={disabled}
              onClick={handleSubmit}
              style={style.ACModal.ButtonConfirm}
              type='submit'
            >
              {disabled ? (
                <div className={styles.spinnerSubmit}>
                  <ACSpinner background='white' size='xxs' />
                  {buttonText}
                </div>
              ) : (
                buttonText
              )}
            </ACButton>
          </div>
        </div>
      </div>
    </ACModal>
  );
};

export default Modal;
