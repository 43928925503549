import { ACAvatar, ACButton, ACCard } from '@antech-consulting/components';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { cardTitle } from 'features/orders/constants/StyleConstants';
import { useAppSelector } from 'hooks/hooks';
import { Mobile, NoteText, User } from 'iconsax-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { handleNavigateLink } from 'utils/helpers';

import { useGetOrderByIdQuery } from '../../services';
import { OrderDetailViewProps } from '../../services/getOrderById';
import { buttonStyle } from '../DetailViewPlace/DetailViewPlace';
import styles from './DetailViewUser.module.scss';

const DetailViewUser: FC<OrderDetailViewProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: order, isSuccess } = useGetOrderByIdQuery(orderId);
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const isSystemRoot = roles[ROLE_SYSTEM];

  const BodyContent = () => {
    return (
      <>
        <div className={styles.userInfoWrapper}>
          <div className={styles.userProfile}>
            <ACAvatar>
              <User className={styles.iconProfile} />
            </ACAvatar>
            <div className={styles.userName}>
              {order?.createdBy?.firstName} {order?.createdBy?.lastName}
            </div>
          </div>
          {isSystemRoot && (
            <div className={styles.userPhone}>
              <Mobile className={styles.iconProfile} />
              <div className={styles.userName}>{order?.createdBy?.phoneNumber}</div>
            </div>
          )}
        </div>
        <div className={styles.userComment}>
          <NoteText className={styles.iconProfile} />
          <div className={styles.userName}>{order?.note || FORM_ELEMENT_EMPTY}</div>
        </div>
      </>
    );
  };

  const handleNavigate = () => {
    handleNavigateLink();
    navigate(`/app-user/${order?.createdBy?.uuid}`, {
      state: { previousLocation: location.pathname },
    });
  };

  const HeaderContent = () => {
    return (
      <div className={styles.userCardWrapper}>
        <div style={cardTitle}>{t('orders.customer')}:</div>
        {isSystemRoot && (
          <ACButton className={styles.link} onClick={handleNavigate} style={buttonStyle}>
            {t('orders.linkUserDetailView')}
          </ACButton>
        )}
      </div>
    );
  };

  return (
    isSuccess && (
      <ACCard
        bodyChildren={<BodyContent />}
        className={styles.userCard}
        headerChildren={<HeaderContent />}
      />
    )
  );
};

export default DetailViewUser;
