import { ACSpinner } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { LANGUAGES_OPTIONS } from 'constants/FormConstants';
import { UpdateUser } from 'features/user/services/updateCurrent';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'store/store';
import { formatLanguageForApi } from 'utils/format-language';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../../../components/FormStickyFooter/FormStickyFooter';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { useForm } from '../../../../hooks/useForm';
import Language from '../../../../layouts/NavBar/Languages';
import { changeLanguage } from '../../../../store/Language';
import { formDataInitialState, FormDataType } from '../../constants/UserProfileFormConstants';
import { validationSchema } from '../../constants/UserProfileSchema';
import { useGetCurrentQuery, useUpdateCurrentMutation } from '../../services/index';
import ProfileBaseForm from '../ProfileBaseForm/ProfileBaseForm';

export type autocompleteOptionType = {
  label: string;
  value: string;
};

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { language } = useAppSelector(({ language }) => language);
  const { displayError, displaySuccess } = useDisplayNotification();
  const { data: currentUser, isSuccess } = useGetCurrentQuery();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const { countries } = store.getState().globalData;

  const [updateUser] = useUpdateCurrentMutation();

  const initialFormValues = () => {
    const countryName = countries?.find((country) => country?.uuid === currentUser?.country);
    setFormData({
      ...currentUser,
      country: countryName?.shortName,
    });
  };

  useEffect(() => {
    if (isSuccess) initialFormValues();
  }, [isSuccess]);

  const [inputValue] = useMemo(() => {
    return LANGUAGES_OPTIONS?.filter(({ label, value }) => {
      return (
        value === language && {
          label,
          value,
        }
      );
    });
  }, [language]);

  const [changeLanguageState, setChangeLanguageState] = useState(inputValue);

  const changeAppLanguage = (_event: any) => {
    setChangeLanguageState(_event?.ac?.value);
  };

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const UpdateUserRequestDTO: UpdateUser = {
      ...trimmedData,
    };
    try {
      dispatch(changeLanguage({ language: changeLanguageState?.value }));
      i18n.changeLanguage(changeLanguageState.value);
      if (
        currentUser?.firstName !== UpdateUserRequestDTO?.firstName ||
        currentUser?.lastName !== UpdateUserRequestDTO?.lastName ||
        currentUser?.settings.dashboard.language !== formatLanguageForApi(changeLanguageState.value)
      ) {
        await updateUser({
          ...UpdateUserRequestDTO,
          settings: { dashboard: { language: formatLanguageForApi(changeLanguageState?.value) } },
        }).unwrap();
        displaySuccess({ key: 'partnerUser.updateUser' });
      }
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedFormData: FormDataType = formData;
    const disabled =
      isEqual(transformedFormData?.firstName, currentUser?.firstName) &&
      isEqual(transformedFormData?.lastName, currentUser?.lastName) &&
      inputValue?.value === changeLanguageState?.value;
    return disabled;
  }, [formData, currentUser, countries, inputValue, changeLanguageState]);

  return isSuccess ? (
    <>
      <ProfileBaseForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <Language changeAppLanguage={changeAppLanguage} inputValue={changeLanguageState} />
      <FormStickyFooter
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={shouldDisableSubmitButton}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};
export default Profile;
