import { ACButton, ACLoading } from '@antech-consulting/components';
import { authDescription } from 'features/auth/constants/StyleConstants';
import { RegistrationRequest } from 'features/auth/services/registration';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { CountryCodesResponse } from 'services/getCountryCodes';
import { trimObjectValues } from 'utils/helpers';

import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style } from '../../constants/StyleConstants';
import { RegistrationForm } from '../../features/auth';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/auth/constants/RegistrationFormConstants';
import { validationSchema } from '../../features/auth/constants/RegistrationSchema';
import { useRegistrationMutation } from '../../features/auth/services';
import { useForm } from '../../hooks/useForm';
import { Country } from '../../services/country';
import styles from './Registration.module.scss';

const Registration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registration, { isLoading }] = useRegistrationMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );

  const onSubmit = async () => {
    const countryId = formData?.country as Country;
    const countryCode = formData?.phoneNumberCountryCode as CountryCodesResponse;
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const RegistrationRequestDTO: RegistrationRequest = {
      ...trimmedData,
      country: countryId?.uuid,
      phoneNumberCountryCode: countryCode?.isoName,
    };
    const { email, password, phoneNumber, phoneNumberCountryCode } = RegistrationRequestDTO;
    try {
      await registration(RegistrationRequestDTO).unwrap();
      localStorage.setItem('user', JSON.stringify(email));
      localStorage.setItem('RegistrationUserData', JSON.stringify({ email, password }));
      localStorage.setItem(
        'RegistrationUserPhoneNumber',
        JSON.stringify({ phoneNumber, phoneNumberCountryCode }),
      );
      navigate('/registration-success');
      displaySuccess({ key: 'registration.registerUser' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return isLoading ? (
    <ACLoading
      isLoading={true}
      loadingElement={<div>{t('registrationForm.placeholder')}</div>}
    ></ACLoading>
  ) : (
    <div className={styles.form}>
      <h3 className={styles.title}>{t('registrationForm.title')}</h3>
      <p style={authDescription}>{t('registrationForm.description')}</p>
      <RegistrationForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <div className={styles.buttonsWrapper}>
        <ACButton
          dataTestid='ButtonRegistration'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
        >
          {t('registrationForm.buttonSignUp')}
        </ACButton>
      </div>
      <div className={styles.regLink}>
        <span className={styles.description}>{t('registrationForm.textSignIn')} </span>
        <Link className={styles.link} to='/'>
          {t('registrationForm.linkSignIn')}
        </Link>
      </div>
    </div>
  );
};

export default Registration;
