import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_COMPANY } from 'services/TagsConstants';

import { Company } from './getCompanies';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Company, string>({
    query: (id) => `/company/${id}`,
    providesTags: [TAG_COMPANY],
  });
