import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_ADDRESS, FORM_ELEMENT_NAME, IS_EMPLOYED } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { DISABLE_SORTING_PLACE_COLUMNS } from 'features/place/constants/TableConstants';
import { Place } from 'features/place/services/createPlace';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { Verify } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import {
  checkIfPropertyExistsInObject,
  handleDefaultPlaceImage,
  transformACState,
} from '../../../../utils/helpers';
import {
  useChangePlacesStatusMutation,
  useLazyGetUnverifiedPlacesQuery,
} from '../../services/index';
import { FilterPropsPlace } from '../TablePlace/TablePlace';
import styles from './TablePlaceUnverified.module.scss';
import tablePlaceUnverified from './tablePlaceUnverifiedSettings';

export type SortProps = {
  field: string;
  type: string;
};

const TablePlaceUnverified = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<Place>(null);
  const tableSettings = useTable(tablePlaceUnverified);

  const [fetchUnverifiedPlaces, { data: unverifiedPlaces, isFetching }] =
    useLazyGetUnverifiedPlacesQuery();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [isVerifyModalOpen, toggleVerify] = useToggle();

  const [changeStatus, { isLoading }] = useChangePlacesStatusMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const system = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);

  const { data } = useGetPartnerApplicationSettingsQuery();

  const placesImageQuality = data?.dashboardImageQualitySettings?.placeList;

  const placesTableData = useMemo(() => {
    return unverifiedPlaces?.content?.map((place) => {
      const { category, document } = place;
      const imageUrl = document && document[placesImageQuality];
      const imageFile = handleDefaultPlaceImage(category, imageUrl);
      return {
        rawData: place,
        render: {
          ...place,
          name: (
            <div className={styles.place}>
              <img alt={place?.name} className={styles.placeImage} src={imageFile} />
              <div>{place?.name}</div>
            </div>
          ),
        },
      };
    });
  }, [unverifiedPlaces?.content]);

  const handleVerifyPlace = ({ rowData }: { rowData: Place }) => {
    setSelectedRow(rowData);
    toggleVerify();
  };

  const handleChangeStatus = async () => {
    try {
      await changeStatus({ id: selectedRow?.uuid, verificationType: 'verify' }).unwrap();
      toggleVerify();
      displaySuccess({ key: 'place.verifyPlace' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleVerify();
      displayError({ key: code });
    }
  };

  const handleChangeTableState = (tableState: TableStateProps<FilterPropsPlace>) => {
    const tableData = system ? { ...tableState } : { ...tableState, [IS_EMPLOYED]: 'true' };
    fetchUnverifiedPlaces(transformACState(tableData, FORM_ELEMENT_NAME));
  };

  if (system) {
    tableSettings.actions.push({
      icon: <Verify data-testid='verifyButton' />,
      tooltip: {
        text: t('place.titleVerify'),
        style: { left: '10px' },
      },
      onClick: handleVerifyPlace,
    });
  }

  const handleOnItemClicked = ({ rowData }: { rowData: Place }) => {
    const { uuid } = rowData;
    navigate(`/place/${uuid}`, { state: { previousLocation: '/places?value=2' } });
  };

  return (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching || isLoading}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={[...tableSettings.actions]}
          allItemsCount={unverifiedPlaces?.totalElements}
          defaultSort={{
            field: FORM_ELEMENT_NAME,
            type: 'asc',
          }}
          disableSorting={DISABLE_SORTING_PLACE_COLUMNS}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleChangeTableState}
          pagesCount={unverifiedPlaces?.totalPages}
          rows={placesTableData}
        />
      </ACLoading>
      {isVerifyModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleVerify}
          disabled={isLoading}
          handleSubmit={handleChangeStatus}
          header={t('place.changeStatus')}
          icon={<Verify style={style.BlueIconModal} />}
          isShow={isVerifyModalOpen}
          message={t('place.verifyPlaceTitle')}
        />
      )}
    </>
  );
};
export default TablePlaceUnverified;
