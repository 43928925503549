import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { OrdersPerStatus } from 'features/place/services/getPlaceStatistics';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { transformSnakeCaseToTextFormTranslates } from 'utils/helpers';

Chart.register(CategoryScale);

const styleColor = {
  PICKED_UP: ['#014342'],
  PENDING: ['#346968'],
  READY: ['#558180'],
  ACCEPTED: ['#8aa9a8'],
  CANCELED: ['#b0c5c4'],
  REFUNDED: ['#e6ecec'],
  REJECTED: ['#8aa9a8'],
  PAYMENT_FAILED: ['#014342'],
};

const BarChartOrdersPerStatus = ({
  handleIntervalMessage,
  interval,
  ordersPerStatus,
}: {
  handleIntervalMessage: (interval: string) => string;
  interval: string;
  ordersPerStatus: OrdersPerStatus;
}) => {
  const { t } = useTranslation();

  const labelData = Object.keys(ordersPerStatus);
  const orderPerStatus = Object.keys(ordersPerStatus).map((property) => ordersPerStatus[property]);

  const statusKeys = [
    'PICKED_UP',
    'PENDING',
    'READY',
    'ACCEPTED',
    'CANCELED',
    'REFUNDED',
    'REJECTED',
    'PAYMENT_FAILED',
  ];

  const statusData = useMemo(() => {
    return statusKeys.map((status) => {
      return orderPerStatus.map((interval) => interval && interval[status]);
    });
  }, [orderPerStatus]);

  const labels = labelData;

  const datasets = statusKeys.map((status, index) => {
    return {
      label: t(`placeStatistics.${transformSnakeCaseToTextFormTranslates(status)}`),
      backgroundColor: styleColor[status],
      borderColor: styleColor[status],
      data: statusData[index],
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: handleIntervalMessage(interval),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          marginLeft: '100px',
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: t('placeStatistics.ordersPerStatusYAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChartOrdersPerStatus;
