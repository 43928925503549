import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import moment from 'moment';
import { TAG_COMPANY_REQUEST, TAG_COMPANY_REQUESTS } from 'services/TagsConstants';
import { store } from 'store/store';

import { createQueryString } from '../../../utils/helpers';
import { NewCompanyRequest } from './createCompanyRequest';

const BASE_URL = '/company-requests';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CompanyRequestResponse, CompanyRequestParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const queryString = createQueryString(_arg, BASE_URL);
      const companyRequestsResponse = await fetchWithBQ(queryString);
      const companyRequestsData = companyRequestsResponse.data as CompanyRequestResponse;
      const countryData = store.getState().globalData.countries;
      const dateFormat = store.getState().auth.userCountry.dateFormat;
      const companyRequests = companyRequestsData.content.map((companyRequest: CompanyRequest) => {
        const { createdAt } = companyRequest;
        const formatDate = moment(createdAt).format(dateFormat.toUpperCase());
        const country = countryData.find((country) => country.uuid === companyRequest.country);
        const countryName = country?.shortName;
        return {
          ...companyRequest,
          createdAt: formatDate,
          country: countryName,
        };
      });
      return {
        data: {
          content: companyRequests,
          totalElements: companyRequestsData.totalElements,
          totalPages: companyRequestsData.totalPages,
          countries: countryData,
        },
      };
    },
    providesTags: [TAG_COMPANY_REQUESTS],
  });

export type CompanyRequestResponse = {
  content: CompanyRequest[];
  totalElements: number;
  totalPages: number;
};

export type CompanyRequestParameters = {
  city?: string;
  country?: string;
  ids?: string;
  name?: string;
  owners?: string;
  statuses?: string;
};

export type FilterProps = {
  city?: string;
  country?: string;
  ids?: string;
  name?: string;
  owners?: string;
  statuses?: string;
};

export type CompanyRequest = NewCompanyRequest & {
  createdAt: string;
  createdBy: string;
  owner: string;
  status?: string;
  updatedAt: string;
  updatedBy: string;
};
