import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { NumberOfAppUsersPerLoginType } from '../../services/getSystemStatistics';

Chart.register(CategoryScale);

const colorStyle = [
  'rgb(230, 236, 236)',
  'rgb(138, 169, 168)',
  'rgb(52, 105, 104)',
  'rgb(1, 61, 60)',
];

const LoginTypeChartStatistics = ({
  numberOfAppUsersPerLoginType,
}: {
  numberOfAppUsersPerLoginType: Array<NumberOfAppUsersPerLoginType>;
}) => {
  const { t } = useTranslation();
  const appUserLoginType = useMemo(() => {
    return numberOfAppUsersPerLoginType?.map((appUserLoginType) => {
      return appUserLoginType.appUserLoginType;
    });
  }, [numberOfAppUsersPerLoginType]);

  const count = useMemo(() => {
    return numberOfAppUsersPerLoginType?.map((count) => {
      return count.count;
    });
  }, [numberOfAppUsersPerLoginType]);

  const labels = appUserLoginType;

  const data = {
    labels: labels,
    datasets: [
      {
        label: t('statistics.numberOfAppUsersPerLoginType'),
        backgroundColor: colorStyle,
        borderColor: colorStyle,
        data: count,
      },
    ],
  };
  return <Pie data={data} />;
};
export default LoginTypeChartStatistics;
