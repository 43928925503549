import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { OptionsType } from 'constants/TypeConstants';
import { store } from 'store/store';

import { Places } from './getPlaces';

export const BASE_URL = '/places';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Places, PlaceRecursiveParameters | void>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 0;
      const responseBody: Places = { content: [], totalElements: 0 };
      const { country } = (_arg as PlaceRecursiveParameters) || {};
      const roles = store.getState().auth.roles;
      const system = roles[ROLE_SYSTEM as never];
      const handleRequests = () => {
        if (system && country) {
          return `${BASE_URL}?statuses=ACTIVE,IN_PREPARATION&page=${page}&size=20&sort=name,ASC&country=${country}`;
        } else if (system) {
          return `${BASE_URL}?statuses=ACTIVE,IN_PREPARATION&page=${page}&size=20&sort=name,ASC`;
        } else {
          return `${BASE_URL}?statuses=ACTIVE,IN_PREPARATION&is-employed=true&page=${page}&size=20&sort=name,ASC`;
        }
      };
      const handleMultipleFetchRequest = async () => {
        const placesFromBackend = await fetchWithBQ(handleRequests());
        const fetchPlacesResponse = placesFromBackend.data as Places;
        page = page + 1;
        fetchPlacesResponse.content.forEach((place) => {
          responseBody.content.push(place);
        });
        if (fetchPlacesResponse.totalElements > responseBody.content.length) {
          await handleMultipleFetchRequest();
        }
      };
      await handleMultipleFetchRequest();
      return { data: responseBody };
    },
  });

export type PlaceRecursiveParameters = {
  country?: string | OptionsType;
};
