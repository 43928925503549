import api from '../../../services/api';
import createCompany from './createCompany';
import getCompanies from './getCompanies';
import getCompaniesRecursive from './getCompaniesRecursive';
import getCompanyById from './getCompanyById';
import getCompanyStatistics from './getCompanyStatistics';
import updateCompany from './updateCompany';

export const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: getCompanies(build),
    getCompanyById: getCompanyById(build),
    updateCompany: updateCompany(build),
    createCompany: createCompany(build),
    getCompanyStatistics: getCompanyStatistics(build),
    getCompaniesRecursive: getCompaniesRecursive(build),
  }),
  overrideExisting: false,
});

export const {
  useCreateCompanyMutation,
  useLazyGetCompaniesQuery,
  useLazyGetCompaniesRecursiveQuery,
  useLazyGetCompanyByIdQuery,
  useLazyGetCompanyStatisticsQuery,
  useUpdateCompanyMutation,
} = companyApi;
