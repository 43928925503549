import { CountryOption } from '../../../services/country';

export const CountryOptions: CountryOption = {
  uuid: '',
  createdAt: '',
  shortName: '',
  fullName: '',
  isoName: '',
  currency: '',
  isoCurrency: '',
  dateFormat: '',
  flag: '',
  visible: true,
  name: '',
  value: '',
};

export const formDataInitialState: FormDataType = {
  idNumber: '',
  vatNumber: '',
  name: '',
  country: '' ?? CountryOptions,
  zipCode: '',
  city: '',
  street: '',
  status: '',
  streetNumber: '',
  floor: '' ?? 0,
  apartment: '' ?? 0,
};

export type FormDataType = {
  apartment?: number | string;
  city: string;
  country: string | CountryOption;
  floor?: number | string;
  idNumber: string;
  name: string;
  status?: string;
  street: string;
  streetNumber: string;
  uuid?: string;
  vatNumber: string;
  zipCode: string;
};
