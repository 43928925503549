import api from '../../../services/api';
import getSystemStatistics from './getSystemStatistics';

export const statisticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSystemStatistics: getSystemStatistics(build),
  }),
  overrideExisting: false,
});

export const { useGetSystemStatisticsQuery, useLazyGetSystemStatisticsQuery } = statisticsApi;
