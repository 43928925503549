import { ACCard, ACSpinner } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import AverageOrderSizeCard from 'features/place/components/Statistics/AverageOrderSizeCard/AverageOrderSizeCard';
import TableProductCategoriesStatistics from 'features/place/components/Statistics/TableProductCategoriesStatistics/TableProductCategoriesStatistics';
import { StatisticsParameters } from 'features/systemStatistics/services/getSystemStatistics';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import i18next from 'i18next';
import { Bag, Task, WeightMeter } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getValidationSchema } from 'utils/helpers';

import PageHeader from '../../components/PageHeader/PageHeader';
import BarChartOrdersDistribution from '../../features/place/components/Statistics/BarChartOrdersDistribution/BarChartOrdersDistribution';
import BarChartOrdersPerStatus from '../../features/place/components/Statistics/BarChartOrdersPerStatus/BarChartOrdersPerStatus';
import BarChartOrdersRevenue from '../../features/place/components/Statistics/BarChartOrdersRevenue/BarChartOrdersRevenue';
import PieChartStatistics from '../../features/place/components/Statistics/PieChartStatistics/PieChartStatistics';
import SavedKilogramsCard from '../../features/place/components/Statistics/SavedKilogramsCard/SavedKilogramsCard';
import StatisticsBaseForm from '../../features/place/components/Statistics/StatisticsBaseForm/StatisticsBaseForm';
import TableProductsStatistics from '../../features/place/components/Statistics/TableProductsStatistics/TableProductsStatistics';
import UniqueProductsSoldInOrdersCard from '../../features/place/components/Statistics/UniqueProductsSoldInOrdersCard/UniqueProductsSoldInOrdersCard';
import UniqueUsersInOrdersCard from '../../features/place/components/Statistics/UniqueUsersInOrdersCard/UniqueUsersInOrdersCard';
import {
  formDataInitialState,
  formDataInitialStateHourly,
  FormDataTypeDefault,
  FormDataTypeHourly,
} from '../../features/place/constants/FormConstantsStatistics';
import { validationSchema } from '../../features/place/constants/PlaceStatisticsSchema';
import { useLazyGetPlaceStatisticsQuery } from '../../features/place/services';
import { PlaceStatisticsParameters } from '../../features/place/services/getPlaceStatistics';
import styles from './PlaceStatistics.module.scss';

const styleHeader = { marginBottom: '10px' };

const styleCard = { wrapper: { height: '450px' } };

const PlaceStatistics = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { displayError } = useDisplayNotification();
  const srb = i18next.getFixedT('srb');
  const en = i18next.getFixedT('en');

  const [fetchPlaceStatistics, { data: placeStatistics, isFetching, isSuccess }] =
    useLazyGetPlaceStatisticsQuery();

  const [currentValidationSchema, setCurrentValidationSchema] = useState(validationSchema);
  const [isSubmitable, setIsSubmitable] = useState<boolean>(false);
  const [currentRange, setCurrentRange] = useState<PlaceStatisticsParameters | null>(null);

  const { errors, formData, handleChangeFormData, handleSubmit, setErrors, setFormData } = useForm<
    FormDataTypeDefault | FormDataTypeHourly | any
  >(formDataInitialState, currentValidationSchema);

  useEffect(() => {
    if (formData?.interval) {
      const { interval } = formData;
      const dynamicValidationSchema = getValidationSchema(interval);
      setCurrentValidationSchema(dynamicValidationSchema);
    }
  }, [formData?.interval]);

  useEffect(() => {
    if (currentValidationSchema?.to) {
      setFormData({ ...formDataInitialState, interval: formData?.interval });
      setErrors(formDataInitialState);
    } else {
      setFormData({ ...formDataInitialStateHourly, interval: formData?.interval });
      setErrors(formDataInitialStateHourly);
    }
  }, [currentValidationSchema]);

  useEffect(() => {
    const { from, interval, to } = formData;

    if (interval) {
      if (
        (interval === 'DAILY' ||
          interval === srb('placeStatistics.interval.daily') ||
          interval === srb('placeStatistics.interval.weekly') ||
          interval === srb('placeStatistics.interval.monthly') ||
          interval === 'WEEKLY' ||
          (interval === 'MONTHLY' && from && to)) &&
        from &&
        to
      ) {
        setIsSubmitable(true);
      } else if (interval === 'HOURLY' || (srb('placeStatistics.interval.hourly') && from)) {
        setIsSubmitable(true);
      } else {
        setIsSubmitable(false);
      }
    } else {
      setIsSubmitable(false);
    }
  }, [formData, currentValidationSchema]);

  useEffect(() => {
    if (location?.search !== '') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const fromDate = urlSearchParams.get('from');
      const toDate = urlSearchParams.get('to');
      const intervalDate = urlSearchParams.get('interval');
      const placeStatisticsDTO: PlaceStatisticsParameters = {
        ...formData,
        id: id,
        interval: intervalDate,
        from: fromDate,
        to: toDate,
      };
      try {
        if (id) {
          fetchPlaceStatistics(placeStatisticsDTO).unwrap();
          setCurrentRange(placeStatisticsDTO);
          setFormData(formDataInitialState);
        }
      } catch (error) {
        const {
          data: { code },
        } = error as ErrorMessage;
        displayError({ key: code });
      }
    }
  }, []);

  const handleIntervalMessage = (interval: string) => {
    if (interval === 'MONTHLY' || interval === 'MESEČNO')
      return t('placeStatistics.ordersRevenueAxis.xAxisMonthly');
    else if (interval === 'WEEKLY' || interval === 'NEDELJNO')
      return t('placeStatistics.ordersRevenueAxis.xAxisWeekly');
    else if (interval === 'DAILY' || interval === 'DNEVNO')
      return t('placeStatistics.ordersRevenueAxis.xAxisDaily');
    else return t('placeStatistics.ordersRevenueAxis.xAxisHourly');
  };

  const onSubmit = async () => {
    let intervalSrbToEn = '';
    if (formData.interval === srb('placeStatistics.interval.hourly')) {
      intervalSrbToEn = en('placeStatistics.interval.hourly');
    } else if (formData.interval === srb('placeStatistics.interval.daily')) {
      intervalSrbToEn = en('placeStatistics.interval.daily');
    } else if (formData.interval === srb('placeStatistics.interval.weekly')) {
      intervalSrbToEn = en('placeStatistics.interval.weekly');
    } else if (formData.interval === srb('placeStatistics.interval.monthly')) {
      intervalSrbToEn = en('placeStatistics.interval.monthly');
    }
    const placeStatisticsDTO: PlaceStatisticsParameters = {
      ...formData,
      id: id,
      interval: intervalSrbToEn || formData.interval,
      from: formData?.from,
      to:
        formData?.interval === t('placeStatistics.interval.hourly') ? formData?.from : formData?.to,
    };
    try {
      if (id) {
        await fetchPlaceStatistics(placeStatisticsDTO).unwrap();
        setCurrentRange(placeStatisticsDTO);
        setFormData(formDataInitialState);
        navigate(
          `/place-statistics/${id}?interval=${placeStatisticsDTO?.interval}&from=${placeStatisticsDTO?.from}&to=${placeStatisticsDTO?.to}`,
        );
      }
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return !isFetching ? (
    <>
      <PageHeader primaryText={t('placeStatistics.title')} />
      {currentRange && isSuccess && params.get('from') !== null && (
        <p className={styles.subtitle}>
          {currentRange?.from} - {currentRange?.to}
        </p>
      )}
      <StatisticsBaseForm
        errors={errors as StatisticsParameters}
        formData={formData}
        handleChange={handleChangeFormData}
        isFetching={isFetching}
        isSubmitable={isSubmitable}
        onSubmit={() => handleSubmit(onSubmit)}
      />
      {isSuccess && params.get('from') !== null && (
        <div className={styles.form}>
          <div className={styles.barChartSegment}>
            <ACCard
              bodyChildren={
                <>
                  <PageHeader
                    primaryText={t('placeStatistics.ordersRevenue')}
                    style={styleHeader}
                  />
                  <BarChartOrdersRevenue
                    handleIntervalMessage={handleIntervalMessage}
                    interval={formData?.interval}
                    ordersRevenue={placeStatistics?.ordersRevenue}
                  />
                </>
              }
              className={styles.barChart}
            />
          </div>
          <div className={styles.barChartSegment}>
            <ACCard
              bodyChildren={
                <>
                  <PageHeader
                    primaryText={t('placeStatistics.ordersPerStatus')}
                    style={styleHeader}
                  />
                  <BarChartOrdersPerStatus
                    handleIntervalMessage={handleIntervalMessage}
                    interval={formData?.interval}
                    ordersPerStatus={placeStatistics?.ordersPerStatus}
                  />
                </>
              }
              className={styles.barChart}
            />
          </div>
          <div className={styles.barChartSegment}>
            <ACCard
              bodyChildren={
                <>
                  <PageHeader
                    primaryText={t('placeStatistics.ordersDistribution')}
                    style={styleHeader}
                  />
                  <BarChartOrdersDistribution
                    ordersDistribution={placeStatistics?.ordersDistribution}
                  />
                </>
              }
              className={styles.barChart}
            />
          </div>
          <div className={styles.cardsTable}>
            <ACCard
              bodyChildren={
                <>
                  <h3 style={styleHeader}>
                    {t('placeStatistics.productCategoriesSoldPercentageInOrders')}
                  </h3>
                  <TableProductCategoriesStatistics
                    tableData={
                      placeStatistics?.productCategoriesSoldPercentageInOrders
                        ?.productCategoriesSoldPercentage
                    }
                  />
                </>
              }
              className={styles.table}
              style={styleCard}
            />
            <ACCard
              bodyChildren={
                <>
                  <h3 style={styleHeader}>
                    {t('placeStatistics.productCategoriesSoldPercentageInOrdersItems')}
                  </h3>
                  <TableProductCategoriesStatistics
                    tableData={
                      placeStatistics?.productCategoriesSoldPercentageInOrdersItems
                        ?.productCategoriesSoldPercentage
                    }
                  />
                </>
              }
              className={styles.table}
              style={styleCard}
            />
          </div>
          <div className={styles.cardsTable}>
            <ACCard
              bodyChildren={
                <>
                  <h3 style={styleHeader}>{t('placeStatistics.productsSoldPercentageInOrders')}</h3>
                  <TableProductsStatistics
                    tableData={
                      placeStatistics?.productsSoldPercentageInOrders?.productsSoldPercentage
                    }
                  />
                </>
              }
              className={styles.table}
              style={styleCard}
            />
            <ACCard
              bodyChildren={
                <>
                  <h3 style={styleHeader}>
                    {t('placeStatistics.productsSoldPercentageInOrdersItems')}
                  </h3>
                  <TableProductsStatistics
                    tableData={
                      placeStatistics?.productsSoldPercentageInOrdersItems?.productsSoldPercentage
                    }
                  />
                </>
              }
              className={styles.table}
              style={styleCard}
            />
          </div>
          <div className={styles.pieChartAndCards}>
            <div className={styles.pieChartSegment}>
              <ACCard
                bodyChildren={
                  <>
                    <PageHeader
                      primaryText={t('placeStatistics.bestSellingProducts')}
                      style={styleHeader}
                    />
                    <PieChartStatistics
                      bestSellingProducts={placeStatistics?.bestSellingProducts}
                    />
                  </>
                }
                className={styles.pieChart}
              />
            </div>
            <div className={styles.cards}>
              <UniqueUsersInOrdersCard
                data={placeStatistics?.uniqueUsersInOrders}
                header={t('placeStatistics.uniqueUsersInOrders')}
              />
              <UniqueProductsSoldInOrdersCard
                data={placeStatistics?.uniqueProductsSoldInOrders}
                header={t('placeStatistics.uniqueProductsSoldInOrders')}
                icon={<Task />}
              />
            </div>
          </div>
          <div className={styles.cardNumberInfo}>
            <AverageOrderSizeCard
              data={placeStatistics?.averageOrderSize}
              header={t('placeStatistics.averageOrderSize')}
              icon={<Bag />}
            />
            <SavedKilogramsCard
              data={placeStatistics?.savedKilograms}
              header={t('placeStatistics.savedKilograms')}
              icon={<WeightMeter />}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' />
  );
};
export default PlaceStatistics;
