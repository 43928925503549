import { OptionsType } from 'constants/TypeConstants';
import { FIRST_ELEMENT } from 'features/company-request/components/BaseForm/BaseForm';
import { Place } from 'features/place/services/createPlace';
import useObjectTranslation from 'hooks/useObjectTranslation';
import _, { omit } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import { useGetPlacesRecursiveQuery, useLazyGetPlaceByIdQuery } from '../../place/services';
import { useGetProductCategoriesRecursiveQuery } from '../../productCategory/services';
import { ProductCategory } from '../../productCategory/services/getProductCategories';
import { FormDataType } from '../constants/FormConstants';
import { useLazyGetProductByIdQuery } from '../services';
import { ProductDTO, ProductParameters } from '../services/getProductById';

const findPlace = (placeId: string, entirePlaceArray: Place[]) => {
  const foundPlace = entirePlaceArray?.find((el) => placeId === el.uuid);
  return {
    uuid: foundPlace?.uuid,
    name: foundPlace?.name,
    country: foundPlace?.country,
  };
};

export const transformToDTOFormat = (formData: FormDataType, productId: any): ProductDTO => {
  const category = formData?.category as OptionsType;
  const place = formData?.place as OptionsType;
  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
    ...finalData
  } = formData;
  const { pickupHours, ...restData } = finalData;

  const ProductDTO: ProductDTO = productId
    ? {
        ...restData,
        category: category?.uuid,
        place: place?.uuid,
        pickupHours,
      }
    : {
        ...restData,
        category: category?.uuid,
        place: place?.uuid,
        pickupHours,
      };
  return ProductDTO;
};

export const useProductsForm = (
  formData?: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  productId?: ProductParameters,
  file?: any,
  stateError?: boolean,
  placeId?: string,
) => {
  const location = useLocation();
  const [fetchProductById, { data: product, isSuccess: isSuccessProduct }] =
    useLazyGetProductByIdQuery();

  const [fetchPlaceById, { data: place, isSuccess: isSuccessPlaceById }] =
    useLazyGetPlaceByIdQuery();

  const { data: places, isSuccess: isSuccessPlaces } = useGetPlacesRecursiveQuery();

  const { data: allProductCategories, isSuccess: isSuccessAllProductCategories } =
    useGetProductCategoriesRecursiveQuery();

  const isSuccessAdd = isSuccessPlaces && isSuccessAllProductCategories;
  const isSuccessEdit = isSuccessProduct && isSuccessPlaces && isSuccessAllProductCategories;
  const { getNameTranslation } = useObjectTranslation();

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedFormData: ProductDTO = transformToDTOFormat(formData, productId);
    transformedFormData.place = product?.place;
    const trimmedData = trimObjectValues<FormDataType>(transformedFormData);
    const disabled = _.isEqual(trimmedData, product) && file === null;
    return disabled;
  }, [formData, file, places, allProductCategories, product]);

  const findCategory = (categoryId: string, entireCategoryArray: ProductCategory[]) => {
    const foundCategory = entireCategoryArray?.find((category) => categoryId === category?.uuid);
    const categoryName = getNameTranslation(foundCategory);
    return {
      uuid: foundCategory?.uuid,
      name: categoryName,
    };
  };
  const selectedProduct = location?.state?.selectedProduct;

  const initialFormValues = () => {
    const placeResponse = productId
      ? { uuid: place?.uuid, name: place?.name, country: place?.country }
      : findPlace(product?.place, places?.content);
    const categoryId = product?.category;

    const category = findCategory(categoryId, allProductCategories);

    setFormData({
      ...product,
      place: placeResponse,
      category,
      mondayFrom: product?.pickupHours?.mondayFrom,
      mondayTo: product?.pickupHours?.mondayTo,
      tuesdayFrom: product?.pickupHours?.tuesdayFrom,
      tuesdayTo: product?.pickupHours?.tuesdayTo,
      wednesdayFrom: product?.pickupHours?.wednesdayFrom,
      wednesdayTo: product?.pickupHours?.wednesdayTo,
      thursdayFrom: product?.pickupHours?.thursdayFrom,
      thursdayTo: product?.pickupHours?.thursdayTo,
      fridayFrom: product?.pickupHours?.fridayFrom,
      fridayTo: product?.pickupHours?.fridayTo,
      saturdayFrom: product?.pickupHours?.saturdayFrom,
      saturdayTo: product?.pickupHours?.saturdayTo,
      sundayFrom: product?.pickupHours?.sundayFrom,
      sundayTo: product?.pickupHours?.sundayTo,
    });
  };

  const initialFormValuePlace = () => {
    const place = findPlace(placeId, places?.content);
    setFormData({
      ...formData,
      place,
    });
  };

  const initialFormValuesDuplicate = () => {
    const placeResponse = findPlace(selectedProduct?.place, places?.content);
    const categoryId = selectedProduct?.category;
    const category = findCategory(categoryId, allProductCategories);
    const baseProductPrice = Number(selectedProduct?.basePrice?.split(' ')[FIRST_ELEMENT]);
    const discountedProductPrice = Number(
      selectedProduct?.discountedPrice?.split(' ')[FIRST_ELEMENT],
    );
    const product = omit(selectedProduct, [
      'uuid',
      'code',
      'document',
      'createdAt',
      'createdBy',
      'updatedAt',
      'updatedBy',
      'lastStockChangeAt',
      'ratingCount',
      'ratingSum',
      'rated',
      'userOrderCount',
      'version',
      'name',
    ]);
    setFormData({
      ...product,
      place: placeResponse,
      category,
      basePrice: baseProductPrice,
      discountedPrice: discountedProductPrice,
      mondayFrom: selectedProduct?.pickupHours?.mondayFrom,
      mondayTo: selectedProduct?.pickupHours?.mondayTo,
      tuesdayFrom: selectedProduct?.pickupHours?.tuesdayFrom,
      tuesdayTo: selectedProduct?.pickupHours?.tuesdayTo,
      wednesdayFrom: selectedProduct?.pickupHours?.wednesdayFrom,
      wednesdayTo: selectedProduct?.pickupHours?.wednesdayTo,
      thursdayFrom: selectedProduct?.pickupHours?.thursdayFrom,
      thursdayTo: selectedProduct?.pickupHours?.thursdayTo,
      fridayFrom: selectedProduct?.pickupHours?.fridayFrom,
      fridayTo: selectedProduct?.pickupHours?.fridayTo,
      saturdayFrom: selectedProduct?.pickupHours?.saturdayFrom,
      saturdayTo: selectedProduct?.pickupHours?.saturdayTo,
      sundayFrom: selectedProduct?.pickupHours?.sundayFrom,
      sundayTo: selectedProduct?.pickupHours?.sundayTo,
    });
  };

  const placeOptions = useMemo(() => {
    return places?.content.map((el) => {
      return {
        uuid: el.uuid,
        name: el.name,
        country: el.country,
      };
    });
  }, [places]);

  const categoryOptions = useMemo(() => {
    return allProductCategories?.map((productCategory) => {
      const categoryName = getNameTranslation(productCategory);
      return {
        uuid: productCategory.uuid,
        name: categoryName,
      };
    });
  }, [allProductCategories]);

  useEffect(() => {
    if (productId || !stateError) fetchProductById(productId, true);
    if (selectedProduct) {
      initialFormValuesDuplicate();
    }
  }, [isSuccessPlaces]);

  useEffect(() => {
    if (isSuccessEdit) initialFormValues();
  }, [isSuccessEdit, product, isSuccessPlaceById, place]);

  useEffect(() => {
    if (productId && product) {
      fetchPlaceById({ id: product?.place }, true);
    } else if (selectedProduct) {
      fetchPlaceById({ id: selectedProduct?.place }, true);
    }
  }, [isSuccessEdit, product, isSuccessProduct, selectedProduct]);

  const placeData = place;

  useEffect(() => {
    if (placeId && isSuccessPlaces) initialFormValuePlace();
  }, [isSuccessPlaces]);

  return {
    isSuccessAdd,
    isSuccessEdit,
    placeOptions,
    categoryOptions,
    product,
    places,
    placeData,
    allProductCategories,
    shouldDisableSubmitButton,
  };
};

export default useProductsForm;
