import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { PartnerUserResponse } from './getPartnerUsers';

export const BASE_URL = '/partner-users';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PartnerUserResponse, void>({
    async queryFn(_queryParams, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 0;
      const responseBody: PartnerUserResponse = { content: [], totalElements: 0 };
      const handleMultipleFetchRequest = async () => {
        const partnerUsersFromBackend = await fetchWithBQ(
          `/partner-users?page=${page}&sort=email,asc`,
        );
        const fetchUsersResponse = partnerUsersFromBackend.data as PartnerUserResponse;
        page = page + 1;
        fetchUsersResponse.content.forEach((partnerUser) => {
          responseBody.content.push(partnerUser);
        });
        if (fetchUsersResponse.totalElements > responseBody.content.length) {
          await handleMultipleFetchRequest();
        }
      };
      await handleMultipleFetchRequest();
      return { data: responseBody };
    },
    keepUnusedDataFor: 0,
  });
