import {
  ACCard,
  ACFilter,
  ACLoading,
  ACPagination,
  ACSpinner,
} from '@antech-consulting/components';
import { FORM_ELEMENT_NAME, IS_EMPLOYED } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { PLACE_STATUSES } from 'features/place/constants/PlaceContants';
import { Place } from 'features/place/services/createPlace';
import { useAppSelector } from 'hooks/hooks';
import { ArrowRight2, Location } from 'iconsax-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { handleDefaultPlaceImage } from 'utils/helpers';

import { useLazyGetPlaceCardsQuery } from '../../features/place/services';
import { PlaceParameters } from '../../features/place/services/getPlaces';
import styles from './PlacesList.module.scss';

export type BodyPlaceProps = {
  place: Place;
  placesImageQuality: string;
};

const style = {
  filter: { border: 'none' },
};

const BodyContent = ({ place, placesImageQuality }: BodyPlaceProps) => {
  const { category, document, name, street, streetNumber } = place;
  const imageUrl = document && (document[placesImageQuality] as string);
  const imageFile = handleDefaultPlaceImage(category, imageUrl);

  return (
    <>
      <div className={styles.cardHeader}>
        <div className={styles.placeName}>
          <span>{name}</span>
        </div>
        <ArrowRight2 />
      </div>
      <div>
        <img className={styles.image} src={imageFile} />
        <div className={styles.placeNameBody}>{name}</div>
        <div className={styles.placeDetails}>
          <Location />
          <div>
            {street} {streetNumber}
          </div>
        </div>
      </div>
    </>
  );
};

const PlacesList: FC<PlaceParameters> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEmployed = IS_EMPLOYED;
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const system = roles[ROLE_SYSTEM as never];
  const isSystem = system
    ? { statuses: [PLACE_STATUSES.ACTIVE, PLACE_STATUSES.IN_PREPARATION] }
    : { [isEmployed]: 'true', statuses: [PLACE_STATUSES.ACTIVE, PLACE_STATUSES.IN_PREPARATION] };
  const [fetchPlaces, { data: places, isFetching }] = useLazyGetPlaceCardsQuery();
  const initialFilterInputs = [
    { field: FORM_ELEMENT_NAME, title: t('place.nameColumn'), filterType: 'text' },
  ];
  const [filter, setFilter] = useState<{ name: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const numOfRequests = useRef(0);
  const [isMobile, setIsMobile] = useState(false);

  const { data } = useGetPartnerApplicationSettingsQuery();

  const placesImageQuality = data?.dashboardImageQualitySettings?.placeList;

  const checkIsMobile = () => {
    if (document.body.clientWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkIsMobile();
    addEventListener('resize', checkIsMobile);

    return () => {
      removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const handleOnFilterChanged = (filter: any) => {
    numOfRequests.current++;
    setFilter(filter);
    setCurrentPage(1);
    fetchPlaces({
      sort: { field: FORM_ELEMENT_NAME, type: 'asc' },
      size: 8,
      page: 0,
      ...filter,
      ...isSystem,
    });
  };

  const handleOnPageChanged = (selectedPage: number) => {
    numOfRequests.current++;
    setCurrentPage(selectedPage);
    fetchPlaces({
      sort: { field: FORM_ELEMENT_NAME, type: 'asc' },
      size: 8,
      page: selectedPage - 1,
      ...filter,
      ...isSystem,
    });
  };

  const isFilterVisible = useMemo(() => {
    if (!system && numOfRequests.current === 1 && places?.content.length === 0) {
      return false;
    }

    return true;
  }, [system, numOfRequests.current, places]);

  const isPaginationVisible = useMemo(() => {
    if (system && places?.content.length > 0) {
      return true;
    }

    return false;
  }, [system, places]);

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        {isFilterVisible && (
          <ACFilter
            filter={initialFilterInputs as any}
            onFilterChanged={(filter) => {
              handleOnFilterChanged(filter);
            }}
            style={style.filter}
            tooltips={{
              clear: { label: t('filter.clear') },
              close: { label: t('filter.close') },
            }}
          />
        )}

        {places?.content.length > 0 && (
          <div className={styles.placesHeader}>{t('place.productsPlacesHeader')}</div>
        )}
        {places?.content.length === 0 && (
          <div className={styles.placesHeader}>{t('product.noResultsText')}</div>
        )}
        <div
          className={`${styles.cards} ${
            places?.content.length === 1 ? styles.singleCardContainer : ''
          }`}
        >
          {places?.content?.map((place) => {
            return (
              <ACCard
                bodyChildren={<BodyContent place={place} placesImageQuality={placesImageQuality} />}
                className={styles.placeCard}
                dataTestiId='placeCard'
                key={place.uuid}
                onClick={() => navigate(`/products/${place.uuid}?value=${0}`)}
              />
            );
          })}
        </div>

        {isPaginationVisible && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ACPagination
              currentPage={currentPage}
              handleChange={({ selectedPage }) => {
                handleOnPageChanged(selectedPage);
              }}
              isMobile={isMobile}
              pagesCount={places?.totalPages || 1}
              paginationItemActiveClass={styles.pagination}
            />
          </div>
        )}
      </ACLoading>
    </>
  );
};

export default PlacesList;
