import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_INVENTORY } from 'services/TagsConstants';

import { Inventory } from './getInventories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Inventory, InventoryParameters>({
    query: ({ id }) => `/inventory/${id}`,
    providesTags: [TAG_INVENTORY],
  });

export type InventoryParameters = {
  id?: string;
};
