import { ACLoading, ACSpinner, IACTableRow } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import {
  STATUS_ELEMENT_APPROVED,
  STATUS_ELEMENT_PENDING,
  STATUS_ELEMENT_REJECTED,
} from 'constants/StatusConstants';
import { style } from 'constants/StyleConstants';
import {
  DEFAULT_SORT,
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_CREATED_BY,
  TABLE_ELEMENT_STATUSES,
} from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import {
  useApproveProductCategoryRequestMutation,
  useDeleteProductCategoryRequestMutation,
  useLazyGetProductCategoryRequestsQuery,
  useRejectProductCategoryRequestMutation,
} from 'features/productCategoryRequest/services';
import { ProductCategoryRequest } from 'features/productCategoryRequest/services/getProductCategoryRequests';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { AddCircle, CloseCircle, Edit2, Eye, TickCircle, Trash } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCompanyRequestStatusColor } from 'utils/getColorStatus';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { checkIfPropertyExistsInObject, transformACState } from '../../../../utils/helpers';
import productCategoryRequestTableSettings from './productCategoryRequestTableSettings';
import styles from './TableProductCategoryRequests.module.scss';

export type FilterProps = {
  name: string;
};

export type SortProps = {
  field: string;
  type: string;
};

export type TableStateProductCategoryRequest = TableStateProps<FilterProps> & {
  name: string;
};

const TableProductCategoryRequests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [fetchProductCategoryRequests, { data: productCategoryRequests, isFetching, isSuccess }] =
    useLazyGetProductCategoryRequestsQuery();
  const system = roles[ROLE_SYSTEM as never];
  const [isApproveModalOpen, toggleApprove] = useToggle();
  const [isRejectModalOpen, toggleReject] = useToggle();
  const tableSettings = useTable(productCategoryRequestTableSettings);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDeleteEnabled, toggleDelete] = useToggle();

  const [approveRequest, { isLoading: isLoadingApprove }] =
    useApproveProductCategoryRequestMutation();
  const [rejectRequest, { isLoading: isLoadingReject }] = useRejectProductCategoryRequestMutation();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteProductCategoryRequestMutation();

  const handleTableStateChange = (tableState: TableStateProductCategoryRequest) => {
    const transformState = transformACState(tableState, TABLE_ELEMENT_CREATED_AT);
    fetchProductCategoryRequests(transformState);
  };

  const handleSubmitApproveProductCategoryRequest = async () => {
    try {
      await approveRequest(selectedRow?.uuid).unwrap();
      toggleApprove();
      displaySuccess({ key: t('productCategoryRequests.approveModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleApprove();
      displayError({ key: code });
    }
  };

  const handleSubmitRejectProductCategoryRequest = async () => {
    try {
      await rejectRequest(selectedRow?.uuid).unwrap();
      toggleReject();
      displaySuccess({ key: t('productCategoryRequests.rejectModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleReject();
      displayError({ key: code });
    }
  };

  const handleSubmitDeleteProductCategoryRequest = async () => {
    try {
      await deleteRequest(selectedRow?.uuid).unwrap();
      toggleDelete();
      displaySuccess({ key: t('productCategoryRequests.deleteModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const productCategoryRequestsTableData = useMemo(() => {
    return productCategoryRequests?.content?.map((productCategoryRequest) => {
      const { status } = productCategoryRequest;
      const styleColor = getCompanyRequestStatusColor(status);
      const statusText = t(`productCategoryRequests.${status.toLowerCase()}`);
      return {
        rawData: productCategoryRequest,
        render: {
          ...productCategoryRequest,
          status: (
            <div className={`${styles[styleColor]} ${styles.container}`}>
              <div>{statusText}</div>
            </div>
          ),
        },
      };
    });
  }, [productCategoryRequests?.content]);

  const handleApproveModalOpen = ({ rowData }: { rowData: ProductCategoryRequest }) => {
    setSelectedRow(rowData);
    toggleApprove();
  };

  const handleRejectModalOpen = ({ rowData }: { rowData: ProductCategoryRequest }) => {
    setSelectedRow(rowData);
    toggleReject();
  };

  const handleDeleteModalOpen = ({ rowData }: { rowData: ProductCategoryRequest }) => {
    setSelectedRow(rowData);
    toggleDelete();
  };

  const additionalColumns = [
    {
      title: t('productCategoryRequests.createdBy'),
      field: TABLE_ELEMENT_CREATED_BY,
    },
  ];

  const PRODUCT_CATEGORY_REQUESTS = [
    {
      value: STATUS_ELEMENT_PENDING,
      render: t('productCategoryRequests.pending'),
    },
    {
      value: STATUS_ELEMENT_APPROVED,
      render: t('productCategoryRequests.approved'),
    },
    {
      value: STATUS_ELEMENT_REJECTED,
      render: t('productCategoryRequests.rejected'),
    },
  ];

  const handleEditIconClick = (rowData: any) => {
    checkIfPropertyExistsInObject(rowData, 'rowData') && (rowData = rowData.rowData);
    setSelectedRow(rowData);
    navigate(`/product-category-request/${rowData.uuid}`, {
      state: { requestId: rowData.uuid },
    });
  };

  const additionalFilters = [
    {
      field: TABLE_ELEMENT_STATUSES,
      title: t('productCategoryRequests.status'),
      filterType: 'selectSingle',
      filterSelectData: PRODUCT_CATEGORY_REQUESTS,
    },
  ];

  const tableColumns = system
    ? [...tableSettings.columns, ...additionalColumns]
    : tableSettings.columns;

  const {
    approveProductCategoryRequest,
    deleteProductCategoryRequest,
    detailViewProductCategoryRequest,
    editProductCategoryRequest,
    rejectProductCategoryRequest,
  } = {
    deleteProductCategoryRequest: {
      tooltip: {
        text: t('productCategoryRequests.delete'),
      },
      icon: <Trash data-testid='deleteButton' />,
      onClick: handleDeleteModalOpen,
      isHidden: ({ rawData }: IACTableRow) => rawData.status !== STATUS_ELEMENT_PENDING,
    },
    approveProductCategoryRequest: {
      tooltip: {
        text: t('productCategoryRequests.approve'),
      },
      icon: <TickCircle data-testid='approveButton' />,
      onClick: handleApproveModalOpen,
      isHidden: ({ rawData }: IACTableRow) => rawData.status !== STATUS_ELEMENT_PENDING,
    },
    rejectProductCategoryRequest: {
      tooltip: {
        text: t('productCategoryRequests.reject'),
        style: {
          left: '15%',
        },
      },
      icon: <CloseCircle data-testid='rejectButton' />,
      onClick: handleRejectModalOpen,
      isHidden: ({ rawData }: IACTableRow) => rawData.status !== STATUS_ELEMENT_PENDING,
    },
    editProductCategoryRequest: {
      tooltip: {
        text: t('productCategoryRequests.edit'),
      },
      icon: <Edit2 data-testid='editButton' />,
      onClick: handleEditIconClick,
      isHidden: ({ rawData }: IACTableRow) => rawData.status !== STATUS_ELEMENT_PENDING,
    },
    detailViewProductCategoryRequest: {
      tooltip: {
        text: t('productCategoryRequests.detailView'),
      },
      icon: <Eye data-testid='detailViewButton' />,
      onClick: handleEditIconClick,
      isHidden: ({ rawData }: IACTableRow) => rawData.status === STATUS_ELEMENT_PENDING,
    },
  };

  const additionalActions = system
    ? [
        editProductCategoryRequest,
        deleteProductCategoryRequest,
        approveProductCategoryRequest,
        rejectProductCategoryRequest,
        detailViewProductCategoryRequest,
      ]
    : [editProductCategoryRequest, deleteProductCategoryRequest, detailViewProductCategoryRequest];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: productCategoryRequests?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter, ...additionalFilters],
  });

  const handleAddCategoryRequest = () => {
    navigate('/product-category-request/add');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const additionalHeaderActions = [
    {
      title: t('productCategoryRequests.addRequest'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('productCategoryRequests.addRequest'),
      },
      onClick: handleAddCategoryRequest,
    },
  ];

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={productCategoryRequests?.totalElements}
          columns={tableColumns}
          defaultSort={DEFAULT_SORT}
          filter={filtersToDisplay}
          headerActions={additionalHeaderActions}
          headerTitle={t('productCategoryRequests.tableName')}
          onItemClicked={(rowData) => handleEditIconClick({ rowData })}
          onTableStateChange={handleTableStateChange}
          pagesCount={productCategoryRequests?.totalPages}
          rows={productCategoryRequestsTableData}
        />
      </ACLoading>
      {isApproveModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleApprove}
          disabled={isLoadingApprove}
          handleSubmit={handleSubmitApproveProductCategoryRequest}
          header={t('productCategoryRequests.approveModal.titleApprove')}
          icon={<TickCircle style={style.BlueIconModal} />}
          isShow={isApproveModalOpen}
          message={t('productCategoryRequests.approveModal.descriptionApprove')}
        />
      )}
      {isRejectModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleReject}
          disabled={isLoadingReject}
          handleSubmit={handleSubmitRejectProductCategoryRequest}
          header={t('productCategoryRequests.rejectModal.titleReject')}
          icon={<CloseCircle style={style.RedIconModal} />}
          isShow={isRejectModalOpen}
          message={t('productCategoryRequests.rejectModal.descriptionReject')}
        />
      )}
      {isDeleteEnabled && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleSubmitDeleteProductCategoryRequest}
          header={t('productCategoryRequests.deleteModal.titleDelete')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteEnabled}
          message={t('productCategoryRequests.deleteModal.descriptionDelete')}
        />
      )}
    </>
  );
};
export default TableProductCategoryRequests;
