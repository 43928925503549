import api from '../../../services/api';
import createProductCategory from './createProductCategory';
import deleteProductCategory from './deleteProductCategory';
import getProductCategories from './getProductCategories';
import getProductCategoriesRecursive from './getProductCategoriesRecursive';
import getProductCategoryById from './getProductCategoryById';
import removeProductCategoryImage from './removeProductCategoryImage';
import updateProductCategory from './updateProductCategory';
import uploadProductCategoryImage from './uploadProductCategoryImage';

export const productCategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    createProductCategory: createProductCategory(build),
    deleteProductCategory: deleteProductCategory(build),
    updateProductCategory: updateProductCategory(build),
    getProductCategories: getProductCategories(build),
    getProductCategoryById: getProductCategoryById(build),
    uploadProductCategoryImage: uploadProductCategoryImage(build),
    removeProductCategoryImage: removeProductCategoryImage(build),
    getProductCategoriesRecursive: getProductCategoriesRecursive(build),
  }),
  overrideExisting: false,
});

export const {
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoriesRecursiveQuery,
  useGetProductCategoryByIdQuery,
  useLazyGetProductCategoriesQuery,
  useLazyGetProductCategoriesRecursiveQuery,
  useRemoveProductCategoryImageMutation,
  useUpdateProductCategoryMutation,
  useUploadProductCategoryImageMutation,
} = productCategoryApi;
