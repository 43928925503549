import { ACButton, ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import Modal from 'components/Modal/Modal';
import PageHeader from 'components/PageHeader/PageHeader';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { style, styleFooterIcon } from 'constants/StyleConstants';
import UserDetailView from 'features/partnerUser/components/UserDetailView/UserDetailView';
import {
  useBlockPartnerUserMutation,
  useGetPartnerUserByIdQuery,
  useUnblockPartnerUserMutation,
} from 'features/partnerUser/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useToggle from 'hooks/useToggle';
import { UserRemove, UserTick } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const PartnerUserDetailView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBlockUserDialogOpen, toggleBlock] = useToggle();
  const [isUnblockUserDialogOpen, toggleUnblock] = useToggle();
  const [blockRequest, { isLoading: isLoadingBlock }] = useBlockPartnerUserMutation();
  const [unblockRequest, { isLoading: isLoadingUnblock }] = useUnblockPartnerUserMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const { data: partnerUser, isFetching } = useGetPartnerUserByIdQuery({
    id,
  });

  const onDiscard = () => {
    navigate(-1);
  };

  const handleUnblockPartnerUser = async () => {
    try {
      await unblockRequest(id).unwrap();
      toggleUnblock();
      displaySuccess({
        key: t('blockModal.unblockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleUnblock();
      displayError({ key: code });
    }
  };

  const handleBlockPartnerUser = async () => {
    try {
      await blockRequest(id).unwrap();
      toggleBlock();
      displaySuccess({
        key: t('blockModal.blockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleBlock();
      displayError({ key: code });
    }
  };
  const additionalFooterButtons = [
    partnerUser?.blocked ? (
      <>
        <ACButton
          dataTestid='unblockUser'
          key='unblockedUser'
          onClick={toggleUnblock}
          style={styleFooterIcon.iconAction}
          variant={'text'}
        >
          <UserTick style={styleFooterIcon.iconAction} />
          {t('partnerUsers.unblockUser')}
        </ACButton>
      </>
    ) : (
      <>
        <ACButton
          dataTestid='blockUser'
          key='blockedUser'
          onClick={toggleBlock}
          style={styleFooterIcon.iconAction}
          variant={'text'}
        >
          <UserRemove style={styleFooterIcon.iconAction} />
          {t('partnerUsers.blockUser')}
        </ACButton>
      </>
    ),
  ];

  return !isFetching ? (
    <>
      <PageHeader primaryText={t('partnerUser.title')} />
      <UserDetailView formData={partnerUser} />
      <FormStickyFooter
        additionalActionButtons={additionalFooterButtons}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
      />
      {isUnblockUserDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleUnblock}
          disabled={isLoadingUnblock}
          handleSubmit={handleUnblockPartnerUser}
          header={t('blockModal.titleUnblock')}
          icon={<UserTick style={style.BlueIconModal} />}
          isShow={isUnblockUserDialogOpen}
          message={t('blockModal.descriptionUnblock')}
        />
      )}
      {isBlockUserDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleBlock}
          disabled={isLoadingBlock}
          handleSubmit={handleBlockPartnerUser}
          header={t('blockModal.titleBlock')}
          icon={<UserRemove style={style.RedIconModal} />}
          isShow={isBlockUserDialogOpen}
          message={t('blockModal.descriptionBlock')}
        />
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default PartnerUserDetailView;
