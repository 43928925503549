import { ACInput } from '@antech-consulting/components';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_STATUS,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import {
  FORM_ELEMENT_USER_EMAIL,
  FORM_ELEMENT_USER_FIRST_NAME,
  FORM_ELEMENT_USER_LAST_NAME,
  FORM_ELEMENT_USER_PHONE_NUMBER,
  FormDataType,
} from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

type BaseFormProductCategoryRequestProps = BaseFormProps<FormDataType> & {
  formData: FormDataType;
  requestId?: boolean;
  requestIsEditable?: boolean;
  system?: boolean;
};

const BaseForm = ({
  errors,
  formData,
  handleChange,
  requestId,
  requestIsEditable,
  system,
}: BaseFormProductCategoryRequestProps) => {
  const { name, status, userInformation } = formData;
  const { t } = useTranslation();
  const baseSecondaryText = requestId ? t('form.editBaseSubtitle') : t('form.addBaseSubtitle');
  const isDetailView = !requestIsEditable && requestId;

  const statusTranslated = useMemo(() => {
    if (isDetailView && status) {
      const val = t(`companyRequest.${status?.toUpperCase()}`);
      return val[0].toUpperCase() + val.slice(1).toLowerCase();
    } else {
      return status ? status[0].toUpperCase() + status.slice(1).toLowerCase() : '';
    }
  }, [isDetailView, status]);

  return (
    <form>
      {isDetailView ? (
        <>
          <FormHeader
            primaryText={t('productCategoryRequests.subtitleView')}
            secondaryText={t('productCategoryRequests.descriptionView')}
          />
          <div className={styles.detailViewInformation}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              disabled
              fullWidth
              id={FORM_ELEMENT_NAME}
              label={t('productCategoryRequests.nameView')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_NAME}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={name}
            />
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              disabled
              fullWidth
              id={FORM_ELEMENT_STATUS}
              label={t('productCategoryRequests.status')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_STATUS}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={statusTranslated}
            />
          </div>
          {system && (
            <>
              <FormHeader
                primaryText={t('productCategoryRequests.subtitleViewUser')}
                secondaryText={t('productCategoryRequests.descriptionViewUser')}
              />
              <div className={styles.detailViewInformation}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_USER_FIRST_NAME}
                  label={t('productCategoryRequests.userFirstName')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_USER_FIRST_NAME}
                  onChange={handleChange}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={userInformation?.firstName}
                />
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_USER_LAST_NAME}
                  label={t('productCategoryRequests.userLastName')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_USER_LAST_NAME}
                  onChange={handleChange}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={userInformation?.lastName}
                />
              </div>
              <div className={styles.detailViewInformation}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_USER_EMAIL}
                  label={t('productCategoryRequests.userEmail')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_USER_EMAIL}
                  onChange={handleChange}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={userInformation?.email}
                />
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_USER_PHONE_NUMBER}
                  label={t('productCategoryRequests.userPhoneNumber')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_USER_PHONE_NUMBER}
                  onChange={handleChange}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={userInformation?.phoneNumber}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <FormHeader
            primaryText={t('productCategoryRequests.subtitleBaseForm')}
            secondaryText={baseSecondaryText}
          />
          <div className={styles.requestField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              disabled={isDetailView}
              errorMessage={errors?.name}
              fullWidth
              hasError={!!errors?.name}
              id={FORM_ELEMENT_NAME}
              label={t('productCategoryRequests.name')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_NAME}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={name}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default BaseForm;
