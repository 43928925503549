import { ACButton } from '@antech-consulting/components';
import { CloseCircle } from 'iconsax-react';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { style } from '../../constants/StyleConstants';
import styles from './DetailViewModal.module.scss';

type TypeDetailModal = {
  children?: ReactElement;
  headerMessage: string;
  message: string;
  setIsDetailViewDialogOpen: (boolean: boolean) => void;
};

const DetailViewModal: FC<TypeDetailModal> = ({
  children,
  headerMessage,
  message,
  setIsDetailViewDialogOpen,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setIsDetailViewDialogOpen(false);
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.mainWrapper}>
        <div className={styles.headerModal}>
          <div>
            <h5 className={styles.title}>{headerMessage}</h5>
            <p className={styles.text}>{message}</p>
          </div>
          <CloseCircle onClick={handleClose} />
        </div>
        <hr />
        {children}
      </div>
      <div style={style.ModalButtons}>
        <div className={styles.buttonCancel}>
          <ACButton dataTestid='Close' onClick={handleClose} style={style.ACModal.ButtonCancel}>
            {t('detailViewModal.buttonClose')}
          </ACButton>
        </div>
      </div>
    </div>
  );
};

export default DetailViewModal;
