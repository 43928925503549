import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_EMAIL,
} from 'constants/FormConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { FORM_ELEMENT_ROLE } from 'features/place/constants/PlaceContants';
import { useTranslation } from 'react-i18next';

import { FormDataEmployeeType } from '../../constants/FormConstants';
import { ROLES } from '../../constants/TableConstants';
import styles from './PlaceEmployees.module.scss';

type BaseFormPlaceEmployeesProps = BaseFormProps<FormDataEmployeeType> & {
  editEmployee?: boolean;
  isEdit?: boolean;
  setFormData?: (formData: FormDataEmployeeType) => void;
};

const BaseForm = ({
  editEmployee,
  errors,
  formData,
  handleChange,
  isEdit,
}: BaseFormPlaceEmployeesProps) => {
  const { t } = useTranslation();
  const { email, role } = formData;

  return (
    <form>
      {!isEdit && (
        <>
          <h3 className={styles.addTitle}>
            {editEmployee ? t('place.editEmployee') : t('place.addEmployee')}
          </h3>
          {!editEmployee && <p className={styles.subtitle}>{t('place.addEmployeeDescription')}</p>}
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='userEmail'
            disabled={editEmployee}
            errorMessage={errors?.email}
            fullWidth
            hasError={!!errors?.email}
            id={FORM_ELEMENT_EMAIL}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_EMAIL}
            onChange={handleChange}
            placeholder={t('place.email')}
            rounded={AC_INPUT_ROUND}
            value={email}
          />
        </>
      )}
      <ACAutocompleteV2
        closeOnSelect
        dataTestId='userRole'
        errorMessage={errors?.role}
        getOptionLabel={(option) => option}
        hasError={!!errors?.role}
        name={FORM_ELEMENT_ROLE}
        onChange={(event) => {
          handleChange(event);
        }}
        options={ROLES}
        renderInput={(params) => (
          <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} placeholder={t('place.role')} />
        )}
        value={role}
      />
    </form>
  );
};

export default BaseForm;
