import { ACLoading, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import {
  FORM_ELEMENT_NAME_EN,
  FORM_ELEMENT_NAME_HR,
  FORM_ELEMENT_NAME_SR,
  LANGUAGES,
} from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { ProductCategory } from 'features/productCategory/services/getProductCategories';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useObjectTranslation from 'hooks/useObjectTranslation';
import useTable from 'hooks/useTable';
import { AddCircle, Trash } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import TastyLogo from '../../../../images/TastyLogo.jpg';
import { CurrentUser } from '../../../../store/Auth';
import { transformACState } from '../../../../utils/helpers';
import {
  useDeleteProductCategoryMutation,
  useLazyGetProductCategoriesQuery,
} from '../../services/index';
import productCategoryTableSettings from './productCategoryTableSettings';
import styles from './TableProductCategory.module.scss';

export type FilterProps = {
  name: string;
};

export type SortProps = {
  field: string;
  type: string;
};

export type TableStateProductCategory = TableStateProps<FilterProps> & {
  name: string;
};

export const iconStyle = {
  width: 40,
  height: 40,
};

const TableProductCategory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { language } = useAppSelector(({ language }) => language);

  const handleNameTranslation = () => {
    switch (language) {
      case LANGUAGES.HR:
        return FORM_ELEMENT_NAME_HR;
      case LANGUAGES.SRB:
        return FORM_ELEMENT_NAME_SR;
      case LANGUAGES.EN:
        return FORM_ELEMENT_NAME_EN;
      default:
        return FORM_ELEMENT_NAME_EN;
    }
  };

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const { getNameTranslation } = useObjectTranslation();
  const { data } = useGetPartnerApplicationSettingsQuery();
  const productCategoryImageQuality = data?.dashboardImageQualitySettings?.productCategoryAll;
  const tableSettings = useTable(productCategoryTableSettings);
  const { actions, isDeleteEnabled, selectedRow, toggleDelete } = tableSettings;

  const [fetchProductCategories, { data: productCategories, isFetching, isSuccess }] =
    useLazyGetProductCategoriesQuery();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteProductCategoryMutation();

  const system = roles[ROLE_SYSTEM as never];

  const DEFAULT_SORT = {
    field: handleNameTranslation(),
    type: 'asc',
  };

  const handleTableStateChange = (tableState: TableStateProductCategory) => {
    const transformState = transformACState(tableState, '');
    fetchProductCategories(transformState);
  };

  const productCategoryTableData = useMemo(() => {
    return productCategories?.content?.map((productCategory) => {
      const categoryName = getNameTranslation(productCategory);
      const imageUrl =
        productCategory?.document &&
        (productCategory?.document[productCategoryImageQuality] as string);
      const productCategoryName = () => {
        return (
          <div className={styles.documentColumn}>
            <img className={styles.categoryImage} src={imageUrl || TastyLogo} style={iconStyle} />
            <div className={styles.productCategoryName}>{categoryName}</div>
          </div>
        );
      };

      return {
        rawData: {
          ...productCategory,
          nameEn: imageUrl || '',
          nameSr: imageUrl || '',
        },
        render: {
          ...productCategory,
          nameEn: productCategoryName(),
          nameSr: productCategoryName(),
        },
      };
    });
  }, [productCategories?.content, document]);

  const handleDelete = async () => {
    try {
      await deleteRequest(selectedRow?.uuid).unwrap();
      toggleDelete();
      displaySuccess({ key: 'deleteModal.removeImage' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const columns = [
    {
      title: t('productCategory.nameColumn'),
      field: handleNameTranslation(),
    },
    {
      title: t('productCategory.createdAt'),
      field: TABLE_ELEMENT_CREATED_AT,
    },
  ];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: productCategories?.totalElements,
    isSuccess,
    filters: tableSettings.filter,
  });

  const handleAddProductCategory = () => {
    navigate('/product-category/add');
       const navEvent = new PopStateEvent('popstate');
       window.dispatchEvent(navEvent);
  };

  const additionalHeaderActions = [
    {
      title: t('productCategory.addProductCategory'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('productCategory.addProductCategory'),
      },
      onClick: handleAddProductCategory,
    },
  ];

  const handleOnItemClicked = ({ rowData }: { rowData: ProductCategory }) => {
    const { uuid } = rowData;
    navigate(`/product-category/${uuid}`);
  };

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={system && actions}
          allItemsCount={productCategories?.totalElements}
          columns={columns}
          defaultSort={DEFAULT_SORT}
          filtersDisplayed={filtersToDisplay}
          headerActions={system && additionalHeaderActions}
          headerTitle={t('productCategory.tableName')}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleTableStateChange}
          pagesCount={productCategories?.totalPages}
          rows={productCategoryTableData}
        />
      </ACLoading>
      {isDeleteEnabled ? (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDelete}
          header={t('deleteModal.headerDeleteProductCategory')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteEnabled}
          message={t('deleteModal.headerMessageDeleteProductCategory')}
        />
      ) : null}
    </>
  );
};
export default TableProductCategory;
