import { ACInput } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import {
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_FIRST_NAME,
  FORM_ELEMENT_LAST_NAME,
} from 'constants/FormConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { FormDataType } from 'features/user/constants/UserProfileFormConstants';
import { useTranslation } from 'react-i18next';

import { style } from '../../../../constants/StyleConstants';
import styles from './ProfileBaseForm.module.scss';

const ProfileBaseForm = ({ errors, formData, handleChange }: BaseFormProps<FormDataType>) => {
  const { t } = useTranslation();
  const { country, email, firstName, lastName } = formData;

  return (
    <>
      <Divider />
      <form className={styles.profileForm}>
        <h5 className={styles.titleGeneral}>{t('profile.titleGeneral')}</h5>
        <p className={styles.description}>{t('profile.descriptionGeneral')}</p>
        <div className={styles.textFieldName}>
          <p className={styles.text}>{t('profile.firstName')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='usersName'
              errorMessage={errors?.firstName}
              fullWidth
              hasError={!!errors?.firstName}
              id={FORM_ELEMENT_FIRST_NAME}
              name={FORM_ELEMENT_FIRST_NAME}
              onChange={handleChange}
              style={style.ACInputProfile}
              type='text'
              value={firstName}
            />
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.textField}>
          <p className={styles.text}>{t('profile.lastName')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='usersLastName'
              errorMessage={errors?.lastName}
              fullWidth
              hasError={!!errors?.lastName}
              id={FORM_ELEMENT_LAST_NAME}
              name={FORM_ELEMENT_LAST_NAME}
              onChange={handleChange}
              style={style.ACInputProfile}
              type='text'
              value={lastName}
            />
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.textField}>
          <p className={styles.text}>{t('profile.email')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='userEmail'
              disabled
              fullWidth
              id={FORM_ELEMENT_EMAIL}
              name={FORM_ELEMENT_EMAIL}
              style={style.ACInputDisable}
              type='text'
              value={email}
            />
          </div>
        </div>
        <hr className={styles.divider} />
        <div className={styles.textField}>
          <p className={styles.text}>{t('profile.country')}</p>
          <div className={styles.inputTextField}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='userCountry'
              disabled
              fullWidth
              id={FORM_ELEMENT_COUNTRY}
              name={FORM_ELEMENT_COUNTRY}
              style={style.ACInputDisable}
              type='text'
              value={country}
            />
          </div>
        </div>
      </form>
    </>
  );
};
export default ProfileBaseForm;
