import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_NAME } from 'constants/FormConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { FilterProps, iconStyle } from 'features/product/constants/TableConstants';
import { Product } from 'features/product/services/getProducts';
import { useLazyGetProductCategoriesRecursiveQuery } from 'features/productCategory/services';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useObjectTranslation from 'hooks/useObjectTranslation';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { AddCircle, Additem, Archive, Trash } from 'iconsax-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import TastyLogo from '../../../../images/TastyLogo.jpg';
import { handleRating, transformACState } from '../../../../utils/helpers';
import {
  useArchiveProductMutation,
  useDeleteProductMutation,
  useLazyGetProductsQuery,
} from '../../services/index';
import productTableSettings from './productTableSettings';
import styles from './TableProduct.module.scss';

const TableProduct = () => {
  const { id: placeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const tableSettings = useTable(productTableSettings);
  const { displayError, displaySuccess } = useDisplayNotification();
  const { isDeleteEnabled, selectedRow, setSelectedRow, toggleDelete } = tableSettings;
  const [deleteProduct, { isLoading: isLoadingDelete }] = useDeleteProductMutation();
  const [archiveProduct, { isLoading: isLoadingArchive }] = useArchiveProductMutation();
  const [isArchiveProductModalOpen, toggleArchive] = useToggle();
  const [fetchProducts, { data: products, isFetching, isSuccess }] = useLazyGetProductsQuery();
  const [
    fetchProductCategories,
    { data: allProductCategories, isSuccess: isSuccessAllProductCategories },
  ] = useLazyGetProductCategoriesRecursiveQuery();
  const { getNameTranslation } = useObjectTranslation();
  const isLoadingTable = isFetching || isLoadingArchive || isLoadingDelete;

  const handleChangeTableData = (tableData: TableStateProps<FilterProps>) => {
    fetchProducts(transformACState({ ...tableData, place: placeId }, FORM_ELEMENT_NAME));
  };

  const { data } = useGetPartnerApplicationSettingsQuery();

  const productsImageQuality = data?.dashboardImageQualitySettings?.productList;

  useEffect(() => {
    if (products && products.totalElements !== 0) {
      fetchProductCategories();
    }
  }, [products]);

  const categoryOptions = useMemo(() => {
    if (isSuccessAllProductCategories) {
      return allProductCategories?.map((productCategory) => {
        const categoryName = getNameTranslation(productCategory);
        return {
          uuid: productCategory.uuid,
          name: categoryName,
        };
      });
    } else {
      return [];
    }
  }, [allProductCategories, isSuccessAllProductCategories]);

  const additionalFilters = [
    {
      filterType: 'selectMulti',
      title: t('product.categoryColumn'),
      field: 'product-categories',
      filterSelectData: categoryOptions?.map((category) => {
        return { value: category?.uuid, render: category?.name };
      }),
    },
  ];

  const handleArchive = ({ rowData }: { rowData: any }) => [
    setSelectedRow(rowData),
    toggleArchive(),
  ];

  const handleAddProduct = () => {
    navigate(`/product/add/${placeId}`, {
      state: { previousLocation: location.pathname + location?.search },
    });
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const handleDuplicateProduct = ({ rowData }: { rowData: Product }) => {
    navigate('/product/add', {
      state: { selectedProduct: rowData, previousLocation: location.pathname + location?.search },
    });
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const handelArchiveProduct = async () => {
    try {
      await archiveProduct(selectedRow?.uuid).unwrap();
      toggleArchive();
      displaySuccess({ key: t('archiveModal.archiveModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleArchive();
      displayError({ key: code });
    }
  };

  const additionalActions = [
    {
      icon: <Additem />,
      tooltip: {
        text: t('product.duplicateProduct'),
        style: {
          left: '15%',
        },
      },
      onClick: handleDuplicateProduct,
    },
    {
      icon: <Archive data-testid='archiveButton' />,
      tooltip: {
        text: t('product.archive'),
        style: {
          left: '15%',
        },
      },
      onClick: handleArchive,
    },
  ];

  const additionalHeaderActions = [
    {
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('product.addProduct'),
      },
      onClick: handleAddProduct,
    },
  ];

  const productTableData = useMemo(() => {
    return products?.content?.map((product) => {
      const rating = handleRating(product);
      const matchedCategory =
        isSuccessAllProductCategories &&
        allProductCategories?.find((el) => el.uuid === product?.category);
      const categoryName = getNameTranslation(matchedCategory);
      const imageUrl = product?.document && product?.document[productsImageQuality];

      return {
        rawData: {
          ...product,
          name: imageUrl || '',
        },
        render: {
          ...product,
          name: (
            <div className={styles.documentColumn}>
              <img className={styles.image} src={imageUrl || TastyLogo} style={iconStyle} />
              <div className={styles.productName}>{product?.name}</div>
            </div>
          ),
          rating: <span className='star'>{rating}</span>,
          category: categoryName,
        },
      };
    });
  }, [products?.content, document, isSuccessAllProductCategories]);

  const handleDelete = async () => {
    try {
      await deleteProduct(selectedRow?.uuid).unwrap();
      toggleDelete();
      displaySuccess({ key: 'deleteModal.removeImage' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: products?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter, ...additionalFilters],
  });

  const handleOnItemClicked = ({ rowData }: { rowData: Product }) => {
    const { uuid } = rowData;
    navigate(`/product/${uuid}`, {
      state: { previousLocation: location.pathname + location?.search },
    });
  };
  return (
    <>
      <ACLoading
        isLoading={isLoadingTable}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={[...tableSettings.actions, ...additionalActions]}
          allItemsCount={products?.totalElements}
          defaultSort={{
            field: FORM_ELEMENT_NAME,
            type: 'asc',
          }}
          filter={filtersToDisplay}
          headerActions={products?.place?.verified ? additionalHeaderActions : []}
          headerTitle={`${t('product.tableName')} ${products?.place?.name}`}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleChangeTableData}
          pagesCount={products?.totalPages}
          rows={productTableData}
        />
      </ACLoading>
      {isArchiveProductModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleArchive}
          disabled={isLoadingArchive}
          handleSubmit={handelArchiveProduct}
          header={t('archiveModal.titleArchive')}
          icon={<Archive style={style.RedIconModal} />}
          isShow={isArchiveProductModalOpen}
          message={t('archiveModal.descriptionArchive')}
        />
      )}
      {isDeleteEnabled && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDelete}
          header={t('deleteModal.headerRemoveProduct')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteEnabled}
          message={t('deleteModal.messageRemoveProduct')}
        />
      )}
    </>
  );
};
export default TableProduct;
