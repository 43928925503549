// eslint-disable-next-line camelcase
import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { Country } from 'services/country';

import { UserResponse } from '../../features/auth/services/login';

export type JWTAcessToken = {
  exp: number;
  permissions: string[];
  roles: string[];
  sub: string;
  type: string;
};

export type CurrentUser = {
  accessToken: string;
  accessTokenExpireDate: string;
  data?: UserResponse;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
  phoneVerified?: boolean;
  refreshToken: string;
  refreshTokenExpireDate: string;
  roles?: string[];
  typeUser?: string;
  user?: string;
  userCountry?: Country;
};

export type CurrentUserPayload = {
  payload: CurrentUser;
};

const initialAuthState: CurrentUser = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneNumberCountryCode: '',
  phoneVerified: false,
  user: '',
  accessToken: '',
  userCountry: null,
  refreshToken: '',
  accessTokenExpireDate: '',
  refreshTokenExpireDate: '',
  roles: [],
  typeUser: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setUserBasicInfo: (state, { payload }) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phoneNumber = payload.phoneNumber;
      state.phoneNumberCountryCode = payload.phoneNumberCountryCode;
      state.userCountry = payload.userCountry;
      state.roles = payload.roles;
    },
    setCurrentUser: (
      state,
      {
        payload: { accessToken, accessTokenExpireDate, refreshToken, refreshTokenExpireDate },
      }: CurrentUserPayload,
    ) => {
      const jwtAccessToken: JWTAcessToken = jwt_decode(accessToken);
      state.user = jwtAccessToken.sub;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.accessTokenExpireDate = accessTokenExpireDate;
      state.refreshTokenExpireDate = refreshTokenExpireDate;
      state.roles = jwtAccessToken.roles;
      state.typeUser = jwtAccessToken.type;
    },
    logout: (state) => {
      state.user = initialAuthState.user;
      state.accessToken = initialAuthState.accessToken;
      state.refreshToken = initialAuthState.refreshToken;
      state.accessTokenExpireDate = initialAuthState.accessTokenExpireDate;
      state.refreshTokenExpireDate = initialAuthState.refreshTokenExpireDate;
      state.roles = [];
      state.typeUser = '';
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.phoneNumber = '';
      state.phoneNumberCountryCode = '';
      state.userCountry = null;
    },
  },
});

export const { logout, setCurrentUser, setUserBasicInfo } = authSlice.actions;

export default authSlice.reducer;
