import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USERS } from 'services/TagsConstants';

import { RequestParameters } from './login';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<RequestParameters, RequestParameters>({
    query: (credentials) => ({
      url: '/partner-user/forgot-password-request',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_USERS],
  });
