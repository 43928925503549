import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USER } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<Code, Code>({
    query: (credentials) => ({
      url: '/partner-user/current/change-phone-number',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_USER],
  });

export type Code = {
  code: string;
};
