import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  currentAndroidVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  currentIosVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  maintenanceMode: {
    isRequired: true,
  },
  maintenanceModeTitle: {
    isRequired: true,
  },
  maintenanceModeMessage: {
    isRequired: true,
  },
  minimalRequiredAndroidVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  minimalRequiredIosVersion: {
    isRequired: true,
    isNumberDecimal: true,
  },
  storyList: {
    isRequired: true,
  },
  storyDetail: {
    isRequired: true,
  },
  placeList: { isRequired: true },
  placeDetail: { isRequired: true },
  productList: { isRequired: true },
  productDetail: { isRequired: true },
  productCategoryAll: { isRequired: true },
  storyDetailHeader: { isRequired: true },
  firebaseTokenValidity: {
    isRequired: true,
    onlyNumbers: true,
  },
};
