import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCT, TAG_PRODUCTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/product/${id}/remove-image`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_PRODUCTS, TAG_PRODUCT],
  });
