export const ORDER_PRODUCTS_STOCK_AVAILABLE = {
  zero: 0,
  one: 1,
};

export const TABLE_ELEMENT_DOCUMENT = 'document';

export type FilterProps = {
  categories: string;
  name: string;
};

export const iconStyle = {
  width: 40,
  height: 40,
};
