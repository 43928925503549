import { CityDTO, OptionsType } from 'constants/TypeConstants';
import translit from 'cyrillic-to-latin';
import {
  useLazyGetCompaniesRecursiveQuery,
  useLazyGetCompanyByIdQuery,
} from 'features/company/services';
import { Company } from 'features/company/services/getCompanies';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useObjectTranslation from 'hooks/useObjectTranslation';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleCountryDisplay, trimObjectValues } from 'utils/helpers';

import { Country } from '../../../services/country';
import { FormDataTypePlace } from '../constants/FormConstants';
import { useGetPlaceCategoriesQuery, useLazyGetPlaceByIdQuery } from '../services';
import { PlaceUpdateDTO } from '../services/updatePlace';
import useMapForm from './useMapForm';

export const findCompany = (comapanyId: string, entireCompaniesArray: Company[]) => {
  const foundCompanies = entireCompaniesArray?.find((company) => company?.uuid === comapanyId);
  return {
    uuid: foundCompanies?.uuid,
    name: foundCompanies?.name,
  };
};

export const findCountry = (countryId: string, entireCountriesArray: Country[]) => {
  const foundCountry = entireCountriesArray?.find((country) => country?.uuid === countryId);
  return {
    uuid: foundCountry?.uuid,
    name: foundCountry?.shortName,
    minimumOrderAmount: foundCountry?.minimumOrderAmount,
    isoCurrency: foundCountry?.isoCurrency,
  };
};

export const findCity = (cityId: string, entireCitiesArray: CityDTO[]) => {
  const foundCity = entireCitiesArray?.find((city) => city?.uuid === cityId);
  return {
    uuid: foundCity?.uuid,
    name: foundCity?.name,
  };
};

export const transformToDTOFormat = (formData: FormDataTypePlace): PlaceUpdateDTO => {
  const { uuid } = formData?.company as Company;
  const { uuid: countryId } = formData?.country as Country;
  const { uuid: cityId } = formData?.city as CityDTO;

  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
    ...finalData
  } = formData;

  const { phoneNumber, ...restData } = finalData;
  const placeData = phoneNumber !== '' ? finalData : restData;
  const placeCategory = placeData?.category as OptionsType;
  return {
    ...placeData,
    category: placeCategory?.uuid.toUpperCase(),
    company: uuid,
    country: countryId,
    workingHours: placeData?.workingHours,
    city: cityId,
    minimumOrderAmount: Number(formData?.minimumOrderAmount),
    fee: parseFloat(formData?.fee as string),
  };
};

export const transformToDTOFormatDisabled = (formData: FormDataTypePlace): PlaceUpdateDTO => {
  const country = formData.country as Country;
  const city = formData?.city as CityDTO;
  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
    ...finalData
  } = formData;
  const { workingHours, ...restData } = finalData;
  const placeCategory = formData?.category as OptionsType;

  return {
    ...restData,
    country: country?.uuid,
    category: placeCategory?.uuid?.toUpperCase(),
    city: city?.uuid,
    workingHours,
  };
};

type MapData = {
  chosenLocation: any;
  city: string | OptionsType;
  country?: string | OptionsType;
  latitude: number;
  longitude: number;
  street: string;
  streetNumber: string;
  zipCode: string;
};
export const LONGITUDE_INITIAL = 19.844609274221057;
export const LATITUDE_INITIAL = 45.25484983261336;

const FIRST_MAP_ITEM = 0;
const SECOND_MAP_ITEM = 1;

export const usePlacesForm = (
  formData: FormDataTypePlace,
  setFormData?: (formData: FormDataTypePlace) => void,
  placeId?: string,
  file?: File,
) => {
  const { t } = useTranslation();
  const [fetchPlaceById, { data: place, isFetching: isFetchingPlace, isSuccess }] =
    useLazyGetPlaceByIdQuery();
  const { cities, countries } = useAppSelector(({ globalData }) => globalData);
  const [fetchAllCompanies, { data: companies, isSuccess: isSuccessCompanies }] =
    useLazyGetCompaniesRecursiveQuery();
  const [fetchCompanyById, { data: company, isSuccess: isSuccessCompany }] =
    useLazyGetCompanyByIdQuery();
  const { data: placeCategories, isSuccess: isSuccessPlaceCategories } =
    useGetPlaceCategoriesQuery();

  const isSuccessEdit = isSuccess && isSuccessCompany && isSuccessPlaceCategories;
  const isSuccessAdd = isSuccessCompanies && isSuccessPlaceCategories && cities && countries;

  const { latitude, longitude } = formData;
  const latitudeMap = latitude ? latitude : LATITUDE_INITIAL;
  const longitudeMap = longitude ? longitude : LONGITUDE_INITIAL;
  const [coordinates, setCoordinates] = useState({
    longitude: longitudeMap,
    latitude: latitudeMap,
  });
  const [initialLocation, setInitialLocation] = useState(false);
  const [addMarker, setAddMarker] = useState(placeId && true);
  const [isClickedOnMap, setIsClickedOnMap] = useState(false);

  const longitudeInitial = placeId && longitude !== 0 ? longitude : longitudeMap;
  const latitudeInitial = placeId && latitude !== 0 ? latitude : latitudeMap;
  const companyId = place?.company as string;

  const initialMapView = {
    longitude: longitudeInitial,
    latitude: latitudeInitial,
    zoom: 5,
    bearing: 0,
    pitch: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  };

  const [viewport, setViewport] = useState(initialMapView);

  const { displayWarning } = useDisplayNotification();

  const {
    handleMapInputChange,
    isFetching,
    isFetchingLocation,
    language,
    mapClickedData,
    mapOptions,
    setInputValue,
  } = useMapForm({
    initialLocation,
    addMarker,
    coordinates,
    isClickedOnMap,
    formData,
  });

  const { chosenLocation, citySr, mapCountryEn, mapStreet, mapStreetNumber, mapZipCode } =
    mapClickedData;
  const { getNameTranslation } = useObjectTranslation();

  const handleInvalidData = (value) => {
    const cityValue = value
      ? translit(value?.context?.find((context) => context.id.includes('place')).text_sr)
      : '';
    if (value && !citiesOptions?.find((city) => city?.name === cityValue)) {
      displayWarning({ message: t('place.invalidCity') });
      return;
    } else if (value && !value?.address) {
      displayWarning({ message: t('place.invalidAddress') });
      return;
    }
  };

  const handleViewportChange = useCallback(
    (viewportState: any) => {
      const { latitude, longitude } = viewportState;
      if (longitude === '' || latitude === '') {
        return setViewport(initialMapView);
      }
      setViewport({ ...viewportState });
    },
    [longitudeInitial, latitudeInitial],
  );

  const handleChangeMapInfo = ({ ac }) => {
    const { value } = ac;
    setIsClickedOnMap(false);
    handleInvalidData(value);
    value === '' && setInputValue('');
    const countries = value && value?.context?.find((context) => context.id.includes('country'));
    const cityChosen = value && value?.context?.find((context) => context.id.includes('place'));
    const postCode = value && value?.context?.find((context) => context.id.includes('postcode'));
    const country = value
      ? countriesOptions?.find((country) => country?.name === countries?.text)
      : null;
    const streetMatching = value && value?.matching_text;
    const streetText = value && value?.text && translit(value?.text);
    const countrySr = value && translit(countries?.text_sr);
    const countryHr = value && translit(countries?.text_hr);
    const street = value && (streetMatching || streetText);
    const newData = {
      ...formData,
      chosenLocation: value,
      street,
      streetNumber: value?.address,
      latitude: value && value?.center[SECOND_MAP_ITEM],
      longitude: value && value?.center[FIRST_MAP_ITEM],
      zipCode: value && postCode?.text,
      city:
        value &&
        citiesOptions?.find(
          (city) =>
            city?.name === translit(cityChosen?.text_sr) ||
            city?.name === cityChosen?.text_en ||
            city?.name === cityChosen?.text_hr,
        ),
      country: value && {
        ...country,
        name: handleCountryDisplay(language, country, countrySr, countryHr),
      },
    };
    value &&
      setCoordinates({
        longitude: value?.center[FIRST_MAP_ITEM],
        latitude: value?.center[SECOND_MAP_ITEM],
      });
    setFormData(newData);
    setInitialLocation(true);
    setAddMarker(true);
    handleViewportChange({
      ...viewport,
      latitude: value && value?.center[SECOND_MAP_ITEM],
      longitude: value && value?.center[FIRST_MAP_ITEM],
      zoom: 14,
    });
  };

  const categoryOptions = useMemo(() => {
    return placeCategories?.map((placeCategory) => {
      const categoryName = getNameTranslation(placeCategory);
      return {
        uuid: placeCategory.category,
        name: categoryName,
      };
    });
  }, [placeCategories]);

  const countriesOptions = useMemo(() => {
    return countries?.map((el) => {
      return {
        ...el,
        uuid: el?.uuid,
        name: el?.shortName,
      };
    });
  }, [countries]);

  const companiesOptions = useMemo(() => {
    return companies?.content.map((el) => {
      return {
        ...el,
        uuid: el?.uuid,
        name: el?.name,
        searchName: el?.name + ' ' + el?.uuid,
      };
    });
  }, [companies, countriesOptions]);

  const citiesOptions = useMemo(() => {
    return cities?.map((city) => {
      return {
        ...city,
        uuid: city?.uuid,
        name: city?.name,
      };
    });
  }, [cities]);

  useEffect(() => {
    if (placeId) {
      fetchPlaceById({ id: placeId }, true);
    } else {
      fetchAllCompanies();
    }
  }, []);

  useEffect(() => {
    if (placeId && place) fetchCompanyById(companyId, true);
  }, [place]);

  useEffect(() => {
    if (isSuccessEdit) initialFormValues();
  }, [isSuccessEdit]);

  const handleChangedClickedInfo = (data: MapData) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    addMarker &&
      isClickedOnMap &&
      handleChangedClickedInfo({
        street: mapStreet,
        streetNumber: mapStreetNumber,
        zipCode: mapZipCode,
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
        chosenLocation,
        country:
          mapCountryEn && countriesOptions?.find((country) => country?.name === mapCountryEn),
        city: citiesOptions?.find((city) => city?.name === citySr),
      });
  }, [coordinates, addMarker, mapClickedData]);
  const cityPlace = formData?.city as CityDTO;
  const chosenCity = citiesOptions?.find((city) => city?.name === cityPlace?.name);

  useEffect(() => {
    if (!chosenCity && !isFetching && isClickedOnMap) {
      displayWarning({ message: t('place.invalidCity') });
      return;
    } else if (!formData?.streetNumber && !isFetching && isClickedOnMap) {
      displayWarning({ message: t('place.invalidAddress') });
      return;
    }
    longitude && latitude
      ? setViewport({ ...initialMapView, zoom: 14 })
      : setViewport({
          ...initialMapView,
          longitude: LONGITUDE_INITIAL,
          latitude: LATITUDE_INITIAL,
        });
  }, [formData]);

  const handleMapActions = (event: any, geolocate: boolean) => {
    setIsClickedOnMap(true);
    setInitialLocation(true);
    !addMarker && setAddMarker(true);
    geolocate
      ? setCoordinates({ longitude: event?.coords?.longitude, latitude: event?.coords?.latitude })
      : setCoordinates({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });
    handleViewportChange({
      ...viewport,
      longitude: geolocate ? event?.coords?.longitude : event.lngLat.lng,
      latitude: geolocate ? event?.coords?.latitude : event.lngLat.lat,
    });
  };

  const initialFormValues = () => {
    const companyName = placeId ? company?.name : findCompany(companyId, companies?.content);
    const country = findCountry(place?.country, countries);
    const cityPlace = place?.city as string;
    const city = findCity(cityPlace, cities);
    const locationName = `${place?.street}, ${place?.streetNumber}, ${place?.zipCode}, ${city?.name}, ${country?.name} `;
    const chosenLocation = {
      uuid: place?.street,
      name: locationName,
    };
    const placeCategory = placeCategories?.find((el) => el?.category === place?.category);
    setFormData({
      ...place,
      company: companyName,
      country,
      city,
      category: {
        ...placeCategory,
        name: getNameTranslation(placeCategory),
        uuid: placeCategory?.category,
      },
      mondayFrom: place?.workingHours?.mondayFrom,
      mondayTo: place?.workingHours?.mondayTo,
      tuesdayFrom: place?.workingHours?.tuesdayFrom,
      tuesdayTo: place?.workingHours?.tuesdayTo,
      wednesdayFrom: place?.workingHours?.wednesdayFrom,
      wednesdayTo: place?.workingHours?.wednesdayTo,
      thursdayFrom: place?.workingHours?.thursdayFrom,
      thursdayTo: place?.workingHours?.thursdayTo,
      fridayFrom: place?.workingHours?.fridayFrom,
      fridayTo: place?.workingHours?.fridayTo,
      saturdayFrom: place?.workingHours?.saturdayFrom,
      saturdayTo: place?.workingHours?.saturdayTo,
      sundayFrom: place?.workingHours?.sundayFrom,
      sundayTo: place?.workingHours?.sundayTo,
      chosenLocation,
      minimumOrderAmountRoot: place?.minimumOrderAmount,
    });
  };

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedCompanyData = transformToDTOFormatDisabled(formData);
    const placeCategory = transformedCompanyData?.category as OptionsType;
    const minimumOrderAmountRoot = transformedCompanyData?.minimumOrderAmount;
    const { chosenLocation, ...transformedPlaceData } = transformedCompanyData;
    const trimmedData = trimObjectValues<FormDataTypePlace>(transformedPlaceData);

    const disabled =
      _.isEqual(
        {
          ...trimmedData,
          category: placeCategory,
          company: place?.company,
          country: place?.country,
        },
        { ...place, minimumOrderAmountRoot: minimumOrderAmountRoot },
      ) && file === null;
    return disabled;
  }, [formData, file, place]);

  const mapData = {
    addMarker,
    latitudeMap,
    longitudeMap,
  };

  return {
    isSuccess,
    shouldDisableSubmitButton,
    companiesOptions,
    countriesOptions,
    citiesOptions,
    isSuccessCompanies,
    coordinates,
    mapData,
    handleMapActions,
    fetchPlaceById,
    isSuccessEdit,
    handleMapInputChange,
    mapOptions,
    handleChangeMapInfo,
    isFetching,
    placeCategoriesOptions: categoryOptions,
    isSuccessAdd,
    place,
    isFetchingLocation,
    handleViewportChange,
    mapViewport: viewport,
    isFetchingPlace,
  };
};
