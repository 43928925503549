import { ACButton } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_CODE } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { authCode } from 'features/auth/constants/StyleConstants';
import { useChangePhoneNumberCodeMutation } from 'features/user/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';

import styles from './ChangePhoneNumber.module.scss';
import { styleButton } from './ChangePhoneNumberRequest';

const CODE_INPUTS = 6;

export type ChangePhoneNumberCodeProps = {
  setIsRequestDone: (value: boolean) => void;
};

const ChangePhoneNumberCode = ({ setIsRequestDone }: ChangePhoneNumberCodeProps) => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [changePhoneNumberCode] = useChangePhoneNumberCodeMutation();

  const [phoneCode, setPhoneCode] = useState('');

  const handleSubmit = async () => {
    try {
      const phoneNumberChangeDTO = {
        code: phoneCode.toString(),
      };
      await changePhoneNumberCode(phoneNumberChangeDTO).unwrap();
      setIsRequestDone(false);
      displaySuccess({ key: 'changePhoneNumber.phoneNumberChanged' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const handleCancelCode = () => {
    setIsRequestDone(false);
  };

  const handlePhoneCodeChange = (value: string) => {
    setPhoneCode(value);
  };

  return (
    <>
      <hr />
      <form className={styles.phoneNumberForm}>
        <h5 className={styles.titlePhoneNumber}>{t('phoneNumber.codeTitle')}</h5>
        <p className={styles.description}>{t('phoneNumber.codeDescription')}</p>
        <div data-testid='input'>
          <ReactCodeInput
            data-testid='inputCode'
            fields={CODE_INPUTS}
            inputMode='numeric'
            inputStyle={authCode}
            name={FORM_ELEMENT_CODE}
            onChange={(value) => handlePhoneCodeChange(value)}
            type='text'
            value={phoneCode}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <ACButton
            className={styles.buttonWrapper}
            dataTestid='ButtonVerifyCode'
            fullWidth
            onClick={handleSubmit}
            style={style.Button}
          >
            {t('phoneNumber.submit')}
          </ACButton>
          <ACButton
            className={styles.buttonWrapper}
            dataTestid='cancelCode'
            fullWidth
            onClick={handleCancelCode}
            style={styleButton}
          >
            {t('phoneNumber.cancel')}
          </ACButton>
        </div>
      </form>
    </>
  );
};

export default ChangePhoneNumberCode;
