import { ACButton, ACTimePicker, ACToolTip } from '@antech-consulting/components';
import { BaseFormProps } from 'constants/TypeConstants';
import { WorkingHoursType } from 'features/place/services/updatePlace';
import { InfoCircle } from 'iconsax-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDown, ArrowUp } from 'react-ionicons';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { HandleChangeType } from '../../../../hooks/useForm';
import styles from './WorkingHoursForm.module.scss';

type BaseFormWorkingHoursProps = Omit<BaseFormProps<void>, 'formData'> & {
  headerPrimaryText: string;
  headerSecondaryText: string;
  hours: WorkingHoursType;
};

const ACToolTipInitialAvailableStyle = { width: '420px', zIndex: 1 };

const styledTimePickerIcon = {
  container: { width: '200px', margin: '5px 0' },
  input: {
    addonWrapperAfter: {
      backgroundColor: 'var(--text-silver)',
      border: '1px solid var(--text-siler',
      borderRadius: '0 5px 5px 0',
    },
  },
  digitalHourWrap: { width: '50px' },
  digitalHour: { width: '50px' },
  digitalMinuteWrap: { width: '50px' },
  digitalMinute: { width: '50px' },
};

const buttonStyle = {
  backgroundColor: 'var(--text-silver)',
  padding: '10px 10px 5px 10px',
};

const WorkingHoursForm = ({
  errors,
  handleChange,
  headerPrimaryText,
  headerSecondaryText,
  hours,
}: BaseFormWorkingHoursProps) => {
  const { t } = useTranslation();
  const [isHoursOpened, setIsHoursOpened] = useState(true);

  const handleOpenWorkingHours = () => {
    setIsHoursOpened(!isHoursOpened);
  };

  return (
    <>
      <div className={styles.workingHoursHeader}>
        <ACToolTip
          label={t('product.pickupHoursTooltip')}
          position='bottomLeft'
          style={ACToolTipInitialAvailableStyle}
        >
          <InfoCircle />
        </ACToolTip>
        <FormHeader primaryText={headerPrimaryText} secondaryText={headerSecondaryText} />
        {!isHoursOpened ? (
          <div className={styles.arrowButton}>
            <ACButton
              dataTestid='arrowDownHours'
              onClick={handleOpenWorkingHours}
              style={buttonStyle}
            >
              <ArrowDown color='white' />
            </ACButton>
          </div>
        ) : (
          <div className={styles.arrowButton}>
            <ACButton
              dataTestid='arrowUpHours'
              onClick={handleOpenWorkingHours}
              style={buttonStyle}
            >
              <ArrowUp color='white' />
            </ACButton>
          </div>
        )}
      </div>
      {isHoursOpened && (
        <>
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.monday')}</div>
            <ACTimePicker
              name='mondayFrom'
              onChange={(value, event: HandleChangeType) => {
                handleChange(event);
              }}
              style={styledTimePickerIcon}
              value={hours?.mondayFrom}
            />
            <ACTimePicker
              name='mondayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.mondayTo}
            />
          </div>
          {errors?.mondayFrom && <div className={styles.errorMessages}>{errors?.mondayFrom}</div>}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.tuesday')}</div>
            <ACTimePicker
              name='tuesdayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.tuesdayFrom}
            />
            <ACTimePicker
              name='tuesdayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.tuesdayTo}
            />
          </div>
          {errors?.tuesdayFrom && <div className={styles.errorMessages}>{errors?.tuesdayFrom}</div>}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.wednesday')}</div>
            <ACTimePicker
              name='wednesdayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.wednesdayFrom}
            />
            <ACTimePicker
              name='wednesdayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.wednesdayTo}
            />
          </div>
          {errors?.wednesdayFrom && (
            <div className={styles.errorMessages}>{errors?.wednesdayFrom}</div>
          )}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.thursday')}</div>
            <ACTimePicker
              name='thursdayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.thursdayFrom}
            />
            <ACTimePicker
              name='thursdayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.thursdayTo}
            />
          </div>
          {errors?.thursdayFrom && (
            <div className={styles.errorMessages}>{errors?.thursdayFrom}</div>
          )}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.friday')}</div>
            <ACTimePicker
              name='fridayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.fridayFrom}
            />
            <ACTimePicker
              name='fridayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.fridayTo}
            />
          </div>
          {errors?.fridayFrom && <div className={styles.errorMessages}>{errors?.fridayFrom}</div>}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.saturday')}</div>
            <ACTimePicker
              name='saturdayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.saturdayFrom}
            />
            <ACTimePicker
              name='saturdayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.saturdayTo}
            />
          </div>
          {errors?.saturdayFrom && (
            <div className={styles.errorMessages}>{errors?.saturdayFrom}</div>
          )}
          <div className={styles.workingHours}>
            <div className={styles.workDay}>{t('place.sunday')}</div>
            <ACTimePicker
              name='sundayFrom'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.sundayFrom}
            />
            <ACTimePicker
              name='sundayTo'
              onChange={(value, event: HandleChangeType) => handleChange(event)}
              style={styledTimePickerIcon}
              value={hours?.sundayTo}
            />
          </div>
          {errors?.sundayFrom && <div className={styles.errorMessages}>{errors?.sundayFrom}</div>}
        </>
      )}
    </>
  );
};

export default WorkingHoursForm;
