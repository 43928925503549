import { ACAutocompleteV2, ACButton, ACInput } from '@antech-consulting/components';
import {
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_PHONE_NUMBER,
  FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { FormDataTypePhone } from 'features/user/constants/ChangePhoneNumber';
import { ErrorsType, HandleChangeType } from 'hooks/useForm';
import { useTranslation } from 'react-i18next';
import { useGetCountryCodesQuery } from 'services';

import styles from './ChangePhoneNumber.module.scss';

const styleComponent = {
  ACAutocomplete: {
    wrapper: {
      marginRight: '20px',
    },
    inputWrapper: {
      backgroundColor: 'var(--background)',
      height: '50px',
      border: '1px solid #d9d9d9',
    },
  },
  ACInputStyle: {
    wrapper: {
      height: '50px',
    },
    affixWrapper: {
      borderRadius: '8px',
      height: '50px',
    },
  },
};

export const styleButton = {
  backgroundColor: 'var(--primary)',
  color: 'var(--text-primary)',
};

export type ChangePhoneNumberRequestProps = {
  errors: ErrorsType;
  formData: FormDataTypePhone;
  handleChangeFormData: (event: HandleChangeType) => void;
  handleSubmit: () => void;
};

const ChangePhoneNumberRequest = ({
  errors,
  formData,
  handleChangeFormData,
  handleSubmit,
}: ChangePhoneNumberRequestProps) => {
  const { t } = useTranslation();
  const { data: countryCodes, isSuccess } = useGetCountryCodesQuery();
  const { phoneNumber, phoneNumberCountryCode } = formData;
  const countryCodeOptions = isSuccess ? countryCodes : [];
  const CustomLabel = (country) => {
    return (
      <div className={styles.options}>
        <span className={styles.name}>(+{country?.code})</span>
        <div className={styles.name}>{country?.label}</div>
      </div>
    );
  };

  return (
    <>
      <hr />
      <form className={styles.phoneNumberForm}>
        <h5 className={styles.titlePhoneNumber}>{t('phoneNumber.title')}</h5>
        <p className={styles.description}>{t('phoneNumber.description')}</p>
        <div className={styles.phoneNumber}>
          <ACAutocompleteV2
            closeOnSelect
            dataTestId='callingCode'
            errorMessage={errors?.phoneNumberCountryCode}
            fullWidth
            getCustomRender={(country) => CustomLabel(country)}
            getOptionLabel={(options) => {
              return options?.codeLabel;
            }}
            hasError={!!errors?.phoneNumberCountryCode}
            id={FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE}
            label={t('phoneNumber.callingCode')}
            name={FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE}
            onChange={(event) => {
              handleChangeFormData(event);
            }}
            options={countryCodeOptions}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={styleComponent.ACAutocomplete}
            value={phoneNumberCountryCode}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='phoneNumber'
            errorMessage={errors?.phoneNumber}
            fullWidth
            hasError={!!errors?.phoneNumber}
            id={FORM_ELEMENT_PHONE_NUMBER}
            label={t('phoneNumber.phoneNumber')}
            name={FORM_ELEMENT_PHONE_NUMBER}
            onChange={handleChangeFormData}
            rounded={AC_INPUT_ROUND}
            style={styleComponent.ACInputStyle}
            type='text'
            value={phoneNumber}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <ACButton
            className={styles.buttonWrapper}
            dataTestid='submitPhoneNumber'
            fullWidth
            onClick={handleSubmit}
            style={style.Button}
          >
            {t('phoneNumber.sendSms')}
          </ACButton>
        </div>
      </form>
    </>
  );
};

export default ChangePhoneNumberRequest;
