import { FORM_ELEMENT_NAME, FORM_ELEMENT_STATUS } from 'constants/FormConstants';
import { TABLE_ELEMENT_CREATED_AT, TABLE_ELEMENT_CREATED_BY } from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const productCategoryRequestTableSettings: TableSettingsType = {
  initialRowsNumber: 10,
  dataTestId: 'ProductCategoryRequestTable',
  filter: [
    {
      field: FORM_ELEMENT_NAME,
      title: 'productCategoryRequests.nameColumn',
      filterType: 'text',
    },
  ],
  columns: [
    {
      title: 'productCategoryRequests.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'productCategoryRequests.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
    {
      title: 'productCategoryRequests.status',
      field: FORM_ELEMENT_STATUS,
    },
  ],
  disableSorting: { columns: [TABLE_ELEMENT_CREATED_BY] },
};

export default productCategoryRequestTableSettings;
