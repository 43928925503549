import ForgotPasswordSuccess from 'features/auth/components/ForgotPasswordSucces/ForgotPasswordSuccess';
import ChangePhoneNumber from 'pages/ChangePhoneNumber/ChangePhoneNumber';
import { Navigate, Route, Routes } from 'react-router-dom';

import RegistrationSuccess from '../features/auth/components/RegistrationSuccess/RegistrationSuccess';
import VerifyEmail from '../features/auth/components/VerifyEmail/VerifyEmail';
import VerifyPhoneNumber from '../features/auth/components/VerifyPhoneNumber/VerifyPhoneNumber';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import Registration from '../pages/Registration/Registration';

const AuthRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<Login />} path='/' />
        <Route element={<VerifyPhoneNumber />} path='/verify-phone-number' />
        <Route element={<RegistrationSuccess />} path='/registration-success' />
        <Route element={<RegistrationSuccess />} path='/verify-email' />
        <Route element={<VerifyEmail />} path='/verify-email/:id' />
        <Route element={<Registration />} path='/registration' />
        <Route element={<ForgotPassword />} path='/forgot-password' />
        <Route element={<Navigate replace to='/' />} path='*' />
        <Route element={<ForgotPasswordSuccess />} path='/forgot-password-success' />
        <Route element={<ChangePhoneNumber />} path='/change-phone-number' />
      </Routes>
    </>
  );
};

export default AuthRouter;
