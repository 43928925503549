import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { FORM_ELEMENT_PLACE } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import {
  DEFAULT_SORT,
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_STATUSES,
} from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { PENDING } from 'features/orders/constants/TableConstants';
import { Order } from 'features/orders/services/getOrders';
import { useLazyGetPlacesRecursiveQuery } from 'features/place/services';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useTable from 'hooks/useTable';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/store';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { getStatusColor } from '../../../../utils/getColorStatus';
import {
  transformACState,
  transformtextCaseToCamelCase,
  transformTextToSnakeCase,
} from '../../../../utils/helpers';
import { useLazyGetOrdersQuery } from '../../services';
import ordersTableSettings, { ORDER_STATUS } from './ordersTableSettings';
import styles from './TableOrder.module.scss';

export type FilterProps = {
  code: string;
  from: string;
  statuses: string;
  to: string;
};

export type TableStatePropsOrder = TableStateProps<FilterProps> & {
  code: string;
  statuses: string;
  user?: string;
};

const TableOrder: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const roles = store.getState().auth.roles;
  const system = roles[ROLE_SYSTEM as never];
  const tableSettings = useTable(ordersTableSettings);
  const [fetchOrders, { data: orders, isFetching, isSuccess }] = useLazyGetOrdersQuery();
  const [fetchPlaces, { data: places, isSuccess: isSuccessPlaces }] =
    useLazyGetPlacesRecursiveQuery();

  useEffect(() => {
    if (orders && orders?.totalElements !== 0) {
      fetchPlaces();
    }
  }, [orders]);

  const ordersTableData = useMemo(() => {
    return orders?.content?.map((order) => {
      const { status } = order;
      const styleColor: string = getStatusColor(status);
      return {
        rawData: order,
        render: {
          ...order,
          status: (
            <div className={`${styles[styleColor]} ${styles.status}`}>
              <span />
              <div>{t(`orders.${transformtextCaseToCamelCase(status)}`)}</div>
              <div className={styles.seenInfo}>
                {system &&
                  order?.status === PENDING &&
                  order?.seen &&
                  '(' + t('orders.seenOrder') + ')'}
              </div>
            </div>
          ),
        },
      };
    });
  }, [orders?.content]);

  const handleChangeTableState = (tableState: TableStatePropsOrder) => {
    const copyTableState = {
      ...tableState,
      filter: {
        ...tableState?.filter,
        from: tableState?.filter?.from && tableState?.filter?.from?.concat('T00:00:01.000Z'),
        to: tableState?.filter?.to && tableState?.filter?.to?.concat('T23:59:59.000Z'),
      },
      sort: { ...tableState?.sort },
    };
    fetchOrders(transformACState(copyTableState, TABLE_ELEMENT_CREATED_AT));
  };

  const additionalFilters = [
    {
      field: TABLE_ELEMENT_STATUSES,
      filterType: 'selectMulti',
      title: t('orders.statusFilter'),
      filterSelectData: ORDER_STATUS.map((item) => {
        return {
          value: transformTextToSnakeCase(item),
          render: t(`orders.${transformtextCaseToCamelCase(item)}`),
        };
      }),
    },
    {
      field: FORM_ELEMENT_PLACE,
      filterType: 'selectSingle',
      title: t('orders.place'),
      filterSelectData: places?.content?.map((item) => {
        return {
          value: item?.uuid,
          render: item?.name,
        };
      }),
    },
  ];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: orders?.totalElements,
    isSuccess,
    filters: [...tableSettings.filter, ...additionalFilters],
  });

  const handleOnItemClicked = ({ rowData }: { rowData: Order }) => {
    const { uuid } = rowData;
    navigate(`/order/${uuid}`);
  };

  return (
    <ACLoading
      isLoading={isFetching || !isSuccess}
      loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
    >
      <StyledACTable
        {...tableSettings}
        allItemsCount={orders?.totalElements}
        defaultSort={DEFAULT_SORT}
        filter={isSuccessPlaces ? filtersToDisplay : null}
        headerTitle={t('orders.title')}
        onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
        onTableStateChange={handleChangeTableState}
        pagesCount={orders?.totalPages}
        rows={ordersTableData}
      />
    </ACLoading>
  );
};

export default TableOrder;
