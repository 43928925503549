import { ACSpinner } from '@antech-consulting/components';
import { FILE } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/store';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/place/components/BaseForm/BaseForm';
import WorkingHoursForm from '../../features/place/components/WorkingHoursForm/WorkingHoursForm';
import {
  formDataInitialState,
  FormDataTypePlace,
} from '../../features/place/constants/FormConstants';
import placeSchema from '../../features/place/constants/PlaceSchema';
import { transformToDTOFormat, usePlacesForm } from '../../features/place/hooks/usePlacesForm';
import { useCreatePlaceMutation, useUploadPlaceImageMutation } from '../../features/place/services';
import { NewPlace } from '../../features/place/services/createPlace';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useForm } from '../../hooks/useForm';

const AddPlace = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createPlace, { isLoading: isLoadingAddPlace }] = useCreatePlaceMutation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [uploadDocument] = useUploadPlaceImageMutation();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataTypePlace>(formDataInitialState, placeSchema);
  const [file, error, handleFileChange, lastUploadAction] = useFileUpload();
  const roles = store.getState().auth.roles;
  const system = roles[ROLE_SYSTEM as never];

  const {
    citiesOptions,
    companiesOptions,
    coordinates,
    handleChangeMapInfo,
    handleMapActions,
    handleMapInputChange,
    handleViewportChange,
    isFetching,
    isFetchingLocation,
    isSuccessAdd,
    mapData,
    mapOptions,
    mapViewport,
    place,
    placeCategoriesOptions,
  } = usePlacesForm(formData, setFormData, file);
  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataTypePlace>(formData);
    const PlaceData = Object.fromEntries(
      Object.entries(trimmedData).filter(([_, v]) => v !== ''),
    ) as FormDataTypePlace;
    const placeAddDTO: NewPlace = transformToDTOFormat({
      ...PlaceData,
      minimumOrderAmount: system
        ? trimmedData?.minimumOrderAmountRoot
        : trimmedData?.minimumOrderAmount,
    });
    delete placeAddDTO.lastStockChangeAt;
    delete placeAddDTO.lastOrderAt;
    try {
      const response = await createPlace(placeAddDTO).unwrap();
      if (response && file) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        uploadDocument({
          file: dataImage,
          id: response?.uuid,
        });
        if (isEqual(placeAddDTO?.document, place?.document)) {
          navigate('/places?value=2');
        }
      }
      navigate('/places?value=2');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'place.createPlace' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const handleSubmitPlace = () => {
    system && delete formData.minimumOrderAmount;
    !system && delete formData.minimumOrderAmountRoot;
    !system && delete formData.fee;
    handleSubmit(onSubmit);
  };

  const onDiscard = () => {
    navigate('/places');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return isSuccessAdd && !isLoadingAddPlace ? (
    <>
      <PageHeader primaryText={t('place.titleCreate')} />
      {isSuccessAdd && (
        <>
          <BaseForm
            cities={citiesOptions}
            companies={companiesOptions}
            coordinates={coordinates}
            errors={errors}
            formData={formData}
            handleChange={handleChangeFormData}
            handleFileChange={handleFileChange}
            handleFileDelete={lastUploadAction}
            handleMapActions={handleMapActions}
            handleMapInputChange={handleMapInputChange}
            handleSearchMap={handleChangeMapInfo}
            handleViewportChange={handleViewportChange}
            isFetching={isFetching}
            isFetchingLocation={isFetchingLocation}
            mapData={mapData}
            mapOptions={mapOptions}
            mapViewport={mapViewport}
            placeCategoriesOptions={placeCategoriesOptions}
            setFormData={setFormData}
            system={system}
          />
          <WorkingHoursForm
            errors={errors}
            handleChange={handleChangeFormData}
            headerPrimaryText={t('form.place.workingHours')}
            headerSecondaryText={t('form.place.addWorkingHours')}
            hours={formData?.workingHours}
          />
        </>
      )}
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={handleSubmitPlace}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddPlace;
