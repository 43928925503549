export type CountryCodeType = {
  name: string;
  value: string;
};

export const formDataInitialStatePhone: FormDataTypePhone = {
  phoneNumber: '',
  phoneNumberCountryCode: '',
};

export type FormDataTypePhone = {
  phoneNumber: string;
  phoneNumberCountryCode: CountryCodeType | string;
};
