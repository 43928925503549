import { FormValidationType } from '../../../hooks/useForm';

export const validationSchemaHourly: FormValidationType = {
  from: {
    isRequired: true,
    isDateCurrentOrBefore: true,
  },
  interval: {
    isRequired: true,
  },
};
