import { CSSProperties } from 'styled-components';

export const authForm: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
};

export const authTitle: CSSProperties = {
  fontSize: 'var(--font-size-header)',
  fontWeight: '600',
  lineHeight: '2rem',
  marginTop: '30px',
};

export const authDescription: CSSProperties = {
  fontSize: 'var(--font-size-label)',
  marginTop: '8px',
  marginBottom: '8px',
  color: 'var(--text-transparent)',
  lineHeight: '20px',
};

export const authTextField: CSSProperties = {
  marginTop: '24px',
  color: 'var(--text-primary)',
};

export const authIcon: CSSProperties = {
  width: '90px',
  height: '90px',
  color: 'var(--text-secondary)',
};

export const authImage: CSSProperties = {
  textAlign: 'center',
};

export const authCode: CSSProperties = {
  width: '50px',
  borderRadius: '6px',
  border: '1px solid lightgrey',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
  margin: '4px',
  paddingLeft: '16px',
  height: '45px',
  fontSize: '30px',
  color: 'var(--text-primary)',
};

export const authDescriptionRegister: CSSProperties = {
  ...authDescription,
  textAlign: 'center',
};

export const authTitleRegister: CSSProperties = {
  ...authTitle,
  textAlign: 'center',
};
