import { ACButton } from '@antech-consulting/components';
import { INCLUDE_EMPLOYEES } from 'features/place/constants/PlaceContants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '../../../../constants/Errors/ErrorTypes';
import { style } from '../../../../constants/StyleConstants';
import { useForm } from '../../../../hooks/useForm';
import { transformRolesToSnakeCase, trimObjectValues } from '../../../../utils/helpers';
import { FormDataEmployeeType, formDataInitialStateEmployee } from '../../constants/FormConstants';
import { placeEmployeeSchema } from '../../constants/PlaceSchema';
import { useAddPlaceEmployeeMutation } from '../../services';
import BaseForm from './BaseForm';
import styles from './PlaceEmployees.module.scss';

export type AddEmployeeProps = {
  placeId: string;
  refetchItem?: (uuid: object) => void;
  setIsAddDialogOpen: (boolean: boolean) => void;
};

const AddEmployee = ({ placeId, refetchItem, setIsAddDialogOpen }: AddEmployeeProps) => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const [addEmployee] = useAddPlaceEmployeeMutation();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataEmployeeType>(
    formDataInitialStateEmployee,
    placeEmployeeSchema,
  );

  const onDiscard = () => {
    setIsAddDialogOpen(false);
  };

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataEmployeeType>(formData);
    const addEmployeeData = { ...trimmedData, role: transformRolesToSnakeCase(formData?.role) };
    try {
      await addEmployee({ id: placeId, employee: addEmployeeData }).unwrap();
      setIsAddDialogOpen(false);
      displaySuccess({ key: 'place.addPlaceEmployee' });
      if (refetchItem !== undefined) {
        refetchItem({ id: placeId, [INCLUDE_EMPLOYEES]: 'true' });
      }
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      setIsAddDialogOpen(false);
      displayError({ key: code });
    }
  };

  return (
    <div>
      <BaseForm errors={errors} formData={formData} handleChange={handleChangeFormData} />
      <div className={styles.buttonsWrapper}>
        <ACButton
          dataTestid='buttonSubmitEmployee'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
          type='submit'
        >
          {t('place.add')}
        </ACButton>
        <ACButton
          dataTestid='buttonCancelEmployeeDiscard'
          fullWidth
          onClick={onDiscard}
          style={style.ACButton}
          type='button'
        >
          {t('place.discard')}
        </ACButton>
      </div>
    </div>
  );
};

export default AddEmployee;
