import { OptionsType } from 'constants/TypeConstants';

import { CountryOption } from '../../../services/country';

export const formDataInitialState: FormDataType = {
  idNumber: '',
  vatNumber: '',
  name: '',
  country: '',
  zipCode: '',
  city: '',
  street: '',
  streetNumber: '',
  floor: '' ?? 0,
  apartment: '' ?? 0,
  owner: '',
};

export type Owner = {
  uuid?: string;
};

export type FormDataType = {
  apartment?: number | string;
  city?: OptionsType | string;
  country: string | CountryOption;
  floor?: number | string;
  idNumber: string;
  name: string;
  owner: string | Owner;
  street: string;
  streetNumber: string;
  uuid?: string;
  vatNumber: string;
  zipCode: string;
};
