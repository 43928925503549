import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<SystemStatisticsResponse, StatisticsParameters>({
    query: ({ from, to }) => `/statistics/system?from=${from}&to=${to}`,
    providesTags: ['statistics'],
  });

export type StatisticsParameters = {
  from?: string;
  to?: string;
};

export type NumberOfAppUsersPerLoginType = {
  appUserLoginType: string;
  count: number;
};

export type BestCompaniesRevenues = {
  gross: number;
  name: string;
  net: number;
  uuid: string;
};

export type BestPlacesRevenues = {
  gross: number;
  name: string;
  net: number;
};

export type OrdersPriceDistribution = {
  count: number;
  from: number;
  index: number;
  to: number;
};

export type SystemStatisticsResponse = {
  averageOrderPrice: number;
  bestCompaniesRevenues: BestCompaniesRevenues[];
  bestPlacesRevenues: BestPlacesRevenues[];
  groupInterval: string;
  numberOfAppUsersPerLoginType: NumberOfAppUsersPerLoginType[];
  numberOfCompanies: number;
  numberOfPlaces: number;
  numberOfRegisteredAppUsers: number;
  numberOfRegisteredPartnerUsers: number;
  numberOfVerifiedAppUsers: number;
  numberOfVerifiedPartnerUsers: number;
  ordersPriceDistribution: OrdersPriceDistribution[];
};
