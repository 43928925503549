import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { isString } from 'lodash';
import {
  TAG_APP_USER,
  TAG_APP_USERS,
  TAG_ARCHIVED_PLACES,
  TAG_ARCHIVED_PRODUCTS,
  TAG_BLOCKED_APP_USERS,
  TAG_BLOCKED_PARTNER_USERS,
  TAG_CITY,
  TAG_COMPANIES,
  TAG_COMPANY,
  TAG_COMPANY_REQUEST,
  TAG_COMPANY_REQUESTS,
  TAG_IN_PREPARATION_PLACES,
  TAG_INVENTORIES,
  TAG_INVENTORY,
  TAG_MAP_LOCATION,
  TAG_ORDER,
  TAG_PARTNER_APPLICATION_SETTINGS,
  TAG_PARTNER_USER,
  TAG_PARTNER_USERS,
  TAG_PLACE,
  TAG_PLACES,
  TAG_PRODUCT,
  TAG_PRODUCT_CATEGORIES,
  TAG_PRODUCT_CATEGORY,
  TAG_PRODUCT_CATEGORY_REQUEST,
  TAG_PRODUCT_CATEGORY_REQUESTS,
  TAG_PRODUCTS,
  TAG_UNVERIFIED_PLACES,
  TAG_USER,
  TAG_USER_APPLICATION_SETTINGS,
  TAG_USERS,
} from 'services/TagsConstants';
import { isRefreshTokenAboutToExpire } from 'utils/check-expiration-date';
import { encodeUrlParams } from 'utils/helpers';

import Config from '../config/index';
import { CurrentUser, logout, setCurrentUser } from '../store/Auth';
import { RootState } from '../store/store';

const routesWithoutHeaders = [
  '/countries',
  '/countries/calling-code',
  '/system/partner-application-setting',
];

const spliceErrorCode = (errorCode: string) => {
  return errorCode?.split('.')[0];
};

const baseQueryWithHeaders = fetchBaseQuery({
  baseUrl: Config.API_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken: string = (getState() as RootState).auth.accessToken;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithoutHeaders = fetchBaseQuery({
  baseUrl: Config.API_URL,
});

export type ServerErrorType = { data: { code: string; message: string }; status: number };

const mutex = new Mutex();

const baseQuery = (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  shouldIncludeHeaders: boolean,
) => {
  const encodedArgs = encodeUrlParams(args as string);
  return shouldIncludeHeaders
    ? baseQueryWithHeaders(encodedArgs, api, extraOptions)
    : baseQueryWithoutHeaders(encodedArgs, api, extraOptions);
};

const AUTH_TOKEN_EXPIRED = 'G012';

const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  const shouldIncludeHeaders = !routesWithoutHeaders.some((routeWithoutHeaders) => {
    const currentRoute = isString(args) ? args : args.url;
    return currentRoute.startsWith(routeWithoutHeaders);
  });

  let result = await baseQuery(args, api, extraOptions, shouldIncludeHeaders);
  const statusCode = result?.error?.status;

  const errorCode = (result?.error as ServerErrorType)?.data?.code;

  if (!mutex.isLocked()) {
    const AsyncStorageState = api.getState() as RootState;
    const refreshToken: string = AsyncStorageState.auth.refreshToken;
    if (
      refreshToken &&
      isRefreshTokenAboutToExpire(AsyncStorageState?.auth?.refreshTokenExpireDate)
    ) {
      const release = await mutex.acquire();
      const refreshResult = await baseQueryWithoutHeaders(
        {
          url: '/token/refresh-refresh-token/partner-user',
          method: 'POST',
          body: { refreshToken: refreshToken },
        },
        api,
        extraOptions,
      );
      if (refreshResult?.data) {
        const { data } = refreshResult;
        api.dispatch(setCurrentUser(data as CurrentUser));
        result = await baseQuery(args, api, extraOptions, shouldIncludeHeaders);
      } else {
        api.dispatch(logout());
      }
      release();
    }
  }
  if (statusCode === 403 && spliceErrorCode(errorCode) === AUTH_TOKEN_EXPIRED) {
    if (!mutex.isLocked()) {
      const currentAsyncStorageState = api.getState() as RootState;
      const release = await mutex.acquire();
      const refreshResult = await baseQueryWithoutHeaders(
        {
          url: '/token/refresh-access-token/partner-user',
          method: 'POST',
          body: { refreshToken: currentAsyncStorageState.auth.refreshToken },
        },
        api,
        extraOptions,
      );
      if (refreshResult?.data) {
        const { data } = refreshResult;
        api.dispatch(setCurrentUser(data as CurrentUser));
        result = await baseQuery(args, api, extraOptions, shouldIncludeHeaders);
      } else {
        api.dispatch(logout());
      }
      release();
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions, shouldIncludeHeaders);
    }
  }
  return result;
};

const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    TAG_USERS,
    TAG_USER,
    TAG_PARTNER_USERS,
    TAG_BLOCKED_PARTNER_USERS,
    TAG_PARTNER_USER,
    TAG_APP_USERS,
    TAG_BLOCKED_APP_USERS,
    TAG_APP_USER,
    TAG_COMPANIES,
    TAG_COMPANY,
    TAG_COMPANY_REQUESTS,
    TAG_COMPANY_REQUEST,
    TAG_PLACES,
    TAG_PLACE,
    TAG_IN_PREPARATION_PLACES,
    TAG_UNVERIFIED_PLACES,
    TAG_ARCHIVED_PLACES,
    TAG_PRODUCTS,
    TAG_ARCHIVED_PRODUCTS,
    TAG_PRODUCT,
    TAG_PRODUCT_CATEGORIES,
    TAG_PRODUCT_CATEGORY,
    TAG_PRODUCT_CATEGORY_REQUESTS,
    TAG_ORDER,
    TAG_INVENTORIES,
    TAG_INVENTORY,
    TAG_USER_APPLICATION_SETTINGS,
    TAG_PARTNER_APPLICATION_SETTINGS,
    TAG_PRODUCT_CATEGORY_REQUEST,
    TAG_CITY,
    TAG_MAP_LOCATION,
  ],
  endpoints: () => ({}),
});
export default api;
