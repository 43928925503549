import {
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_DESCRIPTION,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_PLACE,
  FORM_ELEMENT_SERIALNUMBER,
} from 'constants/FormConstants';
import { TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import { TABLE_INVENTORY_INITIAL_ROWS_NUMBER } from 'features/inventory/constants/FormConstants';
import { TableSettingsType } from 'hooks/useTable';
import { Edit2, Trash } from 'iconsax-react';

const { DELETE, EDIT } = tableActionTypes;

const inventoryTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INVENTORY_INITIAL_ROWS_NUMBER,
  dataTestId: 'inventoryTable',
  actions: [
    {
      header: false,
      name: EDIT,
      icon: <Edit2 data-testid='editButton' />,
      tooltip: 'inventory.titleUpdate',
      redirect: '/inventory',
    },
    {
      redirect: 'modal',
      header: false,
      name: DELETE,
      icon: <Trash data-testid='deleteButton' />,
      tooltip: 'inventory.titleDelete',
      tooltipStyle: { left: '15px' },
    },
  ],
  filter: [
    {
      field: FORM_ELEMENT_NAME,
      title: 'inventory.nameColumn',
      filterType: 'text',
    },
    {
      field: FORM_ELEMENT_SERIALNUMBER,
      title: 'inventory.serialNumberColumn',
      filterType: 'text',
    },
  ],
  columns: [
    {
      title: 'inventory.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'inventory.descriptionColumn',
      field: FORM_ELEMENT_DESCRIPTION,
    },
    {
      title: 'inventory.serialNumberColumn',
      field: FORM_ELEMENT_SERIALNUMBER,
    },
    {
      title: 'inventory.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
    {
      title: 'inventory.country',
      field: FORM_ELEMENT_COUNTRY,
    },
    {
      title: 'inventory.placeColumn',
      field: FORM_ELEMENT_PLACE,
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_PLACE] },
};

export default inventoryTableSettings;
