import { ACButton } from '@antech-consulting/components';
import {
  authDescriptionRegister,
  authForm,
  authIcon,
  authImage,
  authTitleRegister,
} from 'features/auth/constants/StyleConstants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { Sms } from 'iconsax-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ErrorMessage } from '../../../../constants/Errors/ErrorTypes';
import { style } from '../../../../constants/StyleConstants';
import { setCurrentUser } from '../../../../store/Auth';
import { useIssueSmsCodeMutation, useVerifyEmailMutation } from '../../services/index';
import styles from './VerifyEmail.module.scss';

const VerifyEmail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [verifyEmail] = useVerifyEmailMutation();
  const [issueSmsCode] = useIssueSmsCodeMutation();

  const handleVerifyEmail = async () => {
    try {
      const userData = await verifyEmail({
        token: id,
      }).unwrap();
      dispatch(setCurrentUser(userData));
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  const onSubmit = async () => {
    try {
      await issueSmsCode().unwrap();
      navigate('/verify-phone-number');
      displaySuccess({ key: 'sms.smsCodeIssue' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };
  return (
    <div style={authForm}>
      <div style={authImage}>
        <Sms style={authIcon} />
      </div>
      <h3 style={authTitleRegister}>{t('verifyEmail.title')}</h3>
      <p style={authDescriptionRegister}>{t('verifyEmail.description')}</p>
      <ACButton
        className={styles.buttonWrapper}
        dataTestid='ButtonVerifyEmail'
        fullWidth
        onClick={onSubmit}
        style={style.Button}
      >
        {t('verifyEmail.button')}
      </ACButton>
    </div>
  );
};

export default VerifyEmail;
