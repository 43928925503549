import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_EMAIL } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { PartnerUser } from 'features/partnerUser/services/getPartnerUsers';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { InfoCircle, UserTick } from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { transformACState } from '../../../../utils/helpers';
import {
  useLazyGetBlockedPartnerUsersQuery,
  useUnblockPartnerUserMutation,
} from '../../services/index';
import blockedPartnerUsersTableSettings from './blockedPartnerUsersTableSettings';

const TableBlockPartnerUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const tableSettings = useTable(blockedPartnerUsersTableSettings);
  const [selectedRow, setSelectedRow] = useState<PartnerUser>(null);
  const [isUnblockModalOpen, toggleUnblock] = useToggle();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);

  const system = roles[ROLE_SYSTEM as never];

  const [fetchBlockedPartnerUsers, { data: blockedPartnerUsers, isFetching }] =
    useLazyGetBlockedPartnerUsersQuery();
  const [unblockRequest, { isLoading }] = useUnblockPartnerUserMutation();

  const handleTableStateChange = (tableState: TableStateProps<void>) => {
    fetchBlockedPartnerUsers(transformACState(tableState, FORM_ELEMENT_EMAIL));
  };

  const partnerUsersTableData = useMemo(() => {
    return blockedPartnerUsers?.content?.map((partnerUser) => {
      return {
        rawData: partnerUser,
        render: partnerUser,
      };
    });
  }, [blockedPartnerUsers?.content]);

  const handleDetailView = ({ rowData }: { rowData: PartnerUser }) => {
    navigate(`/partner-user/${rowData?.uuid}`);
    setSelectedRow(rowData);
  };

  const handleUnblock = ({ rowData }: { rowData: PartnerUser }) => {
    toggleUnblock();
    setSelectedRow(rowData);
  };

  const unblockPartnerUser = {
    icon: <UserTick data-testid='unblockButton' />,
    tooltip: {
      text: t('partnerUsers.unblockUser'),
      style: {
        left: '5px',
      },
    },
    onClick: handleUnblock,
  };

  const detailViewPartnerUser = {
    icon: <InfoCircle data-testid='detailViewButton' />,
    tooltip: {
      text: t('partnerUsers.detailView'),
    },
    onClick: handleDetailView,
  };

  const additionalActions = system && [detailViewPartnerUser, unblockPartnerUser];

  const handelUnblockPartnerUser = async () => {
    try {
      await unblockRequest(selectedRow?.uuid).unwrap();
      toggleUnblock();
      displaySuccess({ key: t('blockModal.unblockModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleUnblock();
      displayError({ key: code });
    }
  };

  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <Divider />
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={blockedPartnerUsers?.totalElements}
          onItemClicked={(rowData) => handleDetailView({ rowData })}
          onTableStateChange={handleTableStateChange}
          pagesCount={blockedPartnerUsers?.totalPages}
          rows={partnerUsersTableData}
        />
      </ACLoading>
      {isUnblockModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleUnblock}
          disabled={isLoading}
          handleSubmit={handelUnblockPartnerUser}
          header={t('blockModal.titleUnblock')}
          icon={<UserTick style={style.BlueIconModal} />}
          isShow={isUnblockModalOpen}
          message={t('blockModal.descriptionUnblock')}
        />
      )}
    </>
  );
};
export default TableBlockPartnerUsers;
