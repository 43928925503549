import { Dispatch, SetStateAction, useEffect } from 'react';

import { FormDataType } from '../constants/UserAppSettingsFormConstants';
import { useGetUserApplicationSettingsQuery } from '../services';

export const useUserAppSettingsForm = (setFormData: Dispatch<SetStateAction<FormDataType>>) => {
  const { data, isSuccess } = useGetUserApplicationSettingsQuery();

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        ...data,
        placeDetail: data.imageQualitySettings.placeDetail,
        placeList: data.imageQualitySettings.placeList,
        productCategoryAll: data.imageQualitySettings.productCategoryAll,
        productDetail: data.imageQualitySettings.productDetail,
        productList: data.imageQualitySettings.productList,
        storyDetail: data.imageQualitySettings.storyDetail,
        storyDetailHeader: data.imageQualitySettings.storyDetailHeader,
        storyList: data.imageQualitySettings.storyList,
      });
    }
  }, [isSuccess]);

  return { data };
};
