import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  firstName: {
    isRequired: true,
  },
  lastName: {
    isRequired: true,
  },
};

export const validationPasswordSchema: FormValidationType = {
  currentPassword: {
    isRequired: true,
  },
  newPassword: {
    isRequired: true,
  },
  confirmPassword: {
    isRequired: true,
  },
};

export const validationPhoneSchema: FormValidationType = {
  phoneNumber: {
    isRequired: true,
    isPhoneNumber: true,
  },
  phoneNumberCountryCode: {
    isRequired: true,
  },
};

export const validationCodeSchema: FormValidationType = {
  codeOne: {
    isRequired: true,
  },
  codeTwo: {
    isRequired: true,
  },
  codeThree: {
    isRequired: true,
  },
  codeFour: {
    isRequired: true,
  },
  codeFive: {
    isRequired: true,
  },
  codeSix: {
    isRequired: true,
  },
};
