import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { Place } from 'features/place/services/createPlace';
import { TAG_ARCHIVED_PRODUCTS } from 'services/TagsConstants';
import { store } from 'store/store';

import { Country } from '../../../services/country';
import { createQueryString } from '../../../utils/helpers';
import { ProductCategory } from '../../productCategory/services/getProductCategories';
import { Product } from './getProducts';

const BASE_URL = '/products/archived';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Products, ProductParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { place } = _arg;
      const productResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const productsData = productResponse.data as Products;
      const placeResponse = await fetchWithBQ(`/place/${place}`);
      const placeData = placeResponse.data as Place;
      const documents = productsData.content.map((product: Product) => {
        return product?.document ? product?.document : null;
      });
      const documentIds = documents.filter((id) => id !== null);
      const documentResponse =
        documentIds?.length > 0
          ? await fetchWithBQ(`/documents/download-links?ids=${documentIds}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const countries = store.getState().globalData.countries;
      const products = productsData.content.map((product: Product) => {
        const { basePrice, discountedPrice } = product;
        const matchedCountry = countries.find(
          (country: Country) => country.uuid === placeData?.country,
        );
        const { isoCurrency } = matchedCountry;
        return {
          ...product,
          document: documentData ? documentData[product?.document] : null,
          basePrice: `${basePrice} ${isoCurrency}`,
          discountedPrice: `${discountedPrice} ${isoCurrency}`,
        };
      });
      return {
        data: {
          content: products,
          totalElements: productsData?.totalElements,
          totalPages: productsData?.totalPages,
          place: placeData?.name,
        },
      };
    },
    providesTags: [TAG_ARCHIVED_PRODUCTS],
  });

export type ProductParameters = {
  categoryId?: string;
  ids?: string;
  place?: string;
};

export type Products = {
  content: Product[];
  place?: string;
  totalElements: number;
  totalPages: number;
};

export type ProductCategories = {
  content: ProductCategory[];
  totalElements: number;
  totalPages: number;
};

export type Countries = {
  content: Country[];
  totalElements: number;
  totalPages: number;
};
