import { FORM_ELEMENT_PASSWORD } from 'constants/FormConstants';

import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  firstName: {
    isRequired: true,
    max: 50,
  },
  lastName: {
    isRequired: true,
    max: 50,
  },
  password: {
    isRequired: true,
    min: 8,
    password: true,
    max: 40,
  },
  email: {
    isRequired: true,
    isEmail: true,
    max: 60,
  },
  phoneNumber: {
    isRequired: true,
    isPhoneNumber: true,
  },
  confirmPassword: {
    isRequired: true,
    min: 8,
    password: true,
    isPasswordMatch: FORM_ELEMENT_PASSWORD,
  },
  country: {
    isRequired: true,
  },
  phoneNumberCountryCode: {
    isRequired: true,
  },
};
