import { FORM_ELEMENT_COUNTRY } from 'constants/FormConstants';

import { FormValidationType } from '../../../hooks/useForm';

const placeSchema: FormValidationType = {
  name: {
    isRequired: true,
    max: 75,
  },
  description: {
    isRequired: true,
    max: 250,
  },
  street: {
    isRequired: true,
    max: 100,
  },
  streetNumber: {
    isRequired: true,
    max: 20,
  },
  city: {
    isRequired: true,
  },
  zipCode: {
    isRequired: true,
    max: 20,
  },
  longitude: {
    isRequired: true,
    isNumberDecimal: true,
  },
  latitude: {
    isRequired: true,
    isNumberDecimal: true,
  },
  category: {
    isRequired: true,
  },
  company: {
    isRequired: true,
  },
  country: {
    isRequired: true,
  },
  website: {
    max: 50,
  },
  floor: {
    min: 0,
    onlyNumbers: true,
  },
  apartment: {
    min: 0,
    onlyNumbers: true,
  },
  mondayFrom: {
    isDateGreaterThan: 'mondayTo',
  },
  tuesdayFrom: {
    isDateGreaterThan: 'tuesdayTo',
  },
  wednesdayFrom: {
    isDateGreaterThan: 'wednesdayTo',
  },
  thursdayFrom: {
    isDateGreaterThan: 'thursdayTo',
  },
  fridayFrom: {
    isDateGreaterThan: 'fridayTo',
  },
  saturdayFrom: {
    isDateGreaterThan: 'saturdayTo',
  },
  sundayFrom: {
    isDateGreaterThan: 'sundayTo',
  },
  minimumOrderAmount: {
    isRequired: true,
    isRounded: true,
    isValueGreater: FORM_ELEMENT_COUNTRY,
  },
  phoneNumber: {
    isPlacePhoneNumber: true,
  },
  beneficiaryAccount: {
    isRequired: true,
    onlyNumbers: true,
    accountDigits: 18,
  },
  minimumOrderAmountRoot: {
    isRequired: true,
  },
  fee: {
    isRequired: true,
    isPriceNumber: true,
  },
};

export default placeSchema;

export const placeEmployeeSchema: FormValidationType = {
  role: {
    isRequired: true,
  },
};
