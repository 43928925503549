import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { TABLE_ELEMENT_GROSS, TABLE_ELEMENT_NET } from 'constants/TableConstants';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { OrdersRevenue } from '../../../services/getPlaceStatistics';

const styleColor = {
  net: ['rgb(52, 105, 104)'],
  gross: ['rgb(138, 169, 168)'],
};

Chart.register(CategoryScale);

const BarChartOrdersRevenue = ({
  handleIntervalMessage,
  interval,
  ordersRevenue,
}: {
  handleIntervalMessage: (interval: string) => string;
  interval: string;
  ordersRevenue: Array<OrdersRevenue>;
}) => {
  const { t } = useTranslation();

  const labelData = useMemo(() => {
    return ordersRevenue.map((label) => {
      return label?.intervalValue;
    });
  }, [ordersRevenue]);

  const orderRevenue = Object.keys(ordersRevenue).map((property) => ordersRevenue[property]);

  const statusKeys = [TABLE_ELEMENT_NET, TABLE_ELEMENT_GROSS];

  const statusData = useMemo(() => {
    return statusKeys.map((status) => {
      return orderRevenue.map((interval) => interval && interval[status]);
    });
  }, [orderRevenue]);

  const labels = labelData;

  const datasets = statusKeys.map((status, index) => {
    return {
      label: t(`placeStatistics.${status}`),
      backgroundColor: styleColor[status],
      borderColor: styleColor[status],
      data: statusData[index],
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: handleIntervalMessage(interval),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          marginLeft: '100px',
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: t('placeStatistics.ordersRevenueAxis.yAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};
export default BarChartOrdersRevenue;
