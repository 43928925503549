import { ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import PartnerAppSettingsBaseForm from 'features/maintenance/components/PartnerAppMaintenance/BaseForm/BaseForm';
import {
  formDataInitialState as PartnerAppSettingsFormDataInitialState,
  FormDataType as PartnerAppSettingsFormDataType,
} from 'features/maintenance/constants/PartnerAppSettingsFormConstants';
import { validationSchema as PartnerAppSettingsValidationSchema } from 'features/maintenance/constants/PartnerAppSettingsFormSchema';
import { usePartnerAppSettingsForm } from 'features/maintenance/hooks/usePartnerAppSettingsForm';
import { useUpdatePartnerApplicationSettingsMutation } from 'features/maintenance/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { trimObjectValues } from 'utils/helpers';

const PartnerAppSettings = () => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { displayError, displaySuccess } = useDisplayNotification();

  const {
    errors: partnerErrors,
    formData: partnerFormData,
    handleChangeFormData: partnerHandleChangeFormData,
    handleSubmit,
    setFormData: partnerSetFormData,
  } = useForm<PartnerAppSettingsFormDataType>(
    PartnerAppSettingsFormDataInitialState,
    PartnerAppSettingsValidationSchema,
  );
  const { data: initialPartnerAppSettingsFormData } = usePartnerAppSettingsForm(partnerSetFormData);

  const [updatePartnerApplicationSettingsRequest] = useUpdatePartnerApplicationSettingsMutation();

  useEffect(() => {
    const { placeDetail, placeList, productCategoryAll, productDetail, productList, ...restData } =
      partnerFormData;
    const newData = {
      ...restData,
      dashboardImageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
      },
    };
    const isPartnerFormDirty = !isEqual(initialPartnerAppSettingsFormData, newData);
    setIsFormDirty(isPartnerFormDirty);
  }, [initialPartnerAppSettingsFormData, partnerFormData]);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<PartnerAppSettingsFormDataType>(partnerFormData);
    const { placeDetail, placeList, productCategoryAll, productDetail, productList, ...restData } =
      trimmedData;
    const partnerUserSettingsData = {
      ...restData,
      dashboardImageQualitySettings: {
        placeDetail,
        placeList,
        productCategoryAll,
        productDetail,
        productList,
      },
    };

    try {
      await updatePartnerApplicationSettingsRequest(partnerUserSettingsData).unwrap();
      displaySuccess({ key: 'maintenance.updatePartnerAppSettings' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <>
      {initialPartnerAppSettingsFormData ? (
        <PartnerAppSettingsBaseForm
          errors={partnerErrors}
          formData={partnerFormData}
          handleChange={partnerHandleChangeFormData}
        />
      ) : (
        <ACSpinner background='var(--text-secondary)' size='xxs' />
      )}
      <FormStickyFooter
        onSubmit={() => {
          handleSubmit(onSubmit);
        }}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={!isFormDirty}
      />
    </>
  );
};

export default PartnerAppSettings;
