import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import moment from 'moment';
import { TAG_PRODUCT_CATEGORIES } from 'services/TagsConstants';
import { store } from 'store/store';

import { Country } from '../../../services/country';
import { createQueryString } from '../../../utils/helpers';

const BASE_URL = '/product-categories';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ProductCategories, ProductCategoryParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const productCategoryResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const productCategoriesData = productCategoryResponse.data as ProductCategories;
      const documents = productCategoriesData?.content.map((productCategory: ProductCategory) => {
        return productCategory?.document ? productCategory?.document : null;
      });
      const documentIds = documents?.filter((id) => id !== null);
      const documentResponse =
        documentIds?.length > 0
          ? await fetchWithBQ(`/documents/download-links?ids=${documentIds}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const countries = store.getState().globalData.countries;
      const categories = productCategoriesData.content.map((productCategory: ProductCategory) => {
        const { createdAt, updatedAt } = productCategory;
        const { dateFormat } = countries.find((country: Country) => country.uuid);
        const formatDateCreate = moment(createdAt).format(dateFormat.toUpperCase());
        const formatDateUpdate = moment(updatedAt).format(dateFormat.toUpperCase());
        return {
          ...productCategory,
          createdAt: formatDateCreate,
          updatedAt: formatDateUpdate,
          document: documentData ? documentData[productCategory?.document] : null,
        };
      });
      return {
        data: {
          content: categories,
          totalElements: productCategoriesData?.totalElements,
          totalPages: productCategoriesData?.totalPages,
          currentPage: productCategoriesData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_PRODUCT_CATEGORIES],
  });

export type ProductCategoryParameters = {
  ids?: string;
  name?: string;
  page?: number;
  size?: number;
  sort?: any;
};

export type ProductCategories = {
  content: ProductCategory[];
  currentPage?: number;
  pageable?: {
    pageNumber: number;
  };
  totalElements?: number;
  totalPages?: number;
};

export type ProductCategory = {
  createdAt?: string;
  createdBy?: string;
  document?: string;
  name: string;
  nameEn: string;
  nameHr: string;
  nameSr: string;
  showInUserApp?: boolean;
  updatedAt?: string;
  updatedBy?: string;
  uuid: string;
  value?: string;
};
