import { ACTabs } from '@antech-consulting/components';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TAB_FIRST, TAB_ZERO } from 'constants/TabsConstants';
import TableAppUsers from 'features/appUser/components/TableAppUsers/TableAppUsers';
import TableBlockedAppUsers from 'features/appUser/components/TableBlockedAppUsers/TableBlockedAppUsers';
import { useAppSelector } from 'hooks/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';

import { style } from '../../constants/StyleConstants';

const AppUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);

  const system = roles[ROLE_SYSTEM as never];

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/app-users/?value=${id}`);
  };

  const tabs = [
    {
      label: t('appUsers.appUsers'),
      id: TAB_ZERO,
      content: <TableAppUsers />,
    },
    {
      label: t('appUsers.blockedAppUsers'),
      id: TAB_FIRST,
      content: <TableBlockedAppUsers />,
    },
  ];

  return (
    <>
      {system ? (
        <ACTabs
          defaultTab={defaultValueTab}
          onChange={handleChange}
          style={style.ACTabs}
          tabs={tabs}
        />
      ) : (
        <TableAppUsers />
      )}
    </>
  );
};

export default AppUsers;
