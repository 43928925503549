import { CityDTO, OptionsType } from 'constants/TypeConstants';
import { useGetPartnerUsersRecursiveQuery } from 'features/partnerUser/services';
import { useLazyGetUserByIdQuery } from 'features/user/services';
import { useAppSelector } from 'hooks/hooks';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLazyGetCitiesQuery } from 'services';
import { trimObjectValues } from 'utils/helpers';

import { CountryOption } from '../../../services/country';
import { FormDataType } from '../constants/FormConstants';
import { Owner } from '../constants/FormConstants';
import { useLazyGetCompanyByIdQuery } from '../services';
import { CompanyDTO } from '../services/updateCompany';

export const transformToDTOFormat = (formData: FormDataType, id: string): CompanyDTO => {
  const ownerId = formData?.owner as Owner;
  const cityId = formData?.city as CityDTO;
  const CompanyDTO: CompanyDTO = {
    ...formData,
    owner: ownerId?.uuid,
    city: cityId?.uuid,
  };
  const country = formData?.country as CountryOption;
  id && (CompanyDTO.country = country?.uuid);
  return CompanyDTO;
};

export const findCity = (cityId: string, entireCitiesArray: CityDTO[]) => {
  const foundCity = entireCitiesArray?.find((city) => city?.uuid === cityId);
  return {
    uuid: foundCity?.uuid,
    name: foundCity?.name,
  };
};

export const useCompanyForm = (
  formData?: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  companyId?: string,
) => {
  const [fetchCompanyById, { data: company, isSuccess: isSuccessCompany }] =
    useLazyGetCompanyByIdQuery();
  const { data: partnerUsers, isSuccess: isSuccessPartnerUsers } =
    useGetPartnerUsersRecursiveQuery();

  const [fetchOwner, { data: owner, isSuccess: isSuccessOwner }] = useLazyGetUserByIdQuery();
  const { cities, countries } = useAppSelector(({ globalData }) => globalData);

  const [fetchCities, { data: citiesOption }] = useLazyGetCitiesQuery();

  const isSuccessEdit = isSuccessCompany && isSuccessPartnerUsers;
  const initialFormValues = () => {
    const country = countries?.find((country) => country.uuid === company?.country);
    const city = findCity(company?.city, cities);
    setFormData({
      ...company,
      country: {
        ...country,
        name: country?.shortName,
        value: country?.uuid,
      },
      city,
      owner,
    });
  };

  useEffect(() => {
    if (companyId) fetchCompanyById(companyId, true);
  }, []);

  useEffect(() => {
    const country = formData?.country as OptionsType;
    country?.uuid && fetchCities({ country: country?.uuid });
  }, [formData?.country, company]);

  useEffect(() => {
    isSuccessEdit && fetchOwner(company.owner, true);
  }, [isSuccessEdit, company, company?.owner]);

  useEffect(() => {
    if (isSuccessEdit) initialFormValues();
  }, [isSuccessEdit, company, isSuccessOwner, company?.owner]);

  const countriesOptions: CountryOption[] = useMemo(() => {
    return countries?.map((country) => {
      return {
        ...country,
        name: country.shortName,
        value: country.uuid,
      };
    });
  }, [countries]);

  const partnerUsersOptions = useMemo(() => {
    return partnerUsers?.content?.map((partner) => {
      return {
        ...partner,
        name: partner.email,
        value: partner.uuid,
      };
    });
  }, [partnerUsers]);

  const citiesByCountryOptions = citiesOption?.content;

  const shouldDisableSubmitButton = useMemo(() => {
    const transformedFormData: CompanyDTO = transformToDTOFormat(formData, companyId);
    const trimmedData = trimObjectValues<FormDataType>(transformedFormData);

    const disabled = _.isEqual(trimmedData, company);
    return disabled;
  }, [formData, company]);

  return {
    partnerUsersOptions,
    countriesOptions,
    shouldDisableSubmitButton,
    isSuccessEdit,
    isSuccessOwner,
    citiesByCountryOptions,
    isSuccessPartnerUsers,
  };
};
