import { ACButton, ACInput } from '@antech-consulting/components';
import { AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { Add, Minus } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

import { ORDER_PRODUCTS_STOCK_AVAILABLE } from '../../constants/TableConstants';
import { Product } from '../../services/getProducts';
import styles from './ProductsStock.module.scss';

export type AvailableStockProps = {
  handleItemIndicatorColor: (product: Product) => number;
  product: Product;
  updateAvailability: (product: Product) => void;
};

const buttonsAddOnStyle = {
  padding: '0',
  borderRadius: '20px',
};

const addToStockButtonStyle = {
  backgroundColor: 'var(--text-secondary)',
  width: '45px',
};

const AvailableStock = ({
  handleItemIndicatorColor,
  product,
  updateAvailability,
}: AvailableStockProps) => {
  const { t } = useTranslation();
  const { available, code, name, uuid } = product;
  const disabledColor = !available ? ' var(--text-transparent)' : '#A52121';

  const reduceStockButtonStyle = {
    backgroundColor: disabledColor,
    width: '45px',
  };

  const ReduceButton = () => {
    return (
      <div className={styles.availabilityWrapper}>
        <ACButton
          dataTestid='reduceStock'
          disabled={!available}
          onClick={() =>
            updateAvailability({
              ...product,
              available: available - ORDER_PRODUCTS_STOCK_AVAILABLE.one,
            })
          }
          style={reduceStockButtonStyle}
        >
          <Minus />
        </ACButton>
      </div>
    );
  };

  const AddButton = () => {
    return (
      <ACButton
        dataTestid='addStock'
        onClick={() =>
          updateAvailability({
            ...product,
            available: available + ORDER_PRODUCTS_STOCK_AVAILABLE.one,
          })
        }
        style={addToStockButtonStyle}
      >
        <Add />
      </ACButton>
    );
  };

  const handleNumberOfUpdatedStock = () => {
    if (handleItemIndicatorColor(product) < ORDER_PRODUCTS_STOCK_AVAILABLE.zero)
      return (
        <div className={styles.availabilityChangeReduce}>
          -{Math.abs(handleItemIndicatorColor(product))}
        </div>
      );
    else if (handleItemIndicatorColor(product) > ORDER_PRODUCTS_STOCK_AVAILABLE.zero)
      return (
        <div className={styles.availabilityChangeAdded}>
          +{Math.abs(handleItemIndicatorColor(product))}
        </div>
      );
  };

  return (
    <div
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        padding: 10,
        borderBottom: ' 1px solid gray',
      }}
    >
      <div>
        <p style={{ padding: 5 }}>{name}</p>
        {code && (
          <p style={{ fontSize: '0.8em', padding: 5 }}>
            {t('product.code')}: {code}
          </p>
        )}
      </div>
      <div className={styles.availabilityWrapper}>
        {handleNumberOfUpdatedStock()}
        <ACInput
          addonAfter={AddButton()}
          addonBefore={ReduceButton()}
          autoComplete={AUTO_COMPLETE_OFF}
          dataTestId='availableStock'
          name={name}
          onChange={({ target }) =>
            updateAvailability({ ...product, available: parseInt(target.value as string) })
          }
          style={{
            addonWrapperAfter: buttonsAddOnStyle,
            addonWrapperBefore: buttonsAddOnStyle,
            input: { textAlign: 'center', maxWidth: 50 },
            inputGroupWrapper: { display: 'flex', alignItems: 'center' },
          }}
          value={`${available || 0}`}
        />
      </div>
    </div>
  );
};

export default AvailableStock;
