import { FORM_ELEMENT_PRODUCT, FORM_ELEMENT_RATING } from 'constants/FormConstants';
import {
  TABLE_ELEMENT_COMMENT,
  TABLE_ELEMENT_CREATED_AT,
  TABLE_ELEMENT_CREATED_BY,
  TABLE_INITIAL_ROWS_NUMBER,
} from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const tablePlaceReviewsSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'placeReviewsTable',
  columns: [
    {
      title: 'place.product',
      field: FORM_ELEMENT_PRODUCT,
    },
    {
      title: 'place.createdBy',
      field: TABLE_ELEMENT_CREATED_BY,
    },
    {
      title: 'place.createdAt',
      field: TABLE_ELEMENT_CREATED_AT,
    },
    {
      title: 'place.rating',
      field: FORM_ELEMENT_RATING,
    },
    {
      title: 'place.comment',
      field: TABLE_ELEMENT_COMMENT,
    },
  ],
  disableSorting: { columns: [TABLE_ELEMENT_CREATED_BY, FORM_ELEMENT_PRODUCT] },
};

export default tablePlaceReviewsSettings;
