import { ACTabs } from '@antech-consulting/components';
import { TAB_FIRST, TAB_ZERO } from 'constants/TabsConstants';
import ProductReview from 'features/product/components/ProductReview/ProductReview';
import EditProduct from 'pages/EditProduct/EditProduct';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';

const ProductDetailView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);
  const { id: idProduct } = useParams();

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/product/${idProduct}?value=${id}`);
  };

  const tabs = [
    {
      label: t('product.settings'),
      id: TAB_ZERO,
      content: <EditProduct />,
    },
    {
      label: t('product.review'),
      id: TAB_FIRST,
      content: <ProductReview />,
    },
  ];
  return (
    <ACTabs defaultTab={defaultValueTab} onChange={handleChange} style={style.ACTabs} tabs={tabs} />
  );
};

export default ProductDetailView;
