import { ACTabs } from '@antech-consulting/components';
import { TAB_FIRST, TAB_SECOND, TAB_THIRD, TAB_ZERO } from 'constants/TabsConstants';
import TableArchivedPlaces from 'features/place/components/TableArchivedPlaces/TableArchivedPlaces';
import TablePlaceInPreparation from 'features/place/components/TablePlaceInPreparation/TablePlaceInPreparation';
import TablePlaceUnverified from 'features/place/components/TablePlaceUnverified/TablePlaceUnverified';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';
import TablePlace from '../../features/place/components/TablePlace/TablePlace';
import styles from './Place.module.scss';

const Place = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/places?value=${id}`);
  };

  const tabs = [
    {
      label: t('place.verifiedPlaces'),
      id: TAB_ZERO,
      content: <TablePlace />,
    },
    {
      label: t('place.inPreparation'),
      id: TAB_FIRST,
      content: <TablePlaceInPreparation />,
    },
    {
      label: t('place.unverifiedPlaces'),
      id: TAB_SECOND,
      content: <TablePlaceUnverified />,
    },
    {
      label: t('place.archiveTab'),
      id: TAB_THIRD,
      content: <TableArchivedPlaces />,
    },
  ];
  return (
    <div>
      <h3 className={styles.settingsTitle}>{t('place.title')}</h3>
      <ACTabs
        defaultTab={defaultValueTab}
        onChange={handleChange}
        style={style.ACTabs}
        tabs={tabs}
      />
    </div>
  );
};
export default Place;
