/* eslint-disable react/jsx-sort-props */
import useTable from 'hooks/useTable';
import { useMemo } from 'react';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import bestRevenuesTableSettings from './bestRevenuesTableSettings';

export type BestRevenues = {
  gross: number;
  name: string;
  net: number;
};

const TableBestRevenues = ({ bestData, header }: { bestData: BestRevenues[]; header: string }) => {
  const tableSettings = useTable(bestRevenuesTableSettings);

  const bestRevenues = useMemo(() => {
    return bestData?.map((data: BestRevenues) => {
      const bestDataRevenues = {
        ...bestData,
        name: data.name,
        gross: data.gross,
        net: data.net,
      };
      return {
        rawData: bestDataRevenues,
        render: bestDataRevenues,
      };
    });
  }, [bestData]);

  return (
    <>
      <StyledACTable
        {...tableSettings}
        headerTitle={header}
        rows={bestRevenues}
        tableFooter={false}
        statistics
      />
    </>
  );
};
export default TableBestRevenues;
