export const formatLanguageForApi = (language: string) => {
  switch (language) {
    case 'srb': {
      return 'SERBIAN';
    }
    case 'en': {
      return 'ENGLISH';
    }
    case 'hr': {
      return 'CROATIAN';
    }
  }
};

export const formatLanguageForI18n = (language: string) => {
  switch (language) {
    case 'SERBIAN': {
      return 'srb';
    }
    case 'ENGLISH': {
      return 'en';
    }
    case 'CROATIAN': {
      return 'hr';
    }
  }
};
