import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, LogoutRequest>({
    query: (credentials) => ({
      url: '/token/logout/partner-user',
      method: 'POST',
      body: credentials,
    }),
  });

export type LogoutRequest = { refreshToken: string };
