import { FORM_ELEMENT_CATEGORY, FORM_ELEMENT_RATING } from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import {
  FORM_ELEMENT_AVAILABLE,
  FORM_ELEMENT_BASE_PRICE,
  FORM_ELEMENT_DISCOUNTED_PRICE,
} from 'features/product/constants/FormConstants';
import { TABLE_ELEMENT_DOCUMENT } from 'features/product/constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const archivedProductTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'productsTable',
  columns: [
    {
      title: 'product.nameColumn',
      field: TABLE_ELEMENT_DOCUMENT,
    },
    {
      title: 'product.basePriceColumn',
      field: FORM_ELEMENT_BASE_PRICE,
    },
    {
      title: 'product.discountedPriceColumn',
      field: FORM_ELEMENT_DISCOUNTED_PRICE,
    },
    {
      title: 'product.availableColumn',
      field: FORM_ELEMENT_AVAILABLE,
    },
    {
      title: 'product.ratingColumn',
      field: FORM_ELEMENT_RATING,
    },
    {
      title: 'product.categoryColumn',
      field: FORM_ELEMENT_CATEGORY,
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_RATING] },
};

export default archivedProductTableSettings;
