import { ACAvatar } from '@antech-consulting/components';
import { LANGUAGES } from 'constants/FormConstants';
import { ClipboardText, ProfileCircle } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { store } from 'store/store';

import TastyGuideCroatian from '../../assets/files/TastyDashboardGuideCroatian.pdf';
import TastyGuideEnglish from '../../assets/files/TastyDashboardGuideEnglish.pdf';
import TastyGuideSerbian from '../../assets/files/TastyDashboardGuideSerbian.pdf';
import Logout from '../../features/auth/components/Logout/Logout';
import { userInitials } from '../../utils/helpers';
import styles from './MenuBar.module.scss';
import { AvatarColor, AvatarType, BackgroundColor } from './ProfileCard';

export type MenuBarProps = {
  currentUser: CurrentUser;
  isLoading?: boolean;
  openModal?: boolean;
  toggleModal?: () => void;
};

const MenuBar = ({ currentUser, openModal, toggleModal }: MenuBarProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { email } = currentUser;
  const language = store.getState().language.language;
  const tastyGuide =
    (language === LANGUAGES.EN && TastyGuideEnglish) ||
    (language === LANGUAGES.SRB && TastyGuideSerbian) ||
    (language === LANGUAGES.HR && TastyGuideCroatian);
  const openProfilePage = () => {
    navigate(`/user-profile?value=${0}`);
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
    toggleModal();
  };
  const menuBarStyle = openModal ? styles.menuBar : `${styles.menuBar} ${styles.close}`;
  return (
    <div className={menuBarStyle}>
      <div className={styles.userInfo}>
        <ACAvatar
          bgColor={BackgroundColor}
          className={styles.avatar}
          color={AvatarColor}
          type={AvatarType}
        >
          {userInitials({ currentUser, haveInitials: true })}
        </ACAvatar>
        <div>
          <div className={styles.userData}>
            {userInitials({ currentUser, haveInitials: false })}
          </div>
          <div className={styles.emailInfo}>{email}</div>
        </div>
      </div>
      <div className={styles.profileTab} onClick={openProfilePage}>
        <div>
          <ProfileCircle className={styles.icon} />
        </div>
        <div className={styles.buttonLogout} placeholder='buttonLogout'>
          {t('navbar.profile')}
        </div>
      </div>
      <div className={styles.guideTab}>
        <ClipboardText className={styles.icon} />
        <a className={styles.buttonLogout} href={tastyGuide} rel='noreferrer' target='_blank'>
          {t('navbar.tastyGuide')}
        </a>
      </div>
      <Logout />
    </div>
  );
};

export default MenuBar;
