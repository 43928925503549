import { ACSpinner } from '@antech-consulting/components';
import { CityDTO } from 'constants/TypeConstants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/company/components/BaseForm/BaseForm';
import { validationSchema } from '../../features/company/constants/CompanySchema';
import {
  formDataInitialState,
  FormDataType,
  Owner,
} from '../../features/company/constants/FormConstants';
import { useCompanyForm } from '../../features/company/hooks/useCompanyForm';
import { useCreateCompanyMutation } from '../../features/company/services';
import { NewCompany } from '../../features/company/services/createCompany';
import { useForm } from '../../hooks/useForm';
import { CountryOption } from '../../services/country';
import styles from './AddCompany.module.scss';

const AddCompany = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [add, { isLoading }] = useCreateCompanyMutation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const { citiesByCountryOptions, countriesOptions, isSuccessPartnerUsers, partnerUsersOptions } =
    useCompanyForm(formData, setFormData, id);

  const onSubmit = async () => {
    try {
      const { uuid } = formData?.country as CountryOption;
      const { uuid: ownerUuid } = formData?.owner as Owner;
      const { uuid: cityId } = formData?.city as CityDTO;
      const trimmedData = trimObjectValues<FormDataType>(formData);
      const companyData: NewCompany = {
        ...trimmedData,
        country: uuid,
        owner: ownerUuid,
        city: cityId,
      };
      await add(companyData).unwrap();
      navigate('/companies');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'company.addCompany' });
    } catch (e) {
      const {
        data: { code },
      } = e as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = () => {
    navigate('/companies');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return isSuccessPartnerUsers && !isLoading ? (
    <>
      <PageHeader primaryText={t('company.titleAdd')} />
      <div className={styles.wrapper}>
        <BaseForm
          cities={citiesByCountryOptions}
          countriesAutocompleteOptions={countriesOptions}
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          partnerUsersAutocompleteOptions={partnerUsersOptions}
          setFormData={setFormData}
        />
      </div>
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddCompany;
