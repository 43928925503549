import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_ADDRESS, FORM_ELEMENT_NAME, IS_EMPLOYED } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { FORM_ELEMENT_FEE } from 'features/place/constants/PlaceContants';
import { imagePlace } from 'features/place/constants/StyleConstants';
import { DISABLE_SORTING_PLACE_COLUMNS } from 'features/place/constants/TableConstants';
import { Place } from 'features/place/services/createPlace';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import { Additem, Archive } from 'iconsax-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { handleDefaultPlaceImage, transformACState } from '../../../../utils/helpers';
import {
  useArchivePlaceMutation,
  useChangePlacesStatusMutation,
  useLazyGetInPreparationPlacesQuery,
} from '../../services/index';
import { FilterPropsPlace } from '../TablePlace/TablePlace';
import styles from './TablePlaceInPreparation.module.scss';
import tablePlaceInPreparationSettings from './tablePlaceInPreparationSettings';

const TablePlaceInPreparation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableSettings = useTable(tablePlaceInPreparationSettings);
  const { selectedRow, setSelectedRow } = tableSettings;
  const [fetchInPreparationPlaces, { data: inPreparationPlaces, isFetching }] =
    useLazyGetInPreparationPlacesQuery();
  const [changeStatus, { isLoading }] = useChangePlacesStatusMutation();
  const [archivePlace, { isLoading: isLoadingArchive }] = useArchivePlaceMutation();

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [isActiveModalOpen, toggleActive] = useToggle();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [isArchivePlaceModalOpen, toggleArchiveModal] = useToggle();

  const system = roles[ROLE_SYSTEM as never];

  const { data } = useGetPartnerApplicationSettingsQuery();

  const placesImageQuality = data?.dashboardImageQualitySettings?.placeList;

  const placesTableData = useMemo(() => {
    return inPreparationPlaces?.content.map((place) => {
      const { category, document } = place;
      const imageUrl = document && document[placesImageQuality];
      const imageFile = handleDefaultPlaceImage(category, imageUrl);

      return {
        rawData: place,
        render: {
          ...place,
          name: (
            <div className={styles.place}>
              <img alt={place?.name} src={imageFile} style={imagePlace} />
              <div>{place?.name}</div>
            </div>
          ),
        },
      };
    });
  }, [inPreparationPlaces?.content]);

  const handleActivePlace = ({ rowData }: { rowData: Place }) => {
    setSelectedRow(rowData);
    toggleActive();
  };

  const handleChangeStatus = async () => {
    try {
      await changeStatus({ id: selectedRow?.uuid, verificationType: 'active' }).unwrap();
      toggleActive();
      displaySuccess({ key: 'place.activatePlace' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleActive();
      displayError({ key: code });
    }
  };

  const handelArchivePlace = async () => {
    try {
      await archivePlace(selectedRow?.uuid).unwrap();
      toggleArchiveModal();
      displaySuccess({ key: t('archiveModal.archiveModalSuccess') });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleArchiveModal();
      displayError({ key: code });
    }
  };

  const handleChangeTableState = (tableState: TableStateProps<FilterPropsPlace>) => {
    const tableData = system ? { ...tableState } : { ...tableState, [IS_EMPLOYED]: 'true' };
    fetchInPreparationPlaces(transformACState(tableData, FORM_ELEMENT_NAME));
  };

  const handlePlaceArchiveModal = ({ rowData }: { rowData: any }) => [
    setSelectedRow(rowData),
    toggleArchiveModal(),
  ];

  const additionalActions = [
    {
      icon: <Additem data-testid='activationButton' />,
      tooltip: {
        text: t('place.activatePlace'),
        style: { left: '10px' },
      },
      onClick: handleActivePlace,
    },
    {
      title: t('archiveModal.archive'),
      icon: <Archive data-testid='archiveButton' />,
      tooltip: {
        text: t('place.archivePlace'),
        style: {
          left: '10%',
        },
      },
      onClick: handlePlaceArchiveModal,
    },
  ];

  const handleOnItemClicked = ({ rowData }: { rowData: Place }) => {
    const { uuid } = rowData;
    navigate(`/place/${uuid}`, { state: { previousLocation: '/places?value=1' } });
  };

  const additionalColumns = system
    ? [
        {
          title: t('place.feeColumn'),
          field: FORM_ELEMENT_FEE,
        },
      ]
    : [];

  return (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={[...tableSettings.actions, ...additionalActions]}
          allItemsCount={inPreparationPlaces?.totalElements}
          columns={[...tableSettings.columns, ...additionalColumns]}
          defaultSort={{
            field: FORM_ELEMENT_NAME,
            type: 'asc',
          }}
          disableSorting={DISABLE_SORTING_PLACE_COLUMNS}
          onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
          onTableStateChange={handleChangeTableState}
          pagesCount={inPreparationPlaces?.totalPages}
          rows={placesTableData}
        />
      </ACLoading>
      {isActiveModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleActive}
          disabled={isLoading}
          handleSubmit={handleChangeStatus}
          header={t('place.changeStatus')}
          icon={<Additem style={style.BlueIconModal} />}
          isShow={isActiveModalOpen}
          message={t('place.activationPlace')}
        />
      )}
      {isArchivePlaceModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleArchiveModal}
          disabled={isLoadingArchive}
          handleSubmit={handelArchivePlace}
          header={t('archiveModal.titleArchive')}
          icon={<Archive style={style.RedIconModal} />}
          isShow={isArchivePlaceModalOpen}
          message={t('archiveModal.descriptionArchive')}
        />
      )}
    </>
  );
};
export default TablePlaceInPreparation;
