import {
  FORM_ELEMENT_CATEGORY,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_RATING,
} from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { tableActionTypes } from 'constants/useTableConstants';
import {
  FORM_ELEMENT_AVAILABLE,
  FORM_ELEMENT_BASE_PRICE,
  FORM_ELEMENT_DISCOUNTED_PRICE,
} from 'features/product/constants/FormConstants';
import { TableSettingsType } from 'hooks/useTable';
import { Edit2, Trash } from 'iconsax-react';

const { DELETE, EDIT } = tableActionTypes;

const productTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'productsTable',
  actions: [
    {
      redirect: '/product',
      header: false,
      name: EDIT,
      icon: <Edit2 data-testid='editButton' />,
      tooltip: 'product.titleEdit',
    },
    {
      redirect: 'modal',
      header: false,
      name: DELETE,
      icon: <Trash data-testid='deleteButton' />,
      tooltip: 'product.delete',
    },
  ],
  filter: [
    {
      filterType: 'text',
      title: 'product.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
  ],
  columns: [
    {
      title: 'product.nameColumn',
      field: FORM_ELEMENT_NAME,
    },
    {
      title: 'product.basePriceColumn',
      field: FORM_ELEMENT_BASE_PRICE,
    },
    {
      title: 'product.discountedPriceColumn',
      field: FORM_ELEMENT_DISCOUNTED_PRICE,
    },
    {
      title: 'product.availableColumn',
      field: FORM_ELEMENT_AVAILABLE,
    },
    {
      title: 'product.ratingColumn',
      field: FORM_ELEMENT_RATING,
    },
    {
      title: 'product.categoryColumn',
      field: FORM_ELEMENT_CATEGORY,
    },
  ],
  disableSorting: { columns: [FORM_ELEMENT_RATING] },
};

export default productTableSettings;
