export const INCLUDE_NOT_VERIFIED = 'include-not-verified';

export type FilterProps = {
  phoneNumber?: string;
  searchTerm?: string;
};

export const detailViewTooltip = {
  width: '80px',
  right: '50px',
};

export const blockUserTooltip = {
  width: '80px',
  left: '0px',
};
