import { User } from 'features/partnerUser/services/getPartnerUserById';
import { Call, Global, Sms, User as UserIcon } from 'iconsax-react';
import { useTranslation } from 'react-i18next';

import styles from '../UserDetailView/UserDetailView.module.scss';

type UserBasicInformationProps = {
  formData: User;
};
const UserBasicInformations = ({ formData }: UserBasicInformationProps) => {
  const { t } = useTranslation();
  const { country, email, emailVerified, firstName, lastName, phoneNumber, phoneVerified } =
    formData;
  const verifyEmail = emailVerified ? t('partnerUser.verifyYes') : t('partnerUser.verifyNo');
  const verifyPhone = phoneVerified ? t('partnerUser.verifyYes') : t('partnerUser.verifyNo');

  return (
    <>
      <div className={styles.dataWrapper}>
        <UserIcon className={styles.icon} />
        <div className={styles.textData}>
          {firstName} {lastName}
        </div>
      </div>
      <div className={styles.dataWrapper}>
        <Sms className={styles.icon} />
        <div className={styles.textData}>
          {email} ({verifyEmail})
        </div>
      </div>
      <div className={styles.dataWrapper}>
        <Call className={styles.icon} />
        <div className={styles.textData}>
          {phoneNumber} ({verifyPhone})
        </div>
      </div>
      <div className={styles.dataWrapper}>
        <Global className={styles.icon} />
        <div className={styles.textData}>{country}</div>
      </div>
    </>
  );
};

export default UserBasicInformations;
