import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { FORM_ELEMENT_COUNT } from 'constants/FormConstants';
import { OrdersPriceDistribution } from 'features/systemStatistics/services/getSystemStatistics';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

Chart.register(CategoryScale);

const styleColor = {
  count: ['rgb(52, 105, 104)'],
};

const BarChart = ({
  ordersPriceDistribution,
}: {
  ordersPriceDistribution: Array<OrdersPriceDistribution>;
}) => {
  const { t } = useTranslation();

  const labelData = useMemo(() => {
    return ordersPriceDistribution.map((label) => {
      if (label?.to !== undefined) return String(label?.from) + '-' + String(label?.to);
      else return '>' + String(label?.from);
    });
  }, [ordersPriceDistribution]);

  const orderPriceDistribution = Object.keys(ordersPriceDistribution).map(
    (property) => ordersPriceDistribution[property],
  );

  const statusKeys = [FORM_ELEMENT_COUNT];

  const statusData = useMemo(() => {
    return statusKeys.map((status) => {
      return orderPriceDistribution.map((interval) => (interval ? interval[status] : null));
    });
  }, [orderPriceDistribution]);

  const labels = labelData;

  const datasets = statusKeys.map((status, index) => {
    return {
      label: t(`statistics.${status}`),
      backgroundColor: styleColor[status],
      borderColor: styleColor[status],
      data: statusData[index],
    };
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: t('statistics.ordersPriceDistributionXAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          marginLeft: '100px',
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: t('statistics.ordersPriceDistributionYAxis'),
          font: {
            family: 'Times',
            size: 16,
            weight: 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 0 },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};
export default BarChart;
