export type FormDataType = {
  email?: string;
};

export type FormDataTypeNewPassword = {
  password?: string;
};
export const formDataInitialState: FormDataType = {
  email: '',
};

export type NewPassword = {
  password: string;
};

export const formDataInitialStateNewPassword = {
  password: '',
};
