import { ERROR_CODES } from '../constants/Errors/ErrorConstants';

const extractLetters = (code: string) => code.match(/[a-zA-Z]+/g)?.join('') || '';

const getFeatureType = (code: string) => {
  const letters = extractLetters(code);
  switch (letters) {
    case 'P':
      return 'place';
    case 'C':
      return 'company';
    case 'CR':
      return 'companyRequest';
    case 'PR':
      return 'product';
    case 'PC':
      return 'productCategory';
    case 'O':
      return 'order';
    case 'PU':
      return 'partnerUser';
    case 'RT':
      return 'refreshToken';
    case 'CO':
      return 'country';
    case 'ET':
      return 'emailVerificationToken';
    case 'PN':
      return 'emailVerificationToken';
    case 'G':
      return 'general';
    case 'ST':
      return 'statistics';
    case 'PT':
      return 'emailVerificationToken' || 'partnerToken';
    case 'U':
      return 'user';
    case 'PCR':
      return 'productCategoryRequests';
    case 'D':
      return 'applicationSettings';
    case 'I':
      return 'inventory';
    case 'AU':
      return 'appUsers';
    default:
      return 'general';
  }
};

export const readErrorCode = (code: string) => {
  const featureType = getFeatureType(code);
  const splicedCode = code.split('.')[0];
  const key = ERROR_CODES[featureType] as Record<string, string>;
  const translationKey = key[splicedCode];
  return `${featureType}.${translationKey}`;
};
