import { ACButton } from '@antech-consulting/components';
import { FORM_ELEMENT_CODE } from 'constants/FormConstants';
import {
  authCode,
  authDescriptionRegister,
  authForm,
  authIcon,
  authImage,
  authTitleRegister,
} from 'features/auth/constants/StyleConstants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { Call } from 'iconsax-react';
import { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCountryCodesQuery } from 'services';

import { ErrorMessage } from '../../../../constants/Errors/ErrorTypes';
import { style } from '../../../../constants/StyleConstants';
import { setCurrentUser } from '../../../../store/Auth';
import {
  useIssueSmsCodeMutation,
  useLoginMutation,
  useVerifySmsCodeMutation,
} from '../../services/index';
import { UserData } from '../RegistrationSuccess/RegistrationSuccess';
import styles from './VerifyPhoneNumber.module.scss';

export type PhoneNumberData = {
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
};

const CODE_INPUTS = 6;

const VerifyPhoneNumber = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { displayError, displaySuccess, displayWarning } = useDisplayNotification();
  const [verifySmsCode] = useVerifySmsCodeMutation();
  const [issueSmsCode] = useIssueSmsCodeMutation();
  const [phoneCode, setPhoneCode] = useState('');
  const [login] = useLoginMutation();
  const { data: countryCodes } = useGetCountryCodesQuery();

  const userDataLogin = localStorage.getItem('LoginUserData');
  let userLogin;
  const userDataRegistration = localStorage.getItem('RegistrationUserData');
  let userRegistration;
  if (userDataLogin) {
    userLogin = JSON.parse(userDataLogin) as UserData;
  } else if (userDataRegistration) {
    userRegistration = JSON.parse(userDataRegistration) as UserData;
  }

  const userDataPhoneNumber = localStorage.getItem('RegistrationUserPhoneNumber');
  let userPhoneNumber;
  const userDataChangePhoneNumber = localStorage.getItem('ChangePhoneNumberUserPhoneNumber');
  let userChangePhoneNumber;
  if (userDataPhoneNumber) {
    userPhoneNumber = JSON.parse(userDataPhoneNumber) as PhoneNumberData;
  }
  if (userDataChangePhoneNumber) {
    userChangePhoneNumber = JSON.parse(userDataChangePhoneNumber) as PhoneNumberData;
  }

  const countryCode = countryCodes?.find((code) => {
    if (userChangePhoneNumber) {
      if (code?.isoName === userChangePhoneNumber?.phoneNumberCountryCode) return code;
    } else if (userPhoneNumber) {
      if (code?.isoName === userPhoneNumber?.phoneNumberCountryCode) return code;
    } else return null;
  });

  const handlePhoneCodeChange = (value: string) => {
    setPhoneCode(value);
  };

  const resendSmsCode = async () => {
    try {
      await issueSmsCode().unwrap();
      displaySuccess({ key: 'sms.resendSmsCode' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayWarning({ key: code });
    }
  };

  const changePhoneNumber = () => {
    navigate('/change-phone-number');
  };

  const handleLogin = async () => {
    const userData = await login(userLogin).unwrap();
    dispatch(setCurrentUser(userData));
  };

  const handleRegistration = async () => {
    const userData = await login(userRegistration).unwrap();
    dispatch(setCurrentUser(userData));
  };

  const handleSubmit = async () => {
    const verifyPhoneNumberDTO = {
      code: phoneCode.toString(),
    };
    try {
      await verifySmsCode(verifyPhoneNumberDTO).unwrap();
      userLogin ? handleLogin() : handleRegistration();
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  return (
    <div style={authForm}>
      <div style={authImage}>
        <Call style={authIcon} />
      </div>
      <h3 style={authTitleRegister}>{t('verifyPhoneNumber.title')}</h3>
      <p style={authDescriptionRegister}>
        {t('verifyPhoneNumber.descriptionFirstPart')}
        {userPhoneNumber || userChangePhoneNumber ? '+' + countryCode?.callingCode : null}
        {userChangePhoneNumber ? userChangePhoneNumber?.phoneNumber : userPhoneNumber?.phoneNumber}
        {t('verifyPhoneNumber.descriptionSecondPart')}
      </p>
      <div className={styles?.input} data-testid='input'>
        <ReactCodeInput
          data-testid='react-code-input'
          fields={CODE_INPUTS}
          inputMode='numeric'
          inputStyle={authCode}
          name={FORM_ELEMENT_CODE}
          onChange={(value) => handlePhoneCodeChange(value)}
          type='text'
          value={phoneCode}
        />
      </div>
      <ACButton
        className={styles.buttonWrapper}
        dataTestid='ButtonVerifyPhoneNumber'
        fullWidth
        onClick={handleSubmit}
        style={style.Button}
      >
        {t('verifyPhoneNumber.button')}
      </ACButton>
      <div className={styles.buttonsWrapper}>
        <ACButton
          className={styles.buttonWrapper}
          dataTestid='ButtonResendSmsCode'
          fullWidth
          onClick={resendSmsCode}
          style={style.Button}
        >
          {t('verifyPhoneNumber.buttonResendSmsCode')}
        </ACButton>
        <ACButton
          className={styles.buttonWrapper}
          dataTestid='ButtonChangePhoneNumber'
          fullWidth
          onClick={changePhoneNumber}
          style={style.Button}
        >
          {t('verifyPhoneNumber.buttonChangePhoneNumber')}
        </ACButton>
      </div>
    </div>
  );
};

export default VerifyPhoneNumber;
