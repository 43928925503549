import { FORM_ELEMENT_FROM, FORM_ELEMENT_TO } from 'constants/FormConstants';
import { FormDataTypePlace } from 'features/place/constants/FormConstants';
import { FormDataType as FormDataTypeProduct } from 'features/product/constants/FormConstants';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  countNumericDigits,
  isEmptyField,
  validateEmail,
  validateNumberDecimalField,
  validateNumberField,
  validatePassword,
  validatePhoneNumber,
  validatePriceField,
  validateWebsite,
} from '../utils/helpers';
import useDisplayNotification from './useDisplayNotification';

export type FormValidationType = {
  [key: string]: {
    accountDigits?: number;
    formatPlaceholder?: string;
    isDateCurrentOrBefore?: boolean;
    isDateGreaterThan?: string;
    isDateLessThen?: string;
    isEmail?: boolean;
    isNumberDecimal?: boolean;
    isPasswordMatch?: string;
    isPhoneNumber?: boolean;
    isPlacePhoneNumber?: boolean;
    isPriceNumber?: boolean;
    isRequired?: boolean;
    isRounded?: boolean;
    isValueGreater?: string;
    isWebsite?: boolean;
    max?: number;
    message?: string;
    min?: number;
    onlyNumbers?: boolean;
    onlyNumbersDecimal?: boolean;
    password?: boolean;
    regexValidation?: RegExp;
  };
};

type ACInputType = {
  ac: {
    name: string;
    value: Array<object> | string | object | number;
  };
};
export type ACEvent = HTMLInputElement & ACInputType;

export type ErrorsType = {
  [key: string]: string;
};

type UserEvent<TUserEvent> = TUserEvent | undefined;
export type ACEventType<TUserEvent, TValue> = UserEvent<TUserEvent> & {
  ac: {
    event?: string;
    name?: string;
    type?: string;
    value?: TValue;
  };
};

type ChangeValueType = {
  target: {
    value: string | number;
  };
};

export type HandleChangeType = ACEventType<
  ChangeEvent<HTMLInputElement> | ChangeValueType,
  string | number
>;

const validNames = [
  'mondayFrom',
  'mondayTo',
  'tuesdayFrom',
  'tuesdayTo',
  'wednesdayFrom',
  'wednesdayTo',
  'thursdayFrom',
  'thursdayTo',
  'fridayFrom',
  'fridayTo',
  'saturdayFrom',
  'saturdayTo',
  'sundayFrom',
  'sundayTo',
];
export const useForm = <FormDataType>(
  formDataInitialState: FormDataType,
  formValidationSchema: FormValidationType,
) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormDataType>(formDataInitialState);
  const [errors, setErrors] = useState<ErrorsType>(null);
  const { displayWarning } = useDisplayNotification();

  const handleChangeFormData = (event: HandleChangeType, inputName?: string) => {
    if (!inputName) {
      const {
        ac: { name, value },
      } = event;
      if (errors) {
        const errorMessage = validateField(name, value);
        setErrors((errors: ErrorsType) => ({
          ...errors,
          [name]: errorMessage,
        }));
      }
      if (validNames.includes(name)) {
        setFormData((prev) => {
          if ('workingHours' in (prev as object)) {
            const { workingHours } = prev as FormDataTypePlace;
            return {
              ...prev,
              workingHours: { ...workingHours, [name]: value === undefined ? '' : value },
              [name]: value === undefined ? '' : value,
            };
          }
          if ('pickupHours' in (prev as object)) {
            const { pickupHours } = prev as FormDataTypeProduct;
            return {
              ...prev,
              pickupHours: { ...pickupHours, [name]: value === undefined ? '' : value },
              [name]: value === undefined ? '' : value,
            };
          }
        });
      } else {
        setFormData((prev) => {
          return { ...prev, [name]: value };
        });
      }
    } else {
      if (errors) {
        const errorMessage = validateField(inputName, event.target.value);
        setErrors((errors: ErrorsType) => ({
          ...errors,
          [inputName]: errorMessage,
        }));
      }
      setFormData((prev) => {
        return { ...prev, [inputName]: event.target.value };
      });
    }
  };

  // TODO: Add more validations and custom regex
  // TODO: fix any
  const validateField = (fieldName: string, value: any) => {
    const rules = formValidationSchema[fieldName];
    if (!rules) return;
    let errorMessage = '';
    if (rules.isRequired && isEmptyField(value)) {
      errorMessage = t('validation.required');
    } else if (rules.regexValidation && !rules.regexValidation.test(value)) {
      errorMessage = t('validation.format');
    } else if (rules.min && value.length < rules.min) {
      errorMessage =
        `${t('validation.minLength')}` + `${rules.min}` + `${t('validation.characters')}`;
    } else if (rules.max && value.length > rules.max) {
      errorMessage =
        `${t('validation.maxLength')}` + `${rules.max}` + `${t('validation.characters')}`;
    } else if (rules.max && typeof value === 'number' && value.toString().length > rules.max) {
      errorMessage =
        `${t('validation.maxLength')}` + `${rules.max}` + `${t('validation.characters')}`;
    } else if (rules?.isDateGreaterThan && value > formData[rules?.isDateGreaterThan]) {
      errorMessage =
        rules?.isDateGreaterThan === FORM_ELEMENT_TO
          ? t('validation.startDate')
          : t('validation.time');
    } else if (rules?.isDateLessThen && value < formData[rules?.isDateLessThen]) {
      errorMessage =
        rules?.isDateLessThen === FORM_ELEMENT_FROM
          ? t('validation.startDate')
          : t('validation.time');
    } else if (value < rules.min) {
      errorMessage = `${t('validation.minCharacter')}` + `${rules.min}!`;
    } else if (rules.isEmail && !validateEmail(value)) {
      errorMessage = t('validation.email');
    } else if (rules?.password && !validatePassword(value)) {
      errorMessage = t('validation.password');
    } else if (
      rules?.isValueGreater &&
      Number(value) < Number(formData[rules?.isValueGreater].minimumOrderAmount)
    ) {
      errorMessage =
        `${t('validation.valueGreater')}` + `${formData[rules?.isValueGreater].minimumOrderAmount}`;
    } else if (rules?.isPasswordMatch && value !== formData[rules?.isPasswordMatch]) {
      errorMessage = t('validation.passwordMatch');
    } else if (rules?.isPhoneNumber && !validatePhoneNumber(value, false) && value !== '') {
      errorMessage = t('validation.phoneNumberDigits');
    } else if (rules?.onlyNumbers && !validateNumberField(value)) {
      errorMessage = t('validation.onlyNumbers');
    } else if (rules?.isNumberDecimal && !validateNumberDecimalField(value)) {
      errorMessage = t('validation.onlyNumbersDecimal');
    } else if (rules?.isPriceNumber && !validatePriceField(value)) {
      errorMessage = t('validation.onlyNumbersDecimalPrice');
    } else if (rules?.isWebsite && !validateWebsite(value) && value !== '') {
      errorMessage = t('validation.format');
    } else if (rules?.isDateCurrentOrBefore && new Date(value) > new Date()) {
      errorMessage = t('validation.futureDate');
    } else if (rules?.isPlacePhoneNumber && !validatePhoneNumber(value, true) && value !== '') {
      errorMessage = t('validation.placePhoneNumber');
    } else if (rules?.isRounded && !validateNumberField(value)) {
      errorMessage = t('validation.roundedNumbersOnly');
    } else if (rules?.accountDigits && countNumericDigits(value) > rules.accountDigits) {
      errorMessage = t('validation.maxDigitsAccount');
    }
    return errorMessage;
  };

  const validateFieldsOnSubmit = useCallback(() => {
    let errorsLocal: ErrorsType = null;
    for (const property in formData) {
      const errorMessage = validateField(property, formData[property]);
      if (errorMessage) {
        errorsLocal = { ...errorsLocal, [property]: errorMessage };
      }
    }
    setErrors(errorsLocal);
    return errorsLocal === null;
  }, [formData]);

  const handleSubmit = (callback: () => void) => {
    const isValid = validateFieldsOnSubmit();
    if (!isValid) return displayWarning({ message: 'missingData' });
    callback();
  };

  return {
    formData,
    errors,
    setFormData,
    handleSubmit,
    handleChangeFormData,
    setErrors,
  };
};
