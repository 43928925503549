import api from './../../../services/api';
import getPartnerApplicationSettings from './getPartnerApplicationSettings';
import getUserApplicationSettings from './getUserApplicationSettings';
import getWebApplicationSettings from './getWebApplicationSettings';
import updatePartnerApplicationSettings from './updatePartnerApplicationSettings';
import updateUserApplicationSettings from './updateUserApplicationSettings';
import updateWebApplicationSettings from './updateWebApplicationSettings';

export const maintenanceSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserApplicationSettings: getUserApplicationSettings(build),
    getPartnerApplicationSettings: getPartnerApplicationSettings(build),
    getWebApplicationSettings: getWebApplicationSettings(build),
    updateUserApplicationSettings: updateUserApplicationSettings(build),
    updatePartnerApplicationSettings: updatePartnerApplicationSettings(build),
    updateWebApplicationSettings: updateWebApplicationSettings(build),
  }),
});

export const {
  useGetPartnerApplicationSettingsQuery,
  useGetUserApplicationSettingsQuery,
  useGetWebApplicationSettingsQuery,
  useUpdatePartnerApplicationSettingsMutation,
  useUpdateUserApplicationSettingsMutation,
  useUpdateWebApplicationSettingsMutation,
} = maintenanceSettingsApi;
