import Config from 'config';
import { FORM_ELEMENT_COUNTRY, FORM_ELEMENT_PLACE, LANGUAGES } from 'constants/FormConstants';
import translit from 'cyrillic-to-latin';
import { useAppSelector } from 'hooks/hooks';
import { useEffect, useMemo, useState } from 'react';

import { useLazyGetLocationQuery, useLazyGetMapLocationQuery } from '../services';

export const DEBOUNCED_TERM_LENGTH = 2;

const useMapForm = ({ addMarker, coordinates, formData, initialLocation, isClickedOnMap }) => {
  const [fetchLocation, { data: location, isFetching: isFetchingLocation, isSuccess }] =
    useLazyGetLocationQuery();
  const [fetchMapLocation, { data: mapLocation, isFetching, isSuccess: isSuccessMapLocation }] =
    useLazyGetMapLocationQuery();
  const [inputValue, setInputValue] = useState('');
  const { language } = useAppSelector(({ language }) => language);

  const handleMapInputChange = (value) => {
    setInputValue(value);
  };

  const mapOptions = useMemo(() => {
    return location && inputValue !== ''
      ? location?.content?.map((el) => {
          const { context, place_name, place_name_sr } = el;
          const placeName =
            language === LANGUAGES.EN
              ? place_name?.split(',').splice(0, 2).join(',')
              : place_name_sr?.split(',').splice(0, 2).join(',');
          const country = el ? context?.find((context) => context.id.includes('country')) : '';
          const countrySr = el && country ? translit(country?.text_sr) : '';
          const countryEn = el && country ? country?.text : '';
          const countryName = language === LANGUAGES.EN ? countryEn : countrySr;
          const locationName = el ? `${placeName}, ${countryName} ` : '';
          return {
            ...el,
            uuid: el?.id,
            name: el ? translit(locationName) : '',
          };
        })
      : [];
  }, [location?.content, inputValue]);

  useEffect(() => {
    inputValue.length > DEBOUNCED_TERM_LENGTH &&
      fetchLocation({
        accessToken: Config.MAP_KEY,
        search: inputValue.trim(),
      });
  }, [inputValue]);

  useEffect(() => {
    if (initialLocation && addMarker && isClickedOnMap) {
      const { latitude, longitude } = coordinates;
      fetchMapLocation({
        latitude,
        longitude,
        accessToken: Config.MAP_KEY,
      });
    }
  }, [coordinates, initialLocation, addMarker, isClickedOnMap]);

  const mapClickedData = useMemo(() => {
    const mapStreetNumber =
      mapLocation && mapLocation?.content?.address ? mapLocation?.content?.address : '';
    const city =
      language === LANGUAGES.EN
        ? (translit(
            mapLocation?.content?.context?.find((context) =>
              context.id.includes(FORM_ELEMENT_PLACE),
            )?.text || '',
          ) as string)
        : (translit(
            mapLocation?.content?.context?.find((context) =>
              context.id.includes(FORM_ELEMENT_PLACE),
            )?.text_sr || '',
          ) as string);
    const mapCity = mapLocation && city;
    const mapZipCode =
      mapLocation &&
      mapLocation?.content?.context?.find((context) => context.id.includes('postcode'))?.text;
    const mapStreet = mapLocation && (translit(mapLocation?.content?.text || '') as string);
    const country =
      language === LANGUAGES.EN
        ? (translit(
            mapLocation?.content?.context?.find((context) =>
              context.id.includes(FORM_ELEMENT_COUNTRY),
            )?.text || '',
          ) as string)
        : (translit(
            mapLocation?.content?.context?.find((context) =>
              context.id.includes(FORM_ELEMENT_COUNTRY),
            )?.text_sr || '',
          ) as string);
    const mapCountryEn = mapLocation
      ? (translit(
          mapLocation?.content?.context?.find((context) =>
            context.id.includes(FORM_ELEMENT_COUNTRY),
          )?.text || '',
        ) as string)
      : '';
    const mapCountry = mapLocation ? country : '';
    const citySr = mapLocation
      ? translit(
          mapLocation?.content?.context?.find((context) => context.id.includes(FORM_ELEMENT_PLACE))
            .text_sr,
        )
      : '';
    const chosenLocation = mapLocation && {
      ...mapLocation?.content,
      name: `${mapStreet} ${mapStreetNumber}, ${mapCity}, ${mapZipCode}, ${mapCountry}`,
      uuid: mapLocation?.content?.text,
    };
    return (
      isSuccessMapLocation && {
        mapStreetNumber,
        mapZipCode,
        mapStreet,
        chosenLocation,
        citySr,
        mapCountryEn,
      }
    );
  }, [mapLocation, coordinates, isSuccessMapLocation]);

  return {
    handleMapInputChange,
    mapOptions,
    mapClickedData,
    isSuccessMapLocation,
    isFetching,
    isFetchingLocation,
    language,
    setInputValue,
  };
};

export default useMapForm;
