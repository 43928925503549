import { useDispatch } from 'react-redux';
import {
  NotificationState,
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage,
  setWarningMessage,
} from 'store/Notification';

const useDisplayNotification = (): {
  displayError: ({ key, timeout }: NotificationState) => void;
  displayInformation: ({ key, timeout }: NotificationState) => void;
  displaySuccess: ({ key, timeout }: NotificationState) => void;
  displayWarning: ({ key, message, timeout }: NotificationState) => void;
} => {
  const dispatch = useDispatch();

  const displayError = ({ key, timeout }: NotificationState) => {
    dispatch(setErrorMessage({ key, timeout }));
  };

  const displaySuccess = ({ key, timeout }: NotificationState) => {
    dispatch(setSuccessMessage({ key, timeout }));
  };

  const displayWarning = ({ key, message, timeout }: NotificationState) => {
    dispatch(setWarningMessage({ key, timeout, message }));
  };

  const displayInformation = ({ key, timeout }: NotificationState) => {
    dispatch(setInfoMessage({ key, timeout }));
  };

  return { displayError, displaySuccess, displayInformation, displayWarning };
};

export default useDisplayNotification;
