import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PARTNER_APPLICATION_SETTINGS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PartnerApplicationSettingsResponse, void>({
    query: () => '/system/partner-application-setting',
    providesTags: [TAG_PARTNER_APPLICATION_SETTINGS],
  });

type PartnerApplicationSettingsResponse = {
  currentAndroidVersion: string;
  dashboardImageQualitySettings: DashboardImageQualitySettings;
  dashboardMaintenanceMode: boolean;
  firebaseTokenValidity: string;
  maintenanceModeMessage: string;
  maintenanceModeTitle: string;
  minimalRequiredAndroidVersion: string;
  mobileMaintenanceMode: boolean;
  placeDetail: string;
  placeList: string;
  productCategoryAll: string;
  productDetail: string;
  productList: string;
};

type DashboardImageQualitySettings = {
  placeDetail: string;
  placeList: string;
  productCategoryAll: string;
  productDetail: string;
  productList: string;
};
