import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<UserResponse, RequestParameters>({
    query: (credentials: RequestParameters) => ({
      url: '/token/login/partner-user',
      method: 'POST',
      body: credentials,
    }),
  });

export type UserResponse = {
  accessToken: string;
  accessTokenExpireDate: string;
  refreshToken: string;
  refreshTokenExpireDate: string;
  roles: string[];
};

export type RequestParameters = { email?: string; password?: string };
