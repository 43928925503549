import { ItemsSoldPercentage } from 'features/place/services/getPlaceStatistics';
import useTable from 'hooks/useTable';
import { useMemo } from 'react';

import StyledACTable from '../../../../../components/StyledACTable/StyledACTable';
import statisticsTableProductsSettings from './statisticsTableProductsSettings';

const TableProductsStatistics = ({ tableData }: { tableData: Array<ItemsSoldPercentage> }) => {
  const tableSettings = useTable(statisticsTableProductsSettings);

  const rows = useMemo(() => {
    return tableData?.map((data) => {
      return {
        rawData: {
          ...tableData,
          name: data.name,
          sold: data.sold,
          percentage: data.percentage,
        },
        render: {
          ...tableData,
          name: data.name,
          sold: data.sold,
          percentage: data.percentage,
        },
      };
    });
  }, [tableData]);

  return <StyledACTable {...tableSettings} rows={rows} statistics={true} tableFooter={false} />;
};
export default TableProductsStatistics;
