import api from '../../../services/api';
import archiveProduct from './archiveProduct';
import createProduct from './createProduct';
import deleteProduct from './deleteProduct';
import getArchivedProducts from './getArchivedProducts';
import getProductById from './getProductById';
import getProductRating from './getProductRatings';
import getProducts from './getProducts';
import getProductsRating from './getProductsRating';
import getProductsStock from './getProductsStock';
import removeProductImage from './removeProductImage';
import unarchiveProduct from './unarchiveProduct';
import updateProduct from './updateProduct';
import updateProductsStock from './updateProductsStock';
import updateProductStock from './updateProductStock';
import uploadProductImage from './uploadProductImage';

export const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: getProducts(build),
    getProductById: getProductById(build),
    createProduct: createProduct(build),
    updateProduct: updateProduct(build),
    uploadProductImage: uploadProductImage(build),
    removeProductImage: removeProductImage(build),
    updateProductStock: updateProductStock(build),
    updateProductsStock: updateProductsStock(build),
    getProductRating: getProductRating(build),
    archiveProduct: archiveProduct(build),
    deleteProduct: deleteProduct(build),
    unarchiveProduct: unarchiveProduct(build),
    getArchivedProducts: getArchivedProducts(build),
    getProductsRating: getProductsRating(build),
    getProductsStock: getProductsStock(build),
  }),
  overrideExisting: false,
});

export const {
  useArchiveProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductsStockQuery,
  useLazyGetArchivedProductsQuery,
  useLazyGetProductByIdQuery,
  useLazyGetProductRatingQuery,
  useLazyGetProductsQuery,
  useLazyGetProductsRatingQuery,
  useRemoveProductImageMutation,
  useUnarchiveProductMutation,
  useUpdateProductMutation,
  useUpdateProductStockMutation,
  useUpdateProductsStockMutation,
  useUploadProductImageMutation,
} = productApi;
