import './UploadForm.scss';

import { ACUpload } from '@antech-consulting/components';
import {
  UPLOAD_IMAGE_JPEG,
  UPLOAD_IMAGE_JPG,
  UPLOAD_IMAGE_PNG,
  UPLOAD_SIZE,
  UPLOAD_UNIT_MB,
} from 'constants/FormConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { useTranslation } from 'react-i18next';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { style } from '../../../../constants/StyleConstants';
import { FormDataType } from '../../constants/FormConstants';
import styles from './UploadForm.module.scss';

type UploadFormProps = {
  file: File[] | File;
  formData: FormDataType;
  handleChange: (e: any, prev: File[] | File) => void;
  handleFileDelete?: () => void;
};

const styleUpload = {
  selectedItemStyle: {
    width: '100px',
    height: '100px',
  },
  uploadButtonStyle: {
    width: '100px',
    height: '100px important',
  },
};

const UploadForm = ({ formData, handleChange, handleFileDelete }: UploadFormProps) => {
  const { t } = useTranslation();
  const { document } = formData;

  const { data } = useGetPartnerApplicationSettingsQuery();
  const productImageQuality = data?.dashboardImageQualitySettings?.productDetail;

  const imageUrl = document && document[productImageQuality];
  const defaultValue = document
    ? {
        id: document,
        url: imageUrl,
      }
    : undefined;

  return (
    <form className={`uploadProductForm  ${styles.uploadDocumentSection}`}>
      <FormHeader
        primaryText={t('product.subtitleUpload')}
        secondaryText={t('product.descriptionUpload')}
      />
      <p className={styles.uploadText}>{t('product.uploadFormat')}</p>
      <p className={styles.uploadText}>{t('product.uploadSize')}</p>
      <ACUpload
        color='tasty'
        defaultValue={defaultValue}
        fileSizeTooBigMessage={t('upload.fileSizeMessage')}
        isFileNameVisible={false}
        limitSize={{ size: UPLOAD_SIZE, unit: UPLOAD_UNIT_MB }}
        multiple={false}
        onChange={handleChange}
        onDeleteDefault={handleFileDelete}
        onlyAcceptedFileTypes={[UPLOAD_IMAGE_PNG, UPLOAD_IMAGE_JPEG, UPLOAD_IMAGE_JPG]}
        orientation='horizontal'
        selectedItemStyle={styleUpload.selectedItemStyle}
        styled={style.ACUpload}
        type='image'
        uploadButtonStyle={styleUpload.uploadButtonStyle}
      />
    </form>
  );
};

export default UploadForm;
