import { ACLoading, ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { style } from 'constants/StyleConstants';
import { InventoryDTO } from 'features/inventory/services/createInventory';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useToggle from 'hooks/useToggle';
import { Trash } from 'iconsax-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/inventory/components/BaseForm/BaseForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/inventory/constants/FormConstants';
import { validationSchema } from '../../features/inventory/constants/InventorySchema';
import {
  transformToDTOFormat,
  useInventoriesForm,
} from '../../features/inventory/hooks/useInventoryForm';
import {
  useDeleteInventoryMutation,
  useUpdateInventoryMutation,
} from '../../features/inventory/services';
import { useForm } from '../../hooks/useForm';
import styles from './EditInventory.module.scss';

const EditInventory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const [update, { isLoading }] = useUpdateInventoryMutation();
  const [deleteInventory, { isLoading: isLoadingDelete }] = useDeleteInventoryMutation();
  const [isDeleteDialogOpen, toggleDelete] = useToggle(false);

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);
  const { countriesOptions, isSuccessEdit, placeOptions, shouldDisableSubmitButton } =
    useInventoriesForm(formData, setFormData, { id });

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const inventoryUpdateDTO: InventoryDTO = transformToDTOFormat({ ...trimmedData, uuid: id });
    try {
      await update(inventoryUpdateDTO).unwrap();
      navigate('/inventories');
      displaySuccess({ key: 'inventory.updateInventory' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = useCallback(() => {
    navigate('/inventories');
  }, []);

  const onDelete = () => {
    toggleDelete();
  };

  const handleDelete = async () => {
    try {
      await deleteInventory(id).unwrap();
      toggleDelete();
      navigate('/inventories');
      displaySuccess({ key: 'inventory.removeInventory' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  return !isLoading || !isLoadingDelete ? (
    <>
      <div className={styles.wrapper}>
        {isSuccessEdit ? (
          <BaseForm
            countriesOptions={countriesOptions}
            errors={errors}
            formData={formData}
            handleChange={handleChangeFormData}
            placesOptions={placeOptions}
            setFormData={setFormData}
          />
        ) : (
          <ACLoading
            isLoading={!isSuccessEdit}
            loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
          />
        )}
      </div>
      {isDeleteDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDelete}
          header={t('deleteModal.headerRemoveInventory')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteDialogOpen}
          message={t('deleteModal.messageRemoveInventory')}
        />
      )}
      <FormStickyFooter
        onDelete={onDelete}
        onDeleteText={t('footer.deleteButton')}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={shouldDisableSubmitButton}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditInventory;
