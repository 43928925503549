import { APP_USERS_CODES } from './app-users-codes';
import { APPLICATION_SETTINGS_CODES } from './application-settings-codes';
import { COMPANY_CODES } from './company-codes';
import { COMPANY_REQUESTS_CODES } from './company-requests-codes';
import { COUNTRY_CODES } from './country-codes';
import { GENERAL_CODES } from './general-codes';
import { INVENTORY_CODES } from './inventory-code';
import { ORDER_CODES } from './orders-codes';
import { PARTNER_USER_CODES } from './partner-user-codes';
import { PLACE_CODES } from './place-codes';
import { PRODUCT_CATEGORY_CODES } from './product-category-codes';
import { PRODUCT_CATEGORY_REQUESTS_CODES } from './product-category-requests-codes';
import { PRODUCT_CODES } from './product-codes';
import { REFRESH_TOKEN_CODES } from './refresh-token-codes';
import { STATISTICS_CODES } from './statistics-codes';
import { USER_CODES } from './user-codes';
import { VERIFICATION_CODES } from './verification-token-codes';

export const ERROR_CODES = {
  productCategory: PRODUCT_CATEGORY_CODES,
  place: PLACE_CODES,
  company: COMPANY_CODES,
  companyRequest: COMPANY_REQUESTS_CODES,
  product: PRODUCT_CODES,
  order: ORDER_CODES,
  partnerUser: PARTNER_USER_CODES,
  refreshToken: REFRESH_TOKEN_CODES,
  country: COUNTRY_CODES,
  emailVerificationToken: VERIFICATION_CODES,
  general: GENERAL_CODES,
  statistics: STATISTICS_CODES,
  forgotPasswordToken: VERIFICATION_CODES,
  user: USER_CODES,
  productCategoryRequests: PRODUCT_CATEGORY_REQUESTS_CODES,
  applicationSettings: APPLICATION_SETTINGS_CODES,
  inventory: INVENTORY_CODES,
  appUsers: APP_USERS_CODES,
};
