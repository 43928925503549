import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { PartnerUser } from 'features/partnerUser/services/getPartnerUsers';
import { Place } from 'features/place/services/createPlace';
import { TAG_ORDER } from 'services/TagsConstants';
import { store } from 'store/store';

import { handleDateAndTime, transformSnakeCaseToText } from '../../../utils/helpers';
import { Product, Products } from '../../product/services/getProducts';
import { Order } from './getOrders';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<OrderDTO, string>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const orderResponse = await fetchWithBQ(`/order/${_arg}`);
      const orderData = orderResponse.data as OrderDTO;
      const products = orderData?.items.map((item) => item.product);
      const productsIds = products.map((product) => product.uuid);
      const productsResponse = await fetchWithBQ(
        `/products?ids=${productsIds}&statuses=ACTIVE,ARCHIVED`,
      );
      const productsData = productsResponse.data as Products;
      const documents = productsData.content.map((product) =>
        product.document ? product.document : null,
      );
      const documentIds = documents.filter((id) => id !== null);
      const documentResponse =
        documentIds?.length > 0
          ? await fetchWithBQ(`/documents/download-links?ids=${documentIds}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;

      const { cities } = store.getState().globalData;
      const matchedCity = cities?.find((city) => {
        return city.uuid === orderData?.place?.city;
      });
      const countriesContent = store.getState().auth.userCountry;
      const countries = store.getState().globalData.countries;
      const country = countries.find((country) => country.uuid === orderData.country);
      const { isoCurrency } = country;
      const { dateFormat } = countriesContent;
      const { language } = store.getState().language;

      const orderItem = orderData?.items.map((item: OrderItemDTO) => {
        const product = productsData.content.find(
          (product) => product?.uuid === item?.product?.uuid,
        );
        const documentImage = documentData ? documentData[product?.document] : null;
        return {
          ...item,
          total: `${item.discountedPrice * item.quantity} ${isoCurrency}`,
          baseProductPrice: `${item.basePrice} ${isoCurrency}`,
          productPrice: `${item.discountedPrice} ${isoCurrency}`,
          document: documentImage,
        };
      });
      const { createdAt } = orderData;
      const createdAtFormatted = handleDateAndTime(createdAt, dateFormat, language);
      const { formattedDate, formattedTime } = createdAtFormatted;
      const percentage = (orderData?.totalDiscountedPrice * 100) / orderData?.totalBasePrice - 100;
      const place = {
        ...orderData?.place,
        city: matchedCity,
      };
      return {
        data: {
          ...orderData,
          created: `${formattedDate}, ${formattedTime}`,
          items: orderItem,
          status: transformSnakeCaseToText(orderData?.status),
          totalProductBasePrice: `${orderData?.totalBasePrice} ${isoCurrency}`,
          totalProductDiscountedPrice: `${orderData?.totalDiscountedPrice} ${isoCurrency}`,
          percentage: percentage.toFixed(2),
          place,
        },
      };
    },
    providesTags: [TAG_ORDER],
  });

export type OrderItemDTO = {
  basePrice: number;
  discountedPrice: number;
  document: string;
  product: Product;
  productPrice: string;
  quantity: number;
  total: string;
  uuid: string;
};

export type OrderDTO = Omit<Order, 'place' | 'totalDiscountedPrice' | 'createdBy'> & {
  canceledAt: string;
  created: string;
  createdBy?: PartnerUser;
  items: OrderItemDTO[];
  percentage: number | string;
  place: Place;
  rating?: number;
  ratingComment?: string;
  rejectMessage: string;
  rejectedAt: string;
  totalDiscountedPrice: number;
};

export type OrderProps = {
  order: OrderDTO;
};

export type OrderDetailViewProps = {
  orderId: string;
};
