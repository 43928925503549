import { createSlice } from '@reduxjs/toolkit';
import { CityDTO } from 'constants/TypeConstants';
import { Country } from 'services/country';

export type GlobalData = {
  cities: CityDTO[];
  countries: Country[];
};

export const globalDataInitialState: GlobalData = {
  countries: null,
  cities: null,
};

const slice = createSlice({
  name: 'globalData',
  initialState: globalDataInitialState,
  reducers: {
    setCountries: (state, { payload }) => {
      state.countries = payload as Country[];
    },
    setCities: (state, { payload }) => {
      state.cities = payload as CityDTO[];
    },
    removeGlobalData: (state) => {
      state.countries = null;
      state.cities = null;
    },
  },
});

export const { removeGlobalData, setCities, setCountries } = slice.actions;

export default slice.reducer;
