import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  maintenanceMode: {
    isRequired: true,
  },
  maintenanceModeTitle: {
    isRequired: true,
  },
  maintenanceModeMessage: {
    isRequired: true,
  },
  storyList: {
    isRequired: true,
  },
  storyDetail: {
    isRequired: true,
  },
  placeList: { isRequired: true },
  placeDetail: { isRequired: true },
  productList: { isRequired: true },
  productDetail: { isRequired: true },
  productCategoryAll: { isRequired: true },
  storyDetailHeader: { isRequired: true },
};
