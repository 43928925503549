import _isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { trimObjectValues } from 'utils/helpers';

import { FormDataType } from '../constants/FormConstants';
import { useGetProductCategoryRequestByIdQuery } from '../services';

export const useProductCategoryRequest = (
  formData: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  requestId?: string,
) => {
  const { data: productCategoryRequest, isSuccess } = useGetProductCategoryRequestByIdQuery({
    id: requestId,
  });

  const initialFormValues = () => {
    setFormData({
      ...productCategoryRequest,
    });
  };

  useEffect(() => {
    requestId && isSuccess && initialFormValues();
  }, [isSuccess, productCategoryRequest]);

  const shouldDisableSubmitButton = useMemo(() => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const disabled = _isEqual(trimmedData, productCategoryRequest);
    return disabled;
  }, [formData, productCategoryRequest]);

  return {
    shouldDisableSubmitButton,
    productCategoryRequest,
    isSuccess,
  };
};
