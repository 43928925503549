import { ACLoading, ACSpinner, IACTableRow } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { FORM_ELEMENT_COMPANY_REQUEST } from 'constants/FormConstants';
import { FORM_ELEMENT_COUNTRY } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import {
  STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED,
  STATUS_ELEMENT_APPROVED,
  STATUS_ELEMENT_PENDING,
  STATUS_ELEMENT_REJECTED,
} from 'constants/StatusConstants';
import { DEFAULT_SORT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { CompanyRequest, FilterProps } from 'features/company-request/services/getCompanyRequests';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useTable from 'hooks/useTable';
import useToggle from 'hooks/useToggle';
import {
  AddCircle,
  CloseCircle,
  Edit2,
  InfoCircle,
  MessageQuestion,
  TickCircle,
  Trash,
} from 'iconsax-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { style } from '../../../../constants/StyleConstants';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { getCompanyRequestStatusColor } from '../../../../utils/getColorStatus';
import { checkIfPropertyExistsInObject, transformACState } from '../../../../utils/helpers';
import {
  useApproveCompanyRequestMutation,
  useDeleteCompanyRequestMutation,
  useLazyGetCompanyRequestsQuery,
  useRejectCompanyRequestMutation,
  useRequestAdditionalInfoMutation,
} from '../../services';
import styles from './TableCompanyRequest.module.scss';
import tableCompanyRequestSettings from './tableCompanyRequestSettings';

const companyRequestTooltipStyle = {
  width: '120px',
  bottom: '100%',
  transform: 'translate(-50%, -10px)',
  left: '-20%',
};

export type CompanyRequestTableState = TableStateProps<FilterProps> & {
  uuid?: string;
};

const TableCompanyRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [selectedRow, setSelectedRow] = useState<CompanyRequest>(null);

  const [isDeleteModalOpen, toggleDelete] = useToggle();
  const [isApproveModalOpen, toggleApprove] = useToggle();
  const [isRejectModalOpen, toggleReject] = useToggle();
  const [isAddInfoModalOpen, toggleAddInfo] = useToggle();

  const [
    fetchCompanyRequests,
    { data: companyRequests, isFetching, isSuccess: isSuccessCompanyRequests },
  ] = useLazyGetCompanyRequestsQuery();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteCompanyRequestMutation();
  const [rejectRequest, { isLoading: isLoadingReject }] = useRejectCompanyRequestMutation();
  const [approveRequest, { isLoading: isLoadingApprove }] = useApproveCompanyRequestMutation();
  const [requestAddInfo, { isLoading: isLoadingAddInfo }] = useRequestAdditionalInfoMutation();

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const tableSettings = useTable(tableCompanyRequestSettings);
  const systemAdminIsLoggedIn = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);
  const { countries } = useAppSelector(({ globalData }) => globalData);

  const companyRequestTableData = useMemo(() => {
    return companyRequests?.content?.map((companyRequest) => {
      const { status } = companyRequest;
      const styleColor = getCompanyRequestStatusColor(status);
      return {
        rawData: companyRequest,
        render: {
          ...companyRequest,
          status: (
            <div className={styles[`${styleColor}`]}>
              <div>{t(`${FORM_ELEMENT_COMPANY_REQUEST}.${status}`)}</div>
            </div>
          ),
        },
      };
    });
  }, [companyRequests?.content]);

  const handleEdit = (rowData: any) => {
    checkIfPropertyExistsInObject(rowData, 'rowData') && (rowData = rowData.rowData);
    setSelectedRow(rowData);
    navigate(`/company-request/${rowData.uuid}`, { state: { companyID: rowData.uuid } });
  };

  const handleDetailView = (rowData: any) => {
    checkIfPropertyExistsInObject(rowData, 'rowData') && (rowData = rowData.rowData);
    setSelectedRow(rowData);
    navigate(`/company-request/${rowData.uuid}`, {
      state: { companyID: rowData.uuid },
    });
  };

  const handleDelete = ({ rowData }: { rowData: CompanyRequest }) => {
    toggleDelete();
    setSelectedRow(rowData);
  };

  const handleApprove = ({ rowData }: { rowData: CompanyRequest }) => {
    toggleApprove();
    setSelectedRow(rowData);
  };

  const handleApproveCompanyRequest = async () => {
    try {
      await approveRequest(selectedRow?.uuid).unwrap();
      toggleApprove();
      displaySuccess({ key: 'companyRequest.approveModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleApprove();
      displayError({ key: code });
    }
  };

  const handleReject = ({ rowData }: { rowData: CompanyRequest }) => {
    toggleReject();
    setSelectedRow(rowData);
  };

  const handleRejectCompanyRequest = async () => {
    try {
      await rejectRequest(selectedRow?.uuid).unwrap();
      toggleReject();
      displaySuccess({ key: 'companyRequest.rejectModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleReject();
      displayError({ key: code });
    }
  };

  const handleRequestAddInfo = ({ rowData }: { rowData: CompanyRequest }) => {
    toggleAddInfo();
    setSelectedRow(rowData);
  };

  const handleRequestAddInfoCompanyRequest = async () => {
    try {
      await requestAddInfo(selectedRow?.uuid).unwrap();
      toggleAddInfo();
      displaySuccess({ key: 'companyRequest.requestAddInfo' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleAddInfo();
      displayError({ key: code });
    }
  };

  const handleDeleteCompanyRequest = async () => {
    try {
      await deleteRequest(selectedRow?.uuid).unwrap();
      toggleDelete();
      displaySuccess({ key: 'companyRequest.deleteModalSuccess' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  const {
    approveCompanyRequest,
    deleteCompanyRequest,
    detailViewCompanyRequest,
    editCompanyRequest,
    rejectCompanyRequest,
    requestAddInfoCompanyRequest,
  } = {
    detailViewCompanyRequest: {
      icon: <InfoCircle data-testid='detailViewButton' />,
      onClick: handleDetailView,
      isHidden: ({ rawData }: IACTableRow) => {
        return (
          rawData.status !== STATUS_ELEMENT_APPROVED && rawData.status !== STATUS_ELEMENT_REJECTED
        );
      },
      tooltip: {
        text: t('companyRequest.detailViewCompanyRequest'),
        style: {
          width: '120px',
        },
      },
    },
    editCompanyRequest: {
      icon: <Edit2 data-testid='editButton' />,
      onClick: handleEdit,
      isHidden: ({ rawData }: IACTableRow) => {
        return (
          rawData.status !== STATUS_ELEMENT_PENDING &&
          rawData.status !== STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED
        );
      },
      tooltip: {
        text: t('companyRequest.updateCompanyRequest'),
        style: {
          width: '120px',
        },
      },
    },
    deleteCompanyRequest: {
      icon: <Trash data-testid='deleteButton' />,
      onClick: handleDelete,
      isHidden: ({ rawData }: IACTableRow) => {
        return (
          rawData.status !== STATUS_ELEMENT_PENDING &&
          rawData.status !== STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED
        );
      },
      tooltip: {
        text: t('companyRequest.deleteCompanyRequest'),
        style: companyRequestTooltipStyle,
      },
    },
    approveCompanyRequest: {
      icon: <TickCircle data-testid='approveButton' />,
      onClick: handleApprove,
      isHidden: ({ rawData }: IACTableRow) => {
        return (
          rawData.status !== STATUS_ELEMENT_PENDING &&
          rawData.status !== STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED
        );
      },
      tooltip: {
        text: t('companyRequest.approveCompanyRequest'),
        style: {
          width: '130px',
        },
      },
    },
    rejectCompanyRequest: {
      icon: <CloseCircle data-testid='rejectButton' />,
      onClick: handleReject,
      isHidden: ({ rawData }: IACTableRow) => {
        return (
          rawData.status !== STATUS_ELEMENT_PENDING &&
          rawData.status !== STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED
        );
      },
      tooltip: {
        text: t('companyRequest.rejectCompanyRequest'),
        style: { ...companyRequestTooltipStyle, left: '-50%' },
      },
    },
    requestAddInfoCompanyRequest: {
      icon: <MessageQuestion data-testid='requestAddInfoButton' />,
      onClick: handleRequestAddInfo,
      isHidden: ({ rawData }: IACTableRow) => {
        return rawData.status !== STATUS_ELEMENT_PENDING;
      },
      tooltip: {
        text: t('companyRequest.addInfoCompanyRequest'),
        style: {
          left: '0%',
        },
      },
    },
  };

  const handleChangeTableState = (tableState: CompanyRequestTableState) => {
    fetchCompanyRequests(transformACState(tableState, 'createdAt'));
  };

  const companyRequestStatuses = [
    { value: STATUS_ELEMENT_PENDING, render: t('companyRequest.PENDING') },
    { value: STATUS_ELEMENT_APPROVED, render: t('companyRequest.APPROVED') },
    { value: STATUS_ELEMENT_REJECTED, render: t('companyRequest.REJECTED') },
    {
      value: STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED,
      render: t('companyRequest.ADDITIONAL_INFO_REQUIRED'),
    },
  ];

  const additionalTableFilters = [
    {
      field: 'statuses',
      title: t('companyRequest.status'),
      filterType: 'selectSingle',
      filterSelectData: companyRequestStatuses,
    },
    {
      field: FORM_ELEMENT_COUNTRY,
      title: t('company.countryColumn'),
      filterType: 'selectSingle',
      filterSelectData: countries?.map((country) => {
        return { value: country.uuid, render: country.shortName };
      }),
    },
  ];

  const shouldHaveActionsColumn =
    companyRequestTableData &&
    companyRequestTableData.find(
      (c) =>
        c.rawData.status === STATUS_ELEMENT_PENDING ||
        c.rawData.status === STATUS_ELEMENT_ADDITIONAL_INFO_REQUIRED,
    );

  const partnerActions = [detailViewCompanyRequest, editCompanyRequest, deleteCompanyRequest];
  const adminActions = [
    detailViewCompanyRequest,
    editCompanyRequest,
    deleteCompanyRequest,
    approveCompanyRequest,
    rejectCompanyRequest,
    requestAddInfoCompanyRequest,
  ];

  const actions = shouldHaveActionsColumn && systemAdminIsLoggedIn ? adminActions : partnerActions;

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: companyRequests?.totalElements,
    isSuccess: isSuccessCompanyRequests,
    filters: [...tableSettings.filter, ...additionalTableFilters],
  });

  const handleAddRequest = () => {
    navigate('/company-request/add');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const additionalHeaderActions = [
    {
      title: t('companyRequest.addRequest'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('companyRequest.addRequest'),
      },
      onClick: handleAddRequest,
    },
  ];
  return (
    <>
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={actions}
          allItemsCount={companyRequests?.totalElements}
          defaultSort={DEFAULT_SORT}
          filter={filtersToDisplay}
          headerActions={additionalHeaderActions}
          headerTitle={t('companyRequest.tableTitle')}
          onItemClicked={(rowData) => handleDetailView({ rowData })}
          onTableStateChange={handleChangeTableState}
          pagesCount={companyRequests?.totalPages}
          rows={companyRequestTableData ?? []}
        />
      </ACLoading>
      {isDeleteModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDeleteCompanyRequest}
          header={t('companyRequest.titleDelete')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteModalOpen}
          message={t('companyRequest.descriptionDelete')}
        />
      )}
      {isApproveModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleApprove}
          disabled={isLoadingApprove}
          handleSubmit={handleApproveCompanyRequest}
          header={t('companyRequest.titleApprove')}
          icon={<TickCircle style={style.BlueIconModal} />}
          isShow={isApproveModalOpen}
          message={t('companyRequest.descriptionApprove')}
        />
      )}
      {isRejectModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleReject}
          disabled={isLoadingReject}
          handleSubmit={handleRejectCompanyRequest}
          header={t('companyRequest.titleReject')}
          icon={<CloseCircle style={style.RedIconModal} />}
          isShow={isRejectModalOpen}
          message={t('companyRequest.descriptionReject')}
        />
      )}
      {isAddInfoModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleAddInfo}
          disabled={isLoadingAddInfo}
          handleSubmit={handleRequestAddInfoCompanyRequest}
          header={t('companyRequest.titleRequestAddInfoModal')}
          icon={<InfoCircle style={style.BlueIconModal} />}
          isShow={isAddInfoModalOpen}
          message={t('companyRequest.descriptionRequestAddInfo')}
        />
      )}
    </>
  );
};
export default TableCompanyRequest;
