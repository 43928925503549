import { ACCheckBox, ACInput } from '@antech-consulting/components';
import {
  FORM_ELEMENT_NAME_EN,
  FORM_ELEMENT_NAME_HR,
  FORM_ELEMENT_NAME_SR,
} from 'constants/FormConstants';
import { AC_INPUT_MARGIN_BOTTOM, AC_INPUT_ROUND, AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { useTranslation } from 'react-i18next';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { FormDataType } from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

type BaseFormProductCategoryProps = BaseFormProps<FormDataType> & {
  setFormData?: (formData: FormDataType) => void;
};

export const CHECKBOX_STYLE = {
  checkBox: {
    minWidth: '30px',
    minHeight: '30px',
    cursor: 'pointer',
  },
};

const BaseForm = ({
  errors,
  formData,
  handleChange,
  setFormData,
}: BaseFormProductCategoryProps) => {
  const { nameEn, nameHr, nameSr, showInUserApp } = formData;
  const { t } = useTranslation();

  const handleShowInUserApp = (event) => {
    return setFormData({ ...formData, showInUserApp: event?.target?.checked });
  };

  return (
    <form className={styles.basicInformationSection}>
      <FormHeader
        primaryText={t('productCategory.subtitleBaseForm')}
        secondaryText={t('productCategory.descriptionBaseForm')}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        errorMessage={errors?.nameSr}
        fullWidth
        hasError={!!errors?.nameSr}
        id={FORM_ELEMENT_NAME_SR}
        label={t('productCategory.nameSr')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_NAME_SR}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={nameSr}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        errorMessage={errors?.nameEn}
        fullWidth
        hasError={!!errors?.nameEn}
        id={FORM_ELEMENT_NAME_EN}
        label={t('productCategory.nameEn')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_NAME_EN}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={nameEn}
      />
      <ACInput
        autoComplete={AUTO_COMPLETE_OFF}
        errorMessage={errors?.nameHr}
        fullWidth
        hasError={!!errors?.nameHr}
        id={FORM_ELEMENT_NAME_HR}
        label={t('productCategory.nameHr')}
        marginBottom={AC_INPUT_MARGIN_BOTTOM}
        name={FORM_ELEMENT_NAME_HR}
        onChange={handleChange}
        rounded={AC_INPUT_ROUND}
        style={style.ACInputForm}
        value={nameHr}
      />
      <div className={styles.showInApp}>
        <div className={styles.showLabel}>{t('productCategory.showInUserAppLabel')}</div>
        <ACCheckBox
          checked={showInUserApp}
          dataTestId='showInUserAppProductCategory'
          onChange={(event) => handleShowInUserApp(event)}
          selectedBgColor='var(--text-secondary)'
          selectedBorderColor='var(--text-transparent)'
          style={style.ACCheckBox}
        />
      </div>
    </form>
  );
};

export default BaseForm;
