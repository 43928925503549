import { ACUpload } from '@antech-consulting/components';
import {
  UPLOAD_IMAGE_JPEG,
  UPLOAD_IMAGE_JPG,
  UPLOAD_IMAGE_PNG,
  UPLOAD_SIZE,
  UPLOAD_UNIT_MB,
} from 'constants/FormConstants';
import { OptionsType } from 'constants/TypeConstants';
import { useGetPartnerApplicationSettingsQuery } from 'features/maintenance/services';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleDefaultPlaceImage } from 'utils/helpers';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import { style } from '../../../../constants/StyleConstants';
import { FormDataTypePlace } from '../../constants/FormConstants';
import styles from './UploadForm.module.scss';

type UploadFormProps = {
  formData: FormDataTypePlace;
  handleChange: (e: any, prev: File[] | File) => void;
  handleFileDelete?: () => void;
  placeId?: string;
};

const styleUpload = {
  selectedItemStyle: {
    width: '100px',
    height: '100px',
  },
  uploadButtonStyle: {
    width: '100px',
    height: '100px important',
  },
};

const UploadForm = ({ formData, handleChange, handleFileDelete, placeId }: UploadFormProps) => {
  const { t } = useTranslation();
  const { document } = formData;
  const category = formData.category as OptionsType;
  const imageFile = handleDefaultPlaceImage(category?.uuid, document);
  const [isDeletedDocument, setIsDeletedDocument] = useState<boolean>(false);
  const { data } = useGetPartnerApplicationSettingsQuery();
  const placesImageQuality = data?.dashboardImageQualitySettings?.placeDetail;

  const imageUrl = document && document[placesImageQuality];

  const defaultValue =
    document && placeId
      ? { id: document, url: imageUrl }
      : !document && placeId
      ? { id: imageFile, url: imageFile }
      : undefined;

  return (
    <form className={`uploadProductForm  ${styles.uploadDocumentSection}`}>
      <FormHeader
        primaryText={t('place.subtitleUpload')}
        secondaryText={t('place.descriptionUpload')}
      />
      <p className={styles.uploadText}>{t('place.uploadFormat')}</p>
      <p className={styles.uploadText}>{t('place.uploadSize')}</p>
      <ACUpload
        color='tasty'
        defaultValue={isDeletedDocument ? undefined : defaultValue}
        disableDeleteDefault={placeId && !document}
        fileSizeTooBigMessage={t('upload.fileSizeMessage')}
        isFileNameVisible={false}
        limitSize={{ size: UPLOAD_SIZE, unit: UPLOAD_UNIT_MB }}
        multiple={false}
        onChange={handleChange}
        onDeleteDefault={handleFileDelete}
        onlyAcceptedFileTypes={[UPLOAD_IMAGE_PNG, UPLOAD_IMAGE_JPEG, UPLOAD_IMAGE_JPG]}
        orientation='horizontal'
        selectedItemStyle={styleUpload.selectedItemStyle}
        styled={style.ACUpload}
        type='image'
        uploadButtonStyle={styleUpload.uploadButtonStyle}
      />
    </form>
  );
};

export default UploadForm;
