import _isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import { FormDataType } from '../constants/FormConstants';
import { useGetProductCategoryByIdQuery } from '../services';

const useProductCategoryForm = (
  formData?: FormDataType,
  setFormData?: (formData: FormDataType) => void,
  id?: string,
  file?: File,
  isSuccessRemoveDocument?: boolean,
) => {
  const navigate = useNavigate();
  const { data: productCategory, isSuccess } = useGetProductCategoryByIdQuery({
    id,
  });

  const shouldDisableSubmitButton = useMemo(() => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const disabled =
      _isEqual(trimmedData, productCategory) && file === null && !isSuccessRemoveDocument;
    return disabled;
  }, [formData, file, productCategory]);

  useEffect(() => {
    if (id && isSuccess) setFormData(productCategory);
  }, [isSuccess, productCategory]);

  const onDiscard = () => {
    navigate('/product-categories');
  };

  return { shouldDisableSubmitButton, productCategory, isSuccess, onDiscard };
};

export default useProductCategoryForm;
