export const PARTNER_USER_CODES = {
  PU001: 'partnerUserAlreadyExist',
  PU002: 'partnerUserEmailNotExist',
  PU003: 'partnerUserNotVerified',
  PU004: 'partnerUserIdNotExist',
  PU005: 'partnerUserWrongEmailPassword',
  PU006: 'partnerUserMustBeEmployed',
  PU007: 'partnerUserNotEmployed',
  PU008: 'partnerUserEmailVerificationFailed',
  PU009: 'partnerUserPhoneVerificationFailed',
  PU010: 'partnerUserPhoneAlreadyVerified',
  PU011: 'partnerUserEmailAlreadyVerified',
  PU012: 'partnerUserSmsCodeExpired',
  PU013: 'partnerUserBlockedAfterRepeatedFailures',
  PU014: 'partnerUserSmsCodeDoesntMatch',
  PU015: 'verificationEmailExpired',
  PU016: 'smsCodeIssueFailedMin',
  PU017: 'smsCodeIssueFailedHour',
  PU018: 'partnerUserExistWithGivenPhone',
  PU019: 'partnerUserBlocked',
  PU020: 'partnerUserAlreadyBlocked',
  PU021: 'cannotBlockYourself',
  PU022: 'partnerUserNotSystemRoot',
  PU023: 'userAlreadyUsesPhoneNumber',
  PU024: 'invalidOldPassword',
  PU025: 'sameOldAndNewPassword',
  PU027: 'partnerUserIsNotBlocked',
  PU028: 'resendVerificationEmailIssueFailedMin',
  PU029: 'employeeAlreadyTerminal',
};
