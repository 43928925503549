import { ACAutocompleteV2, ACCheckBox, ACInput } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import { FormHeader } from 'components/FormHeader/FormHeader';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF } from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import {
  convertACAutocompleteEventValueFromStringToBoolean,
  getYesNoOptionsArray,
} from 'features/maintenance/helpers';
import { t } from 'i18next';

import {
  APPLE,
  FACEBOOK,
  FORM_ELEMENT_CURRENT_ANDROID_VERSION,
  FORM_ELEMENT_CURRENT_ISO_VERSION,
  FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY,
  FORM_ELEMENT_MAINTENANCE_MODE,
  FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE,
  FORM_ELEMENT_MAINTENANCE_MODE_TITLE,
  FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION,
  FORM_ELEMENT_MINIMAL_REQUIRED_ISO_VERSION,
  FORM_ELEMENT_PLACE_DETAIL,
  FORM_ELEMENT_PLACE_LIST,
  FORM_ELEMENT_PRODUCT_CATEGORY_ALL,
  FORM_ELEMENT_PRODUCT_DETAIL,
  FORM_ELEMENT_PRODUCT_LIST,
  FORM_ELEMENT_STORY_DETAIL,
  FORM_ELEMENT_STORY_DETAIL_HEADER,
  FORM_ELEMENT_STORY_LIST,
  FormDataType,
  GOOGLE,
  MAGIC_LINK,
  SHOW_CATEGORIES,
  SHOW_OFFERS,
} from '../../../constants/UserAppSettingsFormConstants';
import styles from './BaseForm.module.scss';

type BaseFormUserAppMaintenanceProps = BaseFormProps<FormDataType> & {
  setFormData?: (formData: FormDataType) => void;
};

const BaseForm = ({
  errors,
  formData,
  handleChange,
  setFormData,
}: BaseFormUserAppMaintenanceProps) => {
  const {
    currentAndroidVersion,
    currentIosVersion,
    firebaseTokenValidity,
    loginTypes,
    maintenanceMode,
    maintenanceModeMessage,
    maintenanceModeTitle,
    minimalRequiredAndroidVersion,
    minimalRequiredIosVersion,
    placeDetail,
    placeList,
    productCategoryAll,
    productDetail,
    productList,
    showOffersOnHome,
    showProductCategories,
    storyDetail,
    storyDetailHeader,
    storyList,
  } = formData;

  const { apple, facebook, google, magicLink } = loginTypes;

  const handleShowInUserApp = (event, name) => {
    return setFormData({ ...formData, [name]: event?.target?.checked });
  };

  const handleShowTypes = (event, name) => {
    return setFormData({
      ...formData,
      loginTypes: { ...loginTypes, [name]: event?.target?.checked },
    });
  };

  return (
    <>
      <Divider />
      <form>
        <FormHeader
          primaryText={t('maintenance.userAppSettings.mobileVersion')}
          secondaryText={t('maintenance.userAppSettings.mobileVersionSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.currentAndroidVersion}
            fullWidth
            hasError={!!errors?.currentAndroidVersion}
            id={FORM_ELEMENT_CURRENT_ANDROID_VERSION}
            label={t('maintenance.userAppSettings.currentAndroidVersion')}
            name={FORM_ELEMENT_CURRENT_ANDROID_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={currentAndroidVersion}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.minimalRequiredAndroidVersion}
            fullWidth
            hasError={!!errors?.minimalRequiredAndroidVersion}
            id={FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION}
            label={t('maintenance.userAppSettings.minimalRequiredAndroidVersion')}
            name={FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={minimalRequiredAndroidVersion}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.currentIosVersion}
            fullWidth
            hasError={!!errors?.currentIosVersion}
            id={FORM_ELEMENT_CURRENT_ISO_VERSION}
            label={t('maintenance.userAppSettings.currentIosVersion')}
            name={FORM_ELEMENT_CURRENT_ISO_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={currentIosVersion}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.minimalRequiredIosVersion}
            fullWidth
            hasError={!!errors?.minimalRequiredIosVersion}
            id={FORM_ELEMENT_MINIMAL_REQUIRED_ISO_VERSION}
            label={t('maintenance.userAppSettings.minimalRequiredIosVersion')}
            name={FORM_ELEMENT_MINIMAL_REQUIRED_ISO_VERSION}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={minimalRequiredIosVersion}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.userAppSettings.message')}
          secondaryText={t('maintenance.userAppSettings.messageSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACAutocompleteV2
            clearAllBtn={false}
            closeOnSelect
            dataTestId='maintenanceMode'
            errorMessage={errors?.maintenanceMode}
            fullWidth
            hasError={!!errors?.maintenanceMode}
            label={t('maintenance.userAppSettings.maintenanceMode')}
            name={FORM_ELEMENT_MAINTENANCE_MODE}
            onChange={(e) => {
              handleChange(convertACAutocompleteEventValueFromStringToBoolean(e));
            }}
            options={getYesNoOptionsArray()}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceMode ? t('maintenance.yes') : t('maintenance.no')}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeTitle}
            errorMessage={errors?.maintenanceModeTitle}
            fullWidth
            hasError={!!errors?.maintenanceModeTitle}
            id={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            label={t('maintenance.userAppSettings.maintenanceModeTitle')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_TITLE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeTitle}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={maintenanceModeMessage}
            errorMessage={errors?.maintenanceModeMessage}
            fullWidth
            hasError={!!errors?.maintenanceModeMessage}
            id={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            isTextArea
            label={t('maintenance.userAppSettings.maintenanceModeMessage')}
            name={FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={maintenanceModeMessage}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.userAppSettings.fireBase')}
          secondaryText={t('maintenance.userAppSettings.fireBaseSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.firebaseTokenValidity}
            fullWidth
            hasError={!!errors?.firebaseTokenValidity}
            id={FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY}
            label={t('maintenance.userAppSettings.firebaseTokenValidity')}
            name={FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={{ ...style.ACInputForm, wrapper: { marginTop: '5px' } }}
            value={firebaseTokenValidity}
          />
        </div>
        <FormHeader
          primaryText={t('maintenance.userAppSettings.showOptions')}
          secondaryText={t('maintenance.userAppSettings.showOptionsSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <div className={styles.showInformation}>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>
                {t('maintenance.userAppSettings.showProductCategories')}
              </div>
              <ACCheckBox
                checked={showProductCategories}
                dataTestId='showProductCategories'
                onChange={(event) => handleShowInUserApp(event, SHOW_CATEGORIES)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>
                {t('maintenance.userAppSettings.showOffersOnHome')}
              </div>
              <ACCheckBox
                checked={showOffersOnHome}
                dataTestId='showOffersOnHome'
                onChange={(event) => handleShowInUserApp(event, SHOW_OFFERS)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
          </div>
        </div>
        <FormHeader
          primaryText={t('maintenance.userAppSettings.imageQualitySettings')}
          secondaryText={t('maintenance.userAppSettings.imageQualitySettingsSubtitle')}
        />
        <div className={styles.inputsWrapper}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeDetail}
            errorMessage={errors?.placeDetail}
            fullWidth
            hasError={!!errors?.placeDetail}
            id={FORM_ELEMENT_PLACE_DETAIL}
            label={t('maintenance.userAppSettings.placeDetail')}
            name={FORM_ELEMENT_PLACE_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={placeList}
            errorMessage={errors?.placeList}
            fullWidth
            hasError={!!errors?.placeList}
            id={FORM_ELEMENT_PLACE_LIST}
            label={t('maintenance.userAppSettings.placeList')}
            name={FORM_ELEMENT_PLACE_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={placeList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productCategoryAll}
            errorMessage={errors?.productCategoryAll}
            fullWidth
            hasError={!!errors?.productCategoryAll}
            id={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            label={t('maintenance.userAppSettings.productCategoryAll')}
            name={FORM_ELEMENT_PRODUCT_CATEGORY_ALL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productCategoryAll}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productDetail}
            errorMessage={errors?.productDetail}
            fullWidth
            hasError={!!errors?.productDetail}
            id={FORM_ELEMENT_PRODUCT_DETAIL}
            label={t('maintenance.userAppSettings.productDetail')}
            name={FORM_ELEMENT_PRODUCT_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={productList}
            errorMessage={errors?.productList}
            fullWidth
            hasError={!!errors?.productList}
            id={FORM_ELEMENT_PRODUCT_LIST}
            label={t('maintenance.userAppSettings.productList')}
            name={FORM_ELEMENT_PRODUCT_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={productList}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyDetail}
            errorMessage={errors?.storyDetail}
            fullWidth
            hasError={!!errors?.storyDetail}
            id={FORM_ELEMENT_STORY_DETAIL}
            label={t('maintenance.userAppSettings.storyDetail')}
            name={FORM_ELEMENT_STORY_DETAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyDetail}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyDetailHeader}
            errorMessage={errors?.storyDetailHeader}
            fullWidth
            hasError={!!errors?.storyDetailHeader}
            id={FORM_ELEMENT_STORY_DETAIL_HEADER}
            label={t('maintenance.userAppSettings.storyDetailHeader')}
            name={FORM_ELEMENT_STORY_DETAIL_HEADER}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyDetailHeader}
          />
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            defaultValue={storyList}
            errorMessage={errors?.storyList}
            fullWidth
            hasError={!!errors?.storyList}
            id={FORM_ELEMENT_STORY_LIST}
            label={t('maintenance.userAppSettings.storyList')}
            name={FORM_ELEMENT_STORY_LIST}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={storyList}
          />
          <div>
            <FormHeader
              primaryText={t('maintenance.userAppSettings.loginTypes')}
              secondaryText={t('maintenance.userAppSettings.loginTypesSubtitle')}
            />
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.userAppSettings.facebook')}</div>
              <ACCheckBox
                checked={facebook}
                dataTestId='facebook'
                onChange={(event) => handleShowTypes(event, FACEBOOK)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.userAppSettings.apple')}</div>
              <ACCheckBox
                checked={apple}
                dataTestId='apple'
                onChange={(event) => handleShowTypes(event, APPLE)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.userAppSettings.magicLink')}</div>
              <ACCheckBox
                checked={magicLink}
                dataTestId='magicLink'
                onChange={(event) => handleShowTypes(event, MAGIC_LINK)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
            <div className={styles.showInApp}>
              <div className={styles.showLabel}>{t('maintenance.userAppSettings.google')}</div>
              <ACCheckBox
                checked={google}
                dataTestId='google'
                onChange={(event) => handleShowTypes(event, GOOGLE)}
                selectedBgColor='var(--text-secondary)'
                selectedBorderColor='var(--text-transparent)'
                style={style.ACCheckBox}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default BaseForm;
