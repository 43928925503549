export const PLACE_CODES = {
  P001: 'placeNameExist',
  P002: 'placeNotExist',
  P003: 'notEmployedInPlace',
  P004: 'placeImageNotExist',
  P005: 'coordinatesNull',
  P007: 'placeAlreadyVerified',
  P008: 'workingHoursMisconfigured',
  P009: 'placeAlreadyHasTerminalEmployee',
  P010: 'userAlreadyEmployee',
  P011: 'userNotEmployee',
  P012: 'employmentNotExist',
  P013: 'cannotAddOwnerEmployee',
  P014: 'cannotModifyOwnerOrSelf',
  P015: 'notOwnerOfPlace',
  P016: 'placeNameExist',
  P018: 'activeOrdersExist',
  P019: 'onlyOwnerCanCreatePlace',
  P020: 'mustHaveTerminalToActivate',
};
