import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { DocumentDTO } from 'constants/TypeConstants';
import { TAG_PLACE } from 'services/TagsConstants';
import { store } from 'store/store';

import {
  createQueryString,
  handleDateAndTime,
  transformSnakeCaseRoles,
} from '../../../utils/helpers';
import { INCLUDE_EMPLOYEES } from '../constants/PlaceContants';
import { EmployeeBody } from './addPlaceEmployee';
import { Place } from './createPlace';
import { DECIMAL_PLACES } from './getPlaces';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Place, PlaceParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { id, ...params } = _arg;
      const placeResponse = await fetchWithBQ(createQueryString(params, `/place/${id}`));
      const placeData = placeResponse.data as Place;
      const documentResponse =
        !placeData?.employees && placeData?.document
          ? await fetchWithBQ(`/documents/download-links?ids=${placeData?.document}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const placesEmployees = placeData?.employees
        ? placeData?.employees?.map((employe) => employe.uuid)
        : null;
      const employeesResponse = placeData?.employees
        ? await fetchWithBQ(createQueryString(params, `/partner-users?ids=${placesEmployees}`))
        : null;
      const employeesData = employeesResponse?.data as EmployeesBody;
      const placeEmployees = placeData?.employees
        ? placeData?.employees.map((employee) => {
            const matched = employeesData?.content.find(
              (employe: EmployeesResponse) => employe.uuid === employee.uuid,
            );
            return {
              role: transformSnakeCaseRoles(employee?.role),
              email: matched?.email,
            };
          })
        : null;
      const placeEmployeesFinal = placeEmployees?.filter(
        (employee) => employee?.email !== undefined,
      );
      const rating = placeData?.ratingSum / placeData?.ratingCount;
      const { dateFormat } = store.getState().auth.userCountry;
      const { language } = store.getState().language;
      const lastOrderAtFormatted = handleDateAndTime(placeData?.lastOrderAt, dateFormat, language);
      const lastStockChangeAtFormatted = handleDateAndTime(
        placeData?.lastStockChangeAt,
        dateFormat,
        language,
      );
      const { formattedDate: orderDate, formattedTime: orderTime } = lastOrderAtFormatted;
      const { formattedDate: stockDate, formattedTime: stockTime } = lastStockChangeAtFormatted;
      const place = {
        ...placeData,
        employees: placeEmployeesFinal,
        employeesTotalElements: employeesData?.totalElements,
        employeesTotalPages: employeesData?.totalPages,
        document: documentData ? documentData[placeData?.document] : '',
        rating: rating ? rating.toFixed(DECIMAL_PLACES) : FORM_ELEMENT_EMPTY,
        lastStockChangeAt: placeData?.lastStockChangeAt
          ? `${stockDate}, ${stockTime}`
          : FORM_ELEMENT_EMPTY,
        lastOrderAt: placeData?.lastOrderAt ? `${orderDate}, ${orderTime}` : FORM_ELEMENT_EMPTY,
      };
      return {
        data: place,
      };
    },
    providesTags: [TAG_PLACE],
  });

export type Owner = {
  firstName?: string;
  lastName?: string;
  uuid?: string;
};

export type EmployeesFilter = {
  email?: string;
};

export type PlaceParameters = {
  [INCLUDE_EMPLOYEES]?: string;
  filter?: EmployeesFilter;
  id?: string;
};

export type EmployeesResponse = EmployeeBody & {
  uuid?: string;
};

export type EmployeesBody = {
  content: EmployeesResponse[];
  totalElements: number;
  totalPages: number;
};
