import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { store } from 'store/store';

import { CompaniesResponse, Company } from './getCompanies';

export const BASE_URL = '/companies';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CompaniesResponse, void>({
    async queryFn(_queryParams, _queryApi, _extraOptions, fetchWithBQ) {
      let page = 0;
      const responseBody: CompaniesResponse = { content: [], totalElements: 0, countries: [] };
      const countries = store.getState().globalData.countries;

      const handleMultipleFetchRequest = async () => {
        const companiesFromBackend = await fetchWithBQ(
          `${BASE_URL}?page=${page}&size=20&sort=name,ASC`,
        );
        const fetchCompaniesResponse = companiesFromBackend.data as CompaniesResponse;
        page = page + 1;
        fetchCompaniesResponse.content.forEach((place) => {
          responseBody.content.push(place);
        });
        if (fetchCompaniesResponse.totalElements > responseBody.content.length) {
          await handleMultipleFetchRequest();
        }
      };
      await handleMultipleFetchRequest();
      const companies = responseBody.content.map((company: Company) => {
        const country = countries.find((country) => country.uuid === company.country);
        const countryName = country?.shortName;
        return {
          ...company,
          country: countryName,
        };
      });
      return {
        data: {
          ...responseBody,
          content: companies,
        },
      };
    },
  });
