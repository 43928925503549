import { FC } from 'react';

import styles from './FormHeader.module.scss';

type FormHeaderProps = {
  primaryText: string;
  secondaryText: string;
};

export const FormHeader: FC<FormHeaderProps> = ({ primaryText, secondaryText }) => {
  return (
    <div className={styles.wrapper}>
      <h3>{primaryText}</h3>
      <p>{secondaryText}</p>
    </div>
  );
};
