import { ACLoading, ACSpinner } from '@antech-consulting/components';
import { DEFAULT_SORT, TABLE_ELEMENT_CREATED_AT } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import ordersTableSettings from 'features/orders/components/TableOrder/ordersTableSettings';
import { PENDING } from 'features/orders/constants/TableConstants';
import { useLazyGetOrdersQuery } from 'features/orders/services';
import { Order } from 'features/orders/services/getOrders';
import useTable from 'hooks/useTable';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { getStatusColor } from '../../../../utils/getColorStatus';
import { transformACState, transformtextCaseToCamelCase } from '../../../../utils/helpers';
import styles from '../../../orders/components/TableOrder/TableOrder.module.scss';

export type TableStatePropsOrder = TableStateProps<void>;

const AppUserOrders: FC = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const tableSettings = useTable(ordersTableSettings);
  const [fetchOrders, { data: orders, isFetching, isSuccess }] = useLazyGetOrdersQuery();

  const ordersTableData = useMemo(() => {
    return orders?.content?.map((order) => {
      const { status } = order;
      const styleColor: string = getStatusColor(status);
      const orderSeen = `(${t('orders.seenOrder')})`;
      return {
        rawData: order,
        render: {
          ...order,
          status: (
            <div className={`${styles[styleColor]} ${styles.status}`}>
              <span />
              <div>{t(`orders.${transformtextCaseToCamelCase(status)}`)}</div>
              <div className={styles.seenInfo}>
                {order?.status === PENDING && order?.seen && orderSeen}
              </div>
            </div>
          ),
        },
      };
    });
  }, [orders?.content]);

  const handleChangeTableState = (tableState: TableStatePropsOrder) => {
    fetchOrders(transformACState({ ...tableState, user: userId }, TABLE_ELEMENT_CREATED_AT));
  };

  const handleOnItemClicked = ({ rowData }: { rowData: Order }) => {
    const { uuid } = rowData;
    navigate(`/order/${uuid}`);
  };

  return (
    <ACLoading
      isLoading={isFetching || !isSuccess}
      loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
    >
      <StyledACTable
        {...tableSettings}
        allItemsCount={orders?.totalElements}
        defaultSort={DEFAULT_SORT}
        filter={null}
        onItemClicked={(rowData) => handleOnItemClicked({ rowData })}
        onTableStateChange={handleChangeTableState}
        pagesCount={orders?.totalPages}
        rows={ordersTableData}
      />
    </ACLoading>
  );
};

export default AppUserOrders;
