import { useState } from 'react';

export const useFileUpload = () => {
  const [file, setFile] = useState<File | File[]>(null);
  const [error, setError] = useState<string>('');
  const [lastUploadAction, setLastUploadAction] = useState(null);

  const handleFileChange = (e: any, files: File[] | File) => {
    setLastUploadAction(e.ac.event);
    const file = files;
    if (!file) {
      return;
    }
    if (file) {
      setError(null);
    }
    setFile(file);
  };

  return [file, error, handleFileChange, lastUploadAction];
};
