import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Places, PlaceParameters>({
    query: ({ accessToken, latitude, longitude }) =>
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?language=en,sr&address&access_token=${accessToken}`,
    transformResponse: (response: Place) => {
      return {
        content: response?.features[0],
      };
    },
  });

export type Places = {
  content?: Place;
};

export type MapContext = {
  id: string;
  text?: string;
  text_sr?: string;
};

export type MapLocation = {
  content: {
    address?: string;
    context?: MapContext[];
    id?: string;
    place_name?: string;
    place_type?: string[];
    text?: string;
  };
  features: MapLocation[];
};

export type Place = {
  address?: string;
  context?: MapContext[];
  features: MapLocation[];
  place_name?: string;
  text?: string;
};

export type PlaceParameters = {
  accessToken?: string;
  latitude?: number;
  longitude?: number;
};
