export const formDataInitialState: FormDataType = {
  currentAndroidVersion: '',
  currentIosVersion: '',
  maintenanceMode: false,
  maintenanceModeMessage: '',
  maintenanceModeTitle: '',
  minimalRequiredAndroidVersion: '',
  minimalRequiredIosVersion: '',
  showProductCategories: false,
  loginTypes: {
    facebook: false,
    apple: false,
    google: false,
    magicLink: false,
  },
  imageQualitySettings: {
    placeDetail: '',
    placeList: '',
    productCategoryAll: '',
    productDetail: '',
    productList: '',
    storyDetail: '',
    storyDetailHeader: '',
    storyList: '',
  },
  placeDetail: '',
  placeList: '',
  productCategoryAll: '',
  productDetail: '',
  productList: '',
  storyDetail: '',
  storyDetailHeader: '',
  storyList: '',
  firebaseTokenValidity: '',
  showOffersOnHome: false,
};

export type FormDataType = {
  currentAndroidVersion: string;
  currentIosVersion: string;
  firebaseTokenValidity: string;
  imageQualitySettings: {
    placeDetail: string;
    placeList: string;
    productCategoryAll: string;
    productDetail: string;
    productList: string;
    storyDetail: string;
    storyDetailHeader: string;
    storyList: string;
  };
  loginTypes: {
    apple: boolean;
    facebook: boolean;
    google: boolean;
    magicLink: boolean;
  };
  maintenanceMode: boolean;
  maintenanceModeMessage: string;
  maintenanceModeTitle: string;
  minimalRequiredAndroidVersion: string;
  minimalRequiredIosVersion: string;
  placeDetail?: string;
  placeList?: string;
  productCategoryAll?: string;
  productDetail?: string;
  productList?: string;
  showOffersOnHome?: boolean;
  showProductCategories?: boolean;
  storyDetail?: string;
  storyDetailHeader?: string;
  storyList?: string;
};

export const SHOW_CATEGORIES = 'showProductCategories';
export const SHOW_OFFERS = 'showOffersOnHome';
export const FACEBOOK = 'facebook';
export const APPLE = 'apple';
export const MAGIC_LINK = 'magicLink';
export const GOOGLE = 'google';

export const FORM_ELEMENT_CURRENT_ANDROID_VERSION = 'currentAndroidVersion';
export const FORM_ELEMENT_MINIMAL_REQUIRED_ANDROID_VERSION = 'minimalRequiredAndroidVersion';
export const FORM_ELEMENT_CURRENT_ISO_VERSION = 'currentIosVersion';
export const FORM_ELEMENT_MINIMAL_REQUIRED_ISO_VERSION = 'minimalRequiredIosVersion';
export const FORM_ELEMENT_MAINTENANCE_MODE = 'maintenanceMode';
export const FORM_ELEMENT_MAINTENANCE_MODE_TITLE = 'maintenanceModeTitle';
export const FORM_ELEMENT_MAINTENANCE_MODE_MESSAGE = 'maintenanceModeMessage';
export const FORM_ELEMENT_FIREBASE_TOKEN_VALIDITY = 'firebaseTokenValidity';
export const FORM_ELEMENT_PLACE_DETAIL = 'placeDetail';
export const FORM_ELEMENT_PLACE_LIST = 'placeList';
export const FORM_ELEMENT_PRODUCT_CATEGORY_ALL = 'productCategoryAll';
export const FORM_ELEMENT_PRODUCT_DETAIL = 'productDetail';
export const FORM_ELEMENT_PRODUCT_LIST = 'productList';
export const FORM_ELEMENT_STORY_DETAIL = 'storyDetail';
export const FORM_ELEMENT_STORY_DETAIL_HEADER = 'storyDetailHeader';
export const FORM_ELEMENT_STORY_LIST = 'storyList';
