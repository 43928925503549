import { ACCard } from '@antech-consulting/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { wrapperStyle } from '../../constants/TableConstants';
import { useGetOrderByIdQuery } from '../../services';
import { OrderDetailViewProps } from '../../services/getOrderById';
import styles from './OrderActivity.module.scss';
import OrderTimeLine from './OrderTimeLine';

const OrderActivity: FC<OrderDetailViewProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const { data: order, isSuccess } = useGetOrderByIdQuery(orderId);

  const HeaderContent = () => {
    return <div className={styles.headerTitle}>{t('orders.orderActivity')}</div>;
  };

  return (
    isSuccess && (
      <ACCard
        bodyChildren={<OrderTimeLine order={order} />}
        className={styles.activityCard}
        headerChildren={<HeaderContent />}
        style={{ wrapper: wrapperStyle }}
      />
    )
  );
};

export default OrderActivity;
