export const formDataInitialState: FormDataType = {
  maintenanceMode: false,
  maintenanceModeMessage: '',
  maintenanceModeTitle: '',
  showProductCategories: false,
  loginTypes: {
    facebook: false,
    apple: false,
    google: false,
    magicLink: false,
  },
  imageQualitySettings: {
    placeDetail: '',
    placeList: '',
    productCategoryAll: '',
    productDetail: '',
    productList: '',
    storyDetail: '',
    storyDetailHeader: '',
    storyList: '',
  },
  placeDetail: '',
  placeList: '',
  productCategoryAll: '',
  productDetail: '',
  productList: '',
  storyDetail: '',
  storyDetailHeader: '',
  storyList: '',
  showOffersOnHome: false,
};

export type FormDataType = {
  imageQualitySettings: {
    placeDetail: string;
    placeList: string;
    productCategoryAll: string;
    productDetail: string;
    productList: string;
    storyDetail: string;
    storyDetailHeader: string;
    storyList: string;
  };
  loginTypes: {
    apple: boolean;
    facebook: boolean;
    google: boolean;
    magicLink: boolean;
  };
  maintenanceMode: boolean;
  maintenanceModeMessage: string;
  maintenanceModeTitle: string;
  placeDetail?: string;
  placeList?: string;
  productCategoryAll?: string;
  productDetail?: string;
  productList?: string;
  showOffersOnHome?: boolean;
  showProductCategories?: boolean;
  storyDetail?: string;
  storyDetailHeader?: string;
  storyList?: string;
};
