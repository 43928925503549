import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ProductCategory } from 'features/productCategory/services/getProductCategories';
import { Category } from 'hooks/useProductStock';
import { uniq } from 'lodash';
import { store } from 'store/store';
import { getNameFieldTranslation } from 'utils/helpers';

import { Product } from './getProducts';

const typeOfString = 'string';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<ResponseBody, RequestBody>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const { filters, id } = _arg;
      let page = 0;
      const responseBody: ResponseBody = { content: [], totalElements: 0, productsCategories: [] };
      const language = store.getState().language.language;
      const handleMultipleFetchRequest = async () => {
        const filterCategories =
          typeof filters.categories === typeOfString
            ? filters.categories
            : filters?.categories
                ?.filter(({ checked, uuid }) => {
                  if (checked) return uuid;
                })
                .map(({ uuid }) => uuid)
                .join();
        const fetchProducts = await fetchWithBQ(
          `/products?place=${id}&page=${page}&name=${filters.name}&code=${filters.code}&product-categories=${filterCategories}&sort=name,ASC`,
        );
        const fetchProductsResponse = fetchProducts.data as ResponseBody;
        page = page + 1;
        fetchProductsResponse.content.forEach((product) => {
          responseBody.content.push(product);
        });

        if (fetchProductsResponse.totalElements > responseBody.content.length) {
          await handleMultipleFetchRequest();
        }
      };

      await handleMultipleFetchRequest();

      const productCategories = uniq(responseBody?.content.map(({ category }) => category));

      const fetchProductCategories = await fetchWithBQ(
        `/product-categories?ids=${productCategories.join()}`,
      );

      const fetchProductCategoriesResponse = fetchProductCategories.data as ProductCategoryResponse;

      const formatCategoriesForFilterState = fetchProductCategoriesResponse.content?.map(
        ({ nameEn, nameHr, nameSr, uuid }) => {
          const productCategoryName = getNameFieldTranslation(language, nameEn, nameSr, nameHr);
          return {
            name: productCategoryName,
            checked: false,
            uuid,
          };
        },
      );

      return {
        data: { ...responseBody, productsCategories: formatCategoriesForFilterState },
      };
    },

    providesTags: ['products'],
  });

export type ResponseBody = {
  content: Product[];
  productsCategories: { checked: boolean; name: string; uuid: string }[];
  totalElements: number;
};

export type ProductCategoryResponse = {
  content: ProductCategory[];
};

export type RequestBody = {
  filters: { categories: Category[]; code: string; name: string };
  id: string;
};
