import { authImage } from 'features/auth/constants/StyleConstants';
import { useTranslation } from 'react-i18next';

import Tastygator from '../../images/TastygatorAppLayout.png';
import Tasty from '../../images/TastyLogoAppLayout.png';
import styles from './AppLayout.module.scss';

const AppLayout = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.appLayoutWrapper}>
      <div className={styles.title}>
        <img height={60} src={Tasty} width={140} />
      </div>
      <div style={authImage}>
        <img height={300} src={Tastygator} width={250} />
      </div>
      <span>
        {t('appLayout.footer')} &copy; {`${new Date().getFullYear()}`}{' '}
      </span>
    </div>
  );
};
export default AppLayout;
