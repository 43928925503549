import { NamedObject, NameTranslationFunction } from 'constants/TypeConstants';
import { useCallback } from 'react';
import { getNameFieldTranslation } from 'utils/helpers';

import { useAppSelector } from './hooks';

const useObjectTranslation = (): { getNameTranslation: NameTranslationFunction } => {
  const { language } = useAppSelector(({ language }) => language);

  const getNameTranslation = useCallback(
    (namedObject: NamedObject) => {
      const { nameEn, nameHr, nameSr } = namedObject || {};
      return getNameFieldTranslation(language, nameEn, nameSr, nameHr);
    },
    [language],
  );

  return { getNameTranslation };
};

export default useObjectTranslation;
