import { ACCard } from '@antech-consulting/components';
import { cardTitle } from 'features/orders/constants/StyleConstants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { wrapperStyle } from '../../constants/TableConstants';
import { useGetOrderByIdQuery } from '../../services';
import { OrderDetailViewProps } from '../../services/getOrderById';
import styles from './DetailViewPayment.module.scss';

const DetailViewPayment: FC<OrderDetailViewProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const { data: order, isSuccess } = useGetOrderByIdQuery(orderId);

  const BodyContent = () => {
    return (
      <>
        <div className={styles.orderPrice}>
          <div>{t('orders.totalBasePrice')}:</div>
          <span>{order?.totalProductBasePrice}</span>
        </div>
        <div className={styles.orderPrice}>
          <div>{t('orders.percentage')}:</div>
          <span>{order?.percentage}%</span>
        </div>
        <div className={styles.orderPrice}>
          <div>{t('orders.totalDiscountedPrice')}:</div>
          <span>{order?.totalProductDiscountedPrice}</span>
        </div>
      </>
    );
  };

  const HeaderContent = () => {
    return <div style={cardTitle}>{t('orders.payment')}:</div>;
  };

  return (
    isSuccess && (
      <ACCard
        bodyChildren={<BodyContent />}
        className={styles.paymentCard}
        headerChildren={<HeaderContent />}
        style={{ wrapper: wrapperStyle }}
      />
    )
  );
};

export default DetailViewPayment;
