import { PartnerUser } from 'features/partnerUser/services/getPartnerUsers';

export type FormDataType = {
  createdAt?: string;
  createdBy?: string;
  name: string;
  status?: string;
  userInformation?: PartnerUser;
};

export const formDataInitialState: FormDataType = {
  name: '',
};

export const FORM_ELEMENT_USER_FIRST_NAME = 'userFirstName';
export const FORM_ELEMENT_USER_LAST_NAME = 'userLastName';
export const FORM_ELEMENT_USER_EMAIL = 'userEmail';
export const FORM_ELEMENT_USER_PHONE_NUMBER = 'userPhoneNumber';
