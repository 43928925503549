import ForgotPasswordNewPassword from 'features/auth/components/ForgotPasswordNewPassword/ForgotPasswordNewPassword';
import { authDescription, authForm, authTitle } from 'features/auth/constants/StyleConstants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ForgotPasswordForm from '../../features/auth/components/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const titleRequest = token ? t('forgotPassword.passwordTitle') : t('forgotPassword.title');
  const descriptionRequest = token
    ? t('forgotPassword.passwordDescription')
    : t('forgotPassword.description');

  return (
    <div style={authForm}>
      <h3 style={authTitle}>{titleRequest}</h3>
      <p style={authDescription}>{descriptionRequest}</p>
      {token ? <ForgotPasswordNewPassword token={token} /> : <ForgotPasswordForm />}
    </div>
  );
};
export default ForgotPassword;
