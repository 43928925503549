import { ACLoading, ACSpinner, IACTableRow } from '@antech-consulting/components';
import {
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_NAME,
} from 'constants/FormConstants';
import { ROLE_ELEMENT_OWNER, ROLE_SYSTEM } from 'constants/RolesConstants';
import { TABLE_ELEMENT_CREATED_AT, TABLE_ELEMENT_CREATED_BY } from 'constants/TableConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { useCompanyForm } from 'features/company/hooks/useCompanyForm';
import { useLazyGetCompaniesQuery } from 'features/company/services';
import useDisplayingFilters from 'hooks/useDisplayingFilters';
import useTable from 'hooks/useTable';
import { AddCircle, Diagram, Edit2 } from 'iconsax-react';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store } from 'store/store';

import StyledACTable from '../../../../components/StyledACTable/StyledACTable';
import { useAppSelector } from '../../../../hooks/hooks';
import { CurrentUser } from '../../../../store/Auth';
import { checkIfPropertyExistsInObject, transformACState } from '../../../../utils/helpers';
import { Company } from '../../services/getCompanies';
import companyTableSettings from './companyTableSettings';

export type FilterProps = {
  city?: string;
  country?: string;
  ids?: string;
  name?: string;
  owners?: string;
};

const TableCompany: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fetchCompanies, { data: companies, isFetching, isSuccess: isSuccessCompanies }] =
    useLazyGetCompaniesQuery();
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const userId = store.getState().auth.user;
  const tableSettings = useTable(companyTableSettings);
  const [selectedRow, setSelectedRow] = useState<Company>(null);
  const systemAdminIsLoggedIn = roles && checkIfPropertyExistsInObject(roles, ROLE_SYSTEM);
  const { countriesOptions, partnerUsersOptions } = useCompanyForm();
  const { cities } = useAppSelector(({ globalData }) => globalData);

  const companiesTableData = useMemo(() => {
    return companies?.content?.map((company) => {
      return {
        rawData: company,
        render: company,
      };
    });
  }, [companies]);

  const handleChangeTableState = (tableState: TableStateProps<FilterProps>) => {
    fetchCompanies(transformACState(tableState, TABLE_ELEMENT_CREATED_AT)).unwrap();
  };

  const additionalTableFilters = [
    {
      field: FORM_ELEMENT_COUNTRY,
      title: t('company.countryColumn'),
      filterType: 'selectSingle',
      filterSelectData: countriesOptions?.map((country) => {
        return { value: country.uuid, render: country.shortName };
      }),
    },
    {
      field: FORM_ELEMENT_CITY,
      title: t('company.cityColumn'),
      filterType: 'selectSingle',
      filterSelectData: cities?.map((city) => {
        return { ...city, value: city.uuid, render: city.name };
      }),
    },
  ];
  systemAdminIsLoggedIn &&
    tableSettings.columns.push({
      title: t('company.ownerColumn'),
      field: TABLE_ELEMENT_CREATED_BY,
    });

  const handleEdit = (rowData: any) => {
    checkIfPropertyExistsInObject(rowData, 'rowData') && (rowData = rowData.rowData);
    setSelectedRow(rowData);
    navigate(`/company/${rowData.uuid}`, { state: { companyID: rowData.uuid } });
  };

  const handleStatistics = (rowData: any) => {
    checkIfPropertyExistsInObject(rowData, 'rowData') && (rowData = rowData.rowData);
    setSelectedRow(rowData);
    navigate(`/company-statistics/${rowData.uuid}`, { state: { companyID: rowData.uuid } });
  };

  const { editCompany, statisticsCompany } = {
    statisticsCompany: {
      icon: <Diagram data-testid='statisticsButton' />,
      onClick: handleStatistics,
      tooltip: {
        text: t('companyStatistics.title'),
        style: { left: 0 },
      },
      isHidden: ({ rawData }: IACTableRow) => {
        return rawData.owner !== userId && !systemAdminIsLoggedIn;
      },
    },
    editCompany: {
      icon: <Edit2 data-testid='editButton' />,
      onClick: handleEdit,
      tooltip: {
        text: t('company.titleUpdate'),
      },
      isHidden: ({ rawData }: IACTableRow) => {
        return rawData.owner !== userId && !systemAdminIsLoggedIn;
      },
    },
  };

  const additionalSystemFilters = systemAdminIsLoggedIn
    ? [
        ...additionalTableFilters,
        {
          field: ROLE_ELEMENT_OWNER,
          title: t('company.ownerColumn'),
          filterType: 'selectMulti',
          filterSelectData:
            partnerUsersOptions?.map((owner) => {
              return {
                ...owner,
                value: owner.uuid,
                render: owner.email,
              };
            }) || [],
        },
      ]
    : additionalTableFilters;

  const actions = [editCompany, statisticsCompany];

  const { filtersToDisplay } = useDisplayingFilters({
    totalElements: companies?.totalElements,
    isSuccess: isSuccessCompanies,
    filters: [...tableSettings.filter, ...additionalSystemFilters],
  });
  const handleAddCompany = () => {
    navigate('/company/add');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  const additionalHeaderActions = [
    {
      title: t('company.addCompany'),
      icon: <AddCircle data-testid='addButton' />,
      tooltip: {
        text: t('company.addCompany'),
      },
      onClick: handleAddCompany,
    },
  ];

  return (
    <ACLoading
      isLoading={isFetching}
      loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
    >
      <StyledACTable
        {...tableSettings}
        actions={actions}
        allItemsCount={companies?.totalElements}
        defaultSort={{
          field: FORM_ELEMENT_NAME,
          type: 'asc',
        }}
        filter={filtersToDisplay}
        headerActions={systemAdminIsLoggedIn && additionalHeaderActions}
        headerTitle={t('company.title')}
        onItemClicked={(rowData) => handleEdit({ rowData })}
        onTableStateChange={handleChangeTableState}
        pagesCount={companies?.totalPages}
        rows={companiesTableData ?? []}
      />
    </ACLoading>
  );
};

export default TableCompany;
