import { ACCard } from '@antech-consulting/components';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { cardTitle } from 'features/orders/constants/StyleConstants';
import { Star } from 'iconsax-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { wrapperStyle } from '../../constants/TableConstants';
import { useGetOrderByIdQuery } from '../../services';
import { OrderDetailViewProps } from '../../services/getOrderById';
import styles from './OrderReview.module.scss';

const OrderReview: FC<OrderDetailViewProps> = ({ orderId }) => {
  const { t } = useTranslation();
  const { data: order, isSuccess } = useGetOrderByIdQuery(orderId);

  const HeaderContent = () => {
    return <div style={cardTitle}>{t('orders.review')}:</div>;
  };

  const BodyContent = () => {
    return (
      <>
        <div className={styles.rating}>
          <span> {t('orders.reviewRating')}: </span>
          {order?.rating ? (
            <span className={styles.orderRating}>
              <Star color='var(--text-transparent)' />
              {order?.rating}
            </span>
          ) : (
            FORM_ELEMENT_EMPTY
          )}
        </div>
        <div className={styles.rating}>
          {t('orders.reviewComment')}: {order?.ratingComment || FORM_ELEMENT_EMPTY}
        </div>
      </>
    );
  };
  return (
    isSuccess && (
      <ACCard
        bodyChildren={<BodyContent />}
        className={styles.reviewCard}
        headerChildren={<HeaderContent />}
        style={{ wrapper: wrapperStyle }}
      />
    )
  );
};

export default OrderReview;
