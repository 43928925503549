import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { CityDTO, OptionsType } from 'constants/TypeConstants';
import { TAG_UNVERIFIED_PLACES } from 'services/TagsConstants';

import { WorkingHoursType } from './updatePlace';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<Place, NewPlace>({
    query: (credentials) => ({
      url: '/places',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_UNVERIFIED_PLACES],
  });

export type NewPlace = {
  apartment?: number | string;
  beneficiaryAccount?: string;
  category: string;
  city: string;
  company?: string | OptionsType;
  country: string;
  description: string;
  document?: string;
  fee?: string | number;
  floor?: number | string;
  lastOrderAt?: string;
  lastStockChangeAt?: string;
  latitude: number;
  longitude: number;
  minimumOrderAmount?: number | string;
  name: string;
  street: string;
  streetNumber: string;
  workingHours?: WorkingHoursType;
  zipCode: string;
};

export type Employees = {
  role: string;
  uuid?: string;
};

export type Place = Omit<NewPlace, 'city'> & {
  city: string | CityDTO;
  createdAt: string;
  createdBy: string;
  employees: Employees[];
  employeesTotalElements?: number;
  employeesTotalPages?: number;
  owner?: string;
  rating?: string;
  ratingCount: number;
  ratingSum: number;
  status?: string;
  updatedAt: string;
  updatedBy: string;
  uuid: string;
  verified: boolean;
};
