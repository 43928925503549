import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'theme',
  initialState: { appTheme: 'light' },
  reducers: {
    changeTheme: (state, { payload: { appTheme } }) => {
      if (typeof appTheme !== 'undefined') {
        state.appTheme = appTheme as string;
      }
    },
  },
});

export const { changeTheme } = slice.actions;

export default slice.reducer;
