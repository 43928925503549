import { ACTabs } from '@antech-consulting/components';
import {
  TAB_FIFTH,
  TAB_FIRST,
  TAB_FOURTH,
  TAB_SECOND,
  TAB_THIRD,
  TAB_ZERO,
} from 'constants/TabsConstants';
import OrdersReviews from 'features/place/components/PlaceReviews/OrdersReviews/OrdersReviews';
import ProductReviews from 'features/place/components/PlaceReviews/ProductsReviews/ProductReviews';
import { useLazyGetPlaceByIdQuery } from 'features/place/services';
import { TableProduct } from 'features/product';
import ProductsStock from 'features/product/components/ProductsStock/ProductsStock';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { style } from '../../constants/StyleConstants';
import PlaceEmployees from '../../features/place/components/PlaceEmployees/PlaceEmployees';
import EditPlace from '../EditPlace/EditPlace';

const PlaceDetailView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);
  const { id: placeId } = useParams();

  const [fetchPlaceById, { data: place, isLoading, isSuccess }] = useLazyGetPlaceByIdQuery();

  useEffect(() => {
    if (placeId) {
      fetchPlaceById({ id: placeId }, true);
    }
  }, []);

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/place/${placeId}?value=${id}`);
  };

  const unverifiedPlaceTabs = [
    {
      label: t('place.settingsTab'),
      id: TAB_ZERO,
      content: <EditPlace />,
    },
    {
      label: t('place.employeesTab'),
      id: TAB_FIRST,
      content: <PlaceEmployees />,
    },
  ];

  const verifiedPlaceTabs = [
    {
      label: t('place.settingsTab'),
      id: TAB_ZERO,
      content: <EditPlace />,
    },
    {
      label: t('place.employeesTab'),
      id: TAB_FIRST,
      content: <PlaceEmployees />,
    },
    {
      label: t('sideBar.productList'),
      id: TAB_SECOND,
      content: <TableProduct />,
    },
    {
      label: t('product.productStock'),
      id: TAB_THIRD,
      content: <ProductsStock />,
    },
    {
      label: t('place.productReviews'),
      id: TAB_FOURTH,
      content: <ProductReviews />,
    },
    {
      label: t('place.ordersReviews'),
      id: TAB_FIFTH,
      content: <OrdersReviews />,
    },
  ];

  const tabs = place?.verified ? verifiedPlaceTabs : unverifiedPlaceTabs;

  return isSuccess ? (
    <ACTabs defaultTab={defaultValueTab} onChange={handleChange} style={style.ACTabs} tabs={tabs} />
  ) : null;
};

export default PlaceDetailView;
