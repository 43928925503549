import {
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_FIRST_NAME,
  FORM_ELEMENT_LAST_NAME,
} from 'constants/FormConstants';
import { TABLE_INITIAL_ROWS_NUMBER } from 'constants/TableConstants';
import { TableSettingsType } from 'hooks/useTable';

const blockedPartnerUsersTableSettings: TableSettingsType = {
  initialRowsNumber: TABLE_INITIAL_ROWS_NUMBER,
  dataTestId: 'BlockedPartnerUsersTable',
  columns: [
    {
      title: 'partnerUsers.firstName',
      field: FORM_ELEMENT_FIRST_NAME,
    },
    {
      title: 'partnerUsers.lastName',
      field: FORM_ELEMENT_LAST_NAME,
    },
    {
      title: 'partnerUsers.email',
      field: FORM_ELEMENT_EMAIL,
    },
  ],
};

export default blockedPartnerUsersTableSettings;
