import { ACTabs } from '@antech-consulting/components';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TAB_FIRST, TAB_ZERO } from 'constants/TabsConstants';
import TableBlockPartnerUsers from 'features/partnerUser/components/TableBlockPartnerUsers/TableBlockPartnerUsers';
import TablePartnerUsers from 'features/partnerUser/components/TablePartnerUsers/TablePartnerUsers';
import { useAppSelector } from 'hooks/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';

import { style } from '../../constants/StyleConstants';

const PartnerUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tabId = Number(location.search.trim().split('=')[1]);
  const [defaultValueTab, setDefaultValueTab] = useState<string | number>(tabId || 0);
  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);

  const system = roles[ROLE_SYSTEM as never];

  const handleChange = (id: number | string) => {
    setDefaultValueTab(id);
    navigate(`/partner-users/?value=${id}`);
  };
  const tabs = [
    {
      label: t('partnerUsers.tableNamePartnerUsers'),
      id: TAB_ZERO,
      content: <TablePartnerUsers />,
    },
    {
      label: t('partnerUsers.tableNameBlockedPartnerUsers'),
      id: TAB_FIRST,
      content: <TableBlockPartnerUsers />,
    },
  ];
  return (
    <>
      {system ? (
        <div>
          <ACTabs
            defaultTab={defaultValueTab}
            onChange={handleChange}
            style={style.ACTabs}
            tabs={tabs}
          />
        </div>
      ) : (
        <div>
          <TablePartnerUsers />
        </div>
      )}
    </>
  );
};

export default PartnerUsers;
