import api from './api';
import country from './country';
import getCities from './getCities';
import getCountryCodes from './getCountryCodes';
import getDocument from './getDocument';
import initialAppData from './initialAppData';

export const globalApi = api.injectEndpoints({
  endpoints: (build) => ({
    countries: country(build),
    getDocument: getDocument(build),
    getCountryCodes: getCountryCodes(build),
    getCities: getCities(build),
    initialAppData: initialAppData(build),
  }),
  overrideExisting: false,
});

export const {
  useCountriesQuery,
  useGetCitiesQuery,
  useGetCountryCodesQuery,
  useInitialAppDataQuery,
  useLazyCountriesQuery,
  useLazyGetCitiesQuery,
  useLazyGetCountryCodesQuery,
  useLazyGetDocumentQuery,
  useLazyInitialAppDataQuery,
} = globalApi;
