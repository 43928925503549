import { ACAvatar } from '@antech-consulting/components';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '../../hooks/hooks';
import { CurrentUser } from '../../store/Auth';
import { transformSnakeCaseToText, userInitials } from '../../utils/helpers';
import MenuBar from './MenuBar';
import styles from './Navbar.module.scss';

export const BackgroundColor = 'rgba(229, 231, 235, 1)';
export const AvatarType = 'circle';
export const AvatarColor = 'var(--text-primary)';

const ProfileCard = ({ currentUser }: { currentUser: CurrentUser }) => {
  const { typeUser }: CurrentUser = useAppSelector(({ auth }) => auth);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const menuRef = useRef(null);

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const outsideHandler = (e: Event) => {
    if (!menuRef.current?.contains(e.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', outsideHandler);
    return () => {
      document.body.removeEventListener('click', outsideHandler);
    };
  }, []);

  const roleUser = transformSnakeCaseToText(currentUser.typeUser);

  return (
    <div className={styles.navBarCard}>
      <div className={styles.currentUser} onClick={toggleModal} placeholder='profile' ref={menuRef}>
        <ACAvatar
          bgColor={BackgroundColor}
          color={AvatarColor}
          hoverEffect={true}
          type={AvatarType}
        >
          {userInitials({ currentUser, haveInitials: true })}
        </ACAvatar>
        <div className={styles.currentUserInfo}>
          <div className={styles.roleUser}>{roleUser}</div>
          <div> {userInitials({ currentUser, haveInitials: false })}</div>
        </div>
      </div>
      <MenuBar currentUser={currentUser} openModal={openModal} toggleModal={toggleModal} />
    </div>
  );
};
export default ProfileCard;
