import { ACSpinner } from '@antech-consulting/components';
import Modal from 'components/Modal/Modal';
import { FILE, UPLOAD_FILE } from 'constants/FormConstants';
import useProductCategoryForm from 'features/productCategory/hooks/useProductCategoryForm';
import { UpdateProductCategoryFormType } from 'features/productCategory/services/updateProductCategory';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { Trash } from 'iconsax-react';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style } from '../../constants/StyleConstants';
import BaseForm from '../../features/productCategory/components/BaseForm/BaseForm';
import UploadForm from '../../features/productCategory/components/UploadForm/UploadForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/productCategory/constants/FormConstants';
import { validationSchema } from '../../features/productCategory/constants/ProductCategorySchema';
import {
  useDeleteProductCategoryMutation,
  useRemoveProductCategoryImageMutation,
  useUpdateProductCategoryMutation,
  useUploadProductCategoryImageMutation,
} from '../../features/productCategory/services';
import { useFileUpload } from '../../hooks/useFileUpload';
import useToggle from '../../hooks/useToggle';
import styles from './EditProductCategory.module.scss';

const styleFlexPage = { flex: 1 };

const EditProductCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [isDocumentRemoved, setIsDocumentRemove] = useState<boolean>(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useToggle();
  const [isDeleteDialogOpen, toggleDelete] = useToggle();

  const [update, { isLoading: isLoadingUpdate }] = useUpdateProductCategoryMutation();
  const [uploadDocument, { isLoading: isLoadingUploadDocument }] =
    useUploadProductCategoryImageMutation();
  const [
    removeDocument,
    { isLoading: isLoadingRemoveDocument, isSuccess: isSuccessRemoveDocument },
  ] = useRemoveProductCategoryImageMutation();
  const [deleteRequest, { isLoading: isLoadingDelete }] = useDeleteProductCategoryMutation();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const [file, error, handleFileChange, lastUploadAction] = useFileUpload();

  const { isSuccess, onDiscard, productCategory, shouldDisableSubmitButton } =
    useProductCategoryForm(formData, setFormData, id, file, isSuccessRemoveDocument);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const productCategoryEditDTO: UpdateProductCategoryFormType = {
      ...trimmedData,
      uuid: productCategory?.uuid,
    };
    try {
      if (isDocumentRemoved) {
        await removeDocument(productCategoryEditDTO?.uuid);
      }
      if (lastUploadAction === UPLOAD_FILE) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        await uploadDocument({
          file: dataImage,
          id: productCategoryEditDTO?.uuid,
        });
      }
      await update(productCategoryEditDTO).unwrap();
      navigate('/product-categories');
      displaySuccess({ key: 'productCategory.updateProductCategory' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const changeStateRemoveDialog = () => {
    setIsRemoveDialogOpen();
  };

  const handleRemoveDocument = () => {
    setIsDocumentRemove(true);
    changeStateRemoveDialog();
    setFormData((prevFormData) => ({ ...prevFormData, document: undefined }));
  };

  const onDelete = () => {
    toggleDelete();
  };

  const handleDelete = async () => {
    try {
      await deleteRequest(productCategory?.uuid).unwrap();
      toggleDelete();
      navigate('/product-categories');
      displaySuccess({ key: 'deleteModal.removeImage' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleDelete();
      displayError({ key: code });
    }
  };

  return isSuccess && !isLoadingUpdate && !isLoadingUploadDocument ? (
    <>
      <PageHeader primaryText={t('productCategory.titleUpdate')} />
      <div className={styles.wrapper}>
        <BaseForm
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          setFormData={setFormData}
        />
        <div style={styleFlexPage}>
          <UploadForm
            file={file}
            formData={formData}
            handleChange={handleFileChange}
            handleFileDelete={changeStateRemoveDialog}
          />
        </div>
      </div>
      {isRemoveDialogOpen ? (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={changeStateRemoveDialog}
          disabled={isLoadingRemoveDocument}
          handleSubmit={handleRemoveDocument}
          header={t('deleteModal.removeDocumentTitle')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isRemoveDialogOpen}
          message={t('deleteModal.removeDocumentMessage')}
        />
      ) : null}
      {isDeleteDialogOpen ? (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={toggleDelete}
          disabled={isLoadingDelete}
          handleSubmit={handleDelete}
          header={t('deleteModal.headerDeleteProductCategory')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isDeleteDialogOpen}
          message={t('deleteModal.headerMessageDeleteProductCategory')}
        />
      ) : null}
      <FormStickyFooter
        onDelete={onDelete}
        onDeleteText={t('footer.deleteButton')}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={shouldDisableSubmitButton}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditProductCategory;
