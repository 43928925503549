import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UploadRequest } from 'constants/TypeConstants';
import { TAG_PRODUCT, TAG_PRODUCTS } from 'services/TagsConstants';
import { setErrorMessage, setInfoMessage, setSuccessMessage } from 'store/Notification';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<UploadDocumentProductDTO, UploadRequest>({
    query: (credentials: UploadRequest) => ({
      url: `/product/${credentials.id}/upload-image`,
      method: 'POST',
      body: credentials.file,
    }),

    async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
      dispatch(setInfoMessage({ key: 'upload.info' }));
      try {
        const { data: updateProduct } = await queryFulfilled;
        if (updateProduct) {
          dispatch(setSuccessMessage({ key: 'upload.success' }));
        }
      } catch (error) {
        dispatch(setErrorMessage({ message: 'upload.error' }));
      }
    },
    invalidatesTags: [TAG_PRODUCTS, TAG_PRODUCT],
  });

export type UploadDocumentProductDTO = {
  product?: string;
  uuid?: string;
};
