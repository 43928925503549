import { ACAutocompleteV2, ACInput, ACSpinner } from '@antech-consulting/components';
import CountryLabel from 'components/CountryLabel/CountryLabel';
import {
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_CONFIRM_PASSWORD,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_FIRST_NAME,
  FORM_ELEMENT_LAST_NAME,
  FORM_ELEMENT_PASSWORD,
  FORM_ELEMENT_PHONE_NUMBER,
  FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE,
} from 'constants/FormConstants';
import { BaseFormProps } from 'constants/TypeConstants';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CountryCodesResponse } from 'services/getCountryCodes';
import { store } from 'store/store';

import { style } from '../../../../constants/StyleConstants';
import { useGetCountryCodesQuery, useLazyCountriesQuery } from '../../../../services';
import { FormDataType } from '../../constants/RegistrationFormConstants';
import styles from './RegistrationForm.module.scss';

const styleAutocomplete = {
  ACAutocomplete: {
    inputWrapper: { backgroundColor: 'var(--background)', height: '40px' },
  },
};

const RegistrationForm = ({ errors, formData, handleChange }: BaseFormProps<FormDataType>) => {
  const { t } = useTranslation();
  const location = useLocation();
  const registrationPath = location?.pathname === '/registration';
  const {
    confirmPassword,
    country,
    email,
    firstName,
    lastName,
    password,
    phoneNumber,
    phoneNumberCountryCode,
  } = formData;
  const { data: countryCodes, isSuccess: isSuccessCountryCodes } = useGetCountryCodesQuery();
  const [fetchCountries, { data: countriesData, isSuccess }] = useLazyCountriesQuery();
  const { countries } = store.getState().globalData;

  useEffect(() => {
    if (registrationPath) {
      fetchCountries();
    }
  }, []);

  const countryCodeOptions = useMemo(() => {
    return countryCodes?.map((countryCode) => {
      const { callingCode, fullName, isoName } = countryCode;
      return {
        ...countryCode,
        label: fullName,
        value: isoName,
        code: callingCode,
      };
    });
  }, [countryCodes]);

  const customRenderCode = (countryCodes: CountryCodesResponse) => {
    return (
      <div className={styles.options}>
        <span className={styles.name}>(+{countryCodes?.callingCode})</span>
        <div className={styles.name}>{countryCodes?.fullName}</div>
      </div>
    );
  };

  const countryOptions = useMemo(() => {
    const optionsForCountries = registrationPath ? countriesData?.content : countries;
    return optionsForCountries?.map((country) => {
      return {
        ...country,
        label: country?.shortName,
        value: country?.uuid,
        name: country?.shortName,
      };
    });
  }, [countriesData, countries]);

  const displayCondition = registrationPath
    ? isSuccess && isSuccessCountryCodes
    : isSuccessCountryCodes;

  return displayCondition ? (
    <>
      <div className={styles.inputFields}>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.firstName}
            fullWidth
            hasError={!!errors?.firstName}
            id={FORM_ELEMENT_FIRST_NAME}
            label={t('registrationForm.firstName')}
            name={FORM_ELEMENT_FIRST_NAME}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            type='text'
            value={firstName}
          />
        </div>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.lastName}
            fullWidth
            hasError={!!errors?.lastName}
            id={FORM_ELEMENT_LAST_NAME}
            label={t('registrationForm.lastName')}
            name={FORM_ELEMENT_LAST_NAME}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            type='text'
            value={lastName}
          />
        </div>
      </div>
      <div className={styles.inputFields}>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.email}
            fullWidth
            hasError={!!errors?.email}
            id={FORM_ELEMENT_EMAIL}
            label={t('registrationForm.email')}
            name={FORM_ELEMENT_EMAIL}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            type={FORM_ELEMENT_EMAIL}
            value={email}
          />
        </div>
        <div className={styles.textField}>
          <p className={styles.text}>{t('registrationForm.country')}</p>
          <ACAutocompleteV2
            closeOnSelect
            dataTestId='country'
            errorMessage={errors?.country}
            fullWidth
            getCustomRender={(country) => (
              <CountryLabel isoName={country?.isoName} name={country?.name} />
            )}
            getOptionLabel={(option) => option?.shortName}
            hasError={!!errors?.country}
            name={FORM_ELEMENT_COUNTRY}
            onChange={(event) => handleChange(event)}
            options={countryOptions}
            renderInput={(params) => <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />}
            style={styleAutocomplete.ACAutocomplete}
            value={country}
          />
        </div>
      </div>
      <div className={styles.inputFields}>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.password}
            fullWidth
            hasError={!!errors?.password}
            id={FORM_ELEMENT_PASSWORD}
            label={t('registrationForm.password')}
            name={FORM_ELEMENT_PASSWORD}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            type={FORM_ELEMENT_PASSWORD}
            value={password}
          />
        </div>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            errorMessage={errors?.confirmPassword}
            fullWidth
            hasError={!!errors?.confirmPassword}
            id={FORM_ELEMENT_CONFIRM_PASSWORD}
            label={t('registrationForm.confirmPassword')}
            name={FORM_ELEMENT_CONFIRM_PASSWORD}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            type={FORM_ELEMENT_PASSWORD}
            value={confirmPassword}
          />
        </div>
      </div>
      <div className={styles.inputFields}>
        <div className={styles.textField}>
          <div>
            <p className={styles.text}>{t('registrationForm.countryCodes')}</p>
            <ACAutocompleteV2
              closeOnSelect
              dataTestId='countryCodes'
              errorMessage={errors?.phoneNumberCountryCode}
              fullWidth
              getCustomRender={(countryCodes) => customRenderCode(countryCodes)}
              getOptionLabel={(option) => option?.codeLabel}
              hasError={!!errors?.phoneNumberCountryCode}
              name={FORM_ELEMENT_PHONE_NUMBER_COUNTRY_CODE}
              onChange={(event) => handleChange(event)}
              options={countryCodeOptions}
              renderInput={(params) => (
                <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} style={style.ACInputAuth} />
              )}
              style={styleAutocomplete.ACAutocomplete}
              value={phoneNumberCountryCode}
            />
          </div>
        </div>
        <div className={styles.textField}>
          <ACInput
            autoComplete={AUTO_COMPLETE_OFF}
            dataTestId='phoneNumber'
            errorMessage={errors?.phoneNumber}
            fullWidth
            hasError={!!errors?.phoneNumber}
            id={FORM_ELEMENT_PHONE_NUMBER}
            label={t('registrationForm.phoneNumber')}
            name={FORM_ELEMENT_PHONE_NUMBER}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputAuth}
            value={phoneNumber}
          />
        </div>
      </div>
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default RegistrationForm;
