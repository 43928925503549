import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, VerifySmsCodeRequest>({
    query: (credentials) => ({
      url: '/verification/partner-user/verify/sms-code',
      method: 'POST',
      body: credentials,
    }),
  });

export type VerifySmsCodeRequest = { code: string };
