import { ACButton, ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import Modal from 'components/Modal/Modal';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { style, styleFooterIcon } from 'constants/StyleConstants';
import UserDetailView from 'features/appUser/components/UserDetailView/UserDetailView';
import {
  useBlockAppUserMutation,
  useGetAppUserByIdQuery,
  useUnblockAppUserMutation,
} from 'features/appUser/services';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useToggle from 'hooks/useToggle';
import { UserRemove, UserTick } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const DEFAULT_PATH = '/app-users';

const AppUserBasicInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: appUser, isFetching } = useGetAppUserByIdQuery({ id });
  const { userCountry } = useAppSelector(({ auth }) => auth);
  const [isBlockUserDialogOpen, toggleBlock] = useToggle();
  const [isUnblockUserDialogOpen, toggleUnblock] = useToggle();

  const [blockRequest, { isLoading: isLoadingBlock }] = useBlockAppUserMutation();
  const [unblockRequest, { isLoading: isLoadingUnblock }] = useUnblockAppUserMutation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const handleUnblockUser = async () => {
    try {
      await unblockRequest(id).unwrap();
      toggleUnblock();
      displaySuccess({
        key: t('blockModal.unblockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleUnblock();
      displayError({ key: code });
    }
  };

  const handleBlockUser = async () => {
    try {
      await blockRequest(id).unwrap();
      toggleBlock();
      displaySuccess({
        key: t('blockModal.blockModalSuccess'),
      });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      toggleBlock();
      displayError({ key: code });
    }
  };

  const additionalFooterButtons = [
    appUser?.blocked ? (
      <>
        <ACButton
          dataTestid='unblockUser'
          key='unBlockedUser'
          onClick={toggleUnblock}
          style={styleFooterIcon.iconAction}
          variant='text'
        >
          <UserTick style={styleFooterIcon.iconAction} />
          {t('appUsers.unblockUser')}
        </ACButton>
      </>
    ) : (
      <>
        <ACButton
          dataTestid='blockUser'
          key='blockedUser'
          onClick={toggleBlock}
          style={styleFooterIcon.iconAction}
          variant='text'
        >
          <UserRemove style={styleFooterIcon.iconAction} />
          {t('appUsers.blockUser')}
        </ACButton>
      </>
    ),
  ];

  const onDiscard = () => {
    const previousLocation = location?.state?.previousLocation || DEFAULT_PATH;
    const path = previousLocation.includes('product')
      ? `${previousLocation}?value=1`
      : previousLocation;
    navigate(path);
  };

  return !isFetching ? (
    <>
      <UserDetailView appUser={appUser} userCountry={userCountry} />
      <FormStickyFooter
        additionalActionButtons={additionalFooterButtons}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
      />
      {isUnblockUserDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleUnblock}
          disabled={isLoadingUnblock}
          handleSubmit={handleUnblockUser}
          header={t('blockModal.titleUnblock')}
          icon={<UserTick style={style.BlueIconModal} />}
          isShow={isUnblockUserDialogOpen}
          message={t('blockModal.descriptionUnblock')}
        />
      )}
      {isBlockUserDialogOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleBlock}
          disabled={isLoadingBlock}
          handleSubmit={handleBlockUser}
          header={t('blockModal.titleBlock')}
          icon={<UserRemove style={style.RedIconModal} />}
          isShow={isBlockUserDialogOpen}
          message={t('blockModal.descriptionBlock')}
        />
      )}
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='sm' />
  );
};

export default AppUserBasicInfo;
