import { ACButton, ACLoading, ACModal, ACSpinner } from '@antech-consulting/components';
import DetailViewModal from 'components/DetailViewModal/DetailViewModal';
import { Divider } from 'components/Divider/Divider';
import StyledACTable from 'components/StyledACTable/StyledACTable';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import { TableStateProps } from 'constants/TypeConstants';
import { buttonStyle } from 'features/orders/components/DetailViewPlace/DetailViewPlace';
import { useLazyGetOrdersRatingsQuery } from 'features/orders/services';
import { Rating } from 'features/orders/services/getOrdersRatings';
import { TABLE_ELEMENT_PICKED_UD_AT } from 'features/place/constants/TableConstants';
import { useAppSelector } from 'hooks/hooks';
import useTable from 'hooks/useTable';
import { InfoCircle } from 'iconsax-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CurrentUser } from 'store/Auth';
import { handleNavigateLink, transformACState } from 'utils/helpers';

import styles from '../PlaceReviews.module.scss';
import tableOrderReviewsSettings from './tableOrderReviewsSettings';

const OrdersReviews = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableSettings = useTable(tableOrderReviewsSettings);

  const [fetchOrdersRating, { data: ordersRating, isFetching }] = useLazyGetOrdersRatingsQuery();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Rating>(null);

  const handleChangeTableState = (tableState: TableStateProps<void>) => {
    fetchOrdersRating(transformACState({ ...tableState, place: id }, TABLE_ELEMENT_PICKED_UD_AT));
  };

  const { roles }: CurrentUser = useAppSelector(({ auth }) => auth);
  const isSystemRoot = roles[ROLE_SYSTEM];

  const ordersRatingTableData = ordersRating?.content?.map((order) => {
    const { comment, createdBy, userData } = order;
    const ratingComment = comment?.length > 20 ? comment.substring(0, 20) + '...' : comment;
    return {
      rawData: order,
      render: {
        ...order,
        comment: ratingComment,
        createdBy: (
          <>
            <div>{createdBy}</div>
            <div>{userData?.email}</div>
          </>
        ),
      },
    };
  });

  const handleInfo = ({ rowData }: { rowData: Rating }) => {
    setSelectedRow(rowData);
    setIsInfoOpen(true);
  };
  const handleCloseModal = () => {
    setIsInfoOpen(false);
  };

  const additionalActions = [
    {
      icon: <InfoCircle data-testid='reviewInfo' />,
      tooltip: {
        text: t('place.ordersRating.moreDetails'),
      },
      onClick: handleInfo,
    },
  ];

  const ModalBody = () => {
    const { code, comment, firstName, lastName, pickedUpAt, rating } = selectedRow;

    const handleNavigate = () => {
      handleNavigateLink();
      navigate(`/app-user/${selectedRow?.userData?.uuid}`, {
        state: { previousLocation: `${location.pathname}?value=5` },
      });
    };

    return (
      <div className={styles.modalInfo}>
        <div className={styles.linkSection}>
          <div className={styles.labelInfo}>
            {t('place.ordersRating.codeOrder')}
            <span className={styles.ratingInfo}>{code}</span>
          </div>
          {isSystemRoot && (
            <ACButton className={styles.link} onClick={handleNavigate} style={buttonStyle}>
              {t('orders.linkUserDetailView')}
            </ACButton>
          )}
        </div>
        <div className={styles.labelInfo}>
          {t('place.ordersRating.reviewed')}
          <span className={styles.ratingInfo}>
            {firstName} {lastName}
          </span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.ordersRating.pickedUpAt')}
          <span className={styles.ratingInfo}>{pickedUpAt}</span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.ordersRating.rating')}
          <span className={styles.ratingInfo}>{rating}</span>
        </div>
        <div className={styles.labelInfo}>
          {t('place.ordersRating.comment')}
          <span className={styles.ratingInfo}>{comment}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Divider />
      <ACLoading
        isLoading={isFetching}
        loadingElement={<ACSpinner background='var(--text-secondary)' size='xs' />}
      >
        <StyledACTable
          {...tableSettings}
          actions={additionalActions}
          allItemsCount={ordersRating?.totalElements}
          onTableStateChange={handleChangeTableState}
          pagesCount={ordersRating?.totalPages}
          rows={ordersRatingTableData}
        />
      </ACLoading>
      {isInfoOpen && (
        <ACModal isShow={isInfoOpen} onClose={handleCloseModal}>
          <DetailViewModal
            headerMessage={t('place.headerModalReview')}
            message={t('place.messageModalReview')}
            setIsDetailViewDialogOpen={handleCloseModal}
          >
            <ModalBody />
          </DetailViewModal>
        </ACModal>
      )}
    </>
  );
};

export default OrdersReviews;
