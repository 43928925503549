import SplashScreen from 'components/SplashScreen/SplashScreen';
import { useAppSelector } from 'hooks/hooks';
import useInitialAppData from 'hooks/useInitialAppData';
import { createContext, CSSProperties, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import { Notification } from '../Alert/Notification';
import Navbar from '../layouts/NavBar/Navbar';
import Sidebar from '../layouts/SideBar/Sidebar';
import MainRouter from './MainRouter';

export const MainContentRefContext = createContext(null);

const MainApp = () => {
  const shouldShowSplashScreen = useInitialAppData();
  const userFromStore = useAppSelector((state: RootState) => state.auth);

  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const notification = useSelector((state: RootState) => state.notification);
  const mainContentRef = useRef(null);
  const [hasFooterActionsContainer, setHasFooterActionsContainer] = useState(false);
  const heightRightSide = hasFooterActionsContainer ? '100vh' : '100%';
  const heightMainContentWrapper = hasFooterActionsContainer
    ? 'calc(100vh - 138px)'
    : 'calc(100vh - 65px)';

  const handleChangeOpen = () => {
    setIsMobileOpen((prev) => !prev);
  };

  const layoutStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    height: heightRightSide,
    width: '100%',
    position: 'relative',
  };

  const mainStyle: CSSProperties = {
    height: heightMainContentWrapper,
    padding: '20px 20px 20px 20px',
    overflow: 'auto',
    position: 'relative',
  };

  return (
    <>
      {shouldShowSplashScreen && <SplashScreen />}
      <div style={{ width: '100vw', display: 'flex' }}>
        <Sidebar isMobileOpen={isMobileOpen} setIsMobileOpen={setIsMobileOpen} />
        <MainContentRefContext.Provider
          value={{ mainContentRef, hasFooterActionsContainer, setHasFooterActionsContainer }}
        >
          <div ref={mainContentRef} style={layoutStyle}>
            <Navbar currentUser={userFromStore} handleChangeOpen={handleChangeOpen} />
            <div style={mainStyle}>
              <MainRouter />
              {notification.open && <Notification notification={notification} />}
            </div>
          </div>
        </MainContentRefContext.Provider>
      </div>
    </>
  );
};
export default MainApp;
