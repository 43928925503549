import { ACButton, ACInput } from '@antech-consulting/components';
import { ErrorMessage } from 'constants/Errors/ErrorTypes';
import { AC_INPUT_ROUND, AUTO_COMPLETE_OFF, FORM_ELEMENT_EMAIL } from 'constants/FormConstants';
import { validationSchema } from 'features/auth/constants/ForgotPasswordFormSchema';
import { useForgotPasswordRequestMutation } from 'features/auth/services';
import { RequestParameters } from 'features/auth/services/login';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { style } from '../../../../constants/StyleConstants';
import { useForm } from '../../../../hooks/useForm';
import { formDataInitialState, FormDataType } from '../../constants/ForgotPasswordFormConstants';
import styles from './ForgotPasswordForm.module.scss';

const ERROR_CODE = 'PT002';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displayError, displayWarning } = useDisplayNotification();
  const [forgotPasswordRequest] = useForgotPasswordRequestMutation();
  const { errors, formData, handleChangeFormData, handleSubmit } = useForm<FormDataType>(
    formDataInitialState,
    validationSchema,
  );
  const { email } = formData;

  const onSubmit = async () => {
    const ForgotPasswordRequestDTO: Omit<RequestParameters, 'password'> = {
      email,
    };
    try {
      await forgotPasswordRequest(ForgotPasswordRequestDTO).unwrap();
      navigate('/forgot-password-success');
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      code === ERROR_CODE ? displayWarning({ key: code }) : displayError({ key: code });
    }
  };

  return (
    <form>
      <div className={styles.textField}>
        <ACInput
          autoComplete={AUTO_COMPLETE_OFF}
          errorMessage={errors?.email}
          fullWidth
          hasError={!!errors?.email}
          id={FORM_ELEMENT_EMAIL}
          label={t('loginForm.email')}
          name={FORM_ELEMENT_EMAIL}
          onChange={handleChangeFormData}
          rounded={AC_INPUT_ROUND}
          style={style.ACInputAuth}
          type='text'
          value={email}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <ACButton
          dataTestid='ButtonForgotPassword'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
        >
          {t('forgotPassword.buttonSendEmail')}
        </ACButton>
      </div>
      <div className={styles.regLink}>
        <span className={styles.text}>{t('forgotPassword.textBackTo')} </span>
        <Link className={styles.link} to='/'>
          {t('forgotPassword.linkSignIn')}
        </Link>
      </div>
    </form>
  );
};
export default ForgotPasswordForm;
