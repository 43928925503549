import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/product-category/${id}/remove-image`,
      method: 'POST',
    }),
  });
