import { ACCard } from '@antech-consulting/components';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import {
  ROLE_ELEMENT_MANAGER,
  ROLE_ELEMENT_OWNER,
  ROLE_ELEMENT_TERMINAL,
} from 'constants/RolesConstants';
import { User } from 'features/partnerUser/services/getPartnerUserById';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'store/store';
import { handleDateAndTime } from 'utils/helpers';

import { FormHeader } from '../../../../components/FormHeader/FormHeader';
import UserBasicInformations from '../UserBasicInformations/UserBasicInformations';
import styles from './UserDetailView.module.scss';

type UserDetailViewProps = {
  formData: User;
};

const UserDetailView = ({ formData }: UserDetailViewProps) => {
  const { t } = useTranslation();
  const { roles } = formData;
  const nameRoles = Object.keys(roles);
  const countriesContent = store.getState().auth.userCountry;
  const { dateFormat } = countriesContent;
  const { language } = store.getState().language;
  const isRolesSectionDisplay = roles.map((role) => {
    return (
      role?.name === ROLE_ELEMENT_OWNER ||
      role?.name === ROLE_ELEMENT_MANAGER ||
      role?.name === ROLE_ELEMENT_TERMINAL
    );
  });

  const userRoles = useMemo(() => {
    return roles?.map((role) => {
      const roleName =
        (role?.name === ROLE_ELEMENT_OWNER && t('partnerUser.owner') + ': ') ||
        (role?.name === ROLE_ELEMENT_MANAGER && t('partnerUser.manager') + ': ') ||
        (role?.name === ROLE_ELEMENT_TERMINAL && t('partnerUser.terminal') + ': ');
      return (
        <div className={styles.rolesData} key={'roles'}>
          <div className={styles.rolesName}>{roleName}</div>
          {role?.places?.map((place) => {
            return (
              <li className={styles.placeName} key={place}>
                {place}
              </li>
            );
          })}
        </div>
      );
    });
  }, [nameRoles]);

  const BodyContentRolesInfo = () => {
    return (
      <div className={styles.dataWrapper}>
        <div className={styles.textData}>
          <div>{userRoles}</div>
        </div>
      </div>
    );
  };

  const BodyUserActivity = () => {
    const lastActiveAtFormatted = handleDateAndTime(formData?.lastActiveAt, dateFormat, language);
    const { formattedDate, formattedTime } = lastActiveAtFormatted;
    return (
      <div className={styles.lastActiveAt}>
        <div>{t('partnerUser.lastActiveAt')}:</div>
        <div>
          {formData?.lastActiveAt ? `${formattedDate}, ${formattedTime}` : FORM_ELEMENT_EMPTY}
        </div>
      </div>
    );
  };

  return (
    <>
      <form className={styles.form}>
        <div className={styles.baseInfoSection}>
          <FormHeader
            primaryText={t('partnerUser.subtitleBaseInfo')}
            secondaryText={t('partnerUser.descriptionBaseInfo')}
          />
          <div className={styles.divider} />
          <ACCard
            bodyChildren={<UserBasicInformations formData={formData} />}
            className={styles.card}
          />
          <FormHeader
            primaryText={t('partnerUser.titleUserActivity')}
            secondaryText={t('partnerUser.descriptionUserActivity')}
          />
          <div className={styles.divider} />
          <ACCard bodyChildren={<BodyUserActivity />} className={styles.card} />
        </div>
        {isRolesSectionDisplay.some(Boolean) === true && (
          <div className={styles.rolesInfoSection}>
            <FormHeader
              primaryText={t('partnerUser.subtitleRoles')}
              secondaryText={t('partnerUser.descriptionRoles')}
            />
            <div className={styles.divider} />
            <ACCard bodyChildren={<BodyContentRolesInfo />} className={styles.card} />
          </div>
        )}
      </form>
    </>
  );
};

export default UserDetailView;
