import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_INVENTORIES, TAG_INVENTORY } from 'services/TagsConstants';

import { InventoryDTO } from './createInventory';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, InventoryDTO>({
    query: (data) => {
      const { uuid, ...payload } = data;
      return {
        url: `/inventory/${uuid}`,
        method: 'PUT',
        body: payload,
      };
    },
    invalidatesTags: [TAG_INVENTORIES, TAG_INVENTORY],
  });
