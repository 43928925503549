import { ACAutocompleteV2, ACInput } from '@antech-consulting/components';
import CountryLabel from 'components/CountryLabel/CountryLabel';
import { FormHeader } from 'components/FormHeader/FormHeader';
import {
  AC_INPUT_MARGIN_BOTTOM,
  AC_INPUT_ROUND,
  AUTO_COMPLETE_OFF,
  FORM_ELEMENT_APARTMENT,
  FORM_ELEMENT_CITY,
  FORM_ELEMENT_COMPANY_REQUEST,
  FORM_ELEMENT_COUNTRY,
  FORM_ELEMENT_EMAIL,
  FORM_ELEMENT_FIRST_NAME,
  FORM_ELEMENT_FLOOR,
  FORM_ELEMENT_ID_NUMBER,
  FORM_ELEMENT_LAST_NAME,
  FORM_ELEMENT_NAME,
  FORM_ELEMENT_PHONE,
  FORM_ELEMENT_STATUS,
  FORM_ELEMENT_STREET,
  FORM_ELEMENT_STREET_NUMBER,
  FORM_ELEMENT_VAT_NUMBER,
  FORM_ELEMENT_ZIP_CODE,
} from 'constants/FormConstants';
import { style } from 'constants/StyleConstants';
import { BaseFormProps, OptionsType } from 'constants/TypeConstants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormDataType } from '../../constants/FormConstants';
import styles from './BaseForm.module.scss';

type BaseFormCompanyRequestsProps = BaseFormProps<FormDataType> & {
  companyRequestId?: string;
  companyRequestIsEditable: boolean;
  countriesAutocompleteOptions: OptionsType[];
  currentUserData?: any;
  handleInputChangeCountries?: (value: string) => void;
  onDiscard?: () => void;
  onScrollCountries?: () => void;
  setFormData?: (data: FormDataType) => void;
  systemAdminIsLoggedIn?: boolean;
};

export const FIRST_ELEMENT = 0;
const SECOND_ELEMENT = 1;

const BaseForm = ({
  companyRequestId,
  companyRequestIsEditable,
  countriesAutocompleteOptions,
  currentUserData,
  errors,
  formData,
  handleChange,
  setFormData,
  systemAdminIsLoggedIn,
}: BaseFormCompanyRequestsProps) => {
  const { t } = useTranslation();
  const {
    apartment,
    city,
    country,
    floor,
    idNumber,
    name,
    status,
    street,
    streetNumber,
    vatNumber,
    zipCode,
  } = formData;

  const baseSecondaryText = companyRequestId
    ? t('form.editBaseSubtitle')
    : t('form.addBaseSubtitle');
  const addressSecondaryText = companyRequestId
    ? t('form.place.editSubtitleAddress')
    : t('form.place.addSubtitleAddress');

  const statusTranslated = useMemo(() => {
    const val = t(`${FORM_ELEMENT_COMPANY_REQUEST}.${status?.toUpperCase()}`);
    if (companyRequestId && status) {
      return val[FIRST_ELEMENT].toUpperCase() + val.slice(SECOND_ELEMENT).toLowerCase();
    } else {
      return '';
    }
  }, [companyRequestId, status]);

  const handleCountryChange = (event) => {
    const {
      ac: { value },
    } = event;
    setFormData({
      ...formData,
      country: value,
      city: '',
      zipCode: '',
      street: '',
      streetNumber: '',
      floor: '',
      apartment: '',
    });
  };

  return (
    <>
      <form className={styles.formWrapper}>
        {companyRequestIsEditable ? (
          <FormHeader primaryText={t('form.formBaseTitle')} secondaryText={baseSecondaryText} />
        ) : (
          <FormHeader
            primaryText={t('form.formBaseTitle')}
            secondaryText={t('form.place.detailViewSubtitleBase')}
          />
        )}
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestIdNumber'
              disabled={!companyRequestIsEditable}
              errorMessage={errors?.idNumber}
              fullWidth
              hasError={!!errors?.idNumber}
              id={FORM_ELEMENT_ID_NUMBER}
              label={t('companyRequest.idNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_ID_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={idNumber}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestVatNumber'
              disabled={!companyRequestIsEditable}
              errorMessage={errors?.vatNumber}
              fullWidth
              hasError={!!errors?.vatNumber}
              id={FORM_ELEMENT_VAT_NUMBER}
              label={t('companyRequest.vatNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_VAT_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={vatNumber}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <ACInput
            dataTestId='companyRequestName'
            disabled={!companyRequestIsEditable}
            errorMessage={errors?.name}
            fullWidth
            hasError={!!errors?.name}
            id={FORM_ELEMENT_NAME}
            label={t('companyRequest.name')}
            marginBottom={AC_INPUT_MARGIN_BOTTOM}
            name={FORM_ELEMENT_NAME}
            onChange={handleChange}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={name}
          />
        </div>
        {companyRequestId && (
          <div className={styles.inputsContainer}>
            <div className={styles.inputHalfWidth}>
              <ACInput
                autoComplete={AUTO_COMPLETE_OFF}
                dataTestId='companyRequestStatus'
                disabled
                errorMessage={errors?.status}
                fullWidth
                hasError={!!errors?.status}
                id={FORM_ELEMENT_STATUS}
                label={t('companyRequest.status')}
                marginBottom={AC_INPUT_MARGIN_BOTTOM}
                name={FORM_ELEMENT_STATUS}
                onChange={handleChange}
                rounded={AC_INPUT_ROUND}
                style={style.ACInputForm}
                value={statusTranslated}
              />
            </div>
          </div>
        )}
        {companyRequestIsEditable ? (
          <FormHeader
            primaryText={t('form.place.formTitleAddress')}
            secondaryText={addressSecondaryText}
          />
        ) : (
          <FormHeader
            primaryText={t('form.place.formTitleAddress')}
            secondaryText={t('form.place.detailViewSubtitleAddress')}
          />
        )}
        <div className={styles.inputsContainer}>
          <ACAutocompleteV2
            closeOnSelect
            dataTestId='companyRequestCountry'
            disabled={!companyRequestIsEditable}
            errorMessage={errors?.country}
            fullWidth
            getCustomRender={(country) => (
              <CountryLabel isoName={country?.isoName} name={country?.name} />
            )}
            getOptionLabel={(option) => option?.name}
            hasError={!!errors?.country}
            id={FORM_ELEMENT_COUNTRY}
            label={t('companyRequest.country')}
            name={FORM_ELEMENT_COUNTRY}
            onChange={(event) => {
              handleCountryChange(event);
            }}
            options={countriesAutocompleteOptions}
            renderInput={(params) => {
              return <ACInput autoComplete={AUTO_COMPLETE_OFF} {...params} />;
            }}
            rounded={AC_INPUT_ROUND}
            style={style.ACInputForm}
            value={country}
          />
        </div>
        {/* TODO: this is temporary solution before acinput width styling configuration will be fixed */}
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestCity'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.city}
              fullWidth
              hasError={!!errors?.city}
              id={FORM_ELEMENT_CITY}
              label={t('companyRequest.city')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_CITY}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? city : ''}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestZipCode'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.zipCode}
              fullWidth
              hasError={!!errors?.zipCode}
              id={FORM_ELEMENT_ZIP_CODE}
              label={t('companyRequest.zipCode')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_ZIP_CODE}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? zipCode : ''}
            />
          </div>
        </div>
        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestStreet'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.street}
              fullWidth
              hasError={!!errors?.street}
              id={FORM_ELEMENT_STREET}
              label={t('companyRequest.street')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_STREET}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? street : ''}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestStreetNumber'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.streetNumber}
              fullWidth
              hasError={!!errors?.streetNumber}
              id={FORM_ELEMENT_STREET_NUMBER}
              label={t('companyRequest.streetNumber')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_STREET_NUMBER}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? streetNumber : ''}
            />
          </div>
        </div>

        <div className={styles.inputsContainer}>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestFloor'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.floor}
              fullWidth
              hasError={!!errors?.floor}
              id={FORM_ELEMENT_FLOOR}
              label={t('companyRequest.floor')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_FLOOR}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? floor : ''}
            />
          </div>
          <div className={styles.inputHalfWidth}>
            <ACInput
              autoComplete={AUTO_COMPLETE_OFF}
              dataTestId='companyRequestApartment'
              disabled={!companyRequestIsEditable || !country}
              errorMessage={errors?.apartment}
              fullWidth
              hasError={!!errors?.apartment}
              id={FORM_ELEMENT_APARTMENT}
              label={t('companyRequest.apartment')}
              marginBottom={AC_INPUT_MARGIN_BOTTOM}
              name={FORM_ELEMENT_APARTMENT}
              onChange={handleChange}
              rounded={AC_INPUT_ROUND}
              style={style.ACInputForm}
              value={country ? apartment : ''}
            />
          </div>
        </div>
        {systemAdminIsLoggedIn && (
          <>
            <FormHeader
              primaryText={t('form.place.formTitleUser')}
              secondaryText={t('form.place.editSubtitleUser')}
            />
            <div className={styles.inputsContainer}>
              <div className={styles.inputHalfWidth}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_FIRST_NAME}
                  label={t('partnerUsers.firstName')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_FIRST_NAME}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={currentUserData?.firstName}
                />
              </div>
              <div className={styles.inputHalfWidth}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_LAST_NAME}
                  label={t('partnerUsers.lastName')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_LAST_NAME}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={currentUserData?.lastName}
                />
              </div>
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.inputHalfWidth}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_EMAIL}
                  label={t('partnerUsers.email')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_EMAIL}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={currentUserData?.email}
                />
              </div>
              <div className={styles.inputHalfWidth}>
                <ACInput
                  autoComplete={AUTO_COMPLETE_OFF}
                  disabled
                  fullWidth
                  id={FORM_ELEMENT_PHONE}
                  label={t('partnerUsers.phoneNumber')}
                  marginBottom={AC_INPUT_MARGIN_BOTTOM}
                  name={FORM_ELEMENT_PHONE}
                  rounded={AC_INPUT_ROUND}
                  style={style.ACInputForm}
                  value={currentUserData?.phoneNumber}
                />
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default BaseForm;
