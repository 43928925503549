import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { FORM_ELEMENT_EMPTY } from 'constants/FormConstants';
import { SortProps } from 'constants/TypeConstants';
import moment from 'moment';
import { TAG_PARTNER_USERS } from 'services/TagsConstants';
import { store } from 'store/store';

import { createQueryString, handleDateAndTime } from '../../../utils/helpers';

const BASE_URL = '/partner-users';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<PartnerUserResponse, PartnerUserParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const partnerUsersResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const partnerUsersData = partnerUsersResponse.data as PartnerUserResponse;
      const countriesContent = store.getState().auth.userCountry;
      const { dateFormat } = countriesContent;
      const { language } = store.getState().language;
      const partnerUsers = partnerUsersData?.content.map((partnerUser: PartnerUser) => {
        const createdAtFormatted = handleDateAndTime(partnerUser?.createdAt, dateFormat, language);
        const lastActiveAtFormatted = handleDateAndTime(
          partnerUser?.lastActiveAt,
          dateFormat,
          language,
        );
        const { formattedDate: dateCreatedAt, formattedTime: timeCreatedAt } = createdAtFormatted;
        const { formattedDate: dateLastActiveAt, formattedTime: timeLastActiveAt } =
          lastActiveAtFormatted;
        return {
          ...partnerUser,
          createdAt: `${dateCreatedAt}, ${timeCreatedAt}`,
          lastActiveAt: partnerUser?.lastActiveAt
            ? `${dateLastActiveAt}, ${timeLastActiveAt}`
            : FORM_ELEMENT_EMPTY,
        };
      });
      return {
        data: {
          content: partnerUsers,
          totalElements: partnerUsersData?.totalElements,
          totalPages: partnerUsersData?.totalPages,
          currentPage: partnerUsersData?.pageable?.pageNumber,
        },
      };
    },
    providesTags: [TAG_PARTNER_USERS],
  });

export type PartnerUserParameters = {
  ids?: string;
  page?: number;
  searchTerm?: string;
  size?: number;
  sort?: SortProps;
};

export type PartnerUser = {
  blocked: boolean;
  country: string;
  createdAt: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastActiveAt: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  phoneVerified: boolean;
  uuid: string;
};

export type PartnerUserResponse = {
  content: PartnerUser[];
  currentPage?: number;
  pageable?: {
    pageNumber?: number;
  };
  totalElements?: number;
  totalPages?: number;
};
