export const TAG_APP_USERS = 'appUsers';
export const TAG_BLOCKED_APP_USERS = 'blockedAppUsers';
export const TAG_APP_USER = 'appUser';
export const TAG_USERS = 'users';
export const TAG_USER = 'user';
export const TAG_PARTNER_USERS = 'partnerUsers';
export const TAG_BLOCKED_PARTNER_USERS = 'blockedPartnerUsers';
export const TAG_PARTNER_USER = 'partnerUser';
export const TAG_COMPANIES = 'companies';
export const TAG_COMPANY = 'company';
export const TAG_COMPANY_REQUESTS = 'companyRequests';
export const TAG_COMPANY_REQUEST = 'companyRequest';
export const TAG_PLACES = 'places';
export const TAG_PLACE = 'place';
export const TAG_IN_PREPARATION_PLACES = 'inPreparationPlaces';
export const TAG_UNVERIFIED_PLACES = 'unverifiedPlaces';
export const TAG_ARCHIVED_PLACES = 'archivedPlaces';
export const TAG_PRODUCTS = 'products';
export const TAG_ARCHIVED_PRODUCTS = 'archivedProducts';
export const TAG_PRODUCT = 'product';
export const TAG_PRODUCT_CATEGORIES = 'productCategories';
export const TAG_PRODUCT_CATEGORY = 'productCategory';
export const TAG_PRODUCT_CATEGORY_REQUESTS = 'productCategoryRequests';
export const TAG_PRODUCT_CATEGORY_REQUEST = 'productCategoryRequest';
export const TAG_ORDER = 'order';
export const TAG_PARTNER_APPLICATION_SETTINGS = 'partnerApplicationSettings';
export const TAG_USER_APPLICATION_SETTINGS = 'userApplicationSettings';
export const TAG_WEB_APPLICATION_SETTINGS = 'webApplicationSettings';
export const TAG_INVENTORIES = 'inventories';
export const TAG_INVENTORY = 'inventory';
export const TAG_CITY = 'city';
export const TAG_MAP_LOCATION = 'mapLocation';
