export const TABLE_ELEMENT_CREATED_AT = 'createdAt';
export const TABLE_ELEMENT_CREATED_BY = 'createdBy';
export const TABLE_ELEMENT_GROSS = 'gross';
export const TABLE_ELEMENT_NET = 'net';
export const TABLE_ELEMENT_STATUSES = 'statuses';
export const TABLE_ELEMENT_TOTAL = 'total';
export const TABLE_ELEMENT_COMMENT = 'comment';
export const TABLE_ELEMENT_SOLD = 'sold';
export const TABLE_ELEMENT_PERCENTAGE = 'percentage';
export const TABLE_INITIAL_ROWS_NUMBER = 10;
export const TABLE_ALL_ITEMS_COUNT = 5;
export const TABLE_PAGES_COUNT = 1;
export const TABLE_ELEMENT_SEARCH_TERM = 'search-term';
export const TABLE_ELEMENT_LAST_ACTIVE_AT = 'lastActiveAt';
export const DEFAULT_SORT = {
  field: TABLE_ELEMENT_CREATED_AT,
  type: 'desc',
};
