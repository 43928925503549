import { ACSpinner } from '@antech-consulting/components';
import { useCreateCompanyRequestMutation } from 'features/company-request/services';
import { useAppSelector } from 'hooks/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/company-request/components/BaseForm/BaseForm';
import { validationSchema } from '../../features/company-request/constants/CompanyRequestSchema';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/company-request/constants/FormConstants';
import { useCompanyRequestsForm } from '../../features/company-request/hooks/useCompanyRequestsForm';
import { CountryOption } from '../../services/country';
import styles from './AddCompanyRequest.module.scss';

const AddCompanyRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addCompanyRequest, { isLoading }] = useCreateCompanyRequestMutation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const { countriesOptions } = useCompanyRequestsForm(formData, setFormData);
  const { cities, countries } = useAppSelector(({ globalData }) => globalData);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const { uuid } = formData?.country as CountryOption;
    const companyData = { ...trimmedData, country: uuid };
    try {
      await addCompanyRequest(companyData).unwrap();
      navigate('/company-requests');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
      displaySuccess({ key: 'companyRequest.addCompanyRequest' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = () => {
    navigate('/company-requests');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return countries && cities && !isLoading ? (
    <>
      <PageHeader primaryText={t('companyRequest.titleCreate')} />
      <div className={styles.companyRequestForm}>
        <BaseForm
          companyRequestIsEditable={true}
          countriesAutocompleteOptions={countriesOptions}
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          onDiscard={onDiscard}
          setFormData={setFormData}
        />
      </div>
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddCompanyRequest;
