import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  name: {
    isRequired: true,
    max: 50,
  },
  type: {
    isRequired: true,
  },
  description: {
    isRequired: true,
    max: 200,
  },
  serialNumber: {
    isRequired: true,
    max: 50,
  },
  country: {
    isRequired: true,
  },
};
