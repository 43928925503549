import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_BLOCKED_PARTNER_USERS, TAG_PARTNER_USER } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/partner-user/${id}/unblock`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_BLOCKED_PARTNER_USERS, TAG_PARTNER_USER],
  });
