import { ACSpinner } from '@antech-consulting/components';
import { Divider } from 'components/Divider/Divider';
import Modal from 'components/Modal/Modal';
import { FILE, UPLOAD_FILE } from 'constants/FormConstants';
import { ROLE_SYSTEM } from 'constants/RolesConstants';
import DetailViewPlaceActions from 'features/place/components/DetailViewPlaceActions/DetailViewPlaceActions';
import {
  PLACE_STATUS_ACTIVE,
  PLACE_STATUS_IN_PREPARATION,
  PLACE_STATUS_VERIFY,
} from 'features/place/constants/PlaceContants';
import useDisplayNotification from 'hooks/useDisplayNotification';
import useToggle from 'hooks/useToggle';
import { Additem, Archive, ExportSquare, Trash, Verify } from 'iconsax-react';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { store } from 'store/store';
import { handleUpdatePlaceNavigation, trimObjectValues } from 'utils/helpers';

import FormStickyFooter from '../../components/FormStickyFooter/FormStickyFooter';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import { style } from '../../constants/StyleConstants';
import BaseForm from '../../features/place/components/BaseForm/BaseForm';
import WorkingHoursForm from '../../features/place/components/WorkingHoursForm/WorkingHoursForm';
import {
  formDataInitialState,
  FormDataTypePlace,
} from '../../features/place/constants/FormConstants';
import placeSchema from '../../features/place/constants/PlaceSchema';
import { transformToDTOFormat, usePlacesForm } from '../../features/place/hooks/usePlacesForm';
import {
  useArchivePlaceMutation,
  useChangePlacesStatusMutation,
  useRemovePlaceImageMutation,
  useUpdatePlaceMutation,
  useUploadPlaceImageMutation,
} from '../../features/place/services';
import { PlaceUpdateDTO } from '../../features/place/services/updatePlace';
import { useFileUpload } from '../../hooks/useFileUpload';
import { useForm } from '../../hooks/useForm';

const minimumOrderAmountSchema = 'minimumOrderAmount';
const EditPlace = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [isDocumentRemoved, setIsDocumentRemove] = useState<boolean>(false);

  const [update, { isLoading: isLoadingUpdatePlace }] = useUpdatePlaceMutation();
  const [uploadDocument] = useUploadPlaceImageMutation();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataTypePlace>(formDataInitialState, placeSchema);
  const [
    removeDocument,
    { isLoading: isLoadingRemoveDocument, isSuccess: isSuccessRemoveDocument },
  ] = useRemovePlaceImageMutation();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useToggle();
  const [isPreparationModalOpen, togglePreparation] = useToggle();
  const [isArchivePlaceModalOpen, toggleArchive] = useToggle();
  const [changeStatus, { isLoading: isLoadingStatus }] = useChangePlacesStatusMutation();
  const [archivePlace] = useArchivePlaceMutation();
  const [isActiveModalOpen, toggleActive] = useToggle();
  const [isVerifiedModalOpen, toggleVerified] = useToggle();

  const roles = store.getState().auth.roles;
  const system = roles[ROLE_SYSTEM as never];

  const [file, error, handleFileChange, lastUploadAction] = useFileUpload();
  const {
    citiesOptions,
    companiesOptions,
    coordinates,
    countriesOptions,
    handleChangeMapInfo,
    handleMapActions,
    handleMapInputChange,
    handleViewportChange,
    isFetching,
    isFetchingLocation,
    isFetchingPlace,
    isSuccessEdit,
    mapData,
    mapOptions,
    mapViewport,
    place,
    placeCategoriesOptions,
    shouldDisableSubmitButton,
  } = usePlacesForm(formData, setFormData, id, file);
  const shouldButtonBeDisabled = shouldDisableSubmitButton && !isSuccessRemoveDocument;

  useEffect(() => {
    if (!system && place?.minimumOrderAmount !== formData.minimumOrderAmount) {
      placeSchema[minimumOrderAmountSchema] = {
        isRequired: true,
        isRounded: true,
        isValueGreater: 'country',
      };
    }
  }, []);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataTypePlace>(formData);
    const placeUpdateDTO: PlaceUpdateDTO = transformToDTOFormat({
      ...trimmedData,
      uuid: id,
    });
    delete placeUpdateDTO.rating;
    delete placeUpdateDTO.chosenLocation;
    delete placeUpdateDTO.owner;
    const placeUpdateObject = {
      ...placeUpdateDTO,
      minimumOrderAmount: system
        ? trimmedData?.minimumOrderAmountRoot
        : trimmedData?.minimumOrderAmount,
    };
    place?.minimumOrderAmount === placeUpdateObject?.minimumOrderAmount &&
      delete placeUpdateObject?.minimumOrderAmount;
    try {
      if (isDocumentRemoved) {
        await removeDocument(placeUpdateObject?.uuid);
      }
      if (lastUploadAction === UPLOAD_FILE) {
        const dataImage = new FormData();
        dataImage.append(FILE, file);
        uploadDocument({
          file: dataImage,
          id: placeUpdateObject?.uuid,
        });
        navigate(handleUpdatePlaceNavigation(place?.status, place?.verified));
      }
      const placeData = { ...place };
      delete placeData?.document;
      delete placeData?.minimumOrderAmount;
      delete placeData?.company;
      delete placeData?.owner;
      delete placeData?.rating;
      delete placeUpdateObject?.document;
      delete placeUpdateObject?.company;
      delete placeUpdateObject?.lastStockChangeAt;
      delete placeUpdateObject?.lastOrderAt;
      if (!isEqual(placeUpdateObject, placeData)) {
        if (isEqual(placeData.beneficiaryAccount, placeUpdateObject.beneficiaryAccount)) {
          delete placeUpdateObject?.beneficiaryAccount;
          await update(placeUpdateObject).unwrap();
          displaySuccess({ key: 'place.updatePlace' });
        } else {
          await update(placeUpdateObject).unwrap();
          displaySuccess({ key: 'place.updatePlace' });
        }
      }
      navigate(handleUpdatePlaceNavigation(place?.status, place?.verified));
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const handleSubmitPlace = () => {
    system && delete formData.minimumOrderAmount;
    !system &&
      place?.minimumOrderAmount === formData.minimumOrderAmount &&
      delete placeSchema?.minimumOrderAmount;
    !system && delete formData.minimumOrderAmountRoot;
    handleSubmit(onSubmit);
  };

  const onDiscard = () => {
    const path = location?.state?.previousLocation || '/places';
    navigate(path);
  };

  const handelClose = () => {
    setIsRemoveDialogOpen();
  };

  const handleRemoveDocument = () => {
    setIsDocumentRemove(true);
    handelClose();
    setFormData((prevFormData) => ({ ...prevFormData, document: undefined }));
  };

  const handleStatistics = () => {
    navigate(`/place-statistics/${id}`);
  };

  const additionalFooterButtons = [
    <DetailViewPlaceActions
      handleStatistics={handleStatistics}
      key={id}
      place={place}
      toggleActive={toggleActive}
      toggleArchive={toggleArchive}
      togglePreparation={togglePreparation}
      toggleVerified={toggleVerified}
    />,
  ];
  type ChangeStatusProps = {
    archive?: boolean;
    key?: string;
    toggle: () => void;
    verificationType?: string;
  };

  const handleChangeStatus = async ({
    archive,
    key,
    toggle,
    verificationType,
  }: ChangeStatusProps) => {
    try {
      const actions = archive
        ? archivePlace(id).unwrap()
        : changeStatus({ id, verificationType }).unwrap();
      await actions;
      toggle();
      displaySuccess({ key: archive ? t('archiveModal.archiveModalSuccess') : `place.${key}` });
      archive && navigate('/');
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
      toggle();
    }
  };

  return !isFetchingPlace && isSuccessEdit ? (
    <>
      <Divider />
      <BaseForm
        cities={citiesOptions}
        companies={companiesOptions}
        coordinates={coordinates}
        countries={countriesOptions}
        errors={errors}
        file={file}
        formData={formData}
        handleChange={handleChangeFormData}
        handleFileChange={handleFileChange}
        handleFileDelete={handelClose}
        handleMapActions={handleMapActions}
        handleMapInputChange={handleMapInputChange}
        handleSearchMap={handleChangeMapInfo}
        handleViewportChange={handleViewportChange}
        isFetching={isFetching}
        isFetchingLocation={isFetchingLocation}
        mapData={mapData}
        mapOptions={mapOptions}
        mapViewport={mapViewport}
        placeCategoriesOptions={placeCategoriesOptions}
        placeId={id}
        setFormData={setFormData}
        system={system}
      />
      {isRemoveDialogOpen ? (
        <Modal
          buttonText={t('blockModal.buttonDelete')}
          closeModal={handelClose}
          disabled={isLoadingRemoveDocument}
          handleSubmit={handleRemoveDocument}
          header={t('deleteModal.removeDocumentTitle')}
          icon={<Trash style={style.RedIconModal} />}
          isShow={isRemoveDialogOpen}
          message={t('deleteModal.removeDocumentMessage')}
        />
      ) : null}
      {isPreparationModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={togglePreparation}
          disabled={isLoadingStatus}
          handleSubmit={() =>
            handleChangeStatus({
              key: 'putInPreparation',
              toggle: togglePreparation,
              verificationType: PLACE_STATUS_IN_PREPARATION,
            })
          }
          header={t('place.changeStatus')}
          icon={<ExportSquare style={style.RedIconModal} />}
          isShow={isPreparationModalOpen}
          message={t('place.inPreparationPlace')}
        />
      )}
      {isArchivePlaceModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleArchive}
          disabled={isLoadingStatus}
          handleSubmit={() =>
            handleChangeStatus({
              archive: true,
              toggle: toggleArchive,
            })
          }
          header={t('archiveModal.titleArchive')}
          icon={<Archive style={style.RedIconModal} />}
          isShow={isArchivePlaceModalOpen}
          message={t('archiveModal.descriptionArchive')}
        />
      )}
      {isActiveModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleActive}
          disabled={isLoadingStatus}
          handleSubmit={() =>
            handleChangeStatus({
              key: 'activatePlace',
              toggle: toggleActive,
              verificationType: PLACE_STATUS_ACTIVE,
            })
          }
          header={t('place.changeStatus')}
          icon={<Additem style={style.BlueIconModal} />}
          isShow={isActiveModalOpen}
          message={t('place.activationPlace')}
        />
      )}
      {isVerifiedModalOpen && (
        <Modal
          buttonText={t('blockModal.buttonConfirm')}
          closeModal={toggleVerified}
          disabled={isLoadingStatus}
          handleSubmit={() =>
            handleChangeStatus({
              key: 'verifyPlace',
              toggle: toggleVerified,
              verificationType: PLACE_STATUS_VERIFY,
            })
          }
          header={t('place.changeStatus')}
          icon={<Verify style={style.BlueIconModal} />}
          isShow={isVerifiedModalOpen}
          message={t('place.verifyPlaceTitle')}
        />
      )}
      <WorkingHoursForm
        errors={errors}
        handleChange={handleChangeFormData}
        headerPrimaryText={t('form.place.workingHours')}
        headerSecondaryText={t('form.place.editWorkingHours')}
        hours={formData?.workingHours}
      />
      <FormStickyFooter
        additionalActionButtons={isSuccessEdit && additionalFooterButtons}
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={handleSubmitPlace}
        onSubmitText={t('footer.saveButton')}
        shouldDisableSubmitButton={shouldButtonBeDisabled}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default EditPlace;
