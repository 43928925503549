import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_PRODUCTS } from 'services/TagsConstants';

import { ProductUpdate } from './updateProduct';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<ResponseProduct, NewProduct>({
    query: (credentials) => ({
      url: '/products',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_PRODUCTS],
  });

export type NewProduct = ProductUpdate & {
  code?: string;
  place?: string;
  weight?: number | string;
};

export type ResponseProduct = {
  available: number;
  basePrice: number;
  categories: string[];
  code: string;
  createdAt: string;
  createdBy: string;
  description: string;
  discountedPrice: number;
  initialDailyAvailable?: number;
  name: string;
  place: string;
  updatedAt: string;
  updatedBy: string;
  uuid: string;
  version: number;
  weight: number | string;
};
