import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  email: {
    isRequired: true,
    isEmail: true,
    max: 60,
  },
  password: {
    password: true,
  },
};

export const validationSchemaNewPassword: FormValidationType = {
  password: {
    password: true,
  },
};
