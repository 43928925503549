import api from '../../../services/api';
import blockPartnerUser from '../../partnerUser/services/blockPartnerUser';
import getBlockedPartnerUsers from '../../partnerUser/services/getBlockedPartnerUsers';
import getPartnerUsers from '../../partnerUser/services/getPartnerUsers';
import unblockPartnerUser from '../../partnerUser/services/unblockPartnerUser';
import getPartnerUserById from './getPartnerUserById';
import getPartnerUsersRecursive from './getPartnerUsersRecursive';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPartnerUsers: getPartnerUsers(build),
    blockPartnerUser: blockPartnerUser(build),
    unblockPartnerUser: unblockPartnerUser(build),
    getBlockedPartnerUsers: getBlockedPartnerUsers(build),
    getPartnerUsersRecursive: getPartnerUsersRecursive(build),
    getPartnerUserById: getPartnerUserById(build),
  }),
  overrideExisting: false,
});

export const {
  useBlockPartnerUserMutation,
  useGetPartnerUserByIdQuery,
  useGetPartnerUsersRecursiveQuery,
  useLazyGetBlockedPartnerUsersQuery,
  useLazyGetPartnerUsersQuery,
  useUnblockPartnerUserMutation,
} = userApi;
