import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DocumentDTO } from 'constants/TypeConstants';
import { TAG_ARCHIVED_PLACES } from 'services/TagsConstants';
import { store } from 'store/store';

import { createQueryString } from '../../../utils/helpers';
import { Place } from './createPlace';
import { PlaceParameters, Places } from './getPlaces';

const BASE_URL = '/places/archived';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<Places, PlaceParameters>({
    async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
      const placeResponse = await fetchWithBQ(createQueryString(_arg, BASE_URL));
      const placesData = placeResponse.data as Places;
      const documents = placesData.content.map((place: Place) => {
        return place?.document ? place?.document : null;
      });
      const documentIds = documents.filter((id) => id !== null);
      const documentResponse =
        documentIds?.length > 0
          ? await fetchWithBQ(`/documents/download-links?ids=${documentIds}`)
          : null;
      const documentData = documentResponse?.data as DocumentDTO;
      const cities = store.getState().globalData.cities;
      const countries = store.getState().globalData.countries;
      const places = placesData.content.map((place: Place) => {
        const matchedCity = cities?.find((city) => city?.uuid === place?.city);
        const matchedCountry = countries?.find((country) => country?.uuid === place?.country);
        return {
          ...place,
          city: matchedCity?.name,
          country: matchedCountry?.shortName,
          document: documentData ? documentData[place?.document] : null,
          address: `${place?.street} ${place?.streetNumber}, ${matchedCity?.name}`,
        };
      });
      return {
        data: {
          content: places,
          totalElements: placesData?.totalElements,
          totalPages: placesData?.totalPages,
        },
      };
    },
    providesTags: [TAG_ARCHIVED_PLACES],
  });
