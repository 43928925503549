import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.query<CompanyStatisticsResponse, CompanyStatisticsParameters>({
    query: ({ from, id, to }) => `/statistics/company/${id}?from=${from}&to=${to}`,
    transformResponse: (response: CompanyStatisticsResponse) => ({
      groupInterval: response.groupInterval,
      numberOfOrdersPerPlace: response.numberOfOrdersPerPlace,
      totalOrdersCount: response.totalOrdersCount,
      placesRevenue: response.placesRevenue,
      companyRevenueGross: response.companyRevenueGross,
      companyRevenueNet: response.companyRevenueNet,
    }),
  });

export type CompanyStatisticsParameters = {
  from?: string;
  id?: string;
  to?: string;
};

export type NumberOfOrdersPerPlace = {
  count: number;
  name: string;
};

export type PlacesRevenue = {
  gross: number;
  name: string;
  net: number;
};

export type CompanyStatisticsResponse = {
  companyRevenueGross: number;
  companyRevenueNet: number;
  groupInterval: string;
  numberOfOrdersPerPlace: NumberOfOrdersPerPlace[];
  placesRevenue: PlacesRevenue[];
  totalOrdersCount: number;
};
