import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  oldPassword: {
    isRequired: true,
    password: true,
    min: 8,
  },
  newPassword: {
    isRequired: true,
    password: true,
    min: 8,
  },
};
