import { ACButton } from '@antech-consulting/components';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from '../../../../constants/Errors/ErrorTypes';
import { style } from '../../../../constants/StyleConstants';
import { useForm } from '../../../../hooks/useForm';
import { transformRolesToSnakeCase, trimObjectValues } from '../../../../utils/helpers';
import { FormDataEmployeeType, formDataInitialStateEmployee } from '../../constants/FormConstants';
import { placeEmployeeSchema } from '../../constants/PlaceSchema';
import { useUpdatePlaceEmployeeMutation } from '../../services';
import BaseForm from '../PlaceEmployees/BaseForm';
import styles from '../PlaceEmployees/PlaceEmployees.module.scss';

export type SelectedEmployeeType = {
  email: string;
  role: string;
};

export type EditEmployeeProps = {
  editEmployee?: boolean;
  placeId: string;
  selectedEmployee: SelectedEmployeeType;
  setEditEmployee: (boolean: boolean) => void;
};

const EditPlaceEmployee = ({
  editEmployee,
  placeId,
  selectedEmployee,
  setEditEmployee,
}: EditEmployeeProps) => {
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();
  const [updateEmployee] = useUpdatePlaceEmployeeMutation();
  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataEmployeeType>(formDataInitialStateEmployee, placeEmployeeSchema);
  const onDiscard = () => {
    setEditEmployee(false);
  };
  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataEmployeeType>(formData);
    const editedEmployee = { ...trimmedData, role: transformRolesToSnakeCase(formData?.role) };
    try {
      await updateEmployee({ id: placeId, employee: editedEmployee }).unwrap();
      setEditEmployee(false);
      displaySuccess({ key: 'place.updatePlaceEmployee' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      setEditEmployee(false);
      displayError({ key: code });
    }
  };

  useEffect(() => {
    setFormData(selectedEmployee);
  }, [selectedEmployee]);

  return (
    <>
      <BaseForm
        editEmployee={editEmployee}
        errors={errors}
        formData={formData}
        handleChange={handleChangeFormData}
      />
      <div className={styles.buttonsWrapper}>
        <ACButton
          dataTestid='buttonSubmitEmployee'
          fullWidth
          onClick={() => handleSubmit(onSubmit)}
          style={style.Button}
          type='submit'
        >
          {t('footer.saveButton')}
        </ACButton>
        <ACButton
          dataTestid='buttonEmployeeDiscard'
          fullWidth
          onClick={onDiscard}
          style={style.ACButton}
          type='button'
        >
          {t('place.discard')}
        </ACButton>
      </div>
    </>
  );
};

export default EditPlaceEmployee;
