import { ACButton } from '@antech-consulting/components';
import {
  authDescriptionRegister,
  authIcon,
  authTitleRegister,
} from 'features/auth/constants/StyleConstants';
import { useResendVerifyEmailMutation } from 'features/auth/services';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { SmsTracking } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ErrorMessage } from '../../../../constants/Errors/ErrorTypes';
import { style } from '../../../../constants/StyleConstants';
import styles from './RegistrationSuccess.module.scss';

export type UserData = {
  email?: string;
  password?: string;
};

const RegistrationSuccess = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { displaySuccess, displayWarning } = useDisplayNotification();
  const [resendVerifyEmail] = useResendVerifyEmailMutation();

  const userDataLogin = localStorage.getItem('LoginUserData');
  let userLogin;
  const userDataRegistration = localStorage.getItem('RegistrationUserData');
  let userRegistration;
  if (userDataLogin) {
    userLogin = JSON.parse(userDataLogin) as UserData;
  } else if (userDataRegistration) {
    userRegistration = JSON.parse(userDataRegistration) as UserData;
  }

  const verifyEmailDisplay = location.pathname.includes('verify-email');

  const titleDisplay = verifyEmailDisplay
    ? t('registrationForm.titleEmailVerify')
    : t('registrationForm.titleSuccess');

  const descriptionDisplay = verifyEmailDisplay
    ? t('registrationForm.descriptionEmailVerify')
    : t('registrationForm.descriptionSuccess');

  const resendVerificationEmail = async () => {
    try {
      await resendVerifyEmail({
        email: userLogin ? userLogin?.email : userRegistration?.email,
      }).unwrap();
      displaySuccess({ key: 'resendEmail.resendEmail' });
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayWarning({ key: code });
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.image}>
        <SmsTracking style={authIcon} />
      </div>
      <h3 style={authTitleRegister}>{titleDisplay}</h3>
      <p style={authDescriptionRegister}>{descriptionDisplay}</p>
      <ACButton
        className={styles.buttonWrapper}
        dataTestid='ButtonResendVerificationEmail'
        fullWidth
        onClick={resendVerificationEmail}
        style={style.Button}
      >
        {t('verifyEmail.buttonResendEmail')}
      </ACButton>
    </div>
  );
};

export default RegistrationSuccess;
