import { ACSpinner } from '@antech-consulting/components';
import FormStickyFooter from 'components/FormStickyFooter/FormStickyFooter';
import { validationSchema } from 'features/inventory/constants/InventorySchema';
import { InventoryDTO } from 'features/inventory/services/createInventory';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { useForm } from 'hooks/useForm';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { trimObjectValues } from 'utils/helpers';

import PageHeader from '../../components/PageHeader/PageHeader';
import { ErrorMessage } from '../../constants/Errors/ErrorTypes';
import BaseForm from '../../features/inventory/components/BaseForm/BaseForm';
import {
  formDataInitialState,
  FormDataType,
} from '../../features/inventory/constants/FormConstants';
import useInventoriesForm, {
  transformToDTOFormat,
} from '../../features/inventory/hooks/useInventoryForm';
import { useCreateInventoryMutation } from '../../features/inventory/services';
import styles from './AddInventory.module.scss';

const AddInventory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayError, displaySuccess } = useDisplayNotification();

  const [createInventory, { isLoading }] = useCreateInventoryMutation();

  const { errors, formData, handleChangeFormData, handleSubmit, setFormData } =
    useForm<FormDataType>(formDataInitialState, validationSchema);

  const { countriesOptions, placeOptions } = useInventoriesForm(formData, setFormData);

  const onSubmit = async () => {
    const trimmedData = trimObjectValues<FormDataType>(formData);
    const inventoryAddDTO: InventoryDTO = transformToDTOFormat({ ...trimmedData });
    delete inventoryAddDTO.uuid;
    try {
      await createInventory(inventoryAddDTO).unwrap();
      displaySuccess({ key: 'inventory.addInventory' });
      navigate('/inventories');
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    } catch (error) {
      const {
        data: { code },
      } = error as ErrorMessage;
      displayError({ key: code });
    }
  };

  const onDiscard = useCallback(() => {
    navigate('/inventories');
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }, []);

  return !isLoading ? (
    <>
      <PageHeader primaryText={t('inventory.titleCreate')} />
      <div className={styles.wrapper}>
        <BaseForm
          countriesOptions={countriesOptions}
          errors={errors}
          formData={formData}
          handleChange={handleChangeFormData}
          placesOptions={placeOptions}
          setFormData={setFormData}
        />
      </div>
      <FormStickyFooter
        onDiscard={onDiscard}
        onDiscardText={t('footer.discardButton')}
        onSubmit={() => handleSubmit(onSubmit)}
        onSubmitText={t('footer.saveButton')}
      />
    </>
  ) : (
    <ACSpinner background='var(--text-secondary)' size='xs' />
  );
};

export default AddInventory;
