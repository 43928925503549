import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_ORDER } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, RejectParameteres>({
    query: ({ id, message }) => ({
      url: `/order/${id}/reject`,
      method: 'POST',
      body: { message },
    }),
    invalidatesTags: [TAG_ORDER],
  });

export type RejectParameteres = {
  id: string;
  message: string;
};
