import { FormValidationType } from '../../../hooks/useForm';

export const validationSchema: FormValidationType = {
  name: {
    isRequired: true,
    max: 200,
  },
  street: {
    isRequired: true,
    max: 100,
  },
  streetNumber: {
    isRequired: true,
    max: 20,
  },
  city: {
    isRequired: true,
    max: 50,
  },
  floor: {
    isRequired: false,
    regexValidation: /^(?:[1-9]\d*|)$/,
    formatPlaceholder: 'Optional or number greather than 0',
  },
  apartment: {
    isRequired: false,
    regexValidation: /^(?:[1-9]\d*|)$/,
    formatPlaceholder: 'Optional or number greather than 0',
  },
  zipCode: {
    isRequired: true,
    max: 20,
  },
  country: {
    isRequired: true,
  },
  vatNumber: {
    isRequired: true,
  },
  idNumber: {
    isRequired: true,
    max: 20,
  },
};
