import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  TAG_PRODUCT_CATEGORY_REQUEST,
  TAG_PRODUCT_CATEGORY_REQUESTS,
} from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, string>({
    query: (id) => ({
      url: `/product-category-request/${id}/reject`,
      method: 'POST',
    }),
    invalidatesTags: [TAG_PRODUCT_CATEGORY_REQUESTS, TAG_PRODUCT_CATEGORY_REQUEST],
  });
