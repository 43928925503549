import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_COMPANY_REQUESTS } from 'services/TagsConstants';

export default (build: EndpointBuilder<BaseQueryFn, string, string>) =>
  build.mutation<void, NewCompanyRequest>({
    query: (credentials) => ({
      url: '/company-requests',
      method: 'POST',
      body: credentials,
    }),
    invalidatesTags: [TAG_COMPANY_REQUESTS],
  });

export type NewCompanyRequest = {
  apartment?: number | string;
  city: string;
  country: string;
  floor?: number | string;
  idNumber: string;
  name: string;
  street: string;
  streetNumber: string;
  uuid?: string;
  vatNumber: string;
  zipCode: string;
};
